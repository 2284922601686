'use strict';

angular.module('app.controllers')
    .controller(
        'TrackReportsMissingFoodReportsCtrl',
        function TrackReportsMissingFoodReportsCtrl($scope, $http, $notification,
                                                    $modal, session, $location,
                                                    suggest, debounce, commenter,
                                                    $q, confirm, ngTableParams, moment, $filter) {
            let vm = $scope.vm = this;

            vm.reports = {
                current: $location.search().type || 'guessed-foods-track-users',
                types:   [
                    {
                        name: 'Zero Food Queries',
                        id:   'zero-food-queries'
                    },
                    {
                        name: 'Guessed Foods Report (Track Users)',
                        id:   'guessed-foods-track-users'
                    },
                    {
                        name: 'Guessed Foods Report (Twitter Users)',
                        id:   'guessed-foods-twitter-users'
                    }
                ]
            };

            $scope.$watch('vm.reports.current', type => {
                $location.search('type', type === 'guessed-foods-track-users' ? null : type);
            });

            vm.loadReport = function (reset = true) {
                vm.queries = [];
                $http.get('/api/track-reports/missing-food-reports', {params: {type: vm.reports.current}})
                    .success((queries) => {
                        vm.queries = queries;
                        if (reset) {
                            vm.tableParams.sorting({});
                            vm.tableParams.page(1);
                        }
                        vm.tableParams.reload();

                    })
                    .error((/*error*/) => $notification.error('Queries load failed'));
            };


            vm.resolve = function (query, hidePermanently = false) {
                let flow = $q.resolve();

                if (hidePermanently) {
                    flow = confirm(`Are you sure you want to permanently hide "${query.query}" from this report?`);
                }

                flow.then(() => {
                    if (hidePermanently) {
                        _.remove(
                            vm.queries,
                            value => value.query.toLowerCase().trim() === query.query.toLowerCase().trim()
                        )
                    } else {
                        _.pull(vm.queries, query);
                    }

                    vm.tableParams.reload();

                    $http.post(`/api/track-reports/missing-food-reports/${query.id}/resolve`, {hidePermanently})
                        .success(function () {
                            $notification.info(`Query "${query.query}" resolved`);
                        })
                        .error(function () {
                            $notification.error(`Query "${query.query}" not resolved`);
                        });
                });


            };

            vm.sendEmail = function (query) {
                $modal.open({
                    animation:   true,
                    templateUrl: 'views/track-reports/send-email-modal.html',
                    controller:  function ($scope, $modalInstance, query) {
                        let vm = $scope.vm = this;
                        let queryDate = moment(query.created_at).format('MM/DD/YYYY');
                        let name = session.profile.public_name || session.profile.name;

                        vm.query = query;

                        vm.email = {
                            entity:  'natural_queries',
                            id:      query.id,
                            to:      {
                                email: query.email,
                                name:  query.fullname
                            },
                            subject: `"${query.query}" has been added to Nutritionix Track`,
                            message: `Hi ${query.first_name},` +
                                     '\n\n' +
                                     `I wanted to write and let you know that when you recently tried to log "${query.query}" ` +
                                     `on ${queryDate} it was not yet added to our system. ` +
                                     `I went ahead and added "${query.query}" to our system, ` +
                                     'and it should be updated in the Track app in the next hour. ' +
                                     'I apologize for any inconvenience this caused, ' +
                                     'and please do not hesitate to let us know if you have any more feedback.' +
                                     '\n\n' +
                                     'Sincerely,' +
                                     '\n' +
                                     `${name}` +
                                     '\n' +
                                     'www.nutritionix.com'
                        };

                        vm.send = function () {
                            vm.send.$busy = true;
                            $http.post(`/api/track-reports/send-email`, vm.email)
                                .success(function () {
                                    query.hive2_email_sent = true;
                                    $notification.info('Email sent');
                                    $modalInstance.close();
                                })
                                .error(function () {
                                    $notification.error('Email not sent');
                                })
                                .finally(() => vm.send.$busy = false);
                        };

                        vm.close = () => $modalInstance.dismiss();
                    },
                    size:        'lg',
                    resolve:     {
                        query: () => query
                    }
                });
            };

            vm.ignore = function (query) {
                _.remove(
                    vm.queries,
                    value => value.guessed_foods.toLowerCase().trim() === query.guessed_foods.toLowerCase().trim()
                );

                vm.tableParams.reload();

                $http.post('/api/track-reports/missing-food-reports/ignore', {phrase: query.guessed_foods});
            };

            vm.createTag = function (query) {
                var modalInstance = $modal.open({
                    animation:   true,
                    templateUrl: 'track-reports/missing-food-reports/create-tag.html',
                    controller:  function ($scope, $modalInstance) {
                        $scope.tag = {
                            name:      query.guessed_foods || query.query,
                            parent_id: 0
                        };

                        $scope.suggestTags = suggest.tags;

                        $scope.setParentTag = function (tag) {
                            $scope.tag.parent_id = tag && tag.id || 0;
                        };

                        $scope.clearParentTag = function () {
                            $scope.tag.parent_id = 0;
                            $scope.tagSuggestionQuery = '';
                        };

                        $scope.close = function () {
                            $modalInstance.dismiss('cancel');
                        };

                        $scope.save = function () {
                            $http.post('/api/tags/manage', $scope.tag)
                                .success(function () {
                                    $notification.info('Tag Created');
                                    $modalInstance.close();
                                })
                                .error(function () {
                                    $notification.error('Tag Not Created');
                                });
                        };

                        $scope.validate = function () {
                            if ($scope.tag.name && $scope.tagForm) {
                                $scope.validate.$busy = true;
                                $scope.tagForm.tagName.$setValidity('available', true);
                                $scope.validate.execute();
                            }
                        };

                        $scope.validate.execute = debounce(function () {
                            if (!$scope.tag.name) {
                                $scope.tagForm.tagName.$setValidity('available', true);
                                $scope.validate.$busy = false;
                                return;
                            }

                            $http.get('/api/tags/find', {params: {name: $scope.tag.name}})
                                .success(function (found) {
                                    if ($scope.tagForm) {
                                        $scope.tagForm.tagName.$setValidity('available', !found);
                                    }
                                })
                                .error(function () {
                                    $notification.error('Validation failed');
                                })
                                .finally(function () {
                                    $scope.validate.$busy = false;
                                });
                        }, 500);

                        $scope.$watch(function () {
                            return $scope.tagForm && $scope.tag.name;
                        }, function () {
                            $scope.validate();
                        });
                    },
                    size:        'md'
                });
            };

            vm.assignPhrase = function (query) {
                var modalInstance = $modal.open({
                    animation:   true,
                    templateUrl: 'track-reports/missing-food-reports/assign-phrase.html',
                    controller:  function ($scope, $modalInstance, Tag) {
                        let vm = $scope.vm = this;
                        let phrase = $scope.phrase = {
                            id:      query.phrase_id,
                            is_typo: query.phrase_is_typo,
                            text:    query.guessed_foods || query.query
                        };

                        $scope.mode = 'phrase';

                        $scope.suggest = suggest;

                        vm.phraseSuggestionQuery = phrase.text;

                        $scope.suggestPhrases = function () {
                            if (vm.phraseSuggestionQuery) {
                                suggest.similar_phrases(vm.phraseSuggestionQuery)
                                    .then(function (phrases) {
                                        $scope.phrases = phrases;
                                    });
                            } else {
                                $scope.phrases = [];
                            }
                        };

                        $scope.suggestPhrases();

                        $scope.setTag = function (tag, save = false) {
                            if (!angular.isObject(tag)) {
                                tag = {id: tag};
                            }

                            $scope.phrase.exact_match_id = tag && tag.id || null;
                            $scope.phrase.tag_id = tag && tag.id || null;
                            $scope.phrase.exact_match_type = tag && 3 || null;

                            if (save) {
                                $scope.save();
                            }
                        };

                        $scope.clearTag = function () {
                            $scope.phrase.exact_match_id = null;
                            $scope.phrase.tag_id = null;
                            $scope.phrase.exact_match_type = null;
                        };

                        $scope.close = function () {
                            $modalInstance.dismiss('cancel');
                        };

                        $scope.save = function () {
                            Tag.phrases.save($scope.phrase).$promise
                                .then(function () {
                                    $notification.info('Phrase saved');
                                    $modalInstance.close();
                                })
                                .catch(function (response) {
                                    $notification.error('Phrase not updated: ' + $filter('readError')(response.data));
                                });
                        };

                    },
                    size:        'md'
                });

                modalInstance.result.then(() => {_.remove(vm.queries, query);});
            };

            $scope.addNotes = function (query) {
                var dialog = commenter.open(query.notes);
                dialog.result.then(function (notes) {
                    query.notes = notes;
                });

                dialog.submit('nutritionix-track-2.natural_queries', 'id', query.id);
            };

            vm.tableParams = new ngTableParams(
                {
                    page:    $location.search().page || 1,            // show first page
                    count:   25,           // count per page
                    sorting: (function () {
                        let sort = $location.search().sort;
                        if (sort) {
                            sort = sort.split('.');

                            let result = {};
                            result[sort[0]] = sort[1];

                            return result;
                        }

                        return {};
                    }())
                },
                {
                    counts:  [],
                    total:   0, // length of data
                    getData: function ($defer, params) {
                        $location.search('page', params.page() > 1 ? params.page() : null);

                        let data = vm.queries;

                        params.total(data.length);

                        if (_.keys(params.sorting()).length) {
                            let [sortingAttribute, sortingDirection] = _.pairs(params.sorting())[0];

                            $location.search('sort', `${sortingAttribute}.${sortingDirection}`);

                            data = _.sortByOrder(data, [sortingAttribute], [sortingDirection]);
                        } else {
                            $location.search('sort', null);
                        }
                        data = data.slice((params.page() - 1) * params.count(), params.page() * params.count() - 1);

                        $defer.resolve(data);
                    }
                }
            );

            vm.loadReport(false);
        }
    );
