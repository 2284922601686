(function () {
  'use strict';

  const moduleName     = 'bot',
        controllerName = 'ViewConversationCtrl';

  const moduleFullName = `app.modules.${moduleName}`;

  angular.module(moduleFullName)
    .controller(`${moduleFullName}.${controllerName}`, ViewConversationCtrl);

  /*@ngInject*/
  function ViewConversationCtrl($log, $http, $notification, $state, $filter) {
    $log.debug(`[${moduleName}] Controller "${controllerName}" launched`);

    const vm = this;

    $http.get(`/api/bot/conversations/${$state.params.id}`)
      .then(response => vm.conversation = response.data)
      .catch(response => $notification.error(
        'Failed to load conversation: ' + $filter('readError')(response.data)
      ));

    vm.archive = (is_archived = 1) => {
      $http.put(`/api/bot/conversations/${$state.params.id}`, {is_archived})
        .then(response => {
          vm.conversation = response.data;
          if(is_archived){
            $state.go('app.bot.conversations.list');
          }
        })
        .catch(response => $notification.error(
          'Failed to change conversation state: ' + $filter('readError')(response.data)
        ));
    };
  }
}());
