'use strict';

angular.module('app.services')
  .factory('exifStripper', function exifStripperFactory() {
    function _removeExif(imageArrayBuffer, dv) {
      let offset = 0, recess = 0;
      let pieces             = [];
      let i                  = 0;
      if (dv.getUint16(offset) === 0xffd8) {
        offset += 2;
        let app1 = dv.getUint16(offset);
        offset += 2;
        while (offset < dv.byteLength) {
          if (app1 === 0xffe1) {
            pieces[i] = {recess: recess, offset: offset - 2};
            recess    = offset + dv.getUint16(offset);
            i++;
          } else if (app1 === 0xffda) {
            break;
          }
          offset += dv.getUint16(offset);
          app1 = dv.getUint16(offset);
          offset += 2;
        }
        if (pieces.length > 0) {
          let newPieces = [];
          pieces.forEach(function (v) {
            newPieces.push(imageArrayBuffer.slice(v.recess, v.offset));
          }, this);
          newPieces.push(imageArrayBuffer.slice(recess));
          return new Blob(newPieces, {type: 'image/jpeg'});
        }
      }

      return new Blob([imageArrayBuffer], {type: 'image/jpeg'});
    }

    return {
      strip: function (imageUrl) {
        return new Promise((resolve, reject) => {
          var xhr  = new XMLHttpRequest();
          imageUrl = imageUrl.replace(/^http:\/\//i, 'https://');

          const join = imageUrl.indexOf('?') < 0 ? '?' : '&';
          imageUrl += join + Date.now()

          xhr.open("GET", imageUrl, true);
          xhr.responseType = "arraybuffer";

          xhr.onload = function (e) {
            const dataView = new DataView(this.response);
            const blob     = _removeExif(this.response, dataView);

            const fileReader  = new FileReader();
            fileReader.onload = (e) => {
              resolve({url: e.target.result});
            }
            fileReader.readAsDataURL(blob);
          }

          xhr.send();
        });
      }
    }
  });
