(function () {
  'use strict';

  const moduleName     = 'usda',
        controllerName = 'ListCtrl';

  const moduleFullName = `app.modules.${moduleName}`;

  angular.module(moduleFullName)
    .controller(`${moduleFullName}.${controllerName}`, UsdaListCtrl);

  /*@ngInject*/
  function UsdaListCtrl($log, $location, $scope, $state, $http, ngTableParams, debounce) {
    $log.debug(`[${moduleName}] Controller "${controllerName}" launched`);

    const vm = this;

    const defaultOrderBy = 'ndb_no.asc'
    const urlParams      = $location.search()

    vm.search = {
      term:     urlParams.q || '',
      withTags: !!urlParams.withTags,
      perform:  debounce(() => {
        vm.tableParams.page(1);
        vm.tableParams.reload();
      }, 500)
    };

    vm.tableParams = new ngTableParams(
      {
        page:    +urlParams.page || 1,
        count:   25,
        sorting: Object.fromEntries([(urlParams.orderBy || defaultOrderBy).split('.')]),
      },
      {
        counts: [],
        total:  0,
        async getData($defer, params) {
          const orderBy = Object.entries(params.sorting())[0].join('.');
          $location.search('orderBy', orderBy !== defaultOrderBy ? orderBy : null);

          $location.search('page', vm.tableParams.page() > 1 ? vm.tableParams.page() : null);
          $location.search('q', vm.search.term || null);
          $location.search('withTags', vm.search.withTags ? true : null);

          const {data} = await $http.get('/api/usda', {
            params: {
              search:   vm.search.term,
              withTags: vm.search.withTags,

              orderBy,

              limit:  params.count(),
              offset: (params.page() - 1) * params.count(),
            }
          });

          params.total(data.count);
          $defer.resolve(data.results);
        }
      });
  }
}());
