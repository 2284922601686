(function () {
  'use strict';

  const moduleName     = 'ingredient-learning',
        controllerName = 'ListIngredientsCtrl';

  const moduleFullName = `app.modules.${moduleName}`;

  angular.module(moduleFullName)
    .controller(`${moduleFullName}.${controllerName}`, ListIngredientsCtrl);

  /*@ngInject*/
  function ListIngredientsCtrl($log, $scope, $http, $notification, $filter, ngTableParams,
                               $location, debounce, $modal, confirm, $sessionStorage,
                               ingredientFlagReasons, grouping, questions, $q, utils, $state) {
    $log.debug(`[${moduleName}] Controller "${controllerName}" launched`);

    const vm = this;

    $sessionStorage.ingredientLearningListState = null;

    vm.flagReasons = ingredientFlagReasons;
    vm.questions   = questions;

    vm.currentVersion = _.max(questions.map(q => q.id));

    vm.grouping           = grouping;
    vm.groupingFilterData = $q.defer();
    vm.groupingFilterData.resolve(grouping.map(g => ({id: g.id, title: g.name})));

    vm.completedFilterData = $q.defer();
    vm.completedFilterData.resolve(
      [{id: 0, title: 0}]
        .concat(
          _.sortBy(questions.map(q => ({id: q.id, title: q.id})), 'id')
        )
    );

    const debouncedReload = debounce(function () {
      vm.tableParams.reload();
    }, 1000);

    vm.results = [];

    vm.getCompleteLevelHint = (level) => {
      return level ? _.filter(vm.questions, q => q.id <= level).map(q => q.text).join(', ') : 'None';
    };

    vm.removeResult = function (item, reloadAfter = Promise.resolve()) {
      _.pull(vm.results, item);
      reloadAfter.then(debouncedReload);
    };

    vm.addIngredient = () => {
      const modalInstance = $modal.open({
        size:        'md',
        animation:   false,
        templateUrl: utils.templateUrl('add-ingredient.modal'),
        controller:  utils.controller('AddIngredientCtrl')
      });

      modalInstance.result.then(ingredient => vm.openIngredient(ingredient.id));
    };

    vm.flagIngredient = (ingredient) => {
      const initialFlaggedType = ingredient.flagged_type;

      const modalInstance = $modal.open({
        size:        'md',
        animation:   false,
        templateUrl: utils.templateUrl('edit-ingredient.flag.modal'),
        controller:  function ($scope, $modalInstance) {
          const vm = $scope.vm = this;

          vm.reasons = ingredientFlagReasons;

          vm.ingredient    = ingredient;
          vm.unflagEnabled = vm.ingredient.flagged_type;

          vm.save = () => {
            vm.save.$busy = true;

            $http.put(`/api/ingredient-learning/ingredients/${ingredient.id}`, ingredient)
              .then(() => $modalInstance.close(vm.ingredient))
              .finally(() => vm.save.$busy = false);
          };

          vm.unflag = () => {
            _.forEach(ingredient, (value, key) => {
              if (key.substr(0, 8) === 'flagged_') {
                ingredient[key] = null;
              }
            });

            vm.save();
          };

          vm.close = () => {
            $modalInstance.dismiss();
          };
        }
      });

      modalInstance.result.then(ingredient => {
        if (ingredient.flagged_type && !initialFlaggedType && !vm.search.display_flagged) {
          vm.removeResult(ingredient);
        }
      });
    };

    vm.deleteIngredient = (ingredient) => {
      confirm(`Are you sure you want to delete ingredient "${ingredient.name}"`).then(() => {

        const reloadAfter = $http.delete(`/api/ingredient-learning/ingredients/${ingredient.id}`)
          .then(response => {
            $notification.success(response.data.message);
            debouncedReload();
          })
          .catch(response => {
            $notification.error($filter('readError')(response.data));
          });

        vm.removeResult(ingredient, reloadAfter);
      });
    };

    vm.openIngredient = (id, storeState = true) => {
      if(storeState) {
        vm.storeListState();
      }
      $state.go(utils.state('edit-ingredient'), {id});
    };

    vm.storeListState = () => {
      $sessionStorage.ingredientLearningListState = $location.url();
    };

    vm.data = [];

    vm.search = {
      exact:           false,
      term:            $location.search().q || '',
      display_flagged: +$location.search().display_flagged || 0,
      perform:         function (exact = false) {
        this.exact = exact;
        vm.tableParams.page(1);
        vm.tableParams.reload();
      }
    };

    const defaultOrder = 'is_completed.asc';
    $scope.params      = vm.tableParams = new ngTableParams(
      {
        page:    $location.search().page || 1,            // show first page
        count:   25,           // count per page
        sorting: (function () {
          let orderBy = $location.search().orderBy || defaultOrder;
          if (orderBy) {
            orderBy = orderBy.split('.');

            let result         = {};
            result[orderBy[0]] = orderBy[1];

            return result;
          }
        }()),
        filter:  {
          grouping:     +$location.search().grouping || '',
          is_completed: !_.isUndefined($location.search().is_completed) ? +$location.search().is_completed : ''
        }
      },
      {
        counts:  [],
        total:   0, // length of data
        getData: function ($defer, params) {
          $location.search('page', params.page() > 1 ? params.page() : null);

          $location.search('q', vm.search.term || null);
          $location.search('display_flagged', vm.search.display_flagged || null);

          let orderBy = null;
          if (_.keys(params.sorting()).length) {
            let [sortingAttribute, sortingDirection] = _.pairs(params.sorting())[0];
            orderBy                                  = `${sortingAttribute}.${sortingDirection}`;
          }

          $location.search('orderBy', orderBy === defaultOrder ? null : orderBy);

          const filter = params.filter();

          $location.search('grouping', filter.grouping || null);
          $location.search('is_completed', filter.is_completed !== '' ? filter.is_completed : null);

          $http.get('/api/ingredient-learning/ingredients', {
            params: {
              limit:           params.count(),
              offset:          (params.page() - 1) * params.count(),
              q:               vm.search.term || undefined,
              grouping:        filter.grouping || undefined,
              is_completed:    filter.is_completed !== '' ? filter.is_completed : undefined,
              display_flagged: vm.search.display_flagged,
              orderBy:         orderBy,
              exact:           vm.search.exact ? '1' : '0'
            }
          })
            .then(response => {
              params.total(response.data.total);
              $defer.resolve(vm.data = response.data.ingredients);

              if (vm.search.term && vm.data.length === 1) {
                vm.openIngredient(vm.data[0].id, false);
              }
            })
            .catch(response => {
              $notification.error(
                'Failed to load ingredients: ' + $filter('readError')(response.data)
              );

              $defer.reject();
            });
        }
      }
    );
  }
}());
