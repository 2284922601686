(function () {
  'use strict';

  const moduleName     = 'billing',
        controllerName = 'StripeUsersListCtrl';

  const moduleFullName = `app.modules.${moduleName}`;

  angular.module(moduleFullName)
    .controller(`${moduleFullName}.${controllerName}`, StripeUsersListCtrl);

  /*@ngInject*/
  function StripeUsersListCtrl($log, $http, $notification, $filter, ngTableParams, $location, $modal, $timeout) {
    $log.debug(`[${moduleName}] Controller "${controllerName}" launched`);

    const vm = this;

    vm.data = [];

    vm.search = {
      term:    $location.search().q || '',
      perform: function () {
        vm.tableParams.page(1);
        vm.tableParams.reload();
      }
    };

    vm.tableParams = new ngTableParams(
      {
        page:    $location.search().page || 1,            // show first page
        count:   25,           // count per page
        sorting: (function () {
          let sort = $location.search().sort;
          if (sort) {
            sort = sort.split('.');

            let result      = {};
            result[sort[0]] = sort[1];

            return result;
          }

          return {'created': 'desc'};
        }())
      },
      {
        counts:  [],
        total:   0, // length of data
        getData: function ($defer, params) {
          $location.search('page', params.page() > 1 ? params.page() : null);

          let data = vm.data;
          if (vm.search.term) {
            $location.search('q', vm.search.term);
            data = $filter('filter')(data, vm.search.term);
          } else {
            $location.search('q', null);
          }

          params.total(data.length);

          let [sortingAttribute, sortingDirection] = _.pairs(params.sorting())[0];
          let sort                                 = `${sortingAttribute}.${sortingDirection}`

          $location.search('sort', sort === 'created.desc' ? null : sort);

          data = _.sortByOrder(data, [sortingAttribute], [sortingDirection]);
          data = data.slice((params.page() - 1) * params.count(), params.page() * params.count() - 1);

          $defer.resolve(data);
        }
      }
    );

    vm.generateLink = (user) => {
      $modal.open({
        size:        'md',
        animation:   false,
        templateUrl: '/modules/billing/stripe-users/billing.stripe-users.generate-link.modal.html',
        controller:  function ($scope, $modalInstance, tokenData) {
          const vm = $scope.vm = this;

          vm.user = user;
          vm.link = `https://nutritionix.com/customer/${user.id}?key=${tokenData.token}`;

          vm.close = () => {
            $modalInstance.dismiss();
          }
        },
        resolve:     {
          tokenData: $http => $http.post(`/api/billing/stripe-users/${user.id}/generate-token`)
            .then(response => response.data)
            .catch(error => $notification.error(`Link could not be generated: ${$filter('readError')(error.data)}. Please try again`))
        }
      });
    };

    vm.load = () => {
      return $http.get('/api/billing/stripe-users')
        .then(response => {
          vm.data = response.data;
          vm.tableParams.reload();
        })
        .catch(response => $notification.error(
          'Failed to load stripe users: ' + $filter('readError')(response.data)
        ));
    };

    vm.sync = () => {
      vm.sync.$busy = true;
      return $http.put('/api/billing/stripe-users/sync')
        .then(response => {
          return $timeout(() => vm.load(), 30000);
        })
        .catch(response => $notification.error(
          'Failed to sync stripe users: ' + $filter('readError')(response.data)
        ))
        .finally(() => vm.sync.$busy = false);
    };

    vm.load();
  }
}());
