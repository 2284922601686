(()=>{
  'use strict';

  angular.module('app.directives')
    .directive('ngScope',function(){
      return {
        scope: true,
        link: function(scope, elem, attrs){
          // do some scope / element stuff here
        }
      }
    });
})()
