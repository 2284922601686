'use strict';
angular.module('app.controllers')
    .controller('S3Explorer', [
        '$scope', 'S3', 'ngTableParams', '$filter','$location',
        function ($scope, S3, NgTableParams, $filter, $location) {

            console.log($location);

            $scope.search = {
                params: {
                    Bucket: $location.search().Bucket,
                    Delimiter: '/',
                    // EncodingType: 'url',
                    MaxKeys: 1000,
                    Prefix: $location.search().Prefix,
                },
                execute: function () {
                    $scope.tableParams.page(1);
                    $scope.tableParams.reload();
                }
            };

            $scope.reset = function(){
                $scope.search.params.Bucket = null;
                $scope.search.params.Prefix = null;
                $scope.search.execute();
            };

            $scope.back = function() {

                if (!$scope.search.params.Prefix) {
                    $scope.search.params.Bucket = null;
                    $scope.search.execute();
                    return;
                }

                var pos = $scope.search.params.Prefix.split('/');
                var current = pos.pop();

                if (current === '') {
                    pos.pop();
                }

                if (pos.length === 0) {
                    $scope.search.params.Prefix = null;
                } else if (pos.length === 1) {
                    $scope.search.params.Prefix = pos.join('/')+'/';
                } else {
                    $scope.search.params.Prefix = pos.join('/');
                }

                $scope.search.execute();
            };

            $scope.forward = function(){
                console.log('need to implement navigation');
            };

            $scope.initiateDownload = function(s3Obj) {
                var ext = s3Obj.Key.match(/(png|jpg|jpeg|gif)$/);
                var isImage = ext !== null;
                var contentType = isImage ? 'image/'+ext[0] : void 0;

                S3.sign().query({
                    Bucket: $scope.search.params.Bucket,
                    Key: s3Obj.Key,
                    ResponseContentType: contentType,
                    ResponseContentDisposition: isImage ? 'inline' : 'attachement'
                }).$promise.then(function(data){
                    if (isImage) {
                        window.open(data.url,'_blank');
                    } else {
                        window.location = data.url;
                    }

                });
            };


            $scope.deleteKey = function(s3Obj, $index) {
                S3.deleteObject().del({
                    Bucket: $scope.search.params.Bucket,
                    Key: s3Obj.Key
                }).$promise.then(function(){
                    $scope.tableParams.reload();
                    // $scope.$data.splice($index,1);
                }).catch(function(err){
                    console.warn(err);
                    alert('you do not have rights to delete objects');
                });
            };

            $scope.setBucket = function(s3Obj) {
                $scope.search.params.Bucket = s3Obj.Key;
                $scope.search.execute();
            };

            $scope.setPrefix = function(s3Obj) {
                $scope.search.params.Prefix = s3Obj.Key;
                $scope.search.execute();
            };

            $scope.tableParams = new NgTableParams(
                {
                    page:    1,            // show first page
                    count:   25,           // count per page
                    // sorting: {
                    //     Type: 'asc',
                    //     LastModified: 'desc'
                    // }
                },
                {
                    counts:  [],
                    total:   0, // length of data
                    getData: function ($defer, params) {

                        S3.query($scope.search.params).$promise.then(
                            function (results) {

                                $location.search('Bucket',$scope.search.params.Bucket);
                                $location.search('Prefix',$scope.search.params.Prefix);
                                var viewData = $filter('filter')(results, $scope.search.term);

                                $scope.tableParams.total(viewData.length);

                                $scope.tableParams.custom.pages = $scope.tableParams.generatePagesArray(
                                    $scope.tableParams.page(),
                                    $scope.tableParams.total(),
                                    $scope.tableParams.count()
                                );

                                angular.forEach($scope.tableParams.sorting(), function (direction, attribute) {
                                    viewData = $filter('orderBy')(viewData, attribute, direction === 'desc');
                                });

                                viewData = viewData.slice(
                                        (params.page() - 1) * params.count(),
                                        params.page() * params.count()
                                );


                                $defer.resolve(viewData);
                            }
                        );
                    }
                });
            $scope.tableParams.custom = {};

            $scope.min = function (a, b) {
                return Math.min(a, b);
            };
        }
    ]);