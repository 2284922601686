(function () {
  'use strict';

  const moduleName = 'force-transcription';

  const moduleFullName = `app.modules.${moduleName}`;

  const state = stateName => `app.${moduleName}` + (stateName ? `.${stateName}` : '');
  const templateUrl = viewName => `modules/tasks/${moduleName}/${moduleName}.${viewName}.html`;
  const controller = controllerName => `${moduleFullName}.${controllerName} as vm`;

  angular.module(moduleFullName, [])
    .config(function ($stateProvider) {
      $stateProvider
        .state(state(), {
          url:      `/admin/force-transcription`,
          abstract: true,
          template: `<div class="module_${moduleName.replace('.', '_')}" ui-view></div>`,
          data:     {}
        })
        .state(state('index'), {
          url:         '',
          controller:  controller('IndexCtrl'),
          templateUrl: templateUrl('index')
        });


      angular.injector(['ng']).get('$log')
        .debug(`[${moduleName}] Module configured`);
    })
    .run(function ($log) {
      $log.debug(`[${moduleName}] Module launched`);
    });
}());


