(function () {
  'use strict';

  const moduleName     = 'recommended-products',
        controllerName = 'ListCtrl';

  const moduleFullName = `app.modules.${moduleName}`;

  angular.module(moduleFullName)
    .controller(`${moduleFullName}.${controllerName}`, ListCtrl);

  /*@ngInject*/
  function ListCtrl($log, $http, $notification, $filter, ngTableParams, $location) {
    $log.debug(`[${moduleName}] Controller "${controllerName}" launched`);

    const vm = this;

    vm.data = [];

    vm.tableParams = new ngTableParams(
      {
        page:    $location.search().page || 1,            // show first page
        count:   25,           // count per page
        sorting: (function () {
          let sort = $location.search().sort;
          if (sort) {
            sort = sort.split('.');

            let result = {};
            result[sort[0]] = sort[1];

            return result;
          }

          return {};
        }())
      },
      {
        counts:  [],
        total:   0, // length of data
        getData: function ($defer, params) {
          $location.search('page', params.page() > 1 ? params.page() : null);

          let data = vm.data;

          params.total(data.length);

          if (_.keys(params.sorting()).length) {
            let [sortingAttribute, sortingDirection] = _.pairs(params.sorting())[0];

            $location.search('sort', `${sortingAttribute}.${sortingDirection}`);

            data = _.sortByOrder(data, [sortingAttribute], [sortingDirection]);
          } else {
            $location.search('sort', null);
          }
          data = data.slice((params.page() - 1) * params.count(), params.page() * params.count() - 1);

          $defer.resolve(data);
        }
      }
    );

    $http.get('/api/recommended-products')
      .then(response => {
        vm.data = response.data;
        vm.tableParams.reload();
      })
      .catch(response => $notification.error(
        'Failed to load products: ' + $filter('readError')(response.data)
      ));

    vm.rebuild = () => {
      $http.put('/api/recommended-products/publish')
        .then(() => $notification.info('Rebuild is scheduled'))
        .catch(response => $notification.error('Rebuild failed: ' + $filter('readError')(response.data)));
    }
  }
}());
