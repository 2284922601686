(function () {
  'use strict';

  angular.module('app')
    .controller('LocalesListCtrl', LocalesListCtrl);

  /*@ngInject*/
  function LocalesListCtrl($scope, $http, $notification, locales, $filter) {
    const vm   = $scope.vm = this;
    vm.locales = locales;

    $http.get('/api/locales', {params: {active: 0}})
      .then(response => vm.inactiveLocales = response.data);

    vm.toggleLocale = async (locale) => {
      try {
        const response = await $http.put(`/api/locales/${locale.id}`, {active: locale.active ? 0 : 1});
        locale.active  = response.data.active;
        _.remove(locale.active ? vm.inactiveLocales : vm.locales, locale);

        vm[locale.active ? 'locales' : 'inactiveLocales'].push(locale);

        vm.locales.sort((a, b) => a.id - b.id);
        vm.inactiveLocales.sort((a, b) => a.id - b.id);
      } catch (e) {
        $notification.error('Locale state change error: ' + $filter('readError')(e.data))
      }
    }

    vm.triggerExport = async (locale) => {
      try {
        await $http.post('/api/locales/common-export', {locale: locale.language_culture_name});
        $notification.info('Common foods export has been triggered and file will become available in a few minutes');
      } catch (e) {
        $notification.error('Export trigger failed: ' + $filter('readError')(e.data))
      }
    }
  }
}());
