(function () {
  'use strict';

  const moduleName     = 'ingredient-learning',
        controllerName = 'SeparatePhrasesModalCtrl';

  const moduleFullName = `app.modules.${moduleName}`;

  angular.module(moduleFullName)
    .controller(`${moduleFullName}.${controllerName}`, SeparatePhrasesModalCtrl);

  /*@ngInject*/
  function SeparatePhrasesModalCtrl($log, $http, $notification, $modalInstance, phrases, ingredient, confirm, $filter) {
    $log.debug(`[${moduleName}] Controller "${controllerName}" launched`);
    const vm = this;

    vm.phrases = phrases;

    vm.targetIngredient       = null;
    vm.createIngredientPhrase = null;

    vm.searchIngredients = (q) => $http.get('/api/ingredient-learning/ingredients', {
      params: {
        q,
        except: [ingredient.id]
      }
    })
      .then(response => _.sortBy(
        response.data.ingredients,
        i => {
          let index = i.name.indexOf(q);
          if (index === -1) {
            index = 999999;
          }
          return index;
        }
      ))
      .catch(() => []);

    vm.setCreateTarget = phrase => {
      let $default = phrase.$default;
      vm.phrases.forEach(p => p.$default = false);

      if ($default) {
        phrase.$default           = true;
        phrase.$transfer          = true;
        vm.createIngredientPhrase = phrase;
        vm.targetIngredient       = null;
        vm.targetIngredientName   = null;
      } else {
        vm.createIngredientPhrase = null;
      }
    };

    vm.setTargetIngredient = (ingredient) => {
      vm.targetIngredient     = ingredient;
      vm.targetIngredientName = ingredient.name;

      vm.phrases.forEach(p => p.$default = false);
      vm.createIngredientPhrase = null;
    };

    vm.separate = () => {
      const transferPhrases = _.filter(vm.phrases, {$transfer: true});

      let message;
      if (vm.targetIngredient) {
        message = `
        Are you sure you want to transfer phrases [${transferPhrases.map(p => p.text).join(', ')}] 
        to the ingredient [${vm.targetIngredient.name}]?
        `.trim();
      } else {
        message = `
        Are you sure you want to separate phrases [${transferPhrases.map(p => p.text).join(', ')}] 
        into the new ingredient [${vm.createIngredientPhrase.text}]?
        `.trim();
      }

      confirm(message).then(() => {
        $http.post(`/api/ingredient-learning/ingredients/${ingredient.id}/separate-phrases`, {
          phrases:                transferPhrases.map(p => p.id),
          targetIngredient:       vm.targetIngredient && vm.targetIngredient.id,
          createIngredientPhrase: vm.createIngredientPhrase && vm.createIngredientPhrase.id
        })
          .then(response => {
            const targetIngredientId = response.data;
            $modalInstance.close(targetIngredientId);
          })
          .catch(response => {
            $notification.error('Failed to separate phrases: ' + $filter('readError')(response.data));
          });
      });
    };

    vm.close = () => {
      $modalInstance.dismiss();
    };
  }
}());
