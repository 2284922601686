(function () {
  'use strict';

  const moduleName = 'ingredient-learning.dual-entry';

  const moduleFullName = `app.modules.${moduleName}`;

  const state       = stateName => `app.${moduleName}` + (stateName ? `.${stateName}` : '');
  const templateUrl = viewName => `modules/ingredient-learning/dual-entry/dual-entry.${viewName}.html`;
  const controller  = controllerName => `${moduleFullName}.${controllerName} as vm`;

  angular.module(moduleFullName, [])
    .config(function ($stateProvider) {
      $stateProvider
        .state(state(), {
          url:      `/dual-entry`,
          abstract: true,
          template: `<div class="module_${moduleName.replace('.', '_')}" ui-view></div>`,
          data:     {
            permissions: ['ingredient-learning']
          },
        })
        .state(state('queue'), {
          url:         '/queue',
          controller:  controller('QueueCtrl'),
          templateUrl: templateUrl('queue'),
          onEnter:     function ($anchorScroll) {
            $anchorScroll();
          }
        })
        .state(state('skipped'), {
          url:         '/skipped',
          controller:  controller('SkippedCtrl'),
          templateUrl: templateUrl('skipped'),
          onEnter:     $anchorScroll => $anchorScroll()
        })
        .state(state('edit-ingredient'), {
          url:         '/ingredients/:id',
          controller:  controller('EditIngredientCtrl'),
          templateUrl: templateUrl('edit-ingredient'),
          resolve:     {
            ingredient: ($http, $stateParams, $notification, $q, $filter, $state) => {
              return $http.get(`/api/ingredient-learning/dual-entry/ingredients/${$stateParams.id}`)
                .then(response => response.data)
                .catch(response => {
                  $notification.error('Failed to load ingredient: ' + $filter('readError')(response.data));
                  $state.go(state('queue'));

                  return $q.reject(response);
                });
            }
          },
          onEnter:     function ($anchorScroll) {
            $anchorScroll();
          }
        });

      angular.injector(['ng']).get('$log')
        .debug(`[${moduleName}] Module configured`);
    })
    .run(function ($log) {
      $log.debug(`[${moduleName}] Module launched`);
    });
}());


