(function () {
  'use strict';

  const moduleName     = 'track-reports';
  const moduleFullName = `app.modules.${moduleName}`;

  const state       = stateName => `app.${moduleName}` + (stateName ? `.${stateName}` : '');
  const templateUrl = viewName => {
    let parts = viewName.split('/');
    viewName  = parts.pop();
    let path  = parts.join('/');

    return `modules/${moduleName}/${path}/${moduleName}.${viewName}.html`.replace(/\/+/g, '/');
  };
  const controller  = controllerName => `${moduleFullName}.${controllerName} as vm`;

  angular.module(moduleFullName, [])
    .config(function ($stateProvider) {
      $stateProvider
        .state(state(), {
          url:      `/${moduleName}`,
          abstract: true,
          template: `<div class="module_${moduleName}" ui-view></div>`,
          data:     {
            requiresLogin: true
          }
        })
        .state(state('index'), {
          url:         '',
          templateUrl: templateUrl('index')
        })
        .state(state('track-users-feedback'), {
          url:         '/track-users-feedback',
          controller:  controller('TrackUsersFeedbackCtrl'),
          templateUrl: templateUrl('track-users-feedback/track-users-feedback')
        });
      angular.injector(['ng']).get('$log')
        .debug(`[${moduleName}] Module configured`);
    })
    .run(function ($log) {
      $log.debug(`[${moduleName}] Module launched`);
    });
}());


