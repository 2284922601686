(function () {
  'use strict';

  const moduleName     = 'email-file-delivery',
        controllerName = 'DistributionsListCtrl';

  const moduleFullName = `app.modules.${moduleName}`;

  angular.module(moduleFullName)
    .controller(`${moduleFullName}.${controllerName}`, ListCtrl);

  /*@ngInject*/
  function ListCtrl($log, $scope, $http, $notification, $filter, ngTableParams, $location, $q, $sessionStorage,
                    distributions, fileUrls) {
    $log.debug(`[${moduleName}] Controller "${controllerName}" launched`);

    const vm = this;

    vm.data = distributions;

    distributions.forEach(distribution => {
      distribution.file_urls = [];
      fileUrls.forEach(fileUrl => {
        if ((distribution.email_template || '').indexOf(`{{${fileUrl.alias}}}`) > -1) {
          distribution.file_urls.push(fileUrl);
        }
      });

      distribution.data_url_last_updated = _.reduce(
        distribution.file_urls.map(u => u.last_updated),
        (a, b) => a > b ? a : b
      );
    });

    const defaultSort = 'id.asc';

    $scope.params = vm.tableParams = new ngTableParams(
      {
        page:    +$location.search().page || 1,            // show first page
        count:   25,           // count per page
        sorting: (function () {
          let sort = ($location.search().sort || defaultSort);
          sort     = sort.split('.');

          let result      = {};
          result[sort[0]] = sort[1];

          return result;
        }()),
        filter:  (function () {
          const locationFilters = {};

          _.forEach($location.search(), (v, k) => {
            if (k.substr(0, 7) === 'filter.') {
              locationFilters[k.substr(7)] = v;
            }
          });

          return _.defaults(locationFilters, {
            id:          '',
            description: '',
          });
        }())
      },
      {
        counts:      [],
        total:       1000, // length of data,
        filterDelay: 50,
        getData:     function ($defer, params) {
          $location.search('page', params.page() > 1 ? params.page() : null);

          let filter = _.pick(params.filter(), v => !!v);
          let data   = $filter('filter')(vm.data, filter);

          _.keys(params.filter()).forEach(filterField => {
            $location.search(`filter.${filterField}`, filter[filterField] || null);
          });

          params.total(data.length);

          let [sortingAttribute, sortingDirection] = _.pairs(params.sorting())[0];

          const searchSort = `${sortingAttribute}.${sortingDirection}`;
          if (searchSort !== defaultSort) {
            $location.search('sort', searchSort);
            data = _.sortByOrder(data, [sortingAttribute], [sortingDirection]);
          } else {
            $location.search('sort', null);
          }

          data = data.slice((params.page() - 1) * params.count(), params.page() * params.count() - 1);

          $defer.resolve(data);
        }
      }
    );

    vm.open = () => {
      $sessionStorage.efdRecipientsListState = $location.url();
    };

  }
}());
