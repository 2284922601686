'use strict';

angular.module('app')
    .factory('URLON', function () {
        return {
            stringify: function (obj) {
                return JSON.stringify(obj)
                    .replace(/['()~]/g, '')
                    .replace(/"/g, "'")
                    .replace(/\{/g, "(")
                    .replace(/}/g, ")")
                    .replace(/\s/g, "~");
            },
            parse:     function (str) {
                return JSON.parse(
                    str
                        .replace(/'/g, '"')
                        .replace(/\(/g, "{")
                        .replace(/\)/g, "}")
                        .replace(/~/g, " ")
                );
            }
        }
    });
