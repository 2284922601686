(function () {
  "use strict";

  angular.module('app')
    .run(function ($templateCache) {
      [
        'match-multiple.tpl.html', 'match.tpl.html', 'no-choice.tpl.html',
        'select-multiple.tpl.html', 'select.tpl.html', 'choices.tpl.html'
      ].forEach(tpl => $templateCache.put(`track/${tpl}`, $templateCache.get(`bootstrap/${tpl}`)));
    })
    .factory('InstantSmartSearch', function (nixTrackApiClient, $q,
                                             nixTrackUtils, moment,
                                             debounce, $http, $log) {
      function SmartSearch(processFood) {
        this.items = [];
        this.selected = null;

        this.sources = {
          self:    false,
          common:  false,
          branded: true
        };

        this.limits = {
          common:       5,
          branded:      10,
          categoryView: 10
        };

        this.showCounters = true;

        this.enableFreeform = false;
        this.defaultToInput = false;

        if (_.isFunction(processFood)) {
          this.processFood = processFood;
        }
      }

      SmartSearch.prototype.select = function (food) {
        this.selected = null;
        this.error = null;

        let promise;

        if (food.$type === 'phrase' || food.$type === 'freeform') {
          promise = nixTrackApiClient.natural.nutrients({query: food.food_name});

          promise
            .then(response => {
              let data = response.data;

              if (data.foods.length === 0) {

              } else {
                if (food.$type === 'freeform') {
                  this.processFood(data.foods);
                } else {
                  this.processFood(data.foods[0]);
                }
              }
            })
            .catch(response => {
              if (response.status === 404) {
                this.error = `No foods found`;
              } else {
                return $q.reject(response);
              }
            });
        } else if (food.$type === 'branded') {
          promise = $q.when(this.processFood(food));
        }

        promise.catch(response => {
          this.error = response.data;
        });
      };

      SmartSearch.prototype.processFood = function (food) {
        $log.warn('processFood not implemented');
      };

      SmartSearch.prototype.loadFoods = function ($select/*, $event*/) {
        let search = this.$search = $select.search;

        if (!$select.search) {
          this.items = [];
          return;
        }

        nixTrackApiClient('/search/instant', {
          params:           angular.merge({query: $select.search}, this.sources),
          ignoreLoadingBar: true
        })
          .success(data => {
            if (this.$search !== search && this.$search.indexOf(search) === -1) {
              return;
            }

            let items = [], commonPhrases = [];

            if (data.common) {
              data.common.forEach(function (food, index) {
                if (food.food_name[food.food_name.length - 1] === 's') {
                  let unpluralized = food.food_name.replace(/e?s$/, '').toLowerCase();

                  if (_.find(data.common, food => food.food_name.toLowerCase() === unpluralized)) {
                    data.common.splice(index, 1);
                  }
                }
              });

              let category = 'COMMON FOODS';
              if (this.showCounters) {
                category += ` (${data.common.length})`;
              }

              data.common.forEach(food => {
                commonPhrases.push(food.food_name);

                food.$category = category;
                food.$type = 'phrase';
              });

              let common = data.common.slice(0, this.limits.common);
              category = 'COMMON FOODS';
              if (this.showCounters) {
                category += ` (${common.length})`;
              }

              common.forEach(food => {
                food = angular.copy(food);
                food.$category = category;
                items.push(food);
              });
            }


            if (data.branded) {
              let category = 'BRANDED FOODS';
              if (this.showCounters) {
                category += ` (${data.branded.length})`;
              }

              data.branded.forEach(food => {
                food.$category = category;
                food.$type = 'branded';
              });

              let branded = data.branded.slice(0, this.limits.branded);
              category = 'BRANDED FOODS';
              if (this.showCounters) {
                category += ` (${branded.length})`;
              }

              branded.forEach(food => {
                food = angular.copy(food);
                food.$category = category;
                items.push(food);
              });
            }


            if (this.enableFreeform) {
              let action = 'push';

              if (search.length >= 12 || search[0].match(/\d/) || search.indexOf('and') > -1 ||
                search.indexOf('yesterday') > -1) {
                action = 'unshift';
              }

              let freeformItem = {
                food_name: $select.search,
                $category: 'FREEFORM',
                $type:     'freeform'
              };

              if (commonPhrases.indexOf($select.search) === -1) {
                items[action](freeformItem);
              }

              data.freeform = [freeformItem];
            }

            if (this.defaultToInput) {
              items.unshift({
                food_name: $select.search,
                $type:     'default'
              });
            }

            this.items = items;
            this.groupedItems = data;
          });
      };


      return SmartSearch;
    })
    .directive('instantSmartSearch', function ($timeout) {
      return {
        restrict: 'A',
        replace:  true,
        scope:    {
          smartSearch:       '=instantSmartSearch',
          maxFoodNameLength: '=?',
          searchButtonClick: '&?',
          autofocus:         '=?'
        },
        template: `
        <div class="smart-search">
          <div class="input-group">
            <ui-select ng-model="smartSearch.selected" on-select="smartSearch.select($item)" theme="track">
              <ui-select-match placeholder="{{placeholder || 'Search foods to add'}}">
                <span ng-bind="$select.selected.food_name"></span>
              </ui-select-match>
              <ui-select-choices
                ng-show="!(smartSearch.items.length === 1 && smartSearch.items[0].$type === 'default') && "
                refresh="smartSearch.loadFoods($select)"
                refresh-delay="100"
                repeat="item in smartSearch.items"
                group-by="'$category'"
                minimum-input-length="0"
                position="down"
              >
                <div class="list-group-item" ng-if="item.$type == 'history' || item.$type == 'branded' || item.$type == 'suggested'">
                  <span class="badge badge-calorie">
                    {{item.nf_calories | number: 0}}
                    <span class="block text-center note">cal</span>
                  </span>
                  <div class="food-image-wrap">
                    <img class="food-image" ng-src="{{item.photo.thumb || placeholderImage}}" alt="{{item.food_name | ucwords}}">
                  </div>
                  <span class="te">
                    {{item.food_name | ucwords | characters: maxFoodNameLength}}
                  </span>

                  <span class="item-serving">
                  <span ng-if="item.brand_name">{{item.brand_name}},</span>
                    {{item.serving_qty || 1}} {{item.serving_unit || 'Serving'}}
                  </span>
                </div>

                <div class="common-food" ng-if="item.$type == 'phrase' || item.$type == 'freeform'">
                  <div class="food-image-wrap">
                    <img class="common-food-image" ng-src="{{item.photo.thumb || placeholderImage}}" alt="{{item.food_name | ucwords}}">
                  </div>
                  {{item.food_name | ucwords}}
                </div>


              </ui-select-choices>
              <ui-select-no-choice ng-show="smartSearch.items.length && ">
                <div class="nothing">
                  Nothing to show here at the moment...
                </div>
              </ui-select-no-choice>
            </ui-select>
            <div class="input-group-addon">
              <i class="fa fa-search" ng-click="searchButtonClick()"></i>
            </div>
          </div>
        </div>
        `,
        link:     function (scope, element, attributes) {
          scope.placeholderImage = '//d2eawub7utcl6.cloudfront.net/images/nix-apple-grey.png';
          if (!scope.maxFoodNameLength) {scope.maxFoodNameLength = 50;}

          scope.placeholder = attributes.placeholder;

          if (scope.autofocus) {
            $timeout(function () {
              let select = scope.$$childTail.$select;

              if (scope.smartSearch.enableSuggestedFoods) {
                select.activate();
                $timeout(() => select.focusSearchInput(), 300);
              } else {
                select.setFocus();
              }
            }, 100);
          }

          // below is an incredibly hacky way to disable TAB selection of the first item
          // which has been reported as confusing
          $timeout(function () {
            let select = scope.$$childTail.$select;

            let events = $._data($(select.searchInput)[0], 'events');

            let originalKeydownHandler;

            if (events && angular.isArray(events['keydown'])) {
              originalKeydownHandler = events['keydown'][0].handler;
              $(select.searchInput).off('keydown');
            }

            $(select.searchInput).on('keydown', function (e) {
              if (e.which === 9) {
                e.preventDefault();
                e.stopImmediatePropagation();
                e.stopPropagation();

                return false;
              }
            });

            if (originalKeydownHandler) {
              $(select.searchInput).on('keydown', originalKeydownHandler);
            }
          }, 100);
        }
      }
    });
}());
