(function () {
  'use strict';

  angular.module('app.modules.email-templates')
    .factory('emailTemplatesModal', EmailTemplatesModal)
    .directive('validateEmailTemplate', ValidateEmailTemplate);

  /*@ngInject*/
  function EmailTemplatesModal($http, $modal, $notification, $filter) {
    return function emailTemplatesModal(templateId, recipient = null, externalVariables = {}, enableTestMode = false) {
      return $modal.open({
        animation:   false,
        templateUrl: 'modules/email-templates/email-templates.modal.html',
        controller:  function ($scope, $modalInstance, variables, templates) {
          let vm = $scope.vm = this;

          vm.templates = templates;

          vm.template = _.find(templates, {is_default: 1}) || templates[0];

          vm.init = () => {
            vm.email       = angular.copy(vm.template);
            vm.email.$meta = externalVariables.$meta;

            vm.email.recipient = {
              name:  '',
              email: ''
            };

            if (_.isString(recipient)) {
              vm.email.recipient.email = recipient;
            } else if (_.isObject(recipient)) {
              _.extend(vm.email.recipient, recipient);
            }

            vm.email.sendAttachment = !!vm.email.attachment;

            _.forEach(variables, (value, key) => {
              let regex = new RegExp(`{{${key}}}`, 'ig');

              ['subject', 'body'].forEach(attribute => {
                vm.email[attribute] = vm.email[attribute].replace(regex, value || '');
              })
            });
          };

          vm.send = function () {
            vm.send.$busy = true;
            $http.post(`/api/email-templates/${vm.template.alias}/send`, vm.email)
              .then(function () {
                $notification.info('Email sent');
                $modalInstance.close();
              })
              .catch(function (response) {
                $notification.error('Email not sent: ' + $filter('readError')(response.data));
              })
              .finally(() => vm.send.$busy = false);
          };

          vm.setDefaultTemplate = () => {
            vm.templates.forEach(t => t.is_default = 0);
            vm.template.is_default = 1;
            $http.put(`/api/email-templates/${vm.template.id}`, {is_default: 1})
              .then(response => $notification.info('Default template changed'))
              .catch(response => $notification.error('Default template not set: ' + $filter('readError')(response.data)))
          };

          vm.close = () => $modalInstance.dismiss();

          vm.enableTestMode = enableTestMode;
          vm.populateTestData = () => {
            ['subject', 'body'].forEach(attribute => {
              vm.email[attribute] = vm.email[attribute]
                .replace(/\{\{(\w+?)}}/g, '$1');
            });
          };

          vm.init();
        },
        size:        'lg',
        resolve:     {
          variables: emailTemplatesVariables => emailTemplatesVariables.then(
            variablesService => _.extend(
              _.clone(variablesService.get()),
              _.omit(externalVariables, (value, key) => key[0] === '$')
            )
          ),
          templates: () => $http
            .get(`/api/email-templates`)
            .then(response => response.data)
        }
      });
    }
  }

  /*@ngInject*/
  function ValidateEmailTemplate() {
    return {
      require: 'ngModel',
      link:    function (scope, element, attributes, ngModel) {
        ngModel.$validators.validateEmailTemplate = function (modelValue, viewValue) {
          return !(viewValue && viewValue.match(/\{\{\w+?}}/));
        };
      }
    };
  }

}());
