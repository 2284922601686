(function () {
  'use strict';

  const moduleName     = 'bulk-export',
        controllerName = 'IndexCtrl';

  const moduleFullName = `app.modules.${moduleName}`;

  angular.module(moduleFullName)
    .controller(`${moduleFullName}.${controllerName}`, IndexCtrl);

  /*@ngInject*/
  function IndexCtrl($scope, $log, $http, $notification, $filter, $location, $upload) {
    $log.debug(`[${moduleName}] Controller "${controllerName}" launched`);

    const vm = this;

    vm.exportTemplate = {
      upcList: 'full',
      type:    'full',
      subject: '',
      cc:      ''
    };

    vm.upcList = {
      file:        null,
      clearUpload: function () {
        this.file = null;
      }
    };

    vm.export = _.defaults($location.search(), vm.exportTemplate);

    $scope.$watchCollection('vm.export', () => {
      $location.search(_.pick(vm.export, (value, key) => value !== vm.exportTemplate[key]));
    });

    vm.submit = function () {
      const url = '/api/bulk-export';

      vm.submit.$busy = true;

      let flow;

      if (vm.upcList.file && vm.export.upcList === 'segment') {
        flow = $upload
          .upload({
            url:              url,
            file:             vm.upcList.file,
            fileFormDataName: ['upcListFile'],
            fields:           {export: vm.export}
          })
      } else {
        flow = $http.post(url, {export: vm.export});
      }

      flow
        .then(() => {
          $notification.info('Bulk export was triggered and email will be sent soon');
        })
        .catch(response => {
          $notification.error('Bulk export failed: ' + $filter('readError')(response.data));
        })
        .finally(() => vm.submit.$busy = false);
    };
  }
}());
