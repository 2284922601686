'use strict';

'use strict';

const moduleName     = 'reports',
      controllerName = 'VisualizeTranscriptionFlowCtrl';

const moduleFullName = `app.modules.${moduleName}`;

angular.module(moduleFullName)
  .controller(`${moduleFullName}.${controllerName}`, VisualizeTranscriptionFlowCtrl);

/*@ngInject*/
function VisualizeTranscriptionFlowCtrl($scope, $http, $filter, users, $location, regions, moment) {
  const vm   = $scope.vm = this;
  vm.regions = regions;

  function calculateUpdate(product) {
    if (product.masterRecord.api_staging && product.itemRecord) {
      const compare = [];
      for (const key of Object.keys(product.masterRecord.api_staging)) {
        if (key in product.itemRecord) {
          compare.push({
            key,
            staged: product.masterRecord.api_staging[key],
            item:   key === 'region' ? $filter('region')(product.itemRecord[key]) : product.itemRecord[key],
          })
        }
      }

      return compare;
    }

    return null;
  }

  vm.getTaskByType = (chain, type) => {
    return chain.find(t => Array.isArray(type) ? type.includes(t.type) : t.type === type);
  };

  vm.getChainTitle = (chain) => {
    return `[${$filter('amDateFormat')(chain[0].created_at, 'YYYY-MM-DD')}] ${chain.map((t) => t.id).join('->')}`
  }

  vm.users = {}

  users.forEach(u => {
    u.display      = `${u.name} [${u.email}]`;
    vm.users[u.id] = u;
  });

  vm.getUserById = (id) => users.find(u => u.id === id);

  vm.pagination = {
    itemsPerPage: 20,
    totalItems:   0,
    totalPages:   0,
    currentPage:  +$location.search().page || 1,
    setPage:      function (page) {
      if (page < 0) {
        page = 1;
      }

      this.currentPage = page;

      $location.search('page', page > 1 ? page : null);

      return load();
    },
    reset() {
      this.totalItems  = 0;
      this.totalPages  = 0;
      this.currentPage = 1;
      $location.search('page', null);
    }
  };

  vm.searchParams = $location.search();
  if (vm.searchParams.date) {
    vm.searchParams.date = moment(vm.searchParams.date).toDate();
  }
  vm.searchByUpc = () => {
    vm.pagination.reset();

    vm.searchParams.date      = null;
    vm.searchParams.region    = null;
    vm.searchParams.isFlagged = null;

    if (vm.searchParams.upc) {
      $location.search({upc: vm.searchParams.upc});
    } else {
      $location.search({});
    }

    return load();
  }
  vm.search      = () => {
    vm.pagination.reset();

    vm.searchParams.upc = null;
    $location.search({
      isFlagged: vm.searchParams.isFlagged ? true : null,
      date:      vm.searchParams.date ? moment(vm.searchParams.date).format('YYYY-MM-DD') : null,
      region:    vm.searchParams.region,
    });

    return load();
  }

  vm.search.datePicker = {
    isOpened: false,
    maxDate:  new Date(),
    minDate:  new Date('2015-01-01'),
    options:  {},
  }

  async function load() {
    vm.data = null;

    const search = $location.search();

    const {data: {records, total}} = await $http.get(
      '/reports/visualise-transcription-flow',
      {
        params: {
          date:      (search.date || '').toString().trim() || undefined,
          isFlagged: search.isFlagged !== undefined ? 1 : undefined,
          region:    (+search.region || '').toString().trim() || undefined,
          limit:     20,
          offset:    ((+search.page || 1) - 1) * 20,
          upc:       search.upc || undefined,
        }
      }
    );

    vm.data                  = records;
    vm.pagination.totalItems = total;
    vm.pagination.totalPages = Math.ceil(total / vm.pagination.itemsPerPage);

    for (const product of vm.data) {
      product.compare                          = calculateUpdate(product);
      product.tasks.hasFopSelectBest           = !!product.tasks.fop.find(c => c.find(t => t.type === 4));
      product.tasks.hasLabelSelectBest         = !!product.tasks.label.find(c => c.find(t => t.type === 4));
      product.tasks.hasLabelConflictResolution = !!product.tasks.label.find(c => c.find(t => t.type === 5));

      for (const chain of [...product.tasks.fop, ...product.tasks.label]) {
        chain.$isFlagged = !!chain.find((t) => !!t.flag_id);
      }
    }
  }

  load();
}
