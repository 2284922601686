(function () {
  'use strict';

  const moduleName     = 'auth';
  const moduleFullName = `app.modules.${moduleName}`;

  const state       = stateName => `auth` + (stateName ? `.${stateName}` : '');
  const templateUrl = viewName => `modules/${moduleName}/${moduleName}.${viewName}.html`;
  const controller  = controllerName => `${moduleFullName}.${controllerName} as vm`;

  angular.module(moduleFullName, ['google-identity'])
    .config(function ($stateProvider) {
      $stateProvider
        .state(state(), {
          abstract: true,
          template: `<div class="module_${moduleName}" ui-view></div>`,
          data:     {},
        })
        .state(state('login'), {
          url:         '/login',
          controller:  controller('LoginCtrl'),
          templateUrl: templateUrl('login')
        })
        .state(state('pending'), {
          url:         '/pending',
          controller:  function ($state, session) {
            if (!session.profile) {
              $state.go('auth.login');
            }
          },
          templateUrl: templateUrl('pending')
        });

      angular.injector(['ng']).get('$log')
        .debug(`[${moduleName}] Module configured`);
    })
    .config(function ($httpProvider) {
      $httpProvider.interceptors.push(function ($q, $rootScope) {
        return {
          responseError: function (response) {
            if (response.status === 401 && !(response.config && response.config.skipAuthorization)) {
              $rootScope.$broadcast('auth:invalid-session', response);
            }

            return $q.reject(response);
          }
        }
      })
    })
    .run(function ($log, $rootScope, $state, session) {
      $log.debug(`[${moduleName}] Module launched`);

      $rootScope.$on('auth:invalid-session', function () {
        if (!$rootScope.loginRedirect.params.href) {
          $rootScope.loginRedirect.params.href = location.href;
        }

        session.logout();

        if ($state.current.name.indexOf('auth.') !== 0) {
          $state.go('auth.login');
        }
      });
    });
}());


