(function () {
  'use strict';

  const moduleName = 'tasks';

  const moduleFullName = `app.modules.${moduleName}`;

  const state = stateName => `app.${moduleName}` + (stateName ? `.${stateName}` : '');
  const templateUrl = viewName => `modules/${moduleName}/${moduleName}.${viewName}.html`;
  const controller = controllerName => `${moduleFullName}.${controllerName} as vm`;

  angular.module(moduleFullName, [`app.modules.force-transcription`])
    .config(function ($stateProvider) {
      $stateProvider
        .state(state(), {
          url:      `/${moduleName}`,
          abstract: true,
          template: `<div class="module_${moduleName}" ui-view></div>`,
          data:     {}
        })
        .state(state('flagged-tasks-report'), {
          url:         '/flagged-tasks-report',
          controller:  controller('FlaggedTasksReportCtrl'),
          templateUrl: templateUrl('flagged-tasks-report')
        })
        .state(state('flagged-tasks-report-detail'), {
          url:         '/flagged-tasks-report/:upc',
          controller:  controller('FlaggedTasksReportDetailCtrl'),
          templateUrl: templateUrl('flagged-tasks-report-detail')
        })
        .state(state('manual-review-report'), {
          url:         '/manual-review-report',
          controller:  controller('ManualReviewReportCtrl'),
          templateUrl: templateUrl('manual-review-report')
        });


      angular.injector(['ng']).get('$log')
        .debug(`[${moduleName}] Module configured`);
    })
    .run(function ($log) {
      $log.debug(`[${moduleName}] Module launched`);
    });
}());


