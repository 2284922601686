'use strict';

angular.module('app.services')
    .factory('UPCCheck', [
        '$resource',
        function($resource) {
            return $resource('https://worker-aws-us-east-1.iron.io/2/projects/5425885f38ef2d000700008b/tasks/webhook', {
                code_name: 'upc-check',
                oauth: 'Q-Q5FXJtZ5hanpTUJtbwchj51tk',
                cluster: 'nutritionix'
            }, {
                'getTaskInfo': {
                    url: 'https://worker-aws-us-east-1.iron.io/2/projects/5425885f38ef2d000700008b/tasks/:id',
                    params: {
                        id: '@id'
                    },
                    method: 'GET',
                    isArray: false
                }
            });
        }
    ]);
