angular.module('app.controllers')
    .controller('TagsListCtrl', [
        '$scope', '$state', 'Tag', 'ngTableParams',
        function ($scope, $state, Tag, ngTableParams) {
            'use strict';

            function navigate() {
                $state.go(
                    'app.tags.list.navigate',
                    {
                        q:    $scope.search.term || null,
                        page: $scope.tableParams.page() > 1 ? $scope.tableParams.page() : null
                    }
                );
            }

            let exactNavigation = false;

            $scope.search = {
                exact:   true,
                term:    $state.params.q || '',
                perform: function () {
                    exactNavigation = true;
                    navigate();
                    $scope.tableParams.page(1);
                    $scope.tableParams.reload();
                }
            };

            $scope.tableParams = new ngTableParams(
                {
                    page:    $state.params.page || 1,            // show first page
                    count:   25,           // count per page
                    sorting: {
                        tag_id: 'asc'     // initial sorting
                    }
                },
                {
                    counts:  [],
                    total:   0, // length of data
                    getData: function ($defer, params) {
                        var sort;

                        if(!$scope.search.term.trim()){
                            return $defer.resolve([]);
                        }

                        navigate();

                        angular.forEach(params.sorting(), function (order, column) {
                            sort = [column, order].join('.');
                        });
                        Tag.query(
                            {
                                limit:   params.count(),
                                offset:  (params.page() - 1) * params.count(),
                                search:  $scope.search.term,
                                orderBy: sort,
                                exact:   $scope.search.exact ? 1 : 0
                            }
                        ).then(
                            function (tagsData) {
                                params.total(tagsData.count);
                                $defer.resolve(tagsData.results);

                                if (exactNavigation && $scope.search.exact && tagsData.results.length === 1) {
                                    $state.go('app.tags.edit', {
                                        tag_id: tagsData.results[0].tag_id,
                                        locale: tagsData.results[0].phrase.locale === 'en' ? null : tagsData.results[0].phrase.locale
                                    });
                                }
                            }
                        );
                    }
                }
            );
        }
    ]);
