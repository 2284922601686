(function () {
  'use strict';

  const moduleName = 'track-reports',
    controllerName = 'TrackUsersFeedbackCtrl';

  const moduleFullName = `app.modules.${moduleName}`;

  angular.module(moduleFullName)
    .controller(`${moduleFullName}.${controllerName}`, TrackUsersFeedbackCtrl);

  /*@ngInject*/
  function TrackUsersFeedbackCtrl($log, $http, $notification, $filter,
                                  ngTableParams, $location, confirm, $modal, debounce, $q) {
    $log.debug(`[${moduleName}] Controller "${controllerName}" launched`);

    const vm = this;
    const defaultOrderBy = 'f.created_at.desc';

    vm.displayJson = (feedback, property) => {
      $modal.open({
        animation: true,
        templateUrl: '/modules/track-reports/track-users-feedback/track-reports.track-users-feedback.view-json.modal.html',
        controller: function ($scope, $modalInstance) {
          const vm = $scope.vm = this;

          vm.feedback = feedback;
          vm.property = property;

          vm.close = () => $modalInstance.dismiss();
        },
        size:        'lg'
      });
    };

    vm.complete = feedback => {
      $http.put(`/api/track-reports/track-users-feedback/${feedback.id}/resolve`, {})
        .then(() => {
          $notification.info('Feedback completed');
          _.pull(vm.tableParams.data, feedback);
          vm.reload();
        })
        .catch(response => {
          $notification.error($filter('readError')(response.data));
        })
    };

    vm.reload = debounce(() => vm.tableParams.reload(), 500);

    vm.tableParams = new ngTableParams(
      {
        page: $location.search().page || 1,            // show first page
        count: 25,           // count per page
        sorting: _.zipObject([($location.search().orderBy || defaultOrderBy).match(/(.*)\.(asc|desc)/).slice(1, 3)]),
        filter: (function () {
          const locationFilters = {};

          _.forEach($location.search(), (v, k) => {
            if (k.substr(0, 7) === 'filter.') {
              locationFilters[k.substr(7)] = v;
            }
          });

          return _.defaults(locationFilters, {
            type: '',
          });
        }())
      },
      {
        counts:  [],
        total:   0, // length of data
        getData: function ($defer, params) {
          let limit = params.count();
          let offset = (params.page() - 1) * params.count();
          let orderBy = _.pairs(params.sorting())[0].join('.');

          $location.search('orderBy', orderBy === defaultOrderBy ? null : orderBy);
          $location.search('page', params.page() > 1 ? params.page() : null);

          const filter = _.pick(params.filter(), v => !!v);

          _.keys(params.filter()).forEach(filterField => {
            $location.search(`filter.${filterField}`, filter[filterField] || null);
          });

          $http.get('/api/track-reports/track-users-feedback', {params: _.merge({limit, offset, orderBy}, filter)})
            .then(response => {
              params.total(response.data.total);
              $defer.resolve(response.data.data);
            })
        }
      }
    );

    vm.typeFilter = function () {
      const defer = $q.defer();
      defer.resolve([{id: '1', title: '1'}, {id: '2', title: '2'}]);
      return defer;
    };
  }
}());
