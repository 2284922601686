'use strict';

angular.module('app.controllers')
  .controller('TasksCropPhotoCtrl',
    function ($scope, $state, $http, $notification, $q, flagTask, cdnify, exifStripper) {
      $scope.image = {
        src:          null,
        bounds:       {},
        croppedImage: null
      };

      function init() {
        $scope.image.src  = null;
        $scope.showCanvas = false;

        $scope.init(2, {file_group: $state.params.file_group})
          .then(function (task) {
            if (task) {
              let bestPhotoRotated = _.get(task, 'meta.best_photo_rotated');
              let flow;
              if (bestPhotoRotated) {
                flow = $q.resolve(bestPhotoRotated);
              } else {
                flow = $http.get('/api/tasks/crop-photo', {
                  params: {
                    file_group: $state.params.file_group,
                    upc:        task.upc
                  }
                }).then(response => response.data);
              }


              return flow.then(function (src) {
                $scope.vm.debugInfo.push({
                  name:   'Current Image',
                  href:   src,
                  target: '_blank'
                });
                // $scope.image.src = '/cors-proxy?url=' + cdnify(src);
                // $scope.image.src = cdnify(src);

                return exifStripper.strip(cdnify(src))
                  .then((response) => {
                    $scope.image.src = response.url;
                  });
              });
            }
          });
      }

      // wait while cropper will be set in data object
      $scope.$watch(function () {
        return angular.element('canvas').data('crop.angular-img-cropper');
      }, function (cropper) {
        $scope.cropper = cropper;
      });

      // watch cropper srcImage (and its change) to reset bounds.
      $scope.$watch(function () {
        return $scope.cropper && $scope.cropper.srcImage;
      }, function (initialized) {
        var bounds, cropper;
        if (initialized) {
          $scope.showCanvas = true;
          cropper           = $scope.cropper;
          bounds            = angular.copy(cropper.getBounds());

          bounds.left   = cropper.minXClamp;
          bounds.bottom = cropper.minYClamp;
          bounds.right  = cropper.maxXClamp;
          bounds.top    = cropper.maxYClamp;

          cropper.setBounds(bounds);
          $scope.image.bounds = cropper.getCropBounds();
          cropper.draw(cropper.ctx);
        }
      });

      init();

      $scope.submit = function (flag) {
        $scope.submit.$busy = flag ? 'flagging' : 'submitting';
        let flow;

        if (flag) {
          flow = flagTask($scope.vm.currentTask, {}, flagTask.scenarios.INGREDIENT_STATEMENT)
            .then(init);
        } else {
          let params = {
            task_id: $scope.vm.currentTask.id,
            bounds:  $scope.image.bounds,
          };

          flow = $http.post('/api/tasks/crop-photo/', params)
            .then(() => $notification.info('Saved'))
            .catch(() => $notification.error('Not saved'));

          flow.finally(init);
        }

        flow.finally(() => {
          $scope.submit.$busy = null;
        });
      };
    }
  );



