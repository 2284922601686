'use strict';

angular.module('app.controllers').controller('AdminToolsHelpDocLinksCtrl', function ($scope, HelpDocLink, $notification) {
    function initNewDoc() {
        $scope.doc = new HelpDocLink();
    }

    initNewDoc();

    $scope.docs = HelpDocLink.query();

    $scope.save = function (doc) {
        var isNew = !doc.id;

        doc.$busy = true;
        doc.$save()
            .then(function () {
                if (isNew) {
                    $scope.docs.push(doc);
                    $notification.info('doc created');
                    initNewDoc();
                } else {
                    $notification.info('doc updated');
                }
            })
            .catch(function (response) {
                var error;
                if (angular.isString(response.data)) {
                    error = response.data;
                } else {
                    error = response.data.errors &&
                        response.data.errors[0] &&
                        response.data.errors[0].message ||
                        'Unexpected backend error';
                }
                $notification.error('doc was not saved: ' + error);
            })
            .finally(function () {
                doc.$busy = false;
            });
    };
});
