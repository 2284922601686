(function () {
  'use strict';

  angular.module('app.modules.tasks')
    .factory('flagTask', function ($http, $modal, $notification, $filter) {
      const flags = $http.get('/static/static-flag-reasons.json')
        .then(response => response.data)
      /*@ngInject*/
      function flagTaskController($scope, $modalInstance, task, reasons, flags, scenario) {
        const vm = $scope.vm = this;
        vm.flags = flags;

        vm.reason = '';
        vm.reasons = reasons;
        vm.submit = function () {
          if (vm.reason) {
            $http.put(`/api/tasks/${task.id}/flag`, {
              flag_id: vm.flag.id,
              flag_reason: vm.reason,
              scenario
            })
              .then(response => {
                $notification.info('Task has been flagged');
                $modalInstance.close(response.data);
              })
              .catch(response => {
                $notification.error('Task could not been flagged: ' + $filter('readError')(response.data));
              })
          }
        };

        vm.cancel = function () {
          $modalInstance.dismiss('cancel');
        };
      }

      const flagTaskService = async function flagTask(task, modalOptions = {}, scenario = null) {
        const allFlags = await flags;
        const filteredFlags = allFlags.filter(f => !f.scenario);
          if (scenario) {
            filteredFlags.push(...allFlags.filter(flag => flag.scenario === scenario));
          }

          modalOptions = angular.extend({
            animation: true,
            templateUrl: '/modules/tasks/tasks.flag-task.modal.html',
            controller: flagTaskController,
            size: 'lg',
            resolve: {
              task: () => task,
              reasons: () => $http.get('/api/tasks/flag-reasons', { params: { type: task.type } })
                .then(response => response.data),
              flags: () => filteredFlags,
              scenario: () => scenario
            }
          }, modalOptions || {});

          return $modal.open(modalOptions).result;
      };

      flagTaskService.scenarios = {
        INGREDIENT_STATEMENT: 'ingredient-statement'
      }

      return flagTaskService;
    });
}());
