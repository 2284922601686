'use strict';

angular.module('app.controllers')
    .controller('ReportUserPointsCtrl',
        function ($scope, $http, $notification, moment) {
            $scope.monthNames = {
                current: moment().format('MMMM'),
                previous: moment().subtract(1, 'months').format('MMMM')
            };

            $http.get('/reports/user/points')
                .success(function (stats) {
                    $scope.stats = stats;
                })
                .error(function () {
                    $notification.error('Report failed to load');
                });
        }
    );
