'use strict';

angular.module('app.services')
    .factory('Recipe', [
        '$resource', '$filter',
        function ($resource, $filter) {
            var Recipe = $resource(
                '/api/recipes/:id/:action', {id: '@id'},
                {
                    validate: {method: 'POST', params: {validate: true}},
                    publish:  {method: 'POST', params: {action: 'publish'}}
                }
            );

            Recipe.prototype.addIngredient = function (ingredient) {
                ingredient = angular.extend(
                        ingredient || {},
                    {
                        raw_measure:    '',
                        raw_food:       '',
                        calories:       0,
                        serving_weight: 0
                    }
                );

                if (!this.ingredients) {
                    this.ingredients = [];
                }

                this.ingredients.push(ingredient);

                this.calculateTotals();
            };

            Recipe.prototype.removeIngredient = function (ingredient) {
                var index;
                if (!this.ingredients) {
                    this.ingredients = [];
                }

                index = this.ingredients.indexOf(ingredient);
                if (index !== -1) {
                    this.ingredients.splice(index, 1);
                }

                this.calculateTotals();
            };

            Recipe.prototype.addWeight = function (weight) {
                weight = angular.extend(
                        weight || {},
                    {
                        qty:                  1,
                        unit:                 '',
                        serving_weight_grams: 1
                    }
                );

                if (!this.weights) {
                    this.weights = [];
                }

                this.weights.push(weight);

                this.seqWeights();
            };

            Recipe.prototype.removeWeight = function (weight) {
                var index;
                if (!this.weights) {
                    this.weights = [];
                }

                index = this.weights.indexOf(weight);
                if (index !== -1) {
                    this.weights.splice(index, 1);
                }

                this.seqWeights();
            };

            Recipe.prototype.calculateTotals = function () {
                var that = this;
                if (!this.servings) {
                    this.servings = 1;
                }

                this.total_weight = 0;
                this.total_calories = 0;

                angular.forEach(
                    $filter('filter')(
                        this.ingredients,
                        i => i.match_status
                    ),
                    function (ingredient) {
                        that.total_weight += parseFloat(ingredient.serving_weight);
                        that.total_calories += parseFloat(ingredient.calories);
                    }
                );

                this.manageAutoWeight();
            };

            Recipe.prototype.manageAutoWeight = function () {
                var autoWeight;

                if (!this.weights || this.weights.length === 0) {
                    this.addWeight();
                }

                autoWeight = this.weights[0];
                autoWeight.qty = 1;
                autoWeight.unit = this.serving_name;
                autoWeight.serving_weight_grams = this.total_weight / this.servings;
            };

            Recipe.prototype.seqWeights = function () {
                angular.forEach(this.weights, function (weight, index) {
                    weight.seq = index + 1;
                });
            };

            Recipe.prototype.calculateWeightCalories = function (weight) {
                return weight.serving_weight_grams / this.total_weight * this.total_calories;
            };

            Recipe.prototype.getIsPublishable = function () {
                if (!this.id || this.$errors) {
                    return false;
                }

                return $filter('filter')(
                    this.ingredients,
                    function (ingredient) {
                        return !ingredient.match_status;
                    }
                ).length === 0;
            };

            Recipe.prototype.getLastPublished = function(){
                var lastPublished = null, publishedWeights;
                if(this.weights && this.weights.length){
                    publishedWeights = $filter('filter')(this.weights, function(weight){
                        return !!weight.api_item_id;
                    });

                    if(publishedWeights.length){
                        publishedWeights = $filter('orderBy')(publishedWeights, '-api_updated_at');
                        lastPublished = publishedWeights[0].api_updated_at;
                    }
                }
                return lastPublished;
            };

            Recipe.prototype.getIsPublished = function(){
                var publishedWeights;
                if(this.weights && this.weights.length){
                    publishedWeights = $filter('filter')(this.weights, function(weight){
                        return !!weight.api_item_id;
                    });

                    return !!publishedWeights.length;
                }
                return false;
            };

            return Recipe;
        }
    ]);
