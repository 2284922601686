'use strict';

angular.module('app.controllers')
  .controller('TasksPerformCtrl',
    function ($scope, $state, $http, $notification, $location, task, $q, $window, $timeout, confirm, regions) {
      $scope.FLAG_IMAGE_UNUSABLE = 1;
      $scope.regions             = regions;

      if (!$state.params.file_group) {
        $state.go($state.current.name, _.merge({}, $state.params, {file_group: 1}), {reload: true});
        return $q.reject(null);
      }

      $scope.getTemplateUrl = function () {
        if ($scope.vm && $scope.vm.currentTask) {
          let group = 'front-of-package', region = 'all';

          if ($scope.vm.currentTask.file_group === 2) {
            group  = 'nutrition-label';
            region = _.find(regions, {id: $scope.vm.currentTask.region});

            if (region) {
              region = region.name.toLowerCase();
            } else {
              region = 'all';
            }
          }

          return 'views/tasks/transcribe/' + group + '/' + region + '.html';
        }
      };

      $scope.skip = function () {
        confirm('Are you sure you want to skip this task?')
          .then(() => $state.go(
            $state.current.name,
            _.merge({}, $state.params, {taskId: null}),
            {reload: true}
          ));
      };

      $scope.init = function (type, params = {}) {
        const vm = $scope.vm = {
          title:     '',
          debugInfo: []
        };

        const fileGroup = parseInt(params.file_group, 10) || 1;
        vm.currentTask  = null;

        if (type === 1) {
          vm.helpLink = 'rotate-task';
          vm.title += 'Rotate: ';

        } else if (type === 2) {
          vm.helpLink = '';
          vm.title += 'Crop: ';

        } else if (type === 3 || type === 5) {
          if (type === 5) {
            vm.title += 'Conflict Resolution of ';
          }

          vm.helpLink = '';
          if (fileGroup === 1) {
            vm.title += 'Front of Package Transcript: ';
          } else if (fileGroup === 2) {
            vm.title += 'Nutrition Transcribe: ';
          } else {
            vm.title += 'Transcribe [file_group=' + fileGroup.toString() + ']: ';
          }

          if (params.region.toString() === '1') {
            vm.title += 'US';
          } else if (params.region.toString() === '2') {
            vm.title += 'UK';
          } else {
            vm.title += '??';
          }

          if (+fileGroup === 2 && +params.region === 2) {
            vm.helpLink = 'task-nutrition-transcribe-uk';
          }
        } else if (type === 4) {
          vm.helpLink = 'select-best-photo-task';
          vm.title += 'Select Best: ';
        } else if (type === 6) {
          vm.title += 'Custom transcription';
        }

        vm.photoType = fileGroup === 1 && 'Front of Package' ||
          fileGroup === 2 && 'Nutrition Label' ||
          fileGroup === 3 && 'Ingredient Statement' ||
          'file_group=' + fileGroup.toString();

        if (type === 1 || type === 2 || type === 4 || type === 5) {
          vm.title += ' ' + vm.photoType;
        }

        let flow = $q.resolve();

        if (task) {
          vm.currentTask = task;
          task           = null;
          return $q.resolve(vm.currentTask);
        } else {
          const url = $location.url();
          $window.history.forward();

          flow = $q((resolve, reject) => {
            $timeout(function () {
              if (url !== $location.url()) {
                return reject('cancelled');
              }
              resolve();
            }, 500);
          });
        }

        return flow.then(() => {
          return $http.get('/api/tasks/' + type, {params: params})
            .then(response => response.data)
            .then(function (task) {
              if (task) {
                vm.currentTask  = task;
                task.file_group = parseInt(task.file_group, 10);

                $state.go(
                  $state.current.name,
                  _.merge({}, $state.params, {taskId: task.id}),
                  {reload: false, notify: false, location: !$state.params.taskId ? 'replace' : true}
                );
              } else {
                $notification.info('No more tasks found');
                $state.go('app.tasks.stats');
              }

              return task;
            })
            .catch(function (error) {
              $notification.error('Sorry, failed loading task. Please try reloading the page.');
              return $q.reject(error);
            });
        })
      };
    }
  );



