(function () {
    'use strict';

    angular.module('app.directives')
        .constant('developerMode', location.href.indexOf('developer') > -1)
        .directive('unreleased', function (developerMode) {
            return function (scope, element /*, attributes*/) {
                element.toggle(developerMode);
            };
        });
}());
