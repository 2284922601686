(function () {
  'use strict';

  const moduleName = 'recommended-products';

  const moduleFullName = `app.modules.${moduleName}`;

  const state       = stateName => `app.${moduleName}` + (stateName ? `.${stateName}` : '');
  const templateUrl = viewName => `modules/${moduleName}/${moduleName}.${viewName}.html`;
  const controller  = controllerName => `${moduleFullName}.${controllerName} as vm`;

  angular.module(moduleFullName, [])
    .config(function ($stateProvider) {
      $stateProvider
        .state(state(), {
          url:      `/${moduleName}`,
          abstract: true,
          template: `<div class="module_${moduleName}" ui-view></div>`,
          data:     {
            permissions: ['admin']
          }
        })
        .state(state('list'), {
          url:         '',
          controller:  controller('ListCtrl'),
          templateUrl: templateUrl('list'),
          onEnter:     function ($anchorScroll) {
            $anchorScroll();
          }
        })
        .state(state('create'), {
          url:         '/create',
          controller:  controller('SaveCtrl'),
          templateUrl: templateUrl('save'),
          onEnter:     function ($anchorScroll) {
            $anchorScroll();
          }
        })
        .state(state('edit'), {
          url:         '/:id',
          controller:  controller('SaveCtrl'),
          templateUrl: templateUrl('save'),
          onEnter:     function ($anchorScroll) {
            $anchorScroll();
          }
        });

      angular.injector(['ng']).get('$log')
        .debug(`[${moduleName}] Module configured`);
    })
    .run(function ($log) {
      $log.debug(`[${moduleName}] Module launched`);
    });
}());


