(function () {
  'use strict';

  const moduleName     = 'claims',
        controllerName = 'SaveCtrl';

  const moduleFullName = `app.modules.${moduleName}`;

  angular.module(moduleFullName)
    .controller(`${moduleFullName}.${controllerName}`, SaveCtrl);

  /*@ngInject*/
  function SaveCtrl($log, $state, $http, $notification, $q, $upload,
                    $filter, confirm, utils, $location, $sessionStorage, sources) {
    $log.debug(`[${moduleName}] Controller "${controllerName}" launched`);

    const vm  = this;
    const url = '/api/claims' + ($state.params.id ? '/' + $state.params.id : '');

    let flow;

    if ($state.params.id) {
      flow = $http.get(url)
        .then(response => response.data);
    } else {
      flow = $q.resolve({
        nixClaimId:    '',
        claim:         '',
        fda_regulated: 0,
        on_pack_claim: 0,
        completed:     0,
        created_by:    ''
      });
    }

    flow.then(model => {
      vm.model = model;
    });

    vm.save = () => {
      vm.$busy  = true;
      vm.$error = null;

      $http.post(url, vm.model)
        .then(response => {
          $notification.info('Claim Saved');

          _.extend(vm.model, response.data);

          if (!$state.params.id) {
            $state.go(utils.state('edit'), {id: response.data.id});
          }
        })
        .catch(response => vm.$error = $filter('readError')(response.data))
        .finally(() => vm.$busy = false);
    };

    vm.delete = () => {
      vm.$busy  = true;
      vm.$error = null;

      confirm('Are you sure you want to delete this claim?')
        .then(() => {
          return $http.delete(url);
        })
        .then(() => {
          $notification.info('Claim Deleted');
          $state.go(utils.state('list'));
        })
        .catch(response => vm.$error = $filter('readError')(response.data))
        .finally(() => vm.$busy = false);
    };

    vm.goBack = ($event = null) => {
      if ($event) {
        $event.preventDefault();
      }

      if ($sessionStorage.claimsListState) {
        $location.url($sessionStorage.claimsListState);
      } else {
        $state.go(utils.state('list'));
      }
    };

    vm.changeCustom = () => {
      vm.form.claim.$setDirty();
      if(vm.model.claim){
        if (vm.model.custom) {
          if (vm.model.claim.toLowerCase().indexOf('custom') === -1) {
            vm.model.claim += ' (Custom)';
          }
        } else {
          vm.model.claim = vm.model.claim.replace(/\s*\(?custom\)?\s*/i, '');
        }
      }
    };

    vm.sources = sources;
  }
}());
