(function () {
  'use strict';

  const moduleName     = 'ingredient-learning.dual-entry',
        controllerName = 'SkippedCtrl';

  const moduleFullName = `app.modules.${moduleName}`;

  angular.module(moduleFullName)
    .controller(`${moduleFullName}.${controllerName}`, SkippedCtrl);

  /*@ngInject*/
  function SkippedCtrl($log, $http, $notification, $sessionStorage,
                       $filter, ngTableParams, $location) {
    $log.debug(`[${moduleName}] Controller "${controllerName}" launched`);

    const vm = this;

    vm.search = {
      term:    $location.search().q || '',
      perform: function () {
        vm.tableParams.page(1);
        vm.tableParams.reload();
      }
    };

    const defaultOrder = 'conflict_started_at.desc';
    vm.tableParams     = new ngTableParams(
      {
        page:    $location.search().page || 1,            // show first page
        count:   10,           // count per page
        sorting: (function () {
          let orderBy = $location.search().orderBy || defaultOrder;
          orderBy     = orderBy.split('.');

          let result         = {};
          result[orderBy[0]] = orderBy[1];

          return result;
        }())
      },
      {
        counts:  [],
        total:   0, // length of data
        getData: function ($defer, params) {
          $location.search('page', params.page() > 1 ? params.page() : null);

          $location.search('q', vm.search.term || null);

          let orderBy = null;
          if (_.keys(params.sorting()).length) {
            let [sortingAttribute, sortingDirection] = _.pairs(params.sorting())[0];
            orderBy                                  = `${sortingAttribute}.${sortingDirection}`;
          }

          $location.search('orderBy', orderBy === defaultOrder ? null : orderBy);


          $http.get('/api/ingredient-learning/ingredients', {
              params: {
                limit:           params.count(),
                offset:          (params.page() - 1) * params.count(),
                q:               vm.search.term || undefined,
                orderBy:         orderBy,
                flagged_type:    'skipped'
              }
            })
            .then(response => {
              params.total(response.data.total);
              $defer.resolve(response.data.ingredients);

              $sessionStorage.ingredientLearningQueueState = $location.url();
            })
            .catch(response => {
              $notification.error(
                'Failed to load ingredients: ' + $filter('readError')(response.data)
              );

              $defer.reject();
            });
        }
      }
    );
  }
}());
