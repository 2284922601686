(function () {
  'use strict';

  const moduleName     = 'tasks',
        controllerName = 'FlaggedTasksReportDetailCtrl';

  const moduleFullName = `app.modules.${moduleName}`;

  angular.module(moduleFullName)
    .controller(`${moduleFullName}.${controllerName}`, FlaggedTasksReportDetailCtrl);

  /*@ngInject*/
  function FlaggedTasksReportDetailCtrl($state, $log, $http, $notification, $filter,
                                        ngTableParams, $location, confirm, endlessTotal) {
    $log.debug(`[${moduleName}] Controller "${controllerName}" launched`);

    const vm = this;

    vm.upc = $state.params.upc;

    vm.getType = type => {
      switch (type) {
        case 1:
          return 'Rotate';
        case 2:
          return 'Crop';
        case 3:
          return 'Transcribe';
        case 4:
          return 'Select best photo';
        case 5:
          return 'Conflict resolution';
        case 6:
          return 'Transcribe (custom project)';
        case 7:
          return 'Conflict resolution (custom project)';
      }
    };

    vm.getFileGroup = fileGroup => {
      switch (fileGroup) {
        case 1:
          return 'Front of Package';
        case 2:
          return 'Nutrition Label';
      }
    };

    vm.data = [];

    vm.tableParams = new ngTableParams(
      {
        page:    $location.search().page || 1,            // show first page
        count:   25,           // count per page
        sorting: (function () {
          let sort = $location.search().sort;
          if (sort) {
            sort = sort.split('.');

            let result      = {};
            result[sort[0]] = sort[1];

            return result;
          }

          return {id: 'desc'};
        }())
      },
      {
        counts:  [],
        total:   0, // length of data
        getData: function ($defer, params) {
          const page = params.page();
          $location.search('page', page > 1 ? page : null);

          let [sortingAttribute, sortingDirection] = _.pairs(params.sorting())[0];
          let orderBy                              = `${sortingAttribute}.${sortingDirection}`;

          $location.search('sort', orderBy === 'id.desc' ? null : orderBy);

          $http.get(`/api/tasks/reports/flagged-tasks/${$state.params.upc}`, {
            params: {
              orderBy,
              page,
              count: params.count()
            }
          })
            .then(response => {
              params.total(endlessTotal(params, response.data.length));
              $defer.resolve(response.data);
            })
            .catch(response => $notification.error(
              'Failed to load tasks: ' + $filter('readError')(response.data)
            ));
        }
      }
    );

    vm.markUpc = function (type = 'bad') {
      let message, update;

      if (type === 'bad') {
        message = "Are you sure you want to mark this UPC bad and hide it forever?";
        update  = {bad_upc: 1};
      } else if (type === 'no-nutrition') {
        message = "Are you sure you want to mark this UPC having no nutrition info and hide it forever?";
        update  = {missing_nutrition_label: 1};
      }

      confirm(message).then(() => {
        $http.post('/api/reports/masterUpc/' + vm.upc, update)
          .then(function () {
            $notification.info('marked bad upc');
            $state.go('app.tasks.flagged-tasks-report');
          })
          .catch(function () {
            $notification.error('failed to update');
          });
      });
    };
  }
}());
