'use strict';

angular.module('app.services')
  .factory('arrayRefill', function arrayRefillFactory() {
    return function arrayRefill(destination, source, identityAttribute = 'id') {
      let destinationIndex = 0;

      destination.slice(0).forEach((item) => {
        const index = _.findIndex(source, sourceItem => sourceItem[identityAttribute] === item[identityAttribute]);

        if (index < 0 || index !== destinationIndex) {
          _.pull(destination, item);
        } else {
          destinationIndex += 1;
        }
      });

      source.forEach(item => {
        if (!_.find(destination, destinationItem => destinationItem[identityAttribute] === item[identityAttribute])) {
          destination.push(item);
        }
      });

      return destination;
    };
  });
