'use strict';

angular.module('app.controllers')
    .controller('RecipeBuilderCtrl',
        function ($scope, Recipe, debounce, $state, $modal,
                  $filter, $q, session, $http, Tag, $timeout,
                  nixTrackApiClient, suggest) {
            let vm = $scope.vm = this;
            var recipe, init, estimate;

            $scope.total = {
                weight:   function () {
                    if (recipe && recipe.servings) {
                        return recipe.total_weight / recipe.servings;
                    }
                },
                calories: function () {
                    if (recipe && recipe.servings) {
                        return recipe.total_calories / recipe.servings;
                    }
                }
            };

            $scope.copy = function () {
                if ($scope.recipeForm.$invalid || $scope.weightsForm.$invalid) {
                    return;
                }

                var copy = _.pick(
                    $scope.recipe,
                    ['servings', 'serving_name']
                );

                copy.weights = _.map(
                    $scope.recipe.weights,
                    function (item) {
                        return _.pick(item, ['qty', 'unit', 'seq', 'serving_weight_grams']);
                    }
                );

                copy.ingredients = _.map(
                    $scope.recipe.ingredients,
                    function (item) {
                        return _.pick(item, ['raw_food', 'raw_measure']);
                    }
                );

                $modal.open({
                    backdrop:    'static',
                    templateUrl: 'recipe-builder/copy-modal.html',
                    resolve:     {
                        recipe: function () {
                            return copy;
                        }
                    },
                    controller:  function ($scope, $modalInstance, recipe) {
                        $scope.recipe = recipe;
                        $scope.yes    = function () {
                            recipe.$error = null;
                            Recipe.validate(recipe).$promise.then(function (validation) {
                                if (validation.$errors) {
                                    recipe.$error = validation.$errors[0].message;
                                    return $q.reject();
                                }
                            }).then(function () {
                                Recipe.save(recipe).$promise.then(function (saved) {
                                    $modalInstance.close();
                                    $state.go('app.recipebuilder.edit', {id: saved.id});
                                });
                            });
                        };

                        $scope.cancel = function () {
                            $modalInstance.dismiss('cancel');
                        };
                    }
                });
            };

            init = function () {
                if ($state.params.id) {
                    return Recipe.get(
                        {id: $state.params.id},
                        function (responseRecipe) {
                            recipe = $scope.recipe = responseRecipe;
                        }
                    ).$promise;
                } else {
                    recipe = $scope.recipe = new Recipe({
                        servings:       1,
                        name:           $state.params.name || '',
                        staging_tag_id: $state.params.tag || null
                    });
                    recipe.addIngredient();

                    if ($scope.recipe.name) {
                        $scope.validate();
                    }

                    if ($scope.recipe.staging_tag_id) {
                        Tag.get({id: $state.params.tag}).$promise
                            .then(function (tag) {
                                $scope.addTag.select(tag);
                                $scope.addTag.text = tag.name;
                            });
                    }
                }
            };

            $scope.validateWeightUniqueness = function (weight, weightIndex) {
                var i, otherWeight;
                for (i = 0; i < recipe.weights.length; i += 1) {
                    otherWeight = recipe.weights[i];

                    if (
                        otherWeight !== weight &&
                        otherWeight.qty.toString() === weight.qty.toString() &&
                        otherWeight.unit.toString() === weight.unit.toString()
                    ) {

                        $scope.weightsForm['weight_qty_' + weightIndex].$setDirty(true);
                        $scope.weightsForm['weight_qty_' + weightIndex].$setValidity('unique', false);
                        $scope.weightsForm['weight_unit_' + weightIndex].$setDirty(true);
                        $scope.weightsForm['weight_unit_' + weightIndex].$setValidity('unique', false);

                        break;
                    } else {
                        $scope.weightsForm['weight_qty_' + weightIndex].$setValidity('unique', true);
                        $scope.weightsForm['weight_unit_' + weightIndex].$setValidity('unique', true);
                    }
                }
            };

            $scope.submit = function (publish, approve) {
                delete recipe.$error;

                if ($scope.recipeForm.$invalid ||
                    $scope.weightsForm.$invalid ||
                    (publish && !recipe.getIsPublishable())) {
                    return;
                }

                if (approve) {
                    recipe.approve = 1;
                }

                if (approve === 0) {
                    recipe.approve = 0;
                }

                var promise;
                recipe.$busy = true;
                promise      = recipe.$save();

                if (publish) {
                    promise = promise
                        .then(function () {
                            recipe.$busy = true; // updated recipe will loose this
                            return Recipe.publish(recipe).$promise;
                        })
                        .then(function () {
                            if ($state.params && $state.params.id) {
                                return init();
                            }
                        });
                }

                promise
                    .then(function (saved) {
                        recipe.staging_tag_name = saved.staging_tag_name;
                        if (!($state.params && $state.params.id)) {
                            $state.go('app.recipebuilder.edit', {id: recipe.id});
                        }
                    })
                    .catch(function (response) {
                        recipe.$error = response.data;
                    })
                    .finally(function () {
                        recipe.$busy = false;
                    });
            };

            $scope.del = function () {
                var modalInstance = $modal.open({
                    backdrop:    'static',
                    templateUrl: 'recipe-builder/confirm-delete-modal.html',
                    controller:  function ($scope, $modalInstance) {
                        $scope.yes = function () {
                            $modalInstance.close();
                        };

                        $scope.cancel = function () {
                            $modalInstance.dismiss('cancel');
                        };
                    }
                });

                modalInstance.result.then(function () {
                    recipe.$delete(function () {
                        $state.go('app.recipes');
                    });
                });
            };

            $scope.validate = debounce(function () {
                Recipe.validate(recipe, function (validatedRecipe) {
                    recipe.$errors = validatedRecipe.$errors;
                    if (validatedRecipe.$errors) {
                        angular.forEach(validatedRecipe.$errors, function (error) {
                            $scope.recipeForm[error.field].$setValidity(error.validator, false);
                        });
                    } else {
                        angular.forEach($scope.recipeForm.$error, function (dummy, error) {
                            angular.forEach(['name'], function (attribute) {
                                $scope.recipeForm[attribute].$setValidity(error, true);
                            });
                        });
                    }
                });
            }, 500);

            estimate = function (ingredient) {
                ingredient.$error = null;
                if (ingredient.raw_food) {
                    ingredient.$estimating = true;

                    nixTrackApiClient.natural.nutrients(ingredient.raw_measure + ' ' + ingredient.raw_food)
                        .then(response => {
                            const estimation = response.data.foods[0] || {};

                            ingredient.calories       = estimation.nf_calories;
                            ingredient.match_status   = true;
                            ingredient.ndb_number     = estimation.ndb_no;
                            ingredient.serving_qty    = parseFloat(estimation.serving_qty);
                            ingredient.serving_unit   = estimation.serving_unit;
                            ingredient.serving_weight = parseFloat(estimation.serving_weight_grams) || 0;

                            recipe.calculateTotals();
                        })
                        .catch(response => {
                            const data = response.data;

                            ingredient.$error = {
                                message: $filter('readError')(data) || 'Unexpected estimation error',
                                query:   ingredient.raw_measure + ' ' + ingredient.raw_food
                            };

                            ingredient.calories       = null;
                            ingredient.match_status   = false;
                            ingredient.ndb_number     = null;
                            ingredient.serving_qty    = null;
                            ingredient.serving_unit   = null;
                            ingredient.serving_weight = null;
                        })
                        .finally(() => {
                            ingredient.$estimating = false;
                        });
                } else {
                    ingredient.calories       = 0;
                    ingredient.serving_weight = 0;
                    ingredient.tier           = null;
                    ingredient.ndb_number     = null;
                    ingredient.serving_qty    = null;
                    ingredient.serving_unit   = null;

                    recipe.calculateTotals();
                }
            };

            $scope.estimate = debounce(estimate, 500);

            $scope.estimateAll = function () {
                angular.forEach(recipe.ingredients, function (ingredient) {
                    estimate(ingredient);
                });
            };

            $scope.removeIngredient = function (ingredient) {
                recipe.removeIngredient(ingredient);
            };

            init();

            $scope.getTags = suggest.tags;

            $scope.$watch(function () {
                return recipe && recipe.staging_tag_id || $scope.recipeForm && $scope.recipeForm.tag;
            }, function () {
                if (recipe && $scope.recipeForm && $scope.recipeForm.tag) {
                    $scope.recipeForm.tag.$setValidity(
                        'tag-assigned',
                        !!recipe.id || !!recipe.staging_tag_id
                    );
                }
            });

            $scope.addTag = {
                text:   '',
                tag:    null,
                error:  null,
                select: function (tag) {
                    this.tag                = tag;
                    this.text               = tag.name;
                    recipe.staging_tag_id   = this.tag.id;
                    recipe.staging_tag_name = this.tag.name;
                },
                clear:  function (soft) {
                    $scope.addTag.text  = '';
                    $scope.addTag.tag   = null;
                    $scope.addTag.error = null;

                    if (!soft && recipe) {
                        recipe.staging_tag_id   = null;
                        recipe.staging_tag_name = null;
                    }
                },
                create: function () {
                    $modal.open({
                        size:        'md',
                        templateUrl: '/views/tags/create.html',
                        controller:  'CreateTagCtrl'
                    }).result.then(tag => {
                        if (tag.parent) {
                            tag.name = `${tag.parent.name} > ${tag.name}`;
                        }
                        this.select(tag);
                    });
                }
            };

            vm.focusNewIngredient = () => {
                $timeout(() => {
                    $('table#ingredients tbody tr.ingredient').last().find('input').first().focus();
                }, 0)
            }
        }
    );
