(function () {
  'use strict';

  const moduleName = 'email-file-delivery';

  const moduleFullName = `app.modules.${moduleName}`;

  const state       = stateName => `app.${moduleName}` + (stateName ? `.${stateName}` : '');
  const templateUrl = viewName => `modules/${moduleName}/${moduleName}.${viewName}.html`;
  const controller  = controllerName => `${moduleFullName}.${controllerName} as vm`;

  angular.module(moduleFullName, ['ngTagsInput', 'hc.marked'])
    .config(function ($stateProvider) {
      $stateProvider
        .state(state(), {
          url:         `/${moduleName}`,
          abstract:    true,
          templateUrl: templateUrl('header'),
          controller:  controller('HeaderCtrl'),
          data:        {
            permissions: ['admin']
          },
          resolve:     {
            utils:          () => ({state, templateUrl, controller}),
            frequencies:    () => {
              const frequencies = [
                {cron: '0 0 12 1 * ? *', title: 'monthly (1st)'},
                {cron: '0 0 12 3,17 * ? *', title: 'bi-weekly (3st and 17th)'}
              ];

              frequencies.forEach(f => {
                frequencies[f.cron] = f.title;
              });

              return frequencies;
            },
            _distributions: ($http, $notification, $filter) => () => $http.get('/api/email-file-delivery/distributions')
              .then(response => response.data)
              .catch(response => $notification.error('Could not load distributions ' + $filter('readError')(response.data))),
            _fileUrls:      ($http, $notification, $filter) => () => $http.get('/api/email-file-delivery/file-urls')
              .then(response => response.data)
              .catch(response => $notification.error('Could not load file urls ' + $filter('readError')(response.data)))
          }
        })
        .state(state('recipients'), {
          url:      `/recipients`,
          abstract: true,
          template: '<div ui-view></div>',
          resolve:  {
            distributions: _distributions => _distributions()
          }
        })
        .state(state('recipients.list'), {
          url:         '',
          controller:  controller('RecipientsListCtrl'),
          templateUrl: templateUrl('recipients.list'),
          onEnter:     $anchorScroll => $anchorScroll(),
          resolve: {
            recipients:    ($http, $notification, $filter) => $http.get('/api/email-file-delivery/recipients')
              .then(response => response.data)
              .catch(response => $notification.error('Could not load recipients ' + $filter('readError')(response.data)))
          }
        })
        .state(state('recipients.create'), {
          url:         '/create',
          controller:  controller('RecipientsSaveCtrl'),
          templateUrl: templateUrl('recipients.save'),
          onEnter:     function ($anchorScroll) {
            $anchorScroll();
          },
        })
        .state(state('recipients.edit'), {
          url:         '/:id',
          controller:  controller('RecipientsSaveCtrl'),
          templateUrl: templateUrl('recipients.save'),
          onEnter:     function ($anchorScroll) {
            $anchorScroll();
          },
        })
        .state(state('distributions'), {
          url:      `/distributions`,
          abstract: true,
          template: '<div ui-view></div>'
        })
        .state(state('distributions.list'), {
          url:         '',
          controller:  controller('DistributionsListCtrl'),
          templateUrl: templateUrl('distributions.list'),
          onEnter:     $anchorScroll => $anchorScroll(),
          resolve:     {
            distributions: _distributions => _distributions(),
            fileUrls:      _fileUrls => _fileUrls()
          }
        })
        .state(state('distributions.create'), {
          url:         '/create',
          controller:  controller('DistributionsSaveCtrl'),
          templateUrl: templateUrl('distributions.save'),
          onEnter:     function ($anchorScroll) {
            $anchorScroll();
          },
        })
        .state(state('distributions.edit'), {
          url:         '/:id',
          controller:  controller('DistributionsSaveCtrl'),
          templateUrl: templateUrl('distributions.save'),
          onEnter:     function ($anchorScroll) {
            $anchorScroll();
          },
        })
        .state(state('file-urls'), {
          url:      `/file-urls`,
          abstract: true,
          template: '<div ui-view></div>'
        })
        .state(state('file-urls.list'), {
          url:         '',
          controller:  controller('FileUrlsListCtrl'),
          templateUrl: templateUrl('file-urls.list'),
          onEnter:     $anchorScroll => $anchorScroll(),
          resolve:     {
            fileUrls:      _fileUrls => _fileUrls()
          }
        });

      angular.injector(['ng']).get('$log')
        .debug(`[${moduleName}] Module configured`);
    })
    .run(function ($log) {
      $log.debug(`[${moduleName}] Module launched`);
    });
}());


