'use strict';

angular.module('app.controllers')
    .controller('ReportPhrasesCtrl',
        function ($scope, ReportPhrase, ngTableParams, $state, debounce, $q, $window, $http, suggest, $modal, $notification, Tag) {
            var win, promises, resolvePromises;

            if ($state.current.name === 'app.reports.phrases') {
                $scope.tasked = 0;
            } else {
                $scope.tasked = 1;
            }

            $scope.search = {
                term:    '',
                perform: function () {
                    $scope.tableParams.page(1);
                    $scope.tableParams.reload();
                }
            };

            win = {
                locked: false,
                locker: function () {
                    return "Not all operations where processed. Do you want to leave this page?";
                },
                lock:   function () {
                    if (!this.locked) {
                        this.locked = true;
                        angular.element($window).on('beforeunload', this.locker);
                    }

                },
                unlock: function () {
                    this.locked = false;
                    angular.element($window).off('beforeunload', this.locker);
                }
            };

            $scope.$on('$stateChangeStart', function (event) {
                if (win.locked && !confirm(win.locker())) {
                    event.preventDefault();
                }
            });

            promises = [];
            resolvePromises = debounce(function () {
                $q.all(promises)
                    .then((function (promisesLength) {
                        return function () {
                            if (promises.length === promisesLength) {
                                $scope.tableParams.reload();
                                win.unlock();
                            }
                        };
                    }(promises.length)));
            }, 1000);

            $scope.change = function (phrase, action) {
                _.remove($scope.tableParams.data, phrase);

                promises.push(ReportPhrase.save(
                    {action: action},
                    phrase
                ).$promise);

                win.lock();

                resolvePromises();
            };

            $scope.markPhraseTypo = function (phrase) {
                $http.put(`/api/phrases/${phrase.id}/mark-typo`, {is_typo: phrase.is_typo})
                    .then(function () {
                        let action = phrase.is_typo ? 'marked' : 'unmarked';
                        $notification.info(`Phrase ${action} as typo`);
                    })
                    .catch(function (/* error */) {
                        $notification.error('Phrase typo status update failed');
                    });
            };

            $scope.createTag = function (phrase) {
                var modalInstance = $modal.open({
                    animation:   true,
                    templateUrl: 'reports/phrases-todo/create-tag.html',
                    controller:  function ($scope, phrase, $modalInstance) {
                        $scope.tag = {
                            name:      phrase.text,
                            parent_id: 0,
                            phraseId:  phrase.id
                        };

                        $scope.suggestTags = suggest.tags;

                        $scope.setParentTag = function (tag) {
                            $scope.tag.parent_id = tag && tag.id || 0;
                        };

                        $scope.clearParentTag = function () {
                            $scope.tag.parent_id = 0;
                            $scope.tagSuggestionQuery = '';
                        };

                        $scope.close = function () {
                            $modalInstance.dismiss('cancel');
                        };

                        $scope.save = function () {
                            $http.post('/api/tags/manage', $scope.tag)
                                .success(function () {
                                    $notification.info('Tag Created');
                                    $modalInstance.close();
                                })
                                .error(function () {
                                    $notification.error('Tag Not Created');
                                });
                        };

                        $scope.validate = function () {
                            if ($scope.tag.name && $scope.tagForm) {
                                $scope.validate.$busy = true;
                                $scope.tagForm.tagName.$setValidity('available', true);
                                $scope.validate.execute();
                            }
                        };

                        $scope.validate.execute = debounce(function () {
                            if (!$scope.tag.name) {
                                $scope.tagForm.tagName.$setValidity('available', true);
                                $scope.validate.$busy = false;
                                return;
                            }

                            $http.get('/api/tags/find', {params: {name: $scope.tag.name}})
                                .success(function (found) {
                                    if ($scope.tagForm) {
                                        $scope.tagForm.tagName.$setValidity('available', !found);
                                    }
                                })
                                .error(function () {
                                    $notification.error('Validation failed');
                                })
                                .finally(function () {
                                    $scope.validate.$busy = false;
                                });
                        }, 500);

                        $scope.$watch(function () {
                            return $scope.tagForm && $scope.tag.name;
                        }, function () {
                            $scope.validate();
                        });
                    },
                    size:        'md',
                    resolve:     {
                        phrase: function () {
                            return phrase;
                        }
                    }
                });

                modalInstance.result.then(function () {
                    $scope.tableParams.reload();
                });
            };

            $scope.assignPhrase = function (phrase) {
                var modalInstance = $modal.open({
                    animation:   true,
                    templateUrl: 'reports/phrases-todo/assign-phrase.html',
                    controller:  function ($scope, phrase, $modalInstance, Tag) {
                        let vm = $scope.vm = this;

                        $scope.mode = 'phrase';

                        $scope.phrase = phrase;

                        $scope.suggest = suggest;

                        vm.phraseSuggestionQuery = phrase.text;

                        $scope.suggestPhrases = function () {
                            if (vm.phraseSuggestionQuery) {
                                suggest.similar_phrases(vm.phraseSuggestionQuery)
                                    .then(function (phrases) {
                                        $scope.phrases = phrases;
                                    });
                            } else {
                                $scope.phrases = [];
                            }
                        };

                        $scope.suggestPhrases();

                        $scope.setTag = function (tag, save = false) {
                            if (!angular.isObject(tag)) {
                                tag = {id: tag};
                            }

                            $scope.phrase.exact_match_id = tag && tag.id || null;
                            $scope.phrase.tag_id = tag && tag.id || null;
                            $scope.phrase.exact_match_type = tag && 3 || null;

                            if (save) {
                                $scope.save();
                            }
                        };

                        $scope.clearTag = function () {
                            $scope.phrase.exact_match_id = null;
                            $scope.phrase.tag_id = null;
                            $scope.phrase.exact_match_type = null;
                        };

                        $scope.close = function () {
                            $modalInstance.dismiss('cancel');
                        };

                        $scope.save = function () {
                            Tag.phrases.save($scope.phrase).$promise
                                .then(function () {
                                    $notification.info('Phrase assigned to the tag');
                                    $modalInstance.close();
                                })
                                .catch(function () {
                                    $notification.error('Phrase not updated');
                                });
                        };

                    },
                    size:        'md',
                    resolve:     {
                        phrase: function () {
                            return phrase;
                        }
                    }
                });

                modalInstance.result.then(function () {
                    $scope.tableParams.reload();
                });
            };

            $scope.tableParams = new ngTableParams(
                {
                    page:    1,            // show first page
                    count:   25,           // count per page
                    sorting: {
                        count: 'desc'     // initial sorting
                    }
                },
                {
                    counts: [],
                    total:   0, // length of data
                    getData: function ($defer, params) {
                        var sort, promisesLength = promises.length;
                        angular.forEach(params.sorting(), function (order, column) {
                            sort = [column, order].join('.');
                        });
                        ReportPhrase.list(
                            {
                                tasked:  $scope.tasked,
                                limit:   params.count(),
                                offset:  (params.page() - 1) * params.count(),
                                search:  $scope.search.term,
                                orderBy: sort
                            }
                        ).then(
                            function (itemsData) {
                                $scope.dataLoaded = true;

                                if (promisesLength === promises.length) {
                                    params.total(itemsData.count);
                                    $defer.resolve(itemsData.results);
                                } else {
                                    $defer.reject('cancelled by other request');
                                }
                            }
                        );
                    }
                });
        }
    );
