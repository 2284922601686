'use strict';

angular.module('app.controllers')
    .controller('MissingIngredientsCtrl',
        function ($scope, $state, $http, $notification, ngTableParams, $filter, $location) {
            const vm = $scope.vm = this;
            vm.results = [];

            $http.get('/reports/missing-ingredient-statements')
                .then(response => {
                    vm.results = response.data;
                    $scope.tableParams.reload();
                });

            vm.removeRecordFromQueue = async (item) => {
              await $http.delete(`/reports/missing-ingredient-statements/queue/${+item.upc}`);
            };

            vm.removeResult = async (item) => {
                _.pull(vm.results, item);
                $scope.tableParams.reload();
                await vm.removeRecordFromQueue(item);
            };

            $scope.tableParams = new ngTableParams(
                {
                    page:    parseInt($location.search().page) || 1,
                    count:   50,           // count per page
                    filter:  (function () {
                        const filter = {};

                        _.forEach($location.search(), (value, key) => {
                            const parts = key.split('.');
                            if (parts[0] === 'filter') {
                                filter[parts[1]] = value;
                            }
                        });

                        return filter;
                    }()),
                    sorting: (function () {
                        const parts    = ($location.search().sort || 'scans_30day.desc').split('.');
                        const sort     = {};
                        sort[parts[0]] = parts[1];

                        return sort;
                    }())
                },
                {
                    counts:  [],
                    total:   0, // length of data
                    getData: function ($defer, params) {
                        let viewData = vm.results;

                        $location.search('page', params.page() === 1 ? null : params.page());
                        const sort = _.pairs(params.sorting())[0].join('.');
                        $location.search('sort', sort === 'scan_count.desc' ? null : sort);

                        if (_.values(params.filter()).length) {
                            viewData = $filter('filter')(viewData, params.filter());

                            _.forEach(params.filter(), (value, key) => {
                                $location.search('filter.' + key, value);
                            });
                        }

                        viewData = $filter('orderBy')(
                            viewData,
                            _.keys(params.sorting())[0],
                            _.values(params.sorting())[0] === 'desc'
                        );

                        params.total(viewData.length);

                        viewData = viewData.slice(
                            (params.page() - 1) * params.count(),
                            params.page() * params.count()
                        );

                        $defer.resolve(viewData);
                    }
                });

            $scope.difficult = function (record) {
                record.difficult_ing = 1;

                vm.removeResult(record);

                $http.post('/api/reports/masterUpc/' + record.upc, record)
                    .then(function () {
                        $notification.info(record.upc + ' marked difficult');
                    })
                    .catch(function () {
                        $notification.error('failed to update');
                    });
            };
        }
    );
