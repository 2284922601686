'use strict';

angular.module('app.controllers')
    .controller('IngredientStatementTagsCtrl', function ($scope, $http, $filter, $notification, $location) {
        const vm = $scope.vm = this;

        vm.getCheckedIngredients = () => {
            if (!vm.getCheckedIngredients.$cache) {
                vm.getCheckedIngredients.$cache = $filter('filter')(vm.ingredients, {$assign: true})
            }

            return vm.getCheckedIngredients.$cache;
        };

        vm.getCheckedIngredients.dropCache = () => {
            vm.getCheckedIngredients.$cache = null;
        };

        $http.get('/api/ingredient-statement-tags')
            .then(response => {
                vm.ingredientTags = response.data;
                vm.tag = vm.ingredientTags[0];
            });

        vm.search = $location.search().search || '';

        vm.ingredients = [];
        vm.loadIngredients = () => {
            if (vm.search) {
                let search = vm.search;
                $location.search('search', search);
                $http.get('/api/ingredient-statement-tags/ingredients', {params: {search: vm.search}})
                    .then(response => {
                        if (search === vm.search) {
                            vm.ingredients = response.data;
                            vm.getCheckedIngredients.dropCache();
                        }
                    })
            } else {
                vm.ingredients = [];
                vm.getCheckedIngredients.dropCache();
            }

        };

        vm.toggleAll = () => {
            vm.ingredients.forEach(i => i.$assign = !!vm.toggleAll.$value);
            vm.getCheckedIngredients.dropCache();
        };

        vm.applyTag = () => {
            vm.getCheckedIngredients.dropCache();
            let ingredients = vm.getCheckedIngredients();



            if (ingredients.length) {
                $http.post('/api/ingredient-statement-tags/assign', {tag_id: vm.tag.id, ingredients})
                    .then(response => {
                        $notification.info('Ingredients where tagged successfully');
                        vm.loadIngredients();
                    })
                    .catch(response => {
                        $notification.error('Ingredients could not be tagged: ' + $filter('readError')(response.data));
                    });
            }
        };

        vm.loadIngredients();
    });
