(function () {
  'use strict';

  const moduleName = 'track-personal-identification-tool',
  controllerName = 'TrackPersonalIdentificationToolIndexCtrl';
  const moduleFullName = `app.modules.${moduleName}`;  

  angular.module(moduleFullName)
    .controller(`${moduleFullName}.${controllerName}`, TrackPersonalIdentificationToolIndexCtrl);

  /*@ngInject*/
  function TrackPersonalIdentificationToolIndexCtrl($scope, $http, $notification, $filter, $log, confirm) {
    $log.debug(`[${moduleName}] Controller "${controllerName}" launched`);

    const vm = this;    
    vm.foundUsers = []
    vm.maxUsersReached = false
      
    vm.reset = ()=>{
      vm.searching = false
      vm.searched = false;
      vm.searchEmail = ''
      vm.isDeleting = false
    }
    vm.reset();

    vm.searchOnReturn = (event)=>{
      if (event.keyCode === 13) {
        vm.search();
      }
    };

    vm.errorHandler = (error) =>{
      vm.reset();
      let errorMessage = $filter('readError')(error.data);
      if(errorMessage){
        $notification.error(`Error: ${errorMessage}`);
      } else {
        $notification.error('An error has occurred');
      }
    }

    vm.search = async ()=>{
      vm.foundUsers = [];
      vm.searching = true;
      vm.searched = true;
      try{
        let result = (await $http.get('/api/track/users/search', {params: {email: vm.searchEmail}})).data;
        vm.foundUsers = result.users;
        vm.maxUsersReached = result.maxUsersReached;
        vm.searching = false;
      } catch(error){
        vm.errorHandler(error)
      }
    }

    vm.deleteData = async (user)=>{
      confirm(`Are you sure to delete all Data for ${user.first_name} ${user.last_name}`).then(async () => {
        vm.isDeleting = true;
        try {
          let result = (await $http.delete(`/api/track/users/${user.id}`)).data;
          
          if(result.status === 'success'){
            vm.foundUsers = vm.foundUsers.filter(filteredUser => filteredUser.id !== user.id)
            vm.reset()
            $notification.success('User data deleted');
          } else {
            $notification.error('An error occurred, check server logs');
          }
        } catch(error){
          vm.errorHandler(error)
        }
      })
    }
  }
}());
