'use strict';

angular.module('app.controllers')
    .controller('ReportResetPhrasesCtrl',
        function ($scope, $http, $notification, $location) {
            let vm = $scope.vm = this;

            vm.phrases = [];

            vm.search = {
                term:    $location.search().q || '',
                perform: function () {
                    if (this.term) {vm.load();}
                }
            };

            vm.unhide = function (phrase) {
                _.remove(vm.phrases, phrase);

                $http.post(`/reports/reset-phrases/${phrase.id}`)
                    .success(() => {$notification.success('Phrase was reset');})
                    .error(error => {$notification.error(`Phrase reset failed: ${error.errors[0].message}`);});
            };

            vm.load = () => {
                $http.get('/reports/reset-phrases', {params: {search: vm.search.term}})
                    .success(phrases => {vm.phrases = phrases;})
                    .error(error => {$notification.error(`Could not load phrases: ${error.errors[0].message}`);});
            };

            $scope.$watch('vm.search.term', term => $location.search('q', term || undefined));

            if (vm.search.term) {vm.search.perform();}
        }
    );
