(function () {
  'use strict';

  const moduleName = 'translations';

  const moduleFullName = `app.modules.${moduleName}`;

  const state       = stateName => `app.${moduleName}` + (stateName ? `.${stateName}` : '');
  const templateUrl = viewName => `modules/${moduleName}/${moduleName}.${viewName}.html`;
  const controller  = controllerName => `${moduleFullName}.${controllerName} as vm`;

  angular.module(moduleFullName, [])
    .config(function ($stateProvider) {
      $stateProvider
        .state(state(), {
          url:      `/${moduleName}`,
          abstract: true,
          template: `<div class="module_${moduleName}" ui-view></div>`,
          data:     {
            permissions: ['translations']
          }
        })
        .state(state('upload-translated-tags'), {
          url:         '/upload-translated-tags',
          controller:  controller('UploadTranslatedTagsCtrl'),
          templateUrl: templateUrl('upload-translated-tags')
        })
        .state(state('upload-translated-weights'), {
          url:         '/upload-translated-weights',
          controller:  controller('UploadTranslatedWeightsCtrl'),
          templateUrl: templateUrl('upload-translated-weights')
        })
        .state(state('upload-translated-app-screens'), {
          url:         '/upload-translated-app-screens?localeId',
          controller:  controller('UploadTranslatedAppScreensCtrl'),
          templateUrl: templateUrl('upload-translated-app-screens')
        })
      ;

      angular.injector(['ng']).get('$log')
        .debug(`[${moduleName}] Module configured`);
    })
    .run(function ($log) {
      $log.debug(`[${moduleName}] Module launched`);
    });
}());


