(function () {
  'use strict';

  angular.module('app.services')
    .factory('Brand', brandResourceFactory);

  /*@ngInject*/
  function brandResourceFactory($resource, $http) {
    const Brand = $resource(
      '/api/brands/:slug/:id',
      {slug: '@slug', id: '@_id'},
      {
        'save': {method: 'PUT'},
      }
    );

    Brand.list = async function (params) {
      const {data} = await $http.get('/api/brands', {params});

      return data;
    };

    Brand.exportBrandCsv = () => {
      return $http.get('/api/brands/export/csv');
    };

    Brand.Event     = $resource(
      '/api/brands/:brand_id/events',
      {brand_id: '@brand_id'}
    );

    Brand.Asset = $resource(
      '/api/brands/:brand_id/assets/:id',
      {brand_id: '@brand_id', id: '@id'}
    );

    Brand.SectionCorrection = $resource(
      '/api/brands/:brand_id/section-corrections/:id',
      {brand_id: '@brand_id', id: '@original_name'}
    );

    Brand.Alias = $resource(
      '/api/brands/:brand_id/aliases/:id',
      {brand_id: '@brand_id', id: '@id'}
    );

    return Brand;
  }
}());

