'use strict';

angular.module('app.controllers')
  .controller('CreateTagCtrl', function CreateTagCtrl($scope, $modalInstance, suggest, $http, $filter, $notification, confirm) {
    const vm = $scope.vm = this;

    vm.suggest = suggest;

    vm.tag = {
      name:      '',
      parent_id: 0
    };

    vm.parent = {
      tag:   null,
      text:  '',
      set:   function (tag) {
        this.tag         = tag;
        this.text        = tag.name;
        vm.tag.parent_id = tag.id;
      },
      clear: function () {
        this.tag         = null;
        this.text        = null;
        vm.tag.parent_id = null;
      }
    };

    vm.save  = async () => {
      vm.save.$busy = true;


      const name   = vm.tag.name.replace(/[.,!&;?/"*'_]/g, '');
      const phrase = (await $http.get('/api/phrases', {params: {q: name}})).data;

      if (phrase && phrase.tag_name !== vm.tag.name) {
        try {
          await confirm(
            `Please confirm, by creating this tag,
            you will move the phrase "${phrase.text}" from the tag named "${phrase.tag_name}" to this newly created tag.
            Press OK to confirm, or cancel if this is not the intended result`
          );
        } catch (e) {
          vm.save.$busy = false;
          return;
        }
      }

      $http.post('/api/tags', vm.tag)
        .then(function (response) {
          $notification.info('Food Tag Created');
          $modalInstance.close(response.data);
        })
        .catch(function (response) {
          $notification.error('Tag Not Created: ' + $filter('readError')(response.data));
        })
        .finally(() => {
          vm.save.$busy = false;
        });
    };
    vm.close = () => $modalInstance.dismiss();
  });
