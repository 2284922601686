'use strict';

angular.module('app.services')
    .factory('generateThumb', function ($timeout, $q) {
        return function (uploadFile) {
            return $q(function (resolve, reject) {
                if (uploadFile && (uploadFile.type.indexOf('image') > -1 || uploadFile.type.indexOf('octet-stream') > -1)) {
                    $timeout(function () {
                        let fileReader = new FileReader();
                        fileReader.readAsDataURL(uploadFile);
                        fileReader.onload = function (e) {
                            $timeout(function () {
                                resolve(e.target.result);
                            });
                        };
                    });
                } else {
                    reject(null);
                }
            });
        }
    });
