(function () {
  'use strict';

  const moduleName     = 'email-file-delivery',
        controllerName = 'DistributionsSaveCtrl';

  const moduleFullName = `app.modules.${moduleName}`;

  angular.module(moduleFullName)
    .controller(`${moduleFullName}.${controllerName}`, SaveCtrl);

  /*@ngInject*/
  function SaveCtrl($log, $state, $http, $notification, $q, $upload,
                    $filter, confirm, utils, $location, $sessionStorage,
                    frequencies, $timeout) {
    $log.debug(`[${moduleName}] Controller "${controllerName}" launched`);

    const vm  = this;
    const url = '/api/email-file-delivery/distributions' + ($state.params.id ? '/' + $state.params.id : '');

    vm.frequencies = frequencies;

    let flow;

    if ($state.params.id) {
      flow = $http.get(url)
        .then(response => response.data);
    } else {
      flow = $q.resolve({
        description:    '',
        file_urls:      [{alias: '', url: ''}],
        email_template: null,
      });
    }

    flow.then(model => {
      vm.model = model;
    });

    vm.addFileUrl = () => {
      vm.model.file_urls.push({
        alias: '',
        url:   ''
      });
    };

    vm.save = () => {
      vm.$busy  = true;
      vm.$error = null;

      return $http[vm.model.id ? 'put' : 'post'](url, vm.model)
        .then(response => {
          $notification.info('Distribution Saved');

          _.extend(vm.model, response.data);

          if (!$state.params.id) {
            $state.go(utils.state('distributions.edit'), {id: response.data.id});
          }
        })
        .catch(response => vm.$error = $filter('readError')(response.data))
        .finally(() => vm.$busy = false);
    };

    vm.sendTest = () => {
      vm.save()
        .then(() => {
          $http.post(url + '/send-test', {})
            .then(() => $notification.info('Test email sent'))
            .catch(response => vm.$error = $filter('readError')(response.data));
        });
    };

    vm.delete = () => {
      vm.$busy  = true;
      vm.$error = null;

      confirm('Are you sure you want to delete this Distribution?')
        .then(() => {
          return $http.delete(url);
        })
        .then(() => {
          $notification.info('Distribution Deleted');
          vm.goBack();
        })
        .catch(response => vm.$error = $filter('readError')(response.data))
        .finally(() => vm.$busy = false);
    };

    vm.goBack = ($event = null) => {
      if ($event) {
        $event.preventDefault();
      }

      if ($sessionStorage.efdDistributionsListState) {
        $location.url($sessionStorage.efdDistributionsListState);
      } else {
        $state.go(utils.state('distributions.list'));
      }
    };

    vm.getPreview = () => {
      let preview = (vm.model.email_template || '');

      (vm.fileUrls || []).forEach(url => {
        preview = preview.replace(new RegExp(`{{${url.alias}}}`, 'g'), url.url);
      });

      return preview;
    };

    vm.loadFileUrls = () => {
      $http.get('/api/email-file-delivery/file-urls')
        .then(response => {
          vm.fileUrls = response.data;
          vm.initNewFileUrl();
        })
        .catch(response => $notification.error('Could not load file urls: ' + $filter('readError')(response.data)));
    };

    vm.initNewFileUrl = () => {
      vm.editedFileUrl = {alias: '', url: ''};
    };

    vm.selectFileUrl = (fileUrl) => {
      vm.editedFileUrl = fileUrl;

      $timeout(() => {
        vm.model.email_template = vm.model.email_template || '';
        vm.model.email_template += ( (!vm.model.email_template || vm.model.email_template.match(/[\n\s]$/)) ? '' : ' ') + `{{${fileUrl.alias}}}`;

        vm.focusEmailTemplate = true;
        $timeout(() => vm.focusEmailTemplate = false);
      });
    };

    vm.saveFileUrl = (fileUrl) => {
      $http[fileUrl.id ? 'put' : 'post']('/api/email-file-delivery/file-urls' + (fileUrl.id ? `/${fileUrl.id}` : ''), fileUrl)
        .then(() => {
          $notification.info('File url saved');
          vm.loadFileUrls();
        })
        .catch(response => $notification.error('Could not save file url: ' + $filter('readError')(response.data)));
    };

    vm.loadFileUrls();
  }
}());
