'use strict';

angular.module('app.controllers')
    .controller('ReportUserRecentlyEarnedPointsCtrl',
        function ($scope, $http, $notification) {
            $http.get('/reports/user/recently-earned-points')
                .success(function (logs) {
                    _.forEach(logs, function (log) {
                        if (log.meta) {
                            try {
                                log.meta = JSON.parse(log.meta);
                            } catch (e) {
                                log.meta = null;
                            }
                        }
                    });
                    $scope.logs = logs;
                })
                .error(function () {
                    $notification.error('Report failed to load');
                });
        }
    );
