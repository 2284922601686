'use strict';

'use strict';

const moduleName     = 'reports',
      controllerName = 'NixDevPhotoUploadsCtrl';

const moduleFullName = `app.modules.${moduleName}`;

angular.module(moduleFullName)
  .controller(`${moduleFullName}.${controllerName}`, NixDevPhotoUploadsCtrl);

/*@ngInject*/
function NixDevPhotoUploadsCtrl($scope, $http, $state, confirm, $notification,
                                $filter, cdnify, $location, $anchorScroll, developers) {
  const vm = $scope.vm = this;
  vm.developers = developers;

  vm.search = {
    developer: $location.search().developer || vm.developers[0],
    page:      parseInt($location.search().p || 1),
    go:        function (direction) {
      this.page = this.page + parseInt(direction || 1);
      if (this.page < 1) {
        this.page = 1;
      }

      this.perform();
    },
    perform:   function () {
      $location.search('developer', this.developer || null);
      $location.search('p', this.page === 1 ? null : this.page);

      vm.assets       = null;
      vm.assetsGroups = null;

      vm.search.perform.$busy = true;
      $http.get('/reports/nix-dev-photo-uploads/assets',
        {
          params: {
            developer: vm.search.developer,
            page:      this.page || 1
          }
        }
      ).then(function (response) {
          vm.assets       = response.data;
          vm.assetsGroups = _.chunk(vm.assets, 4);

          vm.assets.forEach(a => a.url = cdnify(a.url));
        }
      )
        .finally(() => vm.search.perform.$busy = null);

      $anchorScroll();
    }
  };

  vm.search.perform();
}
