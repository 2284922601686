(function (window, document, angular, undefined) {
  'use strict';
  angular.module('angular-gtag', [])
    .factory('Gtag', function ($log, $q, $rootScope, $location, $window) {
      let ready = $q.defer();

      const scriptLoaded = $q((resolve) => {
        const se  = document.createElement('script');
        se.type   = 'text/javascript';
        se.async  = true;
        se.src    = '//www.googletagmanager.com/gtag/js';
        se.onload = se.onreadystatechange = function () {
          if ((!this.readyState || this.readyState === 'loaded' || this.readyState === 'complete')) {
            resolve();
          }
        };

        const s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(se, s);
      });

      const service = {
        account:    '',
        setAccount: function (id) {
          this.account = id;

          scriptLoaded.then(() => {
            this.gtag('js', new Date());
            ready.resolve(this.gtag);
          });
          return this;
        },
        gtag:       function gtag() {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push(arguments);
        },
        ready:      ready.promise
      };

      $rootScope.$watch(() => $location.url(), function (url) {
        service.ready.then(function (gtag) {
          gtag('config', service.account, {'page_path': url});
        });
      });

      return service;
    });
})(window, document, window.angular);
