'use strict';

/* Filters */
// need load the moment.js to use this filter.
angular.module('app.filters')
    .filter('fromNow', function () {
        return function (date) {
            return moment(date).fromNow();
        };
    })
    .filter('decodeUri', function ($window) {
        return function (value) {
            try {
                return $window.decodeURIComponent(value);
            } catch (e) {
                try {
                    return $window.decodeURIComponent(value.replace(/%/g, $window.encodeURIComponent('%')));
                } catch (e) {
                    return value;
                }
            }
        };
    })
    .filter('encodeUri', function ($window) {
        return $window.encodeURIComponent;
    })
    .filter('servingQty', function ($filter) {
        return function (quantity) {
            var precision = parseInt(quantity) === parseFloat(quantity) ? 0 : 2;
            return $filter('number')(quantity, precision);
        };
    })
    .filter('calories', function ($filter) {
        return function (quantity) {
            return $filter('number')(quantity, 0);
        };
    })
    .filter('weight', function ($filter) {
        return function (quantity) {
            return $filter('number')(quantity, 1);
        };
    })
    .filter('ucfirst', function ($filter) {
        return function (string) {
            string = string ? string.toString() : '';
            if (string.length > 0) {
                return $filter('uppercase')(string[0]) + string.substring(1);
            }
            return string;
        };
    })
    .filter('ucwords', function () {
        return function (string) {
            return (string && string.toString() || '').replace(/^([a-z])|\s+([a-z])/g, function ($1) {
                return $1.toUpperCase();
            });
        };
    })
    .filter('usdaName', function () {
        return function (string) {
            string = string ? string.toString() : '';
            if (string.length > 0) {
                return string.split(' - ')[0];
            }
            return string;
        };
    })
    .filter('padUpc', function () {
        return function (upc) {
            var padLength;
            upc = (upc || '').toString();
            if (upc.length < 12) {
                padLength = 12 - upc.length;
            } else if (upc.length === 13) {
                padLength = 1;
            } else {
                padLength = 0;
            }

            while (padLength > 0) {
                upc = '0' + upc;
                padLength -= 1;
            }

            return upc;
        };
    })
    .filter('capitalize', function () {
        return function (input) {
            input = (input || "").toLowerCase();
            return input.substring(0, 1).toUpperCase() + input.substring(1);
        };
    })
    .filter('divide', function () {
        return function (value, factor) {
            return value / factor || 0;
        };
    })
    .filter('region', function (regions) {
        return function (id) {
            return _.get(_.find(regions, {id: +id}), 'name') || '??';
        };
    })
    .filter('uiHref', function ($state) {
        return function (state) {
            return $state.href(state.name, state.params || {});
        };
    })
    .filter('cleanurl', function () {
        return function (string) {
            var cleanString = _.trim(string || '');
            cleanString = cleanString.replace(/[^\w'+]/g, '-').toLowerCase();
            cleanString = cleanString.replace(/'/g, '');
            return cleanString.replace(/(-)\1{1,}/g, '-');
        };
    })
    .filter('externalLink', function () {
        return function (url) {
            if (!(url || '').match(/^(http|https)?:?\/\//)) {
                url = 'http://' + url;
            }
            return url;
        };
    })
    .filter('readError', function () {
        return function (input) {
            if (_.isString(input)) {
                return input;
            }

            if (_.isObject(input) && input.errors) {
                input = input.errors[0];
            }

            if (_.isArray(input)) {
                input = input[0];
            }

            if (_.isObject(input) && input.message) {
                return input.message;
            }

            return JSON.stringify(input);
        }
    })
    .filter('user', function ($http) {
        let users;

        $http.get('/api/users')
            .then(response => {
                users = response.data;
            });

        function user(id) {
            return _.find(users, {id: +id});
        }

        user.$stateful = true;

        return user;
    })
    .filter('username', function () {
        return function (user) {
            if (_.isObject(user)) {
                return `${user.given_name || ''} ${user.family_name || ''}`.trim();
            }
            return '';
        }
    })
    .filter('yesno', function () {
        return function (value) {
            if(!value || value === 'false' || value === '0' || value === ' '){
                return 'no';
            }

            return 'yes';
        };
    })
    .filter('stripHtmlTags', () =>
      (input) => {
        const strippedText = input ? String(input)
          .replace(/<[^>]+>/g, ' ')
          .replace(/<\/?br\s*\/?>/g, '\n')
          .replace(/&nbsp;/g, ' ')
          .trim()
          : '';
        return strippedText;
      }
    );

