(function () {
  'use strict';

  const moduleName = 'claims';

  const moduleFullName = `app.modules.${moduleName}`;

  const state       = stateName => `app.${moduleName}` + (stateName ? `.${stateName}` : '');
  const templateUrl = viewName => `modules/${moduleName}/${moduleName}.${viewName}.html`;
  const controller  = controllerName => `${moduleFullName}.${controllerName} as vm`;

  angular.module(moduleFullName, [])
    .config(function ($stateProvider) {
      $stateProvider
        .state(state(), {
          url:      `/${moduleName}`,
          abstract: true,
          template: `<div class="module_${moduleName}" ui-view></div>`,
          data:     {
            permissions: ['admin']
          },
          resolve:  {
            utils: () => ({state, templateUrl, controller})
          }
        })
        .state(state('moved'), {
          url:         '',
          templateUrl: templateUrl('moved'),
          onEnter:     function ($anchorScroll) {
            $anchorScroll();
          }
        })
        .state(state('list'), {
          url:         '/list',
          controller:  controller('ListCtrl'),
          templateUrl: templateUrl('list'),
          onEnter:     function ($anchorScroll) {
            $anchorScroll();
          },
          resolve:     {
            claims: ($http, $notification, $q, $filter) => $http.get('/api/claims')
              .then(response => response.data)
              .catch(response => {
                $notification.error(
                  'Failed to load claims: ' + $filter('readError')(response.data)
                );
                return $q.reject(response);
              })
          }
        })
        .state(state('create'), {
          url:         '/create',
          controller:  controller('SaveCtrl'),
          templateUrl: templateUrl('save'),
          onEnter:     function ($anchorScroll) {
            $anchorScroll();
          },
          resolve:     {
            sources: ($http) => $http.get('/api/claims')
              .then(response => _.uniq(response.data.map(c => c.created_by)))
          }
        })
        .state(state('edit'), {
          url:         '/:id',
          controller:  controller('SaveCtrl'),
          templateUrl: templateUrl('save'),
          onEnter:     function ($anchorScroll) {
            $anchorScroll();
          },
          resolve:     {
            sources: ($http) => $http.get('/api/claims')
              .then(response => _.uniq(response.data.map(c => c.created_by)))
          }
        });

      angular.injector(['ng']).get('$log')
        .debug(`[${moduleName}] Module configured`);
    })
    .run(function ($log) {
      $log.debug(`[${moduleName}] Module launched`);
    });
}());


