'use strict';

angular.module('app.controllers').controller('TasksCustomTranscriptionProjectsCtrl',
    function ($scope, $notification, $upload) {
        let vm = $scope.vm = this;

        vm.projects = [{id: 1, name: 'UK Manual Upload', region: 2, file_group: 2}];
        vm.project = vm.projects[0];

        vm.clearUpload = () => {
            vm.uploadedFile = null;
            vm.uploadedFileCollection = null;
        };
        vm.clearUpload();

        vm.fileSelected = collection => {
            if (vm.fileSelected.rejected && vm.fileSelected.rejected.length) {
                $notification.error('Unsupported file type. Please upload CSV files only');
            } else if (collection && collection.length) {
                vm.uploadedFile = collection[0];
            }
        };

        vm.submit = () => {
            vm.submit.$busy = true;

            $upload
                .upload({
                    url:              '/api/tasks/custom-transcription-projects/' + vm.project.id,
                    file:             vm.uploadedFile,
                    fileFormDataName: 'csv',
                    fields:           {project: vm.project}
                })
                .then(function () {
                    $notification.info('File uploaded successfully, tasks are populated in the background and this may take some moments to process. You will receive report email.');
                    vm.clearUpload();
                })
                .catch(function (/*error*/) {
                    $notification.error('File upload failed');
                })
                .finally(function () {
                    vm.submit.$busy = false;
                });
        };
    }
);
