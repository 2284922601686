(function () {
  'use strict';

  const moduleName     = 'ingredient-learning',
        controllerName = 'AddIngredientCtrl';

  const moduleFullName = `app.modules.${moduleName}`;

  angular.module(moduleFullName)
    .controller(`${moduleFullName}.${controllerName}`, AddIngredientCtrl);

  /*@ngInject*/
  function AddIngredientCtrl($scope, $log, $modalInstance, $http) {
    $log.debug(`[${moduleName}] Controller "${controllerName}" launched`);

    const vm = $scope.vm = this;

    vm.ingredient = {name: ''};

    vm.save = () => {
      vm.save.$busy  = true;
      vm.save.$error = null;

      $http.post(`/api/ingredient-learning/ingredients`, vm.ingredient)
        .then(response => $modalInstance.close(response.data))
        .catch(response => {
          vm.save.$error = response.data
        })
        .finally(() => vm.save.$busy = false);
    };

    vm.close = () => {
      $modalInstance.dismiss();
    }
  }
}());
