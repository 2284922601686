'use strict';

angular.module('app.controllers')
    .controller('DifficultBrandCtrl', [
        '$scope', 'Report',
        function ($scope, Report) {

            var results = Report.get({
                id: 8
            }, function () {
                $scope.results = results;
            });
        }
    ]);