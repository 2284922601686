(() => {
  'use strict';

  angular.module('app.controllers').controller('ReportRestaurantsCtrl', ReportRestaurantsCtrl);

  /*@ngInject*/
  function ReportRestaurantsCtrl($scope, $http, ngTableParams, $location) {
    (async () => {
      const vm = $scope.vm = this;

      const reportUrl = '/reports/restaurants';

      $scope.exportLink = '';

      $scope.stats = (await $http.get('/reports/restaurants/stats')).data;
      vm.statusType = (await $http.get('/api/brands/event_types')).data;

      $scope.search = {
        term:           '',
        filter:         {},
        perform:        function () {
          const query = {};
          if (this.term) {
            query.search = this.term;
          }
          _.forEach(this.filter, function (value, key) {
            query['filter.' + key] = value;
          });
          $location.search(query);

          $scope.tableParams.page(1);
          $scope.tableParams.reload();
        },
        specialFilters: [
          {label: 'Has Permanent Data File', value: 'restaurant_has_pdf.permanent'},
          {label: 'Wildcard Needed', value: 'wildcard_needed.1'},
          {label: 'Needs XLS Convert', value: 'needs.xls_convert'},
          {label: 'Needs Clean CSV', value: 'needs.clean_csv'},
          {label: 'Restaurants not verified in last 12 months', value: 'not_verified.1'},
          {label: 'Tag audit not completed in 12 months', value: 'tags_not_audited.1'},
          {label: 'Spot check not completed in 12 month', value: 'spot_check_not_completed.1'},
          {label: 'Is active but don\'t have a Website URL', value: 'missing_website_url.1'}

        ]
      };

      const urlParams = $location.search();

      if (urlParams.hasOwnProperty('filter.status_type')) {
        urlParams['filter.status_type'] = +urlParams['filter.status_type'];
      }

      _.forEach(urlParams, function (value, key) {
        if (key === 'search') {
          $scope.search.term = value;
        } else if (key.slice(0, 7) === 'filter.') {
          $scope.search.filter[key.slice(7)] = value;
        }
      });

      let sorting = {brand_name: 'asc'};

      if (urlParams.sort) {
        let parts         = urlParams.sort.split('.');
        sorting           = {};
        sorting[parts[0]] = parts[1];
      }

      $scope.tableParams = new ngTableParams(
        {
          page:    urlParams.page || 1,
          count:   200,           // count per page
          sorting: sorting
        },
        {
          counts:  [],
          total:   0, // length of data
          getData: async ($defer, params) => {
            let sort;
            angular.forEach(params.sorting(), function (order, column) {
              sort = [column, order].join('.');
            });

            $location.search('sort', sort === 'brand_name.asc' ? null : sort);
            $location.search('page', +params.page() > 1 ? params.page() : null);

            const filter = {};
            _.forEach($scope.search.filter, function (value, key) {
              let special;
              if (key === 'special' && value) {
                special = value.split('.');
                key     = special[0];
                value   = special[1];
              }

              if (value === 'null') {
                filter[key] = null;
              } else if (key === 'restaurant_has_pdf' && value === 'permanent') {
                filter.restaurant_perm_pdf = '!null';

              } else if (value !== null) {
                filter[key] = value;
              }
            });


            const baseParams = {
              search:  $scope.search.term,
              orderBy: sort,
              filter:  JSON.stringify(filter)
            }

            const searchParams = new URLSearchParams(baseParams);
            searchParams.append('export', '1');

            $scope.exportLink = `${reportUrl}?${searchParams.toString()}`

            const {data} = await $http.get(reportUrl, {
              params: {
                ...baseParams,
                limit:  params.count(),
                offset: (params.page() - 1) * params.count(),
              }
            });

            params.total(data.count);
            $defer.resolve(data.results);
          }
        });
    })();
  }
})()
