'use strict';

'use strict';

const moduleName     = 'reports',
      controllerName = 'TaskReviewCtrl';

const moduleFullName = `app.modules.${moduleName}`;

angular.module(moduleFullName)
  .controller(`${moduleFullName}.${controllerName}`, TaskReviewCtrl);

/*@ngInject*/
function TaskReviewCtrl($scope, $http, $state, confirm, $notification, $filter, $location, $anchorScroll, users) {
  const vm = $scope.vm = this;

  users.forEach(u => {
    u.display = `${u.name} [${u.email}]`;
  });
  vm.users = users;

  vm.selectUser = (user = null) => {
    if (user) {
      vm.user = user;
    }

    $location.search('userId', vm.user ? vm.user.id : null);

    vm.loadData();
  };

  vm.selectTask = (task = null) => {
    if (task) {
      vm.task = task;
    }

    $location.search('task', vm.task === 'fop_transcribe' ? null : vm.task);

    vm.loadData();
  };

  vm.loadData = () => {
    vm.points = [];
    if (vm.user && vm.task) {
      $http.get('/reports/user/task-review', {params: {user_id: vm.user.id, task: vm.task}})
        .then(response => {
          vm.points = response.data;
        })
        .catch(response => $notification.error('Could not load data: ' + $filter('readError')(response.data)));
    }
  };


  vm.task = $location.search().task || 'fop_transcribe';


  if ($location.search().userId) {
    vm.selectUser(_.find(vm.users, {id: +$location.search().userId}));
  }
}
