(function () {
  'use strict';

  const moduleName = 'sample-export',
    controllerName = 'IndexCtrl';

  const moduleFullName = `app.modules.${moduleName}`;

  angular.module(moduleFullName)
    .controller(`${moduleFullName}.${controllerName}`, IndexCtrl);

  /*@ngInject*/
  function IndexCtrl($scope, $log, $http, $notification) {
    $log.debug(`[${moduleName}] Controller "${controllerName}" launched`);

    const vm = this;
    vm.fileMapping = [
      {
        name: 'Common (common.json)',
        value: 'common.json',
      },
      {
        name: 'Cpg (cpg-2.csv)',
        value: 'cpg-2.csv',
      },
      {
        name: 'Restaurant (restaurant-2.csv)',
        value: 'restaurant-2.csv',
      },
      {
        name: 'Cpg Wellness Claims (cpg-wellness-claims.csv)',
        value: 'cpg-wellness-claims.csv',
      },
    ];
    vm.fileSizes = [50, 100, 200, 500]

    vm.fileType = vm.fileMapping[0];
    vm.fileSize = vm.fileSizes[0];

    vm.submit = async () => {
      const requestBody = {
        file_name: vm.fileType.value,
        sample_size: vm.fileSize,
      }

      vm.submit.$busy = true;

      try {
        await $http.post('/api/sample-export', requestBody);
        $notification.info('Sample export was triggered and email will be sent soon');
      } catch (error) {
        $notification.error('Sample export failed: ', error);
      } finally {
        vm.submit.$busy = false
      }
    };
  }
}());
