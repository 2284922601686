'use strict';

angular.module('app.controllers')
  .controller('TasksConflictResolutionCtrl',
    function ($scope, $state, $http, $notification, suggest, $q, confirm,
              flagTask, cdnify, regions, $filter, $localStorage, $location) {
      function navigateNext() {
        if ($localStorage.return) {
          $location.url($localStorage.return);
          $localStorage.return = null;
        } else {
          init();
        }
      }

      function init() {
        $scope.regions       = regions;
        $scope.image         = {src: null};
        $scope.transcription = {};

        $scope.init($state.params.task_type, {
          file_group: $state.params.file_group,
          region: $state.params.region
        })
          .then(function (task) {
            if (task) {
              if (task.type === 5) {
                let src, flow;

                if (src = _.get(task, 'meta.best_photo_cropped')) {
                  flow = $q.resolve(src);
                } else {
                  flow = $http.get('/api/tasks/transcribe', {
                    params: {
                      file_group: $state.params.file_group,
                      upc: task.upc
                    }
                  }).then(response => response.data);
                }

                return flow.then(function (src) {
                  $scope.vm.debugInfo.push({
                    name: 'Current Image',
                    href: src,
                    target: '_blank'
                  });
                  $scope.image.src = cdnify(src);
                });
              } else if (task.type === 7) {
                let src = task.custom_project_image_url;

                $scope.vm.debugInfo.push({
                  name: 'Current Image',
                  href: src,
                  target: '_blank'
                });
                $scope.image.src = cdnify(src);
              }
            }
          });
      }

      init();

      $scope.suggest = suggest;

      $scope.approveEntry = function (entry, submit) {
        $scope.transcription = angular.copy(entry);
        if (submit) {
          $scope.submit(null, true);
        }
      };

      $scope.submit = function (flag, force) {
        if (!flag && !force && $scope.transcribeForm && $scope.transcribeForm.$invalid) {
          return;
        }
        $scope.submit.$busy = flag ? 'flagging' : 'submitting';

        let flow;

        if (flag) {
          flow = flagTask($scope.vm.currentTask)
        } else {
          let params = {
            task_id: $scope.vm.currentTask.id,
            transcription: $scope.transcription,
          };

          flow = $http.post('/api/tasks/resolve-conflict/', params)
            .then(() => $notification.info('Saved'))
            .catch(() => $notification.error('Not saved'))
        }


        flow
          .then(navigateNext)
          .finally(() => {
            $scope.submit.$busy = null;
          });
      };

      $scope.sendToManualReview = () => {
        confirm('Are you sure you want to send this task to the manual review?').then(async () => {
          try {
            $scope.submit.$busy = 'review';
            await $http.put(`/api/tasks/${$scope.vm.currentTask.id}/send-to-manual-queue`);
            navigateNext();
          } catch (e) {
            $notification.error(`Failed: ${$filter('readError')(e.data)}`);
          }

          $scope.submit.$busy = null;
        }).catch(e => console.log(e))
      }

      $scope.changeTaskRegion = () => {
        confirm(
          'Are you sure you want to change task region? ' +
          'It will be fully reset and put back into transcription queue'
        )
          .then(() => {
            $http.post('/api/tasks/change-region/' + $scope.vm.currentTask.id, {
              region: $scope.vm.currentTask.region,
              reset: true
            })
              .then(() => {
                $notification.info(
                  'Region changed. ' +
                  'Task is back at transcription queue. ' +
                  'Proceeding to another conflict resolution task'
                );
                init();
              })
              .catch(() => {
                $notification.info('Region change failed');
              });
          });
      };
    }
  );



