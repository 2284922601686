'use strict';

angular.module('app.services')
    .factory('Tests', [
        '$resource',
        function ($resource) {
            return $resource('https://worker-aws-us-east-1.iron.io/2/projects/5425885f38ef2d000700008b/tasks/webhook', {
                code_name: 'restaurant_calculator_checker',
                oauth: 'Q-Q5FXJtZ5hanpTUJtbwchj51tk',
                cluster: 'nutritionix'
            }, {
                get: {
                    isArray: false
                },
                start: {
                    url: '/api/tests/:name',
                    method: 'POST',
                },
                stopGeneralNixcalc: {
                    url: 'https://worker-aws-us-east-1.iron.io/2/projects/5425885f38ef2d000700008b/tasks/:id/cancel',
                    method: 'POST',
                    params: {
                        id: '@id'
                    },
                    isArray: false
                },
                getGeneralNixcalc: {
                    url: 'https://worker-aws-us-east-1.iron.io/2/projects/5425885f38ef2d000700008b/tasks/:id',
                    params: {
                        id: '@id'
                    },
                    isArray: false
                },
            });
        }
    ]);
