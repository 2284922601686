(function (window, angular, undefined) {
    'use strict';

    var module = angular.module('commenter', ['ui.bootstrap']);

    module.provider('commenter', function commenterProvider() {
        var endpoint;

        this.setEndpoint = function (url) {
            endpoint = url;
        };

        this.$get = function commenterFactory($http, $modal, $notification, $q) {
            var commenter = function commenter(table, indexName, indexValue, comment) {
                var r = {
                    method: 'POST',
                    url:    endpoint,
                    params: {
                        table:      table,
                        indexName:  indexName,
                        indexValue: indexValue
                    },
                    data:   {
                        comment: comment
                    }
                };

                return $http(r)
                    .catch(error => {
                        $notification.error('Adding note failed');
                        return $q.reject(error);
                    });
            };

            // @formatter:off
            var template = [
                '<div class="modal-header">',
                    '<h3 class="modal-title">Add comment</h3>',
                '</div>',
                '<div class="modal-body">',
                    '<textarea rows="10" class="form-control" ng-model="comment"></textarea>',
                '</div>',
                '<div class="modal-footer">',
                    '<button class="btn btn-primary" ng-click="ok()">OK</button>',
                    '<button class="btn btn-warning" ng-click="cancel()">Cancel</button>',
                '</div>'
            ].join('\n');
            // @formatter:on

            commenter.open = function open(initialComment, modalOptions) {
                modalOptions = angular.extend({
                    animation:  true,
                    template:   template,
                    controller: 'CommenterController',
                    size:       'lg',
                    resolve:    {
                        comment: function () {
                            return initialComment;
                        }
                    }
                }, modalOptions || {});

                var modalInstance = $modal.open(modalOptions);
                modalInstance.submit = function (table, indexName, indexValue) {
                    return modalInstance.result.then(function (comment) {
                        return commenter(table, indexName, indexValue, comment);
                    });
                };

                return modalInstance;
            };

            return commenter;
        };
    });

    module.controller('CommenterController', function ($scope, $modalInstance, comment) {
        $scope.comment = comment;

        $scope.ok = function () {
            $modalInstance.close($scope.comment);
        };

        $scope.cancel = function () {
            $modalInstance.dismiss('cancel');
        };
    });
})(window, window.angular);
