(function () {
  'use strict';

  const moduleName     = 'recommended-products',
        controllerName = 'SaveCtrl';

  const moduleFullName = `app.modules.${moduleName}`;

  angular.module(moduleFullName)
    .controller(`${moduleFullName}.${controllerName}`, SaveCtrl);

  /*@ngInject*/
  function SaveCtrl($log, $state, $http, $notification, $q, $upload, $filter, confirm) {
    $log.debug(`[${moduleName}] Controller "${controllerName}" launched`);

    const vm  = this;
    const url = '/api/recommended-products' + ($state.params.id ? '/' + $state.params.id : '');

    let flow;

    if ($state.params.id) {
      flow = $http.get(url)
        .then(response => response.data);
    } else {
      flow = $q.resolve({
        item_name:   '',
        brand_name:  '',
        description: '',
        url:         '',
        image_thumb: ''
      });
    }

    flow.then(model => {
      vm.model = model;
    });

    vm.save = () => {
      vm.$busy  = true;
      vm.$error = null;

      $http.post(url, vm.model)
        .then(response => {
          $notification.info('Recommended Product Saved');

          _.extend(vm.model, response.data);

          if (!$state.params.id) {
            $state.go('app.recommended-products.edit', {id: response.data.id})
          }
        })
        .catch(response => vm.$error = $filter('readError')(response.data))
        .finally(() => vm.$busy = false);
    };

    vm.delete = () => {
      vm.$busy  = true;
      vm.$error = null;

      confirm('Are you sure you want to delete this recommended product?')
        .then(() => {
          return $http.delete(url)
        })
        .then(() => {
          $notification.info('Recommended product Deleted');
          $state.go('app.recommended-products.list')
        })
        .catch(response => vm.$error = $filter('readError')(response.data))
        .finally(() => vm.$busy = false);
    }
  }
}());
