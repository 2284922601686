(function () {
  'use strict';

  const moduleName = 'sample-export';

  const moduleFullName = `app.modules.${moduleName}`;

  const state = stateName => `app.${moduleName}` + (stateName ? `.${stateName}` : '');
  const templateUrl = viewName => `modules/${moduleName}/${moduleName}.${viewName}.html`;
  const controller = controllerName => `${moduleFullName}.${controllerName} as vm`;

  angular.module(moduleFullName, [])
    .config(function ($stateProvider) {
      $stateProvider
        .state(state(), {
          url: `/${moduleName}`,
          abstract: true,
          template: `<div class="module_${moduleName}" ui-view></div>`
        })
        .state(state('index'), {
          url: '',
          controller: controller('IndexCtrl'),
          templateUrl: templateUrl('index'),
          onEnter: ($anchorScroll) => $anchorScroll(),
        })

      angular.injector(['ng']).get('$log')
        .debug(`[${moduleName}] Module configured`);
    })
    .run(function ($log) {
      $log.debug(`[${moduleName}] Module launched`);
    });
}());


