(function () {
  'use strict';

  const moduleName     = 'usda',
        controllerName = 'EditCtrl';

  const moduleFullName = `app.modules.${moduleName}`;

  angular.module(moduleFullName)
    .controller(`${moduleFullName}.${controllerName}`, UsdaEditCtrl);

  /*@ngInject*/
  function UsdaEditCtrl($log, $scope, $state, $http, Brand, Tag, $filter,
                        debounce, $q, $notification, navigationGuard,
                        locales, $timeout, confirm, suggest) {
    $log.debug(`[${moduleName}] Controller "${controllerName}" launched`);

    const vm = this;

    const {ndb_no} = $state.params;

    vm.item = {ndb_no};

    vm.itemManager          = createItemManager(
      vm.item,
      $http,
      () => vm.weightsManager.load(),
    );
    vm.weightsManager       = createWeightsManager(
      vm.item,
      $http,
      $filter,
      $notification,
    );
    vm.weightsLocaleManager = createWeightsLocaleManager(
      vm.item,
      locales,
      $http,
      $filter,
      $notification,
      $timeout,
      confirm,
    );
    vm.tagManager           = createTagManager(
      vm.item,
      $http,
      (q) => suggest.tags(q),
    );

    setupNavigationGuard(
      navigationGuard,
      () => [
        vm.itemManager.nameForm,
        ...vm.weightsManager.userWeights.map((w) => w.$form),
        ...vm.weightsLocaleManager.weightLocales.map((w) => w.$form),
      ]
    );

    vm.weightsManager.load();
    vm.weightsLocaleManager.load();
    vm.tagManager.load();
  }

  function pickForeignLocales(locales) {
    return locales.filter((l) => l.country_alpha_2 !== 'us');
  }

  function createItemManager(item, $http, afterSave) {
    return {
      nameForm:  null,
      saveError: null,

      async save() {
        if (item.ndb_no > 999999) {
          /**
           * If NDB Number > 999999 (this means it's a recipe item) disallow  modified name for recipe based items
           * @link https://github.com/mattsilvllc/hive2/issues/51#issuecomment-68879286
           */
          return;
        }

        this.saveError = null;

        try {
          await $http.patch(`/api/usda/${item.ndb_no}`, item);
          this.nameForm.$setPristine();
          await afterSave();
        } catch (e) {
          this.saveError = $filter('readError')(e.data);
        }
      },

      reset() {
        item.usda_modified_name = item.usda_name;
      }
    };
  }

  function createWeightsManager(item, $http, $filter, $notification) {
    const USER_WEIGHTS_START = 80;
    const RECIPES_START      = 1000000;

    const weightsManager = {
      url: `/api/usda/${item.ndb_no}/weights`,

      staticWeights: [],
      userWeights:   [],

      sortableParams: {
        stop: () => weightsManager.updateUserWeightsSeq(),
      },

      get hasMaximumUserWeights() {
        return this.userWeights.filter((w) => !w.$new).length >= 10;
      },

      async load() {
        const {data: weights} = await $http.get(this.url);

        if (weights.length) {
          this.staticWeights = weights.filter((weight) => weight.seq < USER_WEIGHTS_START);
          this.userWeights   = weights.filter((weight) => weight.seq >= USER_WEIGHTS_START);

          const masterWeight      = weights[0];
          item.name               = (masterWeight.item_name || '').split(' - ')[0];
          item.usda_name          = masterWeight.usda_name;
          item.usda_modified_name = masterWeight.usda_modified_name;

        } else {
          this.staticWeights = [];
          this.userWeights   = [];
        }

        await this.addEmpty();
      },

      async addEmpty() {
        if (item.ndb_no >= RECIPES_START) {
          /**
           * If NDB Number > 999999 (this means it's a recipe item) disallow adding weights
           * @link https://github.com/mattsilvllc/hive2/issues/82
           */
          return;
        }

        for (const weight of this.userWeights) {
          delete weight.$new;
        }

        if (this.userWeights.length < 10) {
          this.userWeights.push({
            $new:                    true,
            nf_serving_size_qty:     1,
            remote_db_key:           item.ndb_no,
            nf_serving_size_unit:    null,
            nf_serving_weight_grams: null,
          });
        }

        await this.updateUserWeightsSeq();
      },

      async updateUserWeightsSeq() {
        const userWeights = this.userWeights.slice();
        for (let index = 0; index < userWeights.length; index += 1) {
          const weight = userWeights[index];

          if (weight.$new || !weight._id) {
            this.userWeights.splice(index, 1);
            this.userWeights.push(weight);
          }
        }

        for (let index = 0; index < this.userWeights.length; index += 1) {
          const weight = this.userWeights[index];
          const seq    = USER_WEIGHTS_START + index;

          if (weight.seq !== seq) {
            weight.seq = seq;
            if (!weight.$new) {
              await this.save(weight);
            }
          }
        }
      },

      async save(weight) {
        const {$form, ...submitData} = weight;

        if ($form && $form.$invalid) {
          return;
        }

        const isNewWeight = !weight._id;

        try {
          let response;
          if (isNewWeight) {
            response = await $http.post(this.url, submitData);
          } else {
            response = await $http.put(`${this.url}/${weight._id}`, submitData);
          }

          Object.assign(weight, response.data);

          if ($form) {
            $form.$setPristine();
          }

          $notification.info('weight saved');
          if (isNewWeight) {
            await this.addEmpty();
          }
        } catch (e) {
          $notification.error('save error:' + $filter('readError')(e.data));
        }
      },

      async toggleVisibility(weight) {
        try {
          const {data} = await $http.patch(
            `${this.url}/${weight._id}/toggle-visibility`,
            {is_hidden: !weight.is_hidden}
          )
          Object.assign(weight, data);
        } catch (e) {
          $notification.error('Toggle hide failed: ' + $filter('readError')(e.data))
        }
      }
    };

    return weightsManager;
  }

  function createWeightsLocaleManager({ndb_no}, locales, $http, $filter, $notification, $timeout, confirm) {
    return {
      locales:        pickForeignLocales(locales),
      selectedLocale: null,

      url:           `/api/usda/${ndb_no}/weights-locale`,
      weightLocales: [],

      async load() {
        try {
          const {data: weightLocales} = await $http.get(this.url);
          this.weightLocales          = weightLocales;
          this.add();
        } catch (e) {
          $notification.error(
            'Failed to load weight localisations: ' + $filter('readError')(e.data)
          )
        }
      },
      add() {
        for (const weight of this.weightLocales) {
          delete weight.$new;
        }

        this.weightLocales.push({
          $new:        true,
          serving_qty: 1,
          ndb_no,
          locale_id:   this.selectedLocale,
        });
      },
      async save(weight) {
        const {$form, ...submitData} = weight;

        if ($form && $form.$invalid) {
          return;
        }

        let response;

        try {
          if (weight.$new) {
            response = await $http.post(this.url, submitData);
            this.add();
          } else {
            response = await $http.put(`${this.url}/${weight.id}`, submitData);
          }

          Object.assign(weight, response.data);

          if ($form) {
            $form.$setPristine();
          }

          $notification.info('weight locale saved');
        } catch (e) {
          $notification.error(
            'Could not save weight localisation: ' + $filter('readError')(e.data)
          )
        }
      },
      populateNew(weight) {
        const localisedWeight = this.weightLocales.find((w) => w.$new);

        if (localisedWeight) {
          const {$form} = localisedWeight;

          localisedWeight.serving_qty          = weight.nf_serving_size_qty;
          localisedWeight.serving_unit         = weight.nf_serving_size_unit;
          localisedWeight.serving_weight_grams = weight.nf_serving_weight_grams;
          localisedWeight.parent_seq           = weight.seq;
          localisedWeight.locale_id            = this.selectedLocale;

          $timeout(() => {
            angular.element('#add_localised_weight').closest('tr').find('select').focus();
            $form.$setDirty();
          });
        }
      },

      del(weight) {
        const locale = this.locales.find((l) => l.id === weight.locale_id);

        confirm(`Are you sure you want to delete "${locale.language_culture_name} ${weight.serving_qty} ${weight.serving_unit}"`)
          .then(async () => {
            try {
              await $http.delete(`${this.url}/${weight.id}`);
              $notification.info('Weight was deleted');

              this.weightLocales.splice(this.weightLocales.indexOf(weight), 1);
            } catch (e) {
              $notification.error(
                'Could not delete localisation: ' + $filter('readError')(e.data)
              )
            }
          });
      },

      syncSelectedLocale() {
        const newWeight = this.weightLocales.find((w) => w.$new);
        if (newWeight) {
          newWeight.locale_id = this.selectedLocale;
        }
      },

      getFiltered() {
        if (!this.selectedLocale) {
          return this.weightLocales;
        }

        return this.weightLocales.filter((w) => w.locale_id === this.selectedLocale);
      }
    }
  }

  function createTagManager({ndb_no}, $http, suggestTags) {
    const tagManager = {
      url: `/api/usda/${ndb_no}/tags`,

      tags: [],
      async load() {
        const {data: tags} = await $http.get(this.url);

        this.tags = tags;
      },
      adder: {
        text:  '',
        tag:   null,
        error: null,
        search(q) {
          return suggestTags(q);
        },
        select(tag) {
          this.tag = tag;
        },
        async submit() {
          this.error = null;

          try {
            await $http.post(tagManager.url, this.tag);

            this.tag   = '';
            this.text  = null;
            this.error = null;

            await tagManager.load(tagManager.url);
          } catch (error) {
            let {data: errorData} = error;
            if (errorData && errorData.errors && errorData.errors[0]) {
              errorData = errorData.errors[0];
            }

            if (error.status === 404) {
              this.error = {
                type:    'generic',
                message: 'tag not found',
              };
            } else if (error.status === 409) {
              this.error = {
                type:    'duplicate-assignment',
                message: errorData.message,
                data:    errorData.data,
              };
            } else if (error.status) {
              this.error = {
                type:    'generic',
                message: 'Unexpected backend error'
              };
            } else {
              this.error = {
                type:    'generic',
                message: error.message,
              };
            }
          }
        }
      }
    };

    return tagManager;
  }

  function setupNavigationGuard(navigationGuard, getForms) {
    navigationGuard.watch(() => {
      for (const form of getForms()) {
        if (form.$dirty) {
          return false;
        }
      }

      return true;
    }, 'You have unsaved work on the page. Proceed?');
  }
}());
