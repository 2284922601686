'use strict';

angular.module('app.controllers')
    .controller('TasksFlaggedCtrl',
        function ($scope, $http, $modal) {
            let vm = $scope.vm = this;

            $http.get('/api/tasks/flagged')
              .then(response => response.data)
              .then(tasks => {
                vm.tasks = tasks
              });

            vm.getType = type => {
                switch (type) {
                case 1:
                    return 'Rotate';
                case 2:
                    return 'Crop';
                case 3:
                    return 'Transcribe';
                case 4:
                    return 'Select best photo';
                case 5:
                    return 'Conflict resolution';
                case 6:
                    return 'Transcribe (custom project)';
                case 7:
                    return 'Conflict resolution (custom project)';
                }
            };

            vm.getFileGroup = fileGroup => {
                switch (fileGroup) {
                case 1:
                    return 'Front of Package';
                case 2:
                    return 'Nutrition Label';
                }
            };

            vm.showDetails = task => {
                $modal.open({
                    animation:   true,
                    templateUrl: 'tasks/flagged/details.html',
                    controller:  function ($scope, $modalInstance) {
                        $scope.task = task;

                        $scope.close = function () {
                            $modalInstance.dismiss();
                        };
                    },
                    size:        'lg'
                });
            };

        });
