"use strict";

angular
  .module("app.controllers")
  .controller(
    "CSVDBCompareCtrl",
    (
      $scope,
      $timeout,
      $stateParams,
      $upload,
      $http,
      $notification,
      $localStorage
    ) => {
      /**
       * Setup
       */
      if ($stateParams.location_id) {
        $scope.locationID = $stateParams.location_id;
      }

      if ($stateParams.restaurant) {
        $scope.restaurantName = $stateParams.restaurant;
      }
      $scope.itemNamesMatched = {};
      $scope.itemTagsMatched = {};
      // End

      /**
       * Helpers
       */
      $scope.reset = () => {
        $scope.uploadFinished = false;
        $scope.csvErrors = undefined;
        $scope.sourceUploadProcessing = false;

        $scope.taskFinished = false;
        $scope.results = undefined;
        $scope.itemNamesMatched = {};
        $scope.itemTagsMatched = {};
        $scope.validationNameSource = undefined;
        $scope.validationTagSource = undefined;
        removeLastSession();
      };
      // End

      $scope.error = true;
      $scope.saveState = () => {
        if (typeof Storage !== "undefined") {
          if ($scope.itemNamesMatched || $scope.itemTagsMatched) {
            // Verify Repeated Item Names
            $scope.repeatedDBItemNames = {};
            $scope.csvItemsWithRepeatedDBItem = {};
            let count = 0;

            _.forEach($scope.itemNamesMatched, (value, key) => {
              count += 1;
              if (value) {
                if ($scope.repeatedDBItemNames[value]) {
                  $scope.repeatedDBItemNames[value].push(count);
                  $scope.csvItemsWithRepeatedDBItem[key] = true;
                } else {
                  $scope.repeatedDBItemNames[value] = [count];
                }
              }
            });

            $scope.repeatedDBItemNames = _.filter(
              $scope.repeatedDBItemNames,
              (value, key) => {
                return value.length > 1;
              }
            );

            // Verify Repeated item tags
            $scope.repeatedDBItemTags = {};
            $scope.csvItemsWithRepeatedDBItemTags = {};
            let tagsCount = 0;
            _.forEach($scope.itemTagsMatched, (value, key) => {
              tagsCount += 1;
              if (value) {
                if ($scope.repeatedDBItemTags[value]) {
                  $scope.repeatedDBItemTags[value].push(tagsCount);
                  $scope.csvItemsWithRepeatedDBItemTags[key] = true;
                } else {
                  $scope.repeatedDBItemTags[value] = [tagsCount];
                }
              }
            });

            $scope.repeatedDBItemTags = _.filter(
              $scope.repeatedDBItemTags,
              (value, key) => {
                return value.length > 1;
              }
            );

            $localStorage.CSVDBCompareStates = JSON.stringify([
              {
                uuid: $scope.UUID,
                locationID: $scope.locationID,
                S3URL: $scope.S3URL,
                results: $scope.results,
                itemNamesMatched: $scope.itemNamesMatched,
                itemTagsMatched: $scope.itemTagsMatched,
                repeatedDBItemNames: $scope.repeatedDBItemNames,
                csvItemsWithRepeatedDBItem: $scope.csvItemsWithRepeatedDBItem,
                validationNameSource: $scope.validationNameSource,
                validationInProgress: $scope.validationInProgress,
              },
            ]);
          }
        } else {
          $notification.error("Error!", "No Web Storage support..");
        }
      };

      // Restore Session
      const restoreSession = () => {
        $scope.waiting = false;
        let savedStates = $localStorage.CSVDBCompareStates
          ? JSON.parse($localStorage.CSVDBCompareStates)
          : [];
        let existingState;

        if (savedStates) {
          existingState = savedStates.filter(
            (i) => i.locationID === $scope.locationID
          );
        }

        if (existingState) {
          $scope.locationID = existingState[0].locationID;
          $scope.UUID = existingState[0].uuid;
          $scope.S3URL = existingState[0].S3URL;
          $scope.results = existingState[0].results;
          $scope.itemNamesMatched = existingState[0].itemNamesMatched;
          $scope.itemTagsMatched = existingState[0].itemTagsMatched;
          $scope.repeatedDBItemNames = existingState[0].repeatedDBItemNames;
          $scope.csvItemsWithRepeatedDBItem =
            existingState[0].csvItemsWithRepeatedDBItem;
          $scope.validationNameSource = existingState[0].validationNameSource;
          $scope.validationInProgress = existingState[0].validationInProgress;
        }
      };

      $scope.discardLastSession = () => {
        $scope.reset();
        $scope.waitingSessionConfirmation = false;
      };

      const hasSavedSession = () => {
        let savedStates = $localStorage.CSVDBCompareStates
          ? JSON.parse($localStorage.CSVDBCompareStates)
          : [];
        let existingState;

        if (savedStates) {
          existingState = savedStates.filter(
            (i) => i.locationID === $scope.locationID
          );
        }

        if (existingState && existingState.length > 0) {
          $scope.hasSavedSession = true;
          $scope.waitingSessionConfirmation = true;
        } else {
          $scope.hasSavedSession = false;
        }
      };
      hasSavedSession();

      $scope.restoreLastSession = () => {
        restoreSession();
        $scope.waitingSessionConfirmation = false;
      };

      const removeLastSession = () => {
        let savedStates = $localStorage.CSVDBCompareStates
          ? JSON.parse($localStorage.CSVDBCompareStates)
          : [];
        let existingState;

        if (savedStates) {
          existingState = savedStates.filter(
            (i) => i.locationID === $scope.locationID
          );
        }

        if (existingState) {
          savedStates = savedStates.filter(
            (i) => i.locationID !== $scope.locationID
          );
          $localStorage.CSVDBCompareStates = JSON.stringify(savedStates);
        }
        hasSavedSession();
      };

      $scope.validateForm = () => {
        $scope.saveState();
        if ($scope.repeatedDBItemNames.length > 0) {
          $notification.warning(
            "There are some CSV items matched to the same database item."
          );
          return false;
        }

        if ($scope.repeatedDBItemTags.length > 0) {
          $notification.warning(
            "There are some CSV tags matched to the same database tag."
          );
          return false;
        }

        return true;
      };

      /**
       * Validate Matches
       */
      const generateCsvItemName = (csvSimilarMatch, matchBy) => {
        return matchBy === "as_shown"
          ? `${csvSimilarMatch["input_item_name"]} ## ${csvSimilarMatch["input_tag_name"]}`
          : csvSimilarMatch["input_item_name"];
      };

      $scope.validateNamesMatch = async () => {
        if ($scope.validateForm()) {
          // Ensures every manual matched item name exists in item_names_matched
          if ($scope.results.csv_similar_matches) {
            _.forEach($scope.results.csv_similar_matches, (obj) => {
              const csvItemName = generateCsvItemName(obj, $scope.matchBy);

              if (!$scope.itemNamesMatched[csvItemName]) {
                $scope.itemNamesMatched[csvItemName] = null;
              }
            });
          }

          try {
            await $http({
              method: "POST",
              url: `https://01m1ojayw7.execute-api.us-east-1.amazonaws.com/prod/${$scope.UUID}`,
              data: {
                uuid: $scope.UUID,
                item_names_matched: $scope.itemNamesMatched,
                validation_name_source: $scope.validationNameSource,
                match_by: $scope.matchBy,
              },
            });

            await fetchNextData();
          } catch (error) {
            $notification.error("Error!", error);
          }
        }
      };
      // End

      $scope.validateTagsMatch = async () => {
        if ($scope.validateForm()) {
          // Ensures every manual matched item tag exists in item_tags_matched
          _.forEach($scope.results.similar_items_tags, (obj) => {
            if (!$scope.itemTagsMatched[obj["csv_tag"]]) {
              $scope.itemTagsMatched[obj["csv_tag"]] = null;
            }
          });

          try {
            await $http({
              method: "POST",
              url: `https://01m1ojayw7.execute-api.us-east-1.amazonaws.com/prod/${$scope.UUID}`,
              data: {
                uuid: $scope.UUID,
                item_tags_matched: $scope.itemTagsMatched,
                validation_tag_source: $scope.validationTagSource,
                match_by: $scope.matchBy,
              },
            });

            await fetchNextData();
          } catch (error) {
            $notification.error("Error!", error);
          }
        }
      };
      // End

      const fetchNextData = () => {
        $scope.waiting = true;

        return new Promise(async (resolve, reject) => {
          let fetchComplete = false;

          while (!fetchComplete) {
            try {
              let response = await $http({
                method: "GET",
                url: `https://01m1ojayw7.execute-api.us-east-1.amazonaws.com/prod/${$scope.UUID}`,
              });

              if (response.data && (response.data.brand_id || response.data.error)) {
                fetchComplete = true;
                $scope.results = response.data;

                // Autocomplete Selections
                let seen = [];
                if ($scope.results.csv_similar_matches) {
                  $scope.results.csv_similar_matches.forEach(item => {
                    item.similar_item_names.forEach(item_name => {
                      let newKey, newValue;
                      if ($scope.matchBy === "as_shown") {
                        newKey = `${item.input_item_name} ## ${item.input_tag_name}`;
                        newValue = `${item_name.db_item_name} ## ${item_name.db_item_tag}`;
                      } else {
                        newKey = item.input_item_name;
                        newValue = item_name.db_item_name;
                      }

                      if (item_name.score > 0.92 && !$scope.itemNamesMatched[newKey] && !(seen.includes(newValue))) {
                        $scope.itemNamesMatched[newKey] = newValue;
                        seen.push(newValue); // prevents repeating selection
                      }
                    });
                  });
                }

                // Save Form State
                if (
                  ($scope.results.csv_similar_matches &&
                    !$scope.results.manual_matched) ||
                  ($scope.results.similar_items_tags &&
                    !$scope.results.manual_matched_tags)
                ) {
                  $scope.saveState();
                }

                if ($scope.results && $scope.results.match_by) {
                  $scope.matchBy = $scope.results.match_by;
                }

                $scope.sourceUploadProcessing = false;
                $scope.taskFinished = true;

                $scope.waiting = false;
                removeLastSession();

                $scope.validationNameSource =
                  $scope.results && $scope.results.validation_name_source
                    ? $scope.results.validation_name_source
                    : null;
                $scope.validationInProgress = !!(
                  $scope.results && $scope.results.validation_name_source
                );

                resolve($scope.results); // Resolve the promise with the results
              }
            } catch (error) {
              $notification.error("Error!", error);
              reject(error); // Reject the promise with the error
            }

            await new Promise((resolve) => setTimeout(resolve, 7000)); // wait for 7 seconds before retrying
          }
        });
      };

      /**
       * CSV Upload
       */
      $scope.uploadCSV = async (files) => {
        $scope.sourceUploadProcessing = true;
        $scope.reset();
        const sourceUploaded = async (data, status, headers, config) => {
          $scope.S3URL = data.s3_url;
          try {
            let response = await $http({
              method: "POST",
              url: "https://01m1ojayw7.execute-api.us-east-1.amazonaws.com/prod",
              data: {
                location_id: $scope.locationID,
                url: data.s3_url,
                match_by: $scope.matchBy,
              },
            });

            if (!response.data.errors) {
              $scope.UUID = response.data.uuid;
            } else {
              $scope.errors = response.data.errors;
            }

            await fetchNextData();
          } catch (error) {
            $notification.error("Error!", error);
          }
        };

        if (files && files.length) {
          $scope.sourceUploadProcessing = true;
          for (let i = 0; i < files.length; i++) {
            let file = files[i];

            $upload
              .upload({
                url: "/api/csv-db-compare/upload/",
                fields: {
                  username: $scope.username,
                },
                file: file,
                params: { matchBy: $scope.matchBy },
              })
              .success(sourceUploaded)
              .error((response, status, headers) => {
                if (response.csvErrors) {
                  $scope.csvErrors = response.csvErrors;
                  $scope.sourceUploadProcessing = false;
                }
              });
          }
        }
      };

      // Restore previous session if `uuid` queryString found
      (async () => {
        try {
          if ($stateParams.uuid) {
            $scope.UUID = $stateParams.uuid;

            if ($scope.UUID) {
              await fetchNextData();
            }
          }
        } catch (error) {
          $notification.error("Error!", error);
        }
      })();
      // END
    }
  );
