(function () {
  'use strict';

  const moduleName     = 'email-file-delivery',
        controllerName = 'HeaderCtrl';

  const moduleFullName = `app.modules.${moduleName}`;

  angular.module(moduleFullName)
    .controller(`${moduleFullName}.${controllerName}`, ListCtrl);

  /*@ngInject*/
  function ListCtrl($log, $scope, $http, $notification, $filter, confirm) {
    $log.debug(`[${moduleName}] Controller "${controllerName}" launched`);

    const vm = this;

    vm.runExports = () => {
      confirm('Do you want to generate fresh export files?').then(async () => {
        try {
          await $http.post('/api/admin-tools/all-export');
          $notification.info('Exports jobs has been started. Corresponding file urls will update shortly');
        } catch (e) {
          $notification.error('Exports jobs failed to start: ' + $filter('readError')(e.data));
        }
      });
    };
  }
}());
