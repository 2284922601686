'use strict';

angular.module('app.controllers')
    .controller('RecipesCtrl',
        function ($scope, Recipe, ngTableParams, $filter, $location) {
            let search = $location.search();

            $scope.search = {
                term:    search.search || '',
                perform: function () {
                    $scope.tableParams.page(1);
                    $scope.tableParams.reload();
                }
            };

            $scope.filter = {
                build_status: +search.build_status || null
            };

            $scope.recipes = Recipe.query();

            $scope.tableParams = new ngTableParams(
                {
                    page:    1,            // show first page
                    count:   25,           // count per page
                    sorting: {
                        name: 'asc'     // initial sorting
                    },
                    filter:  {build_status: ''}
                },
                {
                    counts:  [],
                    total:   0, // length of data
                    getData: function ($defer, params) {
                        $scope.recipes.$promise.then(
                            function (recipes) {
                                var viewData = $filter('filter')(recipes, $scope.search.term);

                                if ($scope.filter.build_status) {
                                    viewData = $filter('filter')(viewData, function (recipe) {
                                        return !recipe.published_at &&
                                            $scope.filter.build_status === recipe.build_status;
                                    });
                                }

                                $scope.tableParams.total(viewData.length);

                                $scope.tableParams.custom.pages = $scope.tableParams.generatePagesArray(
                                    $scope.tableParams.page(),
                                    $scope.tableParams.total(),
                                    $scope.tableParams.count()
                                );

                                angular.forEach($scope.tableParams.sorting(), function (direction, attribute) {
                                    viewData = $filter('orderBy')(viewData, attribute, direction === 'desc');
                                });

                                viewData = viewData.slice(
                                    (params.page() - 1) * params.count(),
                                    params.page() * params.count()
                                );


                                $defer.resolve(viewData);
                            }
                        );
                    }
                });
            $scope.tableParams.custom = {};

            $scope.$watchGroup(['search.term', 'filter.build_status'], function () {
                let search = {};
                if ($scope.search.term) {
                    search.search = $scope.search.term;
                }

                if ($scope.filter.build_status) {
                    search.build_status = $scope.filter.build_status;
                }

                $location.search(search);
            });
        }
    );
