'use strict';

angular.module('app.controllers')
    .controller('TopScannedUPCsCatchAllCtrl',
        function ($scope, $state, $http, ngTableParams, $notification, debounce, commenter, confirm,
                  $location, arrayRefill, endlessTotal) {
            const vm = $scope.vm = this;

            $scope.search = {
                term:    $location.search().q || '',
                filter:  {},
                perform: function () {
                    $scope.tableParams.page(1);
                    $scope.tableParams.reload();
                }
            };

            const debouncedReload = debounce(function () {
                $scope.tableParams.reload();
            }, 1000);

            $scope.markBadUpc = function (record, data) {
                confirm("Are you sure you want to hide this UPC forever?").then(() => {
                    record.bad_upc = 1;

                    const reloadAfter = $http.post('/api/reports/masterUpc/' + record.upc, record, {params: {id: record.id}})
                        .then(function () {
                            $notification.info('marked bad upc');
                        })
                        .catch(function () {
                            $notification.error('failed to update');
                        });

                    vm.removeResult(record, reloadAfter);
                });
            };

            vm.markNoPhotos = function (record, data) {
                confirm("Are you sure you want to hide this UPC forever?").then(() => {
                    const reloadAfter = $http.post('/api/reports/masterUpc/' + record.upc, record, {params: {id: record.id}})
                        .then(function () {
                            $notification.info('marked as no photos');
                        })
                        .catch(function () {
                            $notification.error('failed to update');
                        });

                    vm.removeResult(record, reloadAfter);
                });
            };

            $scope.addNotes = function (item) {
                var dialog = commenter.open(item.notes);
                dialog.result.then(function (notes) {
                    item.notes = notes;
                });

                dialog.submit('nutritionix-api.master_upc', 'id', item.id);
            };

            vm.results = [];

            vm.removeResult = function (item, reloadAfter = Promise.resolve()) {
                _.pull(vm.results, item);
                reloadAfter.then(debouncedReload);
            };

            $scope.tableParams = new ngTableParams(
                {
                    page:    parseInt($location.search().page) || 1,            // show first page
                    count:   25,           // count per page
                    sorting: (function () {
                        const parts    = ($location.search().sort || 'scans.desc').split('.');
                        const sort     = {};
                        sort[parts[0]] = parts[1];

                        return sort;
                    }())
                },
                {
                    counts:  [],
                    total:   0, // length of data
                    getData: function ($defer, params) {
                        $location.search('page', params.page() === 1 ? null : params.page());
                        const sort = _.pairs(params.sorting())[0].join('.');
                        $location.search('sort', sort === 'scans.desc' ? null : sort);
                        $location.search('q', $scope.search.term || null);

                        $http.get('/reports/top-scanned-upcs-catch-all',
                            {
                                params: {
                                    limit:   params.count(),
                                    offset:  (params.page() - 1) * params.count(),
                                    search:  $scope.search.term,
                                    orderBy: sort,
                                    filter:  JSON.stringify($scope.search.filter)
                                }
                            }
                        ).then(function (response) {
                            const results = response.data.results;

                            params.total(endlessTotal(params, results.length));

                            $defer.resolve(arrayRefill(vm.results, results));
                        });
                    }
                }
            );
        }
    );
