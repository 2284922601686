(() => {
  'use strict';

  angular.module('app.controllers').controller('TasksSelectBestPhotoCtrl', TasksSelectBestPhotoCtrl);

  /*@ngInject*/
  function TasksSelectBestPhotoCtrl($scope, $state, $http, $notification, $modal, $q, cdnify, flagTask) {
    setup();
    init().catch(e => console.error('Init failed', e));

    function setup() {
      $scope.preview = function (asset) {
        $scope.setBestImage(asset);

        $modal.open({
          animation:   true,
          templateUrl: 'tasks/select-best-photo/preview-image.html',
          controller:  function ($scope, $modalInstance, src, photoType) {
            $scope.src       = src;
            $scope.photoType = photoType;

            $scope.close = function () {
              $modalInstance.dismiss();
            };
          },
          openedClass: 'body-scrollable',
          windowClass: 'modal-scrollable',
          //backdropClass: 'backdrop-scrollable',
          //backdrop:    false,
          size:    'lg',
          resolve: {
            src:       function () {
              return asset.src;
            },
            photoType: function () {
              return $scope.photoType;
            }
          }
        });
      };

      $scope.submit = async function (flag = false) {
        $scope.submit.$busy = flag ? 'flagging' : 'submitting';

        try {
          if (flag) {
            await flagTask($scope.vm.currentTask);
          } else {
            const asset = $scope.vm.bestImage;
            if (!asset) { return; }

            const fileGroup = $state.params.file_group;
            const taskId    = $scope.vm.currentTask.id;

            await setBestPhotoOnBackend(
              {
                src: asset.src,
                upc: $scope.vm.currentTask.upc,
              },
              fileGroup,
              taskId,
            );
          }

          await init();
        } finally {
          $scope.submit.$busy = null;
        }

        async function setBestPhotoOnBackend({upc, src}, fileGroup, taskId) {
          try {
            await $http.post(
              `/api/tasks/set-best-photo/${upc}`,
              {[`hive2_best_photo_${fileGroup}`]: src},
              {
                params: {task_id: taskId}
              }
            );

            $notification.info('Saved');
          } catch (e) {
            $notification.error('Not saved');
          }
        }
      };

      $scope.setBestImage = (asset) => {
        $scope.vm.bestImage = asset;
      };
    }

    async function init() {
      $scope.vm.bestImage = null;
      $scope.assets       = null;
      const fileGroup     = $state.params.file_group;

      const task = await $scope.init(4, {file_group: fileGroup});

      if (task) {
        let assets = getAssetsFromTaskMeta(task, fileGroup);

        if (!assets || !assets.length) {
          assets = await getUpcAssetsFromBackend(task.upc, fileGroup);
        }

        $scope.assets = assets.map(postProcessAsset);
      }

      function getAssetsFromTaskMeta(task, fileGroup) {
        if (_.get(task, 'meta.insertedBy') === 'populate-tasks-fitbit') {
          return _.get(task, 'meta.assets.' + fileGroup);
        } else {
          return _.get(task, 'meta.assets')
        }
      }

      async function getUpcAssetsFromBackend(upc, fileGroup) {
        return (await $http.get('/api/tasks/best-photo', {
          params: {
            file_group: fileGroup,
            upc
          }
        })).data;
      }

      function postProcessAsset(asset) {
        return {
          ...asset,
          src: cdnify('https://' + asset.bucket + '.s3.amazonaws.com/' + asset.prefix + asset.key)
        };
      }
    }
  }
})()



