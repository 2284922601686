(function () {
  'use strict';

  const moduleName     = 'admin-tools',
        controllerName = 'CreateBrandCtrl';

  const moduleFullName = `app.modules.${moduleName}`;

  angular.module(moduleFullName)
    .controller(`${moduleFullName}.${controllerName}`, CreateBrandCtrl);

  /*@ngInject*/
  function CreateBrandCtrl($log, $scope, $http, $notification, navigationGuard) {
    $log.debug(`[${moduleName}] Controller "${controllerName}" launched`);

    const vm = this;

    vm.brandForm = null;

    function resetForm() {
      vm.brand = {
        name:      '',
        is_active: true,
        type:      2
      };

      if (vm.brandForm) {
        vm.brandForm.$setPristine();
      }
    }

    vm.save = async function () {
      if (!vm.brandForm.$valid) {
        return $notification.error('Invalid form');
      }

      vm.save.$busy = true;

      try {
        await $http.post('/api/brands', vm.brand);
        $notification.info('Brand created');
        resetForm();
      } catch (e) {
        $notification.error(
          e.status === 409 ? 'Brand exists' : 'Brand creation failed'
        );
      } finally {
        vm.save.$busy = false;
      }
    };

    resetForm();

    navigationGuard.watch(
      () => vm.brandForm && vm.brandForm.$pristine,
      'Unsaved work here. Are you sure you want to leave the page?',
    );
  }
}());
