(function () {
  'use strict';

  const moduleName     = 'claims',
        controllerName = 'ListCtrl';

  const moduleFullName = `app.modules.${moduleName}`;

  angular.module(moduleFullName)
    .controller(`${moduleFullName}.${controllerName}`, ListCtrl);

  /*@ngInject*/
  function ListCtrl($log, $scope, $http, $notification, $filter, ngTableParams, $location, $q, $sessionStorage, claims, $timeout) {
    $log.debug(`[${moduleName}] Controller "${controllerName}" launched`);

    const vm = this;

    vm.data = claims;

    $scope.params = vm.tableParams = new ngTableParams(
      {
        page:    +$location.search().page || 1,            // show first page
        count:   25,           // count per page
        sorting: (function () {
          let sort = $location.search().sort;
          if (sort) {
            sort = sort.split('.');

            let result      = {};
            result[sort[0]] = sort[1];

            return result;
          }

          return {};
        }()),
        filter:  (function () {
          const locationFilters = {};

          _.forEach($location.search(), (v, k) => {
            if (k.substr(0, 7) === 'filter.') {
              locationFilters[k.substr(7)] = v;
            }
          });

          return _.defaults(locationFilters, {
            id:            '',
            nixClaimId:    '',
            claim:         '',
            description:   '',
            fda_regulated: '',
            on_pack_claim: '',
            completed:     '',
            custom:        '',
            created_by:    '',
          });
        }())
      },
      {
        counts:      [],
        total:       1000, // length of data,
        filterDelay: 50,
        getData:     function ($defer, params) {
          $location.search('page', params.page() > 1 ? params.page() : null);

          let filter = _.pick(params.filter(), v => !!v);
          let data   = $filter('filter')(vm.data, filter);

          _.keys(params.filter()).forEach(filterField => {
            $location.search(`filter.${filterField}`, filter[filterField] || null);
          });

          params.total(data.length);

          if (_.keys(params.sorting()).length) {
            let [sortingAttribute, sortingDirection] = _.pairs(params.sorting())[0];

            $location.search('sort', `${sortingAttribute}.${sortingDirection}`);

            data = _.sortByOrder(data, [sortingAttribute], [sortingDirection]);
          } else {
            $location.search('sort', null);
          }
          data = data.slice((params.page() - 1) * params.count(), params.page() * params.count() - 1);

          $defer.resolve(data);
        }
      }
    );


    vm.sources = $q.defer();
    vm.sources.resolve(_.uniq(claims.map(c => c.created_by)).map(s => ({id: s, title: s})));

    vm.booleanFilter = function () {
      const defer = $q.defer();
      defer.resolve([{id: '1', title: 'yes'}, {id: '0', title: 'no'}]);
      return defer;
    };

    vm.openClaim = () => {
      $sessionStorage.claimsListState = $location.url();
    };

  }
}());
