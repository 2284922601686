'use strict';

angular.module('app.controllers')
  .controller('DailyStatsViewCtrl',
    function ($scope, $http, $state, $notification, $filter, moment) {
      let vm = $scope.vm = this;

      vm.csvData = null;
      vm.now = moment();

      $scope.chart = {
        labels: [],
        data:   []
      };

      $scope.loadStats.success(function (statReports) {
        $scope.currentReport = _.find(statReports, {id: parseInt($state.params.id)});
      });

      $scope.tooltip = function (value) {
        return $filter('number')(value.value);
      };


      $http.get('/reports/dailystats/' + $state.params.id, {params: _.pick($state.params, 'source')})
        .success(function (stats) {
          $scope.stats = stats.slice(-30);
          $scope.chart.labels = _.map(_.pluck($scope.stats, 'date'), function (date) {
            var timestamp = [date.slice(0, 4), date.slice(4, 6), date.slice(6, 8)].join('-');
            return $filter('amDateFormat')(timestamp, 'ddd, MM/DD/YY');
          });
          $scope.chart.data = [_.pluck($scope.stats, 'stat_value')];

          vm.csvData = stats.map(row => {
            let timestamp = [row.date.slice(0, 4), row.date.slice(4, 6), row.date.slice(6, 8)].join('-');
            return {
              date:  $filter('amDateFormat')(timestamp, 'YYYY-MM-DD'),
              day:   $filter('amDateFormat')(timestamp, 'dddd'),
              value: row.stat_value
            };

          });
        })
        .catch(function () {
          $notification.error('Failed to load stats with id ' + $state.params.id);
        });
    }
  );
