'use strict';

angular.module('app.controllers')
  .controller('DailyStatsListCtrl',
    function ($scope, $http, $notification, $state) {
      const vm = $scope.vm = this;

      vm.source = $state.params.source;
      vm.section = $state.params.source === 'track' ? 'track-reports' : 'reports';

      $scope.loadStats = $http.get('/reports/dailystats', {params: $state.params});
      $scope.loadStats.success(function (statReports) {
          $scope.statReports = statReports;
        })
        .catch(function () {
          $notification.error('Failed to load dailystats');
        });
    }
  );
