'use strict';

angular.module('app.controllers')
    .controller('EnterpriseModifierGroupsCtrl', [
        '$scope', '$state', '$http', 'ngTableParams', '$notification', 'debounce',
        function ($scope, $state, $http, ngTableParams, $notification, debounce) {
            $http.get('/reports/enterprise/locations/' + $state.params.location_id)
                .success(function (location) {
                    $scope.location = location;
                })
                .catch(function () {
                    $notification.error('Failed to load location data');
                });

            $scope.search = {
                term:    '',
                filter:  {
                    'ingredients.location_id': $state.params.location_id
                },
                perform: function () {
                    $scope.tableParams.page(1);
                    $scope.tableParams.reload();
                }
            };

            $scope.tableParams = new ngTableParams(
                {
                    page:    1,            // show first page
                    count:   25,           // count per page
                    sorting: {
                        id: 'asc'     // initial sorting
                    }
                },
                {
                    counts:  [],
                    total:   0, // length of data
                    getData: function ($defer, params) {
                        var urlPath = '/export/reports/enterprise/modifier-groups';
                        var queryParams = {
                            search:  $scope.search.term,
                            orderBy: _.pairs(params.sorting())[0].join('.'),
                            filter:  JSON.stringify($scope.search.filter)
                        };

                        $scope.exportUrl = urlPath + '?' + $.param(_.extend({'export': 1}, queryParams));

                        queryParams.limit = params.count();
                        queryParams.offset = (params.page() - 1) * params.count();

                        $http.get(urlPath, {params: queryParams}).success(
                            function (data) {
                                params.total(data.count);
                                $defer.resolve(data.results);
                            }
                        );
                    }
                }
            );
        }
    ]);
