(function () {
  'use strict';

  const moduleName     = 'tasks',
        controllerName = 'FlaggedTasksReportCtrl';

  const moduleFullName = `app.modules.${moduleName}`;

  angular.module(moduleFullName)
    .controller(`${moduleFullName}.${controllerName}`, FlaggedTasksReportCtrl);

  /*@ngInject*/
  function FlaggedTasksReportCtrl($log, $http, $notification, $filter, ngTableParams,
                                  $location, confirm, debounce, endlessTotal, arrayRefill) {
    $log.debug(`[${moduleName}] Controller "${controllerName}" launched`);

    const vm = this;

    const debouncedReload = debounce(function () {
      vm.tableParams.reload();
    }, 1000);

    vm.results = [];

    vm.removeResult = function (item, reloadAfter = Promise.resolve()) {
      _.pull(vm.results, item);
      reloadAfter.then(debouncedReload);
    };

    vm.tableParams = new ngTableParams(
      {
        page:    $location.search().page || 1,            // show first page
        count:   25,           // count per page
        sorting: (function () {
          let sort = $location.search().sort;
          if (sort) {
            sort = sort.split('.');

            let result = {};
            result[sort[0]] = sort[1];

            return result;
          }

          return {totalUpcFlagged: 'desc'};
        }()),
        filter:  {upc: $location.search()['filter.upc'] || ''}
      },
      {
        counts:  [],
        total:   0, // length of data
        getData: function ($defer, params) {
          const page = params.page();
          $location.search('page', page > 1 ? page : null);

          let [sortingAttribute, sortingDirection] = _.pairs(params.sorting())[0];
          let orderBy                              = `${sortingAttribute}.${sortingDirection}`;

          $location.search('sort', orderBy === 'totalUpcFlagged.desc' ? null : orderBy);
          $location.search('filter.upc', params.filter().upc || null);

          $http.get(
            '/api/tasks/reports/flagged-tasks',
            {
              params: _.pick({
                orderBy, page, count: params.count(),
                'filter.upc':         params.filter().upc || undefined
              }, v => !!v)
            })
            .then(response => {
              arrayRefill(vm.results, response.data);
              params.total(endlessTotal(params, response.data.length));
              $defer.resolve(vm.results);
            })
            .catch(response => $notification.error(
              'Failed to load tasks: ' + $filter('readError')(response.data)
            ));
        }
      }
    );

    vm.markUpc = function (upc, type = 'bad') {
      let message, update;

      if (type === 'bad') {
        message = "Are you sure you want to mark this UPC bad and hide it forever?";
        update  = {bad_upc: 1};
      } else if (type === 'no-nutrition') {
        message = "Are you sure you want to mark this UPC having no nutrition info and hide it forever?";
        update  = {missing_nutrition_label: 1};
      }

      confirm(message).then(() => {
        const reloadAfter = $http.post('/api/reports/masterUpc/' + upc.upc, update)
          .then(function () {
            $notification.info('marked bad upc');
          })
          .catch(function () {
            $notification.error('failed to update');
          });

        vm.removeResult(upc, reloadAfter);
      });
    };
  }
}());
