'use strict';

angular.module('app.controllers').controller(
    'AdminToolsTrackTwitterResponderCtrl',
    function ($scope, $http, $notification, $state, $filter, tweet) {
        var vm = $scope.vm = this;
        vm.tweet = tweet;

        vm.reply = function () {
            tweet.replied = true;
            $http.post('/api/admin-tools/track/twitter-responder/tweets/' +
                    $state.params.tweet_id, {status: vm.reply.text})
                .success(function () {
                    $notification.info('Response tweeted');
                })
                .error(function (error) {
                    var message;

                    if (angular.isString(error)) {
                        message = error;
                    } else {
                        message = error.errors && error.errors[0] && error.errors[0].message ||
                            'Unexpected backend error';
                    }

                    $notification.error('Could not tweet response: ' + message);

                });
        };

        vm.reply.generate = function () {
            if (!(tweet && angular.isArray(tweet.foods))) {
                return null;
            }

            var text = '@' + tweet.user + ' ';

            if (tweet.foods.length === 1) {
                text += [
                    tweet.foods[0].food_name + ',',
                    (tweet.foods[0].serving_qty || 1),
                    tweet.foods[0].serving_unit,
                    'has approximately', $filter('number')(tweet.foods[0].nf_calories, 0), 'calories'
                ].join(' ');
            } else {
                text += 'I estimate that you just consumed ' +
                    $filter('number')(_.sum(_.pluck(tweet.foods, 'nf_calories')) || 0, 0) + ' calories';
            }

            text += " http://www.nutritionix.com/labs/twitter-analyzer/view/" + tweet.tweet_id;

            text += ' #nixtrack';

            vm.reply.text = text;
        };

        vm.reply.generate();
    });
