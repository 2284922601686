'use strict';

angular.module('app.controllers')
    .controller('CsvUploadValidationToolCtrl',
        function ($scope, $timeout, $upload, $notification, session) {
            function reset() {
                $scope.csv = {
                    file:    null,
                    getFile: function () {
                        return this.file && this.file[0] || null;
                    }
                };

                (function () {
                    var csvFormUnwatch = $scope.$watch('csvForm', function csvFormWatch() {
                        csvFormUnwatch();
                        $scope.csvForm.$setPristine();
                    });
                }());
            }

            reset();

            $scope.clearUpload = function () {
                $scope.csv.file = null;
            };

            $scope.submit = function () {
                if ($scope.csv.getFile()) {
                    $upload
                        .upload({
                            url:              '/api/admin/csv-upload-validation-tool',
                            file:             $scope.csv.file,
                            fileFormDataName: ['file']
                        })
                        .then(function () {
                            $notification.info(
                                'File uploaded successfully. ' +
                                'Results will e-mailed to ' + session.profile.email + ' shortly'
                            );
                            reset();
                        })
                        .catch(function (error) {
                            $notification.error('File upload failed');
                        });
                } else {
                    $notification.error('Required csv file');
                }
            };
        }
    );
