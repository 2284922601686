(function () {
  'use strict';

  const moduleName     = 'developers',
        controllerName = 'EditCtrl';

  const moduleFullName = `app.modules.${moduleName}`;

  angular.module(moduleFullName)
    .controller(`${moduleFullName}.${controllerName}`, EditCtrl);

  /*@ngInject*/
  function EditCtrl($log, $http, $filter, $notification, developer, applications) {
    $log.debug(`[${moduleName}] Controller "${controllerName}" launched`);

    const vm = this;

    vm.developer    = developer;
    vm.applications = applications;

    vm.save = async () => {
      try {
        await $http.put(`/api/developers/${developer.id}`, developer);
        $notification.info('Developer updated')
      } catch (e) {
        $notification.error(`Developer not updated: ${$filter('readError')(e.data)}`);
      }
    }

    vm.updateApp = async (app) => {
      try {
        await $http.patch(`/api/developers/${developer.id}/apps/${app.id}`, { contract_users: app.contract_users });

        $notification.info('Application updated')
      } catch (e) {
        $notification.error(`Application not updated: ${$filter('readError')(e.data)}`)
      }
    }
  }
}());
