(function () {
  'use strict';

  const moduleName     = 'ingredients-based-claims-generator',
        controllerName = 'UploadStatementsCtrl';

  const moduleFullName = `app.modules.${moduleName}`;

  angular.module(moduleFullName)
    .controller(`${moduleFullName}.${controllerName}`, UploadStatementsCtrl);

  /*@ngInject*/
  function UploadStatementsCtrl($scope, $notification, $upload, $filter) {
    let vm = $scope.vm = this;

    vm.clearUpload = () => {
      vm.uploadedFile           = null;
      vm.uploadedFileCollection = null;
    };
    vm.clearUpload();

    vm.fileSelected = collection => {
      if (vm.fileSelected.rejected && vm.fileSelected.rejected.length) {
        $notification.error('Unsupported file type. Please upload CSV files only');
      } else if (collection && collection.length) {
        vm.uploadedFile = collection[0];
      }
    };

    vm.submit = () => {
      vm.submit.$busy = true;

      $upload
        .upload({
          url:              '/api/ingredients-based-claims-generator/upload-statements',
          file:             vm.uploadedFile,
          fileFormDataName: 'csv',
        })
        .then(function () {
          $notification.info('File uploaded successfully and is being processed in the background.');
          vm.clearUpload();
        })
        .catch(function (response) {
          $notification.error('File upload failed: ' + $filter('readError')(response.data));
        })
        .finally(function () {
          vm.submit.$busy = false;
        });
    };
  }
}());
