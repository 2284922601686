(function () {
  'use strict';

  angular.module('app.modules.claims')
    .directive('claimName', function claimNameDirectiveFactory() {
      return {
        scope:   {
          claim: '=claimName'
        },
        require: 'ngModel',
        link:    function link(scope, elm, attrs, ctrl) {
          ctrl.$validators.claimName = function (modelValue, viewValue) {
            if(!scope.claim.custom){
              return true;
            }

            return viewValue.toLowerCase().indexOf('custom') > -1;
          };


          scope.$watch('claim.custom', function(){
            ctrl.$validate();
          });
        }
      };
    });
}());
