'use strict';

'use strict';

const moduleName     = 'reports',
      controllerName = 'CpgImageSearchCtrl';

const moduleFullName = `app.modules.${moduleName}`;

angular.module(moduleFullName)
  .controller(`${moduleFullName}.${controllerName}`, CpgImageSearchCtrl);

/*@ngInject*/
function CpgImageSearchCtrl($scope, $http, $state, confirm, $notification, $filter, cdnify, $location, $anchorScroll) {
  const vm = $scope.vm = this;

  vm.search = {
    term:    $location.search().q || '',
    page:    parseInt($location.search().p || 1),
    go:      function (direction) {
      this.page = this.page + parseInt(direction || 1);
      if (this.page < 1) {
        this.page = 1;
      }

      this.perform();
    },
    perform: function () {
      $location.search('q', this.term || null);
      $location.search('p', this.page === 1 ? null : this.page);

      vm.assets       = null;
      vm.assetsGroups = null;

      vm.search.perform.$busy = true;
      $http.get('/reports/cpg-image-search',
        {
          params: {
            q:    vm.search.term,
            page: this.page || 1
          }
        }
      ).then(function (response) {
          vm.assets       = response.data;
          vm.assetsGroups = _.chunk(vm.assets, 4);

          vm.assets.forEach(a => a.url = cdnify(a.url));
        }
      )
        .finally(() => vm.search.perform.$busy = null);

      $anchorScroll();
    }
  };

  if (vm.search.term) {
    vm.search.perform();
  }
}
