'use strict';

angular.module('app.controllers')
    .controller('UtilFixMissingTagPhrases',
        function ($scope, $state, $http, $notification) {
            var url = '/api/utils/fix-missing-tag-phrases';
            $scope.execute = function (preview) {
                $scope.preview = preview;

                $http[preview ? 'get' : 'post'](url)
                    .success(function (report) {
                        $scope.report = report;
                    })
                    .catch(function () {
                        $notification.error('Failed to execute utility');
                    });
            };

            $scope.execute(true);
        });
