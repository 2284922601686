(function () {
  'use strict';

  const moduleName = 'record-edit-history';

  const moduleFullName = `app.modules.${moduleName}`;

  angular.module(moduleFullName, [])
    .config(function () {
      angular.injector(['ng']).get('$log')
        .debug(`[${moduleName}] Module configured`);
    })
    .run(function ($log) {
      $log.debug(`[${moduleName}] Module launched`);
    });
}());


