(function () {
  'use strict';

  const moduleName = 'reports';

  const moduleFullName = `app.modules.${moduleName}`;

  const state       = stateName => `app.${moduleName}` + (stateName ? `.${stateName}` : '');
  const templateUrl = viewName => {
    let parts = viewName.split('/');
    viewName  = parts.pop();
    let path  = parts.join('/');

    return `modules/${moduleName}/${path}/${moduleName}.${viewName}.html`.replace(/\/+/g, '/');
  };
  const controller  = controllerName => `${moduleFullName}.${controllerName} as vm`;

  angular.module(moduleFullName, [])
    .config(function ($stateProvider) {
      $stateProvider
        .state(state(), {
          url:      `/${moduleName}`,
          abstract: true,
          template: `<div class="module_${moduleName}" ui-view></div>`,
          data:        {
            requiresLogin: true
          }
        })
        .state(state('non-client-restaurant-updates'), {
          url: '/non-client-restaurant-updates',
          controller: controller('NonClientRestaurantUpdatesController'),
          templateUrl: templateUrl('non-client-restaurant-updates/non-client-restaurant-updates')
        })
        .state(state('tags-without-food-group'), {
          url:         '/tags-without-food-group',
          controller:  controller('TagsWithoutFoodGroupCtrl'),
          templateUrl: templateUrl('tags/tags-without-food-group')
        })
        .state(state('tags-without-gs1-class'), {
          url:         '/tags-without-gs1-class',
          controller:  controller('TagsWithoutGs1ClassCtrl'),
          templateUrl: templateUrl('tags/tags-without-gs1-class')
        })
        .state(state('tags-without-taxonomy'), {
          url:         '/tags-without-taxonomy',
          controller:  controller('TagsWithoutTaxonomyCtrl'),
          templateUrl: templateUrl('tags/tags-without-taxonomy')
        })
        .state(state('tags-without-valid-image'), {
          url:         '/tags-without-valid-image',
          controller:  controller('TagsWithoutValidImageCtrl'),
          templateUrl: templateUrl('tags/tags-without-valid-image')
        })
        .state(state('upc-trace'), {
          url:         '/upc-trace',
          controller:  controller('UpcTraceCtrl'),
          templateUrl: templateUrl('upc-trace/upc-trace'),
          onEnter:     function ($anchorScroll) {
            $anchorScroll();
          },
        })
        .state(state('upc-trace.view'), {
          url:         '/:upc',
          controller:  controller('UpcTraceViewCtrl'),
          templateUrl: templateUrl('upc-trace/upc-trace.view')
        })
        .state(state('top-grocery-30-days'), {
          url:         '/top-grocery-30-days',
          controller:  controller('TopGrocery30DaysCtrl'),
          templateUrl: templateUrl('top-grocery-30-days/top-grocery-30-days')
        })
        .state(state('cpg-image-search'), {
          url:         '/cpg-image-search',
          controller:  controller('CpgImageSearchCtrl'),
          templateUrl: templateUrl('cpg-image-search/cpg-image-search')
        })
        .state(state('missing-ingredients-cxh-sourced'), {
          url:         '/missing-ingredients-cxh-sourced',
          controller:  controller('MissingIngredientsCxhSourcedCtrl'),
          templateUrl: templateUrl('missing-ingredients-cxh-sourced/missing-ingredients-cxh-sourced')
        })
        .state(state('nix-dev-photo-uploads'), {
          url:         '/nix-dev-photo-uploads',
          controller:  controller('NixDevPhotoUploadsCtrl'),
          templateUrl: templateUrl('nix-dev-photo-uploads/nix-dev-photo-uploads'),
          resolve:     {
            developers: ($http, $notification, $filter) => $http.get('/reports/nix-dev-photo-uploads/developers')
              .then(response => response.data)
              .catch(response => {
                $notification.error('Failed to load developers: ' + $filter('readError')(response.data));
                return [];
              })
          }
        })
        .state(state('task-review'), {
          url:         '/task-review',
          controller:  controller('TaskReviewCtrl'),
          templateUrl: templateUrl('task-review/task-review'),
          resolve:     {
            users: ($http, $notification, $filter) => $http.get('/api/users')
              .then(response => response.data)
              .catch(response => {
                $notification.error('Failed to load users: ' + $filter('readError')(response.data));
                return [];
              })
          },
          onEnter:     function ($anchorScroll) {
            $anchorScroll();
          },
        })
        .state(state('visualise-transcription-flow'), {
          url:         '/visualise-transcription-flow',
          controller:  controller('VisualizeTranscriptionFlowCtrl'),
          templateUrl: templateUrl('visualise-transcription-flow/visualise-transcription-flow'),
          resolve:     {
            users: ($http, $notification, $filter) => $http.get('/api/users')
              .then(response => response.data)
              .catch(response => {
                $notification.error('Failed to load users: ' + $filter('readError')(response.data));
                return [];
              })
          },
          onEnter:     function ($anchorScroll) {
            $anchorScroll();
          },
        })
        .state(state('report-builder'), {
          url:         '/builder',
          controller:  controller('ReportBuilderCtrl'),
          templateUrl: templateUrl('report-builder/report-builder'),
          onEnter:     ($anchorScroll) => $anchorScroll(),
        });

      angular.injector(['ng']).get('$log')
        .debug(`[${moduleName}] Module configured`);
    })
    .run(function ($log) {
      $log.debug(`[${moduleName}] Module launched`);
    });
}());


