(function () {
  'use strict';

  const moduleName     = 'developers',
        controllerName = 'ListCtrl';

  const moduleFullName = `app.modules.${moduleName}`;

  angular.module(moduleFullName)
    .controller(`${moduleFullName}.${controllerName}`, ListCtrl);

  /*@ngInject*/
  function ListCtrl($log, $http, $notification, $filter, ngTableParams, $location) {
    $log.debug(`[${moduleName}] Controller "${controllerName}" launched`);

    const vm = this;

    vm.search = {
      term:    $location.search().q || '',
      perform: function () {
        vm.tableParams.page(1);
        vm.tableParams.reload();
      }
    };

    vm.tableParams = new ngTableParams(
      {
        page:    $location.search().page || 1,            // show first page
        count:   25,           // count per page
        sorting: (function () {
          let sort = $location.search().sort;
          if (sort) {
            sort = sort.split('.');

            let result      = {};
            result[sort[0]] = sort[1];

            return result;
          }

          return {'users_updated_at': 'desc'};
        }())
      },
      {
        counts:  [],
        total:   0, // length of data
        getData: function ($defer, params) {
          $location.search('page', params.page() > 1 ? params.page() : null);
          $location.search('q', vm.search.term || null);

          let [sortingAttribute, sortingDirection] = _.pairs(params.sorting())[0];
          let sort                                 = `${sortingAttribute}.${sortingDirection}`;

          $location.search('sort', sort === 'users_updated_at.desc' ? null : sort);

          $http.get('/api/developers', {params: {page: params.page(), search: vm.search.term, sort}})
            .then(response => {
              const data = response.data;
              params.total(data.total);
              $defer.resolve(data.developers);
            })
            .catch(response => {
              $notification.error('Failed to load developers: ' + $filter('readError')(response.data));
              $defer.reject();
            });
        }
      }
    );
  }
}());
