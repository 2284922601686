angular.module('app.modules.auth')
  .factory('session', function SessionFactory($http, $cookies, $q, $sessionStorage) {
    const cookieName = 'SESSION_ID';

    let resolveSignedIn;

    const service = {
      profile: null,

      setProfile(profile) {
        $sessionStorage.profile = this.profile = profile;

        const permissions = this.profile.permissions || [];
        permissions.has   = (key) => permissions.indexOf('admin') !== -1 || permissions.indexOf(key) !== -1

        this.profile.permissions = permissions;
      },

      signedIn: $q(resolve => {
        resolveSignedIn = resolve;
      }),

      isCookieSet() {
        return !!$cookies.get(cookieName);
      },

      async loadProfile() {
        return (await $http.get('/profile')).data;
      },

      async isValid() {
        if (this.isCookieSet()) {
          try {
            if (!this.profile) {
              this.setProfile(await this.loadProfile());
            }

            return this.profile.status === 'active';
          } catch (e) {
            return false;
          }
        }
      },

      async activate() {
        if (await this.isValid()) {
          resolveSignedIn(true);
        }
      },

      logout() {
        $cookies.remove(cookieName)
      }
    };

    if ($sessionStorage.profile) {
      service.setProfile($sessionStorage.profile);
    }

    return service;
  });
