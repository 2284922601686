(function () {
  'use strict';

  const moduleName     = 'labels',
        controllerName = 'ListCtrl';

  const moduleFullName = `app.modules.${moduleName}`;

  angular.module(moduleFullName)
    .controller(`${moduleFullName}.${controllerName}`, ListCtrl);

  /*@ngInject*/
  function ListCtrl($log, $http, $notification, $filter, ngTableParams, $location, labelCategories) {
    $log.debug(`[${moduleName}] Controller "${controllerName}" launched`);

    const vm = this;

    vm.categories = labelCategories;

    vm.data = [];

    vm.tableParams = new ngTableParams(
      {
        page:    $location.search().page || 1,            // show first page
        count:   25,           // count per page
        sorting: (function () {
          let sort = $location.search().sort;
          if (sort) {
            sort = sort.split('.');

            let result = {};
            result[sort[0]] = sort[1];

            return result;
          }

          return {};
        }())
      },
      {
        counts:  [],
        total:   0, // length of data
        getData: function ($defer, params) {
          $location.search('page', params.page() > 1 ? params.page() : null);

          let data = vm.data;

          params.total(data.length);

          if (_.keys(params.sorting()).length) {
            let [sortingAttribute, sortingDirection] = _.pairs(params.sorting())[0];

            $location.search('sort', `${sortingAttribute}.${sortingDirection}`);

            data = _.sortByOrder(data, [sortingAttribute], [sortingDirection]);
          } else {
            $location.search('sort', null);
          }
          data = data.slice((params.page() - 1) * params.count(), params.page() * params.count() - 1);

          $defer.resolve(data);
        }
      }
    );

    $http.get('/api/labels')
      .then(response => {
        vm.data = response.data;
        vm.tableParams.reload();
      })
      .catch(response => $notification.error(
        'Failed to load labels: ' + $filter('readError')(response.data)
      ));

    vm.editModel = label => {
      vm.model = label;
      $('form').focus();
    };

    vm.save = () => {
      vm.save.$busy = true;
      let id = vm.model.id;


      $http.post('/api/labels' + (vm.model.id ? `/${vm.model.id}` : ''), vm.model)
        .then(response => {
          if (!id) {
            vm.data.push(response.data);
            vm.tableParams.reload();
          } else {
            _.merge(_.find(vm.data, {id}), response.data);
          }

          $notification.success('Label saved');

          vm.resetModel();
        })
        .catch(response => $notification.error(
          'Failed to save label: ' + $filter('readError')(response.data)
        ))
        .finally(() => {
          vm.save.$busy = false;
        });
    };

    vm.resetModel = () => {
      vm.model = {
        name:        '',
        category_id: 1
      };
    };

    vm.resetModel();
  }
}());
