(function () {
  'use strict';

  const moduleName = 'admin-tools';

  const moduleFullName = `app.modules.${moduleName}`;

  const state       = stateName => `app.${moduleName}` + (stateName ? `.${stateName}` : '');
  const templateUrl = viewName => {
    let parts = viewName.split('/');
    viewName  = parts.pop();
    let path  = parts.join('/');

    return `modules/${moduleName}/${path}/${moduleName}.${viewName}.html`.replace(/\/+/g, '/');
  };
  const controller  = controllerName => `${moduleFullName}.${controllerName} as vm`;

  angular.module(moduleFullName, [])
    .config(function ($stateProvider) {
      $stateProvider
        .state(state(), {
          url:      `/${moduleName}`,
          abstract: true,
          template: `<div class="module_${moduleName}" ui-view></div>`,
          data:     {},
        })
        .state(state('list'), {
          url:         '',
          controller:  controller('ListCtrl'),
          templateUrl: templateUrl('list')
        })
        .state(state('categorization-demo'), {
          url:         '/categorization-demo',
          controller:  controller('CategorizationDemoCtrl'),
          templateUrl: templateUrl('categorization-demo/categorization-demo'),
          resolve:     {
            gs1: (gs1Promise) => gs1Promise,
          }
        })
        .state(state('create-brand'), {
          url:         '/create-brand',
          controller:  controller('CreateBrandCtrl'),
          templateUrl: templateUrl('create-brand/create-brand'),
        })
        .state(state('api-review-interface'), {
          url:        '/api-review-interface',
          controller: controller('ApiReviewInterfaceCtrl'),
          templateUrl: templateUrl('api-review-interface/api-review-interface'),
        })
        .state('app.admin-tools.help-doc-links', {
          url:         '/help-doc-links',
          controller:  'AdminToolsHelpDocLinksCtrl',
          templateUrl: 'views/admin-tools/help-doc-links.html'
        })
        .state('app.admin-tools.spot-check', {
          url:         '/spot-check',
          templateUrl: 'views/admin-tools/spot-check.html',
          controller:  'spotCheckCtrl'
        })
        .state('app.admin-tools.spot-check-view', {
          url:         '/spot-check/:id',
          templateUrl: 'views/admin-tools/spot-check-view.html',
          controller:  'spotCheckViewCtrl'
        })
        .state('app.admin-tools.track', {
          abstract: true,
          url:      '/track',
          template: '<div ui-view></div>'
        })
        .state('app.admin-tools.track.twitter-responder', {
          url:         '/twitter-responder/:tweet_id',
          controller:  'AdminToolsTrackTwitterResponderCtrl',
          templateUrl: 'views/admin-tools/track/twitter-responder.html',
          resolve:     {
            tweet: function ($http, $notification, $stateParams) {
              return $http.get('/api/admin-tools/track/twitter-responder/tweets/' + $stateParams.tweet_id)
                .then(function (response) {
                  return response.data;
                })
                .catch(function () {
                  $notification.error('Failed to load tweet');
                });
            }
          }
        });

      angular.injector(['ng']).get('$log')
        .debug(`[${moduleName}] Module configured`);
    })
    .run(function ($log) {
      $log.debug(`[${moduleName}] Module launched`);
    });
}());


