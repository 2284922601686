'use strict';

angular.module('app.controllers')
    .controller('ImageUploadCtrl',
        function($scope, $interval, $upload, $http) {
            $scope.height = 480;
            $scope.width = 480;
            $scope.fontSize = 32;

            var vm = this;

            /**
             * Nutrition Source Upload
             */
            $scope.stopRefreshUploadLog = function() {
                $interval.cancel(intervalPromise);
            };

            $scope.refreshUploadLogEach = function(interval) {
                return $interval(function() {
                    NutritionSrcUpload.getTaskInfo({
                        id: $scope.taskID
                    }).$promise.then(function(taskInfo) {
                        $scope.currentStatus = taskInfo.status;

                        if ($scope.currentStatus == 'queued' || $scope.currentStatus == 'running') {
                            $scope.sourceUploadProcessing = false;
                        }

                        if ($scope.currentStatus == 'complete') {
                            stopRefreshUploadLog();
                            $scope.waiting = false;
                            $scope.finished = true;
                            $scope.sourceUploadSuccess = true;

                            // Reload
                            Brand.get({
                                id: $state.params.id
                            }, function(data) {
                                $scope.brand['assetCollections'] = data.assetCollections;
                            });
                        } else if ($scope.currentStatus == 'error') {
                            $scope.sourceUploadError = 'There was a server-side error processing this file, please contact the administrator!';
                        } else if ($scope.currentStatus == 'timeout' || $scope.currentStatus == 'killed' || $scope.currentStatus == 'cancelled') {
                            $scope.sourceUploadError = 'The server-side processing was stopped before completion';
                        } else {
                            // Code here
                        }

                    });
                }, interval);
            };

            $scope.intervalPromise = {};

            $scope.uploadImage = function(files) {
                var convertImage = function(s3_url) {
                    var body = {
                        s3_url: s3_url,
                        attributes: {
                            height: parseInt($scope.height),
                            width: parseInt($scope.width),
                            crop: "true",
                            format: "jpg",
                            optimize: "true",
                            enhance: "true",
                            name_template: "{timestamp}_{original_filename}_{height}x{width}_cropped",
                            filter: "fancyfood",
                            overlay: {
                                title: $scope.title,
                                subtext: $scope.subtext,
                                position: "bottom",
                                size: parseInt($scope.fontSize),
                                color: "#ffffff",
                                opacity: 50,
                                "background-color": "#000000",
                                "background-opacity": 50
                            }
                        }
                    };

                    $http({
                        method: 'POST',
                        url: 'https://pb0vqoe829.execute-api.us-east-1.amazonaws.com/dev',
                        data: body
                    }).then(function successCallback(response) {
                        // this callback will be called asynchronously
                        // when the response is available
                        $scope.sourceUploadProcessing = false;
                        console.log('success: ', response);
                        $scope.s3_url = response.data.s3_url;
                    }, function errorCallback(response) {
                        // called asynchronously if an error occurs
                        // or server returns response with an error status.
                        $scope.sourceUploadProcessing = false;
                        console.log('error: ', response);
                    });
                };

                var sourceUploaded = function(data, status, headers, config) {
                    // console.log('file ' + config.file.name + 'uploaded. Response: ' + JSON.stringify(data) );
                    // $scope.uploadID = data.uploadID;
                    // $scope.taskID = data.taskID;

                    // console.log('Upload ID: ', $scope.uploadID);
                    // intervalPromise = $scope.refreshUploadLogEach(3000);
                    $scope.sourceUploadProcessing = true;

                    $scope.sourceImgURL = data.s3_url;
                    convertImage(data.s3_url);
                };

                $scope.sourceUploadSuccess = false;
                $scope.sourceUploadProcessing = false;
                $scope.sourceUploadWarning = NaN;
                $scope.sourceUploadError = NaN;
                if (files && files.length) {
                    $scope.sourceUploadProcessing = true;
                    for (var i = 0; i < files.length; i++) {
                        var file = files[i];

                        $upload.upload({
                            url: '/api/image/upload/',
                            fields: {
                                'username': $scope.username
                            },
                            file: file
                        }).success(sourceUploaded);
                    }
                }
            };
            // END
        });
