(function () {
  'use strict';

  angular.module('app.modules.email-templates')
    .factory(`emailTemplatesVariables`, EmailTemplatesVariables);

  /*@ngInject*/
  function EmailTemplatesVariables($http, session, $notification) {
    const url = '/api/email-templates-variables';
    const variables = {};

    return Promise.resolve(session.profile)
      .then(profile => {
        variables['hive2_user_name'] = profile.public_name ||
          `${profile.given_name || ''} ${profile.family_name || ''}`.trim();
      })
      .then(() => $http.get(url))
      .then(response => {
        response.data.forEach(variableData => {
          variables[variableData.key] = variableData.value;
        });
      })
      .then(() => ({
        get: function (key = null) {
          return key ? variables[key] : variables;
        },
        set: function (key, value) {
          if (key) {
            return $http.post(url, {key, value})
              .then(response => {
                variables[response.data.key] = response.data.value;

                $notification.info(
                  'Email template variable set ' +
                  `${response.data.key}="${response.data.value}"`
                );
              });
          }
        }
      }));
  }
}());
