'use strict';

angular.module('app.services').factory('HelpDocLink', function ($resource) {
    var HelpDocLink = $resource('/api/admin-tools/help-doc-links/:id', {id: '@id'});

    HelpDocLink.prototype.updateSlug = function () {
        this.slug = (this.title || '')
            .replace(/[^\w\s]+/g, '')
            .replace(/\s+/g, '-').toLowerCase();
    };

    return HelpDocLink;
});
