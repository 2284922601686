(function () {
  'use strict';

  angular.module('app.services')
    .factory('gs1Promise', function ($http, $filter, $notification) {
      const gs1 = {classes: [], bricks: []};

      return $http.get('https://nix-hive2.s3.amazonaws.com/misc/gs1_brick_codes.json.gz')
        .then(response => {
          gs1.bricks = response.data;
          gs1.bricks.forEach((brick) => {
            brick.class_code = +brick.class_code;
            brick.brick_code = +brick.brick_code;
          });

          let classes = gs1.bricks.map(v => _.pick(v, ['class_code', 'class_description']));
          classes     = _.unique(classes, c => c.class_code);
          classes.push({
            class_code:        -1,
            class_description: 'UNKNOWN GS1 Class'
          });

          gs1.classes = classes;
        })
        .catch(response => {
          $notification.error('Failed to load gs1 data: ' + $filter('readError')(response.data));
        })
        .then(() => gs1)
    })
    .factory('taxonomyPromise', function ($http, $filter, $notification) {
      return $http.get('https://d1t8wlwervl0at.cloudfront.net/leaf_nodes.json.gz')
        .then((response) => response.data)
        .catch((response) => {
          $notification.error(`Failed to load taxonomy: ${$filter('readError')(response.data)}`);
          return [];
        })
    })
}());
