(function () {
  'use strict';

  const moduleName     = 'admin-tools',
        controllerName = 'ListCtrl';

  const moduleFullName = `app.modules.${moduleName}`;

  angular.module(moduleFullName)
    .controller(`${moduleFullName}.${controllerName}`, ListCtrl);

  /*@ngInject*/
  function ListCtrl($scope, $http, $notification, $filter, confirm) {
    const vm = $scope.vm = this;

    vm.triggers = [
      {
        'id':             'naturalUpdate',
        'url':            '/api/admin-tools/natural-update',
        'successMessage': 'Natural update was triggered',
        'errorMessage':   'Natural update was not triggered',
        'name':           'Trigger Natural Update'
      },
      {
        'id':             'dumpForApi',
        'url':            '/api/admin-tools/dump-for-api',
        'successMessage': 'Dump For Api was triggered',
        'errorMessage':   'Dump For Api was not triggered',
        'name':           'Trigger Dump For Api',
        'permission':     'admin'
      },
      {
        'id':             'phrasesExport',
        'url':            '/api/admin-tools/phrases-export',
        'successMessage': 'Phrases Export was triggered',
        'errorMessage':   'Phrases Export was not triggered',
        'name':           'Trigger Phrases Export',
        'permission':     'admin'
      },
      {
        'id':             'allExport',
        'url':            '/api/admin-tools/all-export',
        'successMessage': 'Phrases and Bulk Exports were triggered',
        'errorMessage':   'Phrases and Bulk Exports were not triggered',
        'name':           'Trigger Phrases and Bulk Exports',
        'permission':     'admin'
      }
    ];

    vm.trigger = (func) => {
      const definition = _.find(vm.triggers, {id: func});

      confirm(`Are you sure you want to ${definition.name}`).then(() => {
        vm.trigger.$busy[func] = true;
        $http.post(definition.url, definition.payload || {})
          .then(response => {
            $notification.info(definition.successMessage);
          })
          .catch(response => {
            $notification.error(
              `${definition.errorMessage}: ` +
              $filter('readError')(response.data)
            );
          })
          .finally(function () {
            vm.trigger.$busy[func] = false;
          });
      })
    };

    vm.trigger.$busy = {};
  }
}());
