'use strict';

angular.module('app.controllers')
    .controller('TasksRotatePhotoCtrl',
        function ($scope, $state, $http, $notification, $q, flagTask, cdnify, exifStripper) {
        $scope.image = {
            src:     null,
            degrees: 0,
            rotate:  function (direction) {
                if (direction === 'right') {
                    this.degrees += 90;
                } else if (direction === 'left') {
                    this.degrees -= 90;
                } else {
                    this.degrees += parseInt(direction, 10);
                }

                if (Math.abs(this.degrees) === 360) {
                    this.degrees = 0;
                } else if (this.degrees < -180) {
                    this.degrees = 360 + this.degrees;
                } else if (this.degrees > 180) {
                    this.degrees = -360 + this.degrees;
                }
            }
        };
        function init() {
            $scope.image.src = null;
            $scope.image.degrees = 0;

            if(!$scope.init) {return;}

            $scope.init(1, {file_group: $state.params.file_group})
                .then(function (task) {
                    if (task) {
                        let bestPhoto = _.get(task, 'meta.best_photo');
                        let flow;
                        if (bestPhoto) {
                            flow = $q.resolve(bestPhoto);
                        } else {
                            flow = $http.get('/api/tasks/rotate-photo', {
                                params: {
                                    file_group: task.file_group,
                                    upc:        task.upc
                                }
                            }).then(response => response.data);
                        }

                        return flow.then(function (src) {
                            $scope.vm.debugInfo.push({
                                name: 'Current Image',
                                href: src,
                                target: '_blank'
                            });
                            // $scope.image.src = cdnify(src);

                          return exifStripper.strip(cdnify(src))
                            .then((response) => {
                              $scope.image.src = response.url;
                            });
                        });
                    }
                });
        }

        init();

        $scope.submit = function (flag) {
            $scope.submit.$busy = flag ? 'flagging' : 'submitting';
            let flow;

            if (flag) {
                flow = flagTask($scope.vm.currentTask, {}, flagTask.scenarios.INGREDIENT_STATEMENT)
                    .then(init);
            } else {
                let params = {
                    task_id: $scope.vm.currentTask.id,
                    degrees: $scope.image.degrees,
                };

                flow = $http.post('/api/tasks/rotate-photo/', params)
                    .then(() => $notification.info('Saved'))
                    .catch(() => $notification.error('Not saved'))
                    .finally(init);
            }

            flow.finally(() => {
                $scope.submit.$busy = null;
            });
        };
    }
);



