'use strict';

angular.module('app.services')
    .factory('endlessTotal', function endlessTotalFactory() {
        return function endlessTotal(params, length) {
            return (params.page() - 1) * params.count() +
                length +
                (length < params.count() ? 0 : params.count())
        }
    });
