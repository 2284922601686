(function () {
  'use strict';

  const moduleName     = 'admin-tools',
        controllerName = 'CategorizationDemoCtrl';

  const moduleFullName = `app.modules.${moduleName}`;

  angular.module(moduleFullName)
    .controller(`${moduleFullName}.${controllerName}`, CategorizationDemoCtrl);

  /*@ngInject*/
  function CategorizationDemoCtrl($log, $http, suggest, gs1, $notification, $timeout, $q, $filter) {
    $log.debug(`[${moduleName}] Controller "${controllerName}" launched`);

    const vm = this;

    vm.result = {
      tag_id: null,
      class_code: null,
      brick_code: null,
    }

    vm.suggest = suggest;
    vm.gs1     = gs1;

    vm.tagManager = {
      text:     '',
      gs1Class: '',
      gs1Brick: '',
      select:   async function (tag) {
        this.text                   = tag.name.split('>').pop().trim();
        vm.result.tag_id = tag.id;

        try {
          const fullTag = (await $http.get(`/api/tags/${tag.id}`)).data;

          if (fullTag.class_code) {
            vm.result.class_code = fullTag.class_code;

            this.gs1Class = _.get(_.find(gs1.classes, data => +data.class_code === +fullTag.class_code), 'class_description');
          }

          if (fullTag.brick_code) {
            vm.result.brick_code = fullTag.brick_code;

            this.gs1Brick = _.get(_.find(gs1.bricks, data => +data.brick_code === +fullTag.brick_code), 'brick_description');
          }

        } catch (e) {
          $notification.error('Could not load tag data: ' + $filter('readError')(e.message || e.data));
        }
      },

      setClass:        function ($item = null) {
        if ($item) {
          vm.result.class_code = $item.class_code;
          vm.result.brick_code = null;

          this.gs1Class = $item.class_description;
          this.gs1Brick = '';
        } else {
          vm.result.class_code = null;
          vm.result.brick_code = null;

          this.gs1Class = '';
          this.gs1Brick = ''
        }
      },
      // fixes wrong positioning of dropdown
      filterClass:     function ($viewValue) {
        const deferred = $q.defer();
        $timeout(() => {
          const results = $filter('filter')(gs1.classes, {class_description: $viewValue});
          if (!results.length) {
            results.push(gs1.classes[gs1.classes.length - 1]);
          }
          deferred.resolve(results);
        });

        return deferred.promise;
      },
      filterBrick:     function ($viewValue) {
        const deferred = $q.defer();
        $timeout(() => {
          deferred.resolve($filter('filter')(gs1.bricks, {
            brick_description: $viewValue,
            class_code:        vm.result.class_code || undefined
          }));
        });

        return deferred.promise;
      },
      setUnknownClass: function () {
        this.setClass(gs1.classes[gs1.classes.length - 1]);
      },
      setBrick:        function ($item = null) {
        if ($item) {
          vm.result.class_code = $item.class_code;
          vm.result.brick_code = $item.brick_code;

          this.gs1Class = $item.class_description;
          this.gs1Brick = $item.brick_description;
        } else {
          this.gs1Brick                   = '';
          vm.result.brick_code = null;
        }
      },
      clear:           function () {
        this.text     = '';
        this.gs1Class = '';
        this.gs1Brick = '';
      }
    };
  }
}());
