(function () {
  'use strict';

  const moduleName = 'bot';

  const moduleFullName = `app.modules.${moduleName}`;

  const state       = stateName => `app.${moduleName}` + (stateName ? `.${stateName}` : '');
  const templateUrl = viewName => `modules/${moduleName}/${moduleName}.${viewName}.html`;
  const controller  = controllerName => `${moduleFullName}.${controllerName} as vm`;

  angular.module(moduleFullName, [])
    .config(function ($stateProvider) {
      $stateProvider
        .state(state(), {
          url:      `/${moduleName}`,
          abstract: true,
          template: `<div class="module_${moduleName}" ui-view></div>`,
          data:     {
            // permissions: ['admin']
          }
        })
        .state(state('list-unmatched-utterances'), {
          url:         '/unmatched-utterances',
          controller:  controller('ListUnmatchedUtterancesCtrl'),
          templateUrl: templateUrl('list-unmatched-utterances')
        })
        .state(state('conversations'), {
          url:      `/conversations`,
          abstract: true,
          template: `<div ui-view></div>`
        })
        .state(state('conversations.list'), {
          url:         '',
          controller:  controller('ListConversationsCtrl'),
          templateUrl: templateUrl('list-conversations')
        })
        .state(state('conversations.view'), {
          url:         '/:id',
          controller:  controller('ViewConversationCtrl'),
          templateUrl: templateUrl('view-conversation')
        });

      angular.injector(['ng']).get('$log')
        .debug(`[${moduleName}] Module configured`);
    })
    .run(function ($log) {
      $log.debug(`[${moduleName}] Module launched`);
    });
}());


