'use strict';

const moduleName     = 'reports',
      controllerName = 'UpcTraceViewCtrl';

const moduleFullName = `app.modules.${moduleName}`;

angular.module(moduleFullName)
  .controller(`${moduleFullName}.${controllerName}`, UpcTraceViewCtrl);


/*@ngInject*/
function UpcTraceViewCtrl($scope, $http, $notification, ngTableParams, $location, $state, $filter, commenter) {
  const vm = $scope.vm = this;
  vm.upc = $state.params.upc;

  vm.taskTypes = {
    1: 'rotate',
    2: 'crop',
    3: 'transcribe',
    4: 'select best photo',
    5: 'conflict resolution',
    6: 'custom transcribe',
    7: 'custom conflict resolution'
  };

  vm.taskFileGroups = {
    1: 'FOP',
    2: 'Nutrition'
  };

  vm.tableParams = new ngTableParams(
    {
      page:    $location.search().page || 1,
      count:   50,
      sorting: (function () {
        let sort = $location.search().sort;
        if (sort) {
          let parts      = sort.split('.');
          sort           = {};
          sort[parts.slice(0, -1).join('.')] = parts[parts.length - 1];

          return sort;
        }

        return {'lastInteraction.timestamp': 'desc'};
      }())
    },
    {
      counts:  [],
      total:   0, // length of data
      getData: function ($defer, params) {
        $location.search('page', params.page() === 1 ? null : params.page());

        let data = vm.data;
        params.total(data.length);

        if (_.keys(params.sorting()).length) {
          let [sortingAttribute, sortingDirection] = _.pairs(params.sorting())[0];
          let sort                                 = `${sortingAttribute}.${sortingDirection}`;

          $location.search('sort', sort === 'lastInteraction.timestamp.desc' ? null : sort);

          data = _.sortByOrder(data, [sortingAttribute], [sortingDirection]);
        } else {
          $location.search('sort', null);
        }
        data = data.slice((params.page() - 1) * params.count(), params.page() * params.count() - 1);

        $defer.resolve(data);
      }
    }
  );

  vm.editNotes = function () {
    const dialog = commenter.open(vm.upcRecord.notes);
    dialog.result.then(function (notes) {
      vm.upcRecord.notes = notes;
    });

    dialog.submit('nutritionix-api.master_upc', 'upc', vm.upc);
  };

  vm.data = [];
  $http.get(`/reports/upc-trace/${vm.upc}`)
    .then(response => {
      vm.data = response.data;
      vm.tableParams.reload();
    })
    .catch(response => $notification.error('Failed to load report: ' + $filter('readError')(response.data)));

  $http.get(`/api/upc/${vm.upc}`)
    .then(response => {
      vm.upcRecord = response.data;
    })
    .catch(response => $notification.error('Failed to load upc record: ' + $filter('readError')(response.data)));
}
