(function () {
    'use strict';

    angular
        .module('app.filters')
        .filter('dataUrl', function ($timeout) {
            return function dataUrl(source) {
                if (angular.isArray(source)) {
                    source = source[0];
                }

                if (angular.isString(source) || !source || !source.type) {
                    return source;
                }

                if (source.type.indexOf('image') > -1 && !source.$dataUrl && source.$dataUrl !== '') {
                    source.$dataUrl = '';

                    let fileReader = new FileReader();
                    fileReader.readAsDataURL(source);
                    fileReader.onload = function (e) {
                        $timeout(function () {
                            source.$dataUrl = e.target.result;
                        });
                    };
                }

                return source.$dataUrl;
            };
        });

}());
