'use strict';

angular.module('chart.js')
    .directive('chartStackedBar', function (ChartJsFactory) { return new ChartJsFactory('StackedBar'); });
angular.module('app.controllers')
    .controller('DensityChartsCtrl',
    function ($scope, $http, $state, $filter) {
        var dataPromise = $http.get(
            'https://nix-export.s3.amazonaws.com/tagstats/' + $state.params.id + '.json.gz',
            {skipAuthorization: true} //for Auth0
        );

        dataPromise.catch(function (response) {
            console.error(response);
        });

        $scope.chart = {
            nutrient:          'protein',
            getAttribute:      function () {
                return 'ratio_' + this.nutrient;
            },
            nutrients:         {
                protein:  'Protein',
                calories: 'Calories',
                carb:     'Carbohydrate',
                sodium:   'Sodium',
                fat:      'Fat',
                sugars:    'Sugars'
            },
            reset:             function () {
                dataPromise.success(function (data) {
                    data.items = _.sortBy(data.items, $scope.chart.getAttribute());
                    $scope.data = data;


                    $scope.chart.range.define();
                    $scope.chart.populateChartData();
                });
            },
            populateChartData: function () {
                if($scope.data && $scope.data.items){
                    this.data = [[], []];

                    _($scope.data.items).pluck(this.getAttribute()).forEach(function (value) {
                        if (value >= $scope.chart.min / 1000 && value <= $scope.chart.max / 1000) {
                            $scope.chart.data[0].push(value);
                            $scope.chart.data[1].push(0);
                        } else {
                            $scope.chart.data[1].push(value);
                            $scope.chart.data[0].push(0);
                        }
                    }).value();
                    this.labels = _.map(this.data[0], function () {return ''; });
                }
            },
            labels:            [],
            data:              [],
            getLegend:         function (prefix, suffix) {
                return (prefix || '') + this.nutrients[this.nutrient] + (suffix || '');
            },
            range:             {
                min:    0,
                max:    0,
                define: function () {
                    var values;
                    if ($scope.data && $scope.data.items) {
                        values = _($scope.data.items).pluck($scope.chart.getAttribute()).sort().value();

                        $scope.chart.range.min = $scope.chart.min = values[0] * 1000;
                        $scope.chart.range.max = $scope.chart.max = values[values.length - 1] * 1000;
                    }
                }
            },
            min:               0,
            max:               0

        };
        $scope.chart.reset();

        $scope.$watch(function () {
            return $scope.chart.min.toString() + '.' + $scope.chart.max.toString();
        }, function () {
            $scope.chart.populateChartData();
        });

        $scope.tooltip = function (value) {
            return $filter('number')(value.value);
        };

        $scope.getItems = function () {
            var attribute = $scope.chart.getAttribute();
            if ($scope.data && $scope.data.items) {
                return _.filter($scope.data.items, function (item) {
                    return item[attribute] >= $scope.chart.min / 1000 && item[attribute] <= $scope.chart.max / 1000;
                });
            }

            return [];
        };
    }
);
