'use strict';

const moduleName     = 'reports',
      controllerName = 'TopGrocery30DaysCtrl';

const moduleFullName = `app.modules.${moduleName}`;

angular.module(moduleFullName)
  .controller(`${moduleFullName}.${controllerName}`, TopGrocery30DaysCtrl);

/*@ngInject*/
function TopGrocery30DaysCtrl($scope, $state, $http, $notification,
                              ngTableParams, $filter, $location, URLON) {
  const vm = $scope.vm = this;

  let initialFilter = $location.search().q ? URLON.parse($location.search().q) : {};
  let initialPage   = +$location.search().page || 1;

  let init = true;

  vm.tableParams = new ngTableParams(
    {
      page:    initialPage,
      count:   50,           // count per page
      sorting: {'i.scans_30day': 'desc'},
      filter:  initialFilter
    },
    {
      counts:      [],
      total:       0, // length of data
      getData:     function ($defer, params) {
        // fix filtering bug
        if (_.keys(initialFilter).length > 0 && init) {
          init = false;
          params.page(initialPage);
          params.reload();
          return;
        }
        init = false;

        $location.search('page', params.page() > 1 ? params.page() : null);

        let filter = _.pick(params.filter(), v => v);
        let qRaw   = _.keys(filter).length > 0 ? URLON.stringify(_.pick(vm.tableParams.filter(), v => v)) : null;
        $location.search('q', qRaw);

        const requestParams = {
          limit:   params.count(),
          offset:  (params.page() - 1) * params.count(),
          orderBy: _.pairs(params.sorting())[0].join('.'),
          filter:  params.filter()
        };


        $http.get('/reports/top-grocery-30-days', {params: requestParams})
          .then(response => {
              const results = response.data;
              params.total(
                (params.page() - 1) * params.count() +
                results.length +
                (results.length < params.count() ? 0 : params.count())
              );

              $defer.resolve(results);
            }
          );
      }
    });
}
