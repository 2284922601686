'use strict';

angular.module('app.controllers')
  .controller('TagsEditCtrl',
    function ($scope, $state, Tag, ngTableParams, debounce, $filter,
              $notification, $http, generateThumb, $upload, navigationGuard, confirm,
              locales, $location, nixTrackApiClient, $modal, InstantSmartSearch,
              suggest, User, recordEditHistory, taxonomy, gs1) {
      let vm = $scope.vm = this;

      vm.taxonomy = taxonomy;

      const tagId = $state.params.tag_id;

      vm.locales = locales;

      vm.suggestUsda = search => {
        const isExactUsdaNdb = search.trim().match(/\d+/)
        if (!isExactUsdaNdb) {
          return suggest.common_foods(search);
        }

        return [];
      };

      vm.loadUsda = food => {
        nixTrackApiClient.natural.nutrients(food)
          .then(response => {
            $scope.tag.usda_ndb = response.data.foods[0].ndb_no;
          })
          .catch(() => {
            $notification.error("Could not load USDA #");
            $scope.tag.usda_ndb = '';
          });
      };

      vm.getIsValidUsda = () => {
        return !$scope.tag || !$scope.tag.usda_ndb || $scope.tag.usda_ndb.toString().trim().match(/\d+/);
      };

      let locale = $location.search().locale;
      if (locale) {
        vm.phraseLocale = _.find(locales, {language_culture_name: locale});
      }

      if (!vm.phraseLocale) {
        vm.phraseLocale = _.find(locales, {language_culture_name: 'en-US'});
      }

      vm.setPhraseLocale = (locale) => {
        $scope.error = null;
        $location.search('locale', locale.language_culture_name === 'en-US' ? null : locale.language_culture_name);
        vm.loadPhrases();
      };

      vm.loadPhrases = async () => {
        try {
          vm.phrases = [];
          vm.phrases = (await $http.get(
              `/api/tags/${tagId}/phrases`,
              {params: {locale: vm.phraseLocale.language_culture_name}})
          ).data;
        } catch (e) {
          $notification.error('Phrases could not be loaded: '.JSON.stringify(error.data))
        }
      };
      vm.loadPhrases();

      vm.loadPlus = () => {
        vm.plus = [];
        $http.get(`/api/tags/${tagId}/plu`)
          .then(response => vm.plus = response.data)
          .catch(response => {
            $notification.error('PLU could not be loaded: ' + $filter('readError')(response.data));
          });
      };
      vm.loadPlus();

      vm.deletePlu = async function (plu) {
        try {
          await confirm(`Are you sure you want to delete PLU ${plu.plu}`);
          await $http.delete(`/api/tags/${tagId}/plu/${plu.id}`);
          $notification.info(`PLU ${plu.plu} was deleted`);
          _.pull(vm.plus, plu);
        } catch (e) {
          if (!confirm.isCancelled(e)) {
            $notification.error('PLU was not deleted: ' + $filter('readError')(e.data));
          }
        }
      };

      vm.addPlu       = async function () {
        try {
          vm.plus.push((await $http.post(`/api/tags/${tagId}/plu`, vm.addPlu.newPlu)).data);
          vm.addPlu.reset();
        } catch (e) {
          $notification.error('PLU was not added: ' + $filter('readError')(e.data));
        }
      };
      vm.addPlu.reset = () => {
        vm.addPlu.newPlu = {plu: null, retailer_name: ''};
      };

      vm.addPlu.suggestRetailers = async function (searchTerm = '') {
        let retailers;
        try {
          retailers = (await $http.get('/api/tags/plu/retailers', {params: {searchTerm}})).data;
        } catch (e) {
          retailers = [];
        }

        if (!_.find(retailers, {retailer_id: 'default'}) && (!searchTerm || 'Default'.indexOf(searchTerm) > -1)) {
          retailers.push({
            retailer_id:   'default',
            retailer_name: 'Default'
          });
        }

        return retailers;
      };

      vm.addPlu.reset();

      navigationGuard.watch(
        () => !vm.photo.file,
        'You haven\'t saved the image. Are you sure you want to leave the page? Press "Save" button to finish upload'
      );

      navigationGuard.watch(
        () => $scope.tagForm.$pristine,
        'You have unsaved changes. Are you sure you want to leave the page?'
      );

      vm.foodGroups = [
        {id: 1, label: 'Dairy'},
        {id: 2, label: 'Protein'},
        {id: 3, label: 'Fruit'},
        {id: 4, label: 'Vegetable'},
        {id: 5, label: 'Grain'},
        {id: 6, label: 'Fat'},
        {id: 7, label: 'Legume'},
        {id: 8, label: 'Combination'}
      ];

      vm.foodGroups = _.sortBy(vm.foodGroups, 'label');
      vm.foodGroups.unshift({id: 9, label: '*Not Applicable*'});

      vm.photo = {
        thumbnail:         null,
        file:              null,
        url:               null,
        generateThumbnail: function () {
          if (this.file) {
            generateThumb(this.file[0])
              .then(thumbnail => {this.thumbnail = thumbnail});
          }
        },
        clearUpload:       function () {
          this.file      = null;
          this.thumbnail = null;
        },
        clearExisting:     function () {
          confirm('Are you sure you want tot remove this tag\'s photo?')
            .then(() => {
              $scope.tag.image       = null;
              $scope.tag.admin_image = null;

              return Tag.save(_.pick($scope.tag, ['id', 'image', 'admin_image'])).$promise;
            })
            .then(() => {
              $notification.info('Tag image removed');
            })
        },
        crop:              function () {
          const modalInstance = $modal.open({
            size:        'tag-image-crop',
            templateUrl: '/views/tags/tag-image-crop-modal.html',
            controller:  function ($scope, $modalInstance, photo) {
              const vm = $scope.vm = this;

              vm.photo = photo;

              // wait while cropper will be set in data object
              $scope.$watch(
                () => angular.element('#crop-tag-image-canvas').data('crop.angular-img-cropper'),
                (cropper) => vm.cropper = cropper
              );

              // watch cropper srcImage (and its change) to reset bounds.
              $scope.$watch(
                () => vm.cropper && vm.cropper.srcImage,
                function (initialized) {
                  let bounds, cropper;
                  if (initialized) {
                    cropper = vm.cropper;
                    bounds  = angular.copy(cropper.getBounds());

                    bounds.left   = cropper.minXClamp;
                    bounds.bottom = cropper.minYClamp;
                    bounds.right  = cropper.maxXClamp;
                    bounds.top    = cropper.maxYClamp;

                    cropper.setBounds(bounds);
                    cropper.draw(cropper.ctx);
                  }
                }
              );


              vm.submit = function () {
                const cropped = vm.cropper.getCroppedImage();

                vm.cropper.cropCanvas.toBlob(
                  blob => {
                    photo.file      = blob;
                    photo.thumbnail = cropped.src;
                    photo.cropped   = null;
                    photo.bounds    = {};

                    $modalInstance.close();
                  },
                  'image/jpeg'
                );
              };
              vm.close  = function () {
                $modalInstance.dismiss('cancel');
              };
            },
            resolve:     {photo: () => vm.photo}
          });
        },
        loadByUrl:         function () {
          if (!this.url) {
            return;
          }

          this.clearUpload();

          $http.get('/cors-proxy?url=' + this.url, {
            responseType:      'blob',
            transformResponse: function (data, headers) {
              return new Blob([data], {type: headers('content-type')});
            }
          })
            .then(response => {
              this.file = [response.data];
              this.generateThumbnail();
            })
            .catch(response => $notification.error('Image could not been loaded'))
            .finally(() => this.url = null)
        }
      };

      vm.refreshPhoto = () => {
        vm.refreshPhoto.tag = Date.now();
      };

      vm.refreshPhoto();


      let init = function () {
        return Tag.get({id: $state.params.tag_id}).$promise
          .then(function (tag) {
            vm.tag = $scope.tag = tag;

            vm.showUsdaNdbRawLink = tag.usda_ndb_raw > 0;
            $scope.tag.$noRawUSDA = tag.usda_ndb_raw === -1;

            if (tag.meta) {
              vm.brandedFoodSearchTerm = `${tag.meta.nix_brand_name} ${tag.meta.nix_item_name}`;
            }

            if (tag.taxonomy_node_id) {
              vm.taxonomyName = _.get(_.find(taxonomy, {node_id: tag.taxonomy_node_id}), 'full_tree_text');
            }

            return tag && tag.parent_id && Tag.get({id: tag.parent_id}).$promise;
          })
          .then(function (parentTag) {
            vm.parent = parentTag;
          });
      };

      const initialisedPromise = init();


      $scope.addPhrase = function () {
        $scope.error = null;

        Tag.phrases.save({
          text:       $scope.addPhrase.text,
          tag_id:     $scope.tag.id,
          locale:     vm.phraseLocale.language_culture_name,
          country:    vm.phraseLocale.country_alpha_2,
          is_default: vm.phrases.length ? 0 : 1
        }).$promise
          .then(function (phrase) {
            if ($filter('filter')(vm.phrases, {id: phrase.id}).length === 0) {
              vm.phrases.push(phrase);
            }
            $scope.addPhrase.text = null;
            $scope.error          = null;

            vm.tag.updated_at = new Date();
            vm.tag.updated_by = $scope.auth.profile.id;
          })
          .catch(function (error) {
            error.data = error.data.errors && error.data.errors[0] || error.data;
            if (error.data && ['duplicate-assignment', 'hidden-phrase'].indexOf(error.data.type) > -1) {
              $scope.error = error.data;
            } else {
              $scope.error = {
                type:    'generic',
                message: error.data && $.type(error.data) === 'string' ?
                           error.data :
                           'Unexpected backend error'
              };
            }
          });
      };

      $scope.detachPhrase = function (phrase) {
        Tag.phrases.save({
          id:      phrase.id,
          tag_id:  null,
          locale:  vm.phraseLocale.language_culture_name,
          country: vm.phraseLocale.country_alpha_2,
        }).$promise
          .then(function () {
            let index = vm.phrases.indexOf(phrase);
            if (index !== -1) {
              vm.phrases.splice(index, 1);
            }

            $scope.error = null;
          })
          .catch(function (error) {
            $scope.error = {
              type:    'generic',
              message: $filter('readError')(error.data) || 'Unexpected backend error'
            };
          });
      };

      $scope.markPhraseTypo = function (phrase) {
        $http.put(`/api/phrases/${vm.phraseLocale.country_alpha_2}/${phrase.id}/mark-typo`, {is_typo: phrase.is_typo})
          .then(function () {
            let action = phrase.is_typo ? 'marked' : 'unmarked';
            $notification.info(`Phrase ${action} as typo`);
          })
          .catch(function (/* error */) {
            $notification.error('Phrase typo status update failed');
          });
      };

      vm.toggleDefaultPhrase = phrase => {
        if (!phrase.is_default) {
          vm.phrases.forEach(p => p.is_default = false);
          phrase.is_default = true;
        } else {
          phrase.is_default = false;
        }

        $http.put(`/api/phrases/${vm.phraseLocale.country_alpha_2}/${phrase.id}/set-default`, {is_default: phrase.is_default})
          .then(() => {
            $notification.success(`Phrase was ${phrase.is_default ? 'set' : 'unset'} as default`);
          })
          .catch(response => {
            $notification.error($filter('readError')(response.data));
          });
      };

      $scope.save = function () {
        $scope.save.$busy = true;
        $scope.save.error = false;

        if ($scope.tag.$noRawUSDA) {
          $scope.tag.usda_ndb_raw = -1;
        }

        let flow;

        if (vm.photo.file) {
          flow = $upload
            .upload({
              url:              `/api/tags/${$scope.tag.id}`,
              file:             vm.photo.file,
              fileFormDataName: ['photo'],
              fields:           $scope.tag
            })
            .then(response => response.data)
        } else {
          flow = Tag.save($scope.tag).$promise;
        }

        flow.then(function (updatedTag) {
          _.extend($scope.tag, updatedTag);

          $scope.tagForm.$setPristine(true);
          _.forEach($scope.tagForm.$error, function (inputs, validation) {
            _.forEach(inputs, function (input) {
              input.$setValidity(validation, true);
            });
          });

          vm.showUsdaNdbRawLink = updatedTag.usda_ndb_raw > 0;
          $scope.tag.$noRawUSDA = updatedTag.usda_ndb_raw === -1;

          vm.refreshPhoto();
          vm.photo.clearUpload();
        })
          .catch(function (response) {
            var errors = response.data && response.data.errors ||
              [{
                type:    'generic',
                message: 'Unexpected backend error'
              }];

            $scope.save.error = {
              message: _.pluck(errors, 'message').join('; ')
            };
          })
          .finally(function () {
            $scope.save.$busy = false;
          });

      };

      vm.delete = () => {
        confirm(`Are you sure you want to delete tag "${vm.tag.name}"?`)
          .then(() => {
            $http.delete(`/api/tags/manage/${tagId}`)
              .then(() => {
                $notification.info(`Tag "${vm.tag.name}" was deleted`);
                $state.go('app.tags.list');
              })
              .catch(response => $notification.error(
                'Tag could not been deleted: ' +
                $filter('readError')(response.data))
              );
          });

      };

      vm.undelete = () => {
        vm.tag.deleted = 0;
        $scope.save();
      };

      vm.resetPhrase = phrase => {
        $http.post(`/reports/reset-phrases/${phrase.id}`)
          .success(() => {$notification.success('Phrase was reset');})
          .error(error => {$notification.error(`Phrase reset failed: ${error.errors[0].message}`);});
      };

      vm.instantSmartSearch = new InstantSmartSearch(($item) => {
        $scope.tag.nix_item_id  = $item.nix_item_id;
        $scope.tag.nix_brand_id = $item.nix_brand_id;
        $scope.tag.meta         = {
          nix_item_name:  $item.food_name,
          nix_brand_name: $item.brand_name
        };
      });

      vm.clearBrandedFood = () => {
        $scope.tag.nix_item_id  = null;
        $scope.tag.nix_brand_id = null;
        $scope.tag.meta         = {
          nix_item_name:  null,
          nix_brand_name: null
        };
      };

      vm.searchTagPhotos = () => {
        const modalInstance = $modal.open({
          size:        'md',
          templateUrl: '/views/tags/tag-image-search-modal.html',
          controller:  function ($scope, $modalInstance) {
            const vm = $scope.vm = this;

            vm.instantSmartSearch               = new InstantSmartSearch($item => {
              const photo = {};

              $http.get(`/api/tags/${$item.tags.tag_id}`)
                .then(response => response.data)
                .then(tag => {
                  photo.image_license_type               = tag.image_license_type;
                  photo.image_attribution_url            = tag.image_attribution_url;
                  photo.image_creative_commons_user_name = tag.image_creative_commons_user_name;
                  photo.image_upc                        = tag.image_upc;

                  return $http.get(tag.image_highres, {
                    responseType:      'blob',
                    transformResponse: function (data, headers) {
                      return new Blob([data], {type: headers('content-type')});
                    }
                  })
                })
                .then(response => {
                  photo.file = response.data;

                  $modalInstance.close(photo);
                });
            });
            vm.instantSmartSearch.sources       = {
              self:    false,
              common:  true,
              branded: false
            };
            vm.instantSmartSearch.limits.common = 10;

            vm.close = function () {
              $modalInstance.dismiss('cancel');
            };
          }
        });

        modalInstance.result.then(function (photo) {
          vm.photo.file = [photo.file];
          vm.photo.generateThumbnail();

          _.extend(vm.tag, _.omit(photo, 'file'));
        });
      };


      vm.copyTag = function () {
        $modal.open({
          size:        'md',
          templateUrl: '/views/tags/copy.html',
          controller:  function ($scope, $modalInstance, tag, parent, phrases, source) {
            const vm = $scope.vm = this;

            vm.source  = source;
            vm.tag     = tag;
            vm.parent  = parent;
            vm.phrases = phrases;

            vm.close = function () {
              $modalInstance.dismiss('cancel');
            };

            vm.save = async function () {
              vm.save.$busy = true;

              vm.tag.transferPhrases = vm.phrases
                .filter(phrase => phrase.$transfer)
                .map(phrase => phrase.id);

              const name = vm.tag.name.replace(/[.,!&;?"*'_]/g, '');

              if (!_.find(vm.phrases, {text: name, $transfer: true})) {
                const phrase = (await $http.get('/api/phrases', {params: {q: name}})).data;

                if (phrase && phrase.tag_name !== vm.tag.name) {
                  try {
                    await confirm(
                      `Please confirm, by creating this tag,
                      you will move the phrase "${phrase.text}" from the tag named "${phrase.tag_name}"
                      to this newly created tag.
                      Press OK to confirm, or cancel if this is not the intended result`
                    );
                  } catch (e) {
                    vm.save.$busy = false;
                    return;
                  }
                }
              }

              $http.post('/api/tags', vm.tag)
                .success(function (createdTag) {
                  $notification.info('Food Tag Created');
                  $state.go('app.tags.edit', {tag_id: createdTag.id});
                  $modalInstance.close(createdTag);
                })
                .error(function (error) {
                  $notification.error('Tag Not Created: ' + $filter('readError')(error));
                })
                .finally(() => {
                  vm.save.$busy = false;
                });
            };
          },
          resolve:     {
            source:  () => $scope.tag,
            tag:     () => {
              let copy  = _.pick(
                $scope.tag,
                [
                  'usda_ndb', 'usda_ndb_raw', 'usda_seq', 'usda_seq_raw',
                  'parent_id', 'food_group', 'item_type',
                  'image', 'admin_image', 'image_highres', 'image_original_stats',
                  'image_license_type', 'image_attribution_url',
                  'image_creative_commons_user_name', 'image_upc',
                  'meta', 'nix_item_id', 'nix_brand_id'
                ]
              );
              copy.name = '';
              return copy;
            },
            parent:  () => vm.parent,
            phrases: () => $http.get(`/api/tags/${tagId}/phrases`, {params: {locale: 'en-US'}})
              .then(response => _.sortBy(response.data, 'text'))
              .catch(error => $notification.error('Phrases could not be loaded: '.JSON.stringify(error)))
          }
        })
      };

      vm.movePhrases = function () {
        $modal.open({
          size:        'md',
          templateUrl: '/views/tags/move-phrases.html',
          controller:  function ($scope, $modalInstance, phrases) {
            const vm = $scope.vm = this;

            vm.phrases = phrases;

            vm.suggestTags       = suggest.tags;
            vm.setDestinationTag = function (tag) {
              vm.destinationTag = tag;
            };

            vm.close = function () {
              $modalInstance.dismiss('cancel');
            };

            vm.save = function () {
              vm.save.$busy = true;

              let transferPhrases = vm.phrases
                .filter(phrase => phrase.$transfer)
                .map(phrase => phrase.id);

              $http.post(`/api/tags/${vm.destinationTag.id}/phrases/move`, {phrases: transferPhrases})
                .success(function (affectedRows) {
                  $notification.info(`Moved ${affectedRows} phrases to tag ${vm.destinationTag.name}`);
                  $state.go('app.tags.edit', {tag_id: vm.destinationTag.id});
                  $modalInstance.close();
                })
                .error(function (error) {
                  $notification.error('Phrases not moved: ' + $filter('readError')(error));
                })
                .finally(() => {
                  vm.save.$busy = false;
                });
            };
          },
          resolve:     {
            phrases: () => $http.get(`/api/tags/${tagId}/phrases`, {params: {locale: 'en-US'}})
              .then(response => _.sortBy(response.data, 'text'))
              .catch(error => $notification.error('Phrases could not be loaded: '.JSON.stringify(error)))
          }
        })
      };

      vm.changeParent = function () {
        $modal.open({
          size:        'md',
          templateUrl: '/views/tags/change-parent.html',
          controller:  function ($scope, $modalInstance, parentVm) {
            const vm = $scope.vm = this;

            vm.suggestTags = function (search) {
              return suggest.tags(search)
                .then(tags => _.sortBy(
                  tags.filter(tag => tag.id !== parentVm.tag.id),
                  t => t.name.length
                )
                  .slice(0, 10));
            };

            vm.setParentTag = function (parent) {
              parentVm.parent        = parent;
              parentVm.tag.parent_id = parent.id;

              $modalInstance.close();
            };

            vm.close = function () {
              $modalInstance.dismiss('cancel');
            };
          },
          resolve:     {
            parentVm: () => vm
          }
        })
      };

      vm.showEditHistory = () => {
        recordEditHistory('nutritionix-api.tags', vm.tag.id);
      };

      vm.setClass = ($item = null) => {
        if ($item) {
          vm.tag.class_code = $item.class_code;
          vm.tag.brick_code = null;

          vm.gs1_class_description = $item.class_description;
          vm.gs1_brick_description = ''
        } else {
          vm.tag.class_code = null;
          vm.tag.brick_code = null;

          vm.gs1_class_description = '';
          vm.gs1_brick_description = '';
        }
      };

      vm.setUnknownClass = () => {
        vm.setClass(vm.setUnknownClass.value);
      };

      vm.setUnknownClass.value = _.find(gs1.classes, {class_code: -1});

      vm.setBrick = ($item = null) => {
        if ($item) {
          vm.tag.brick_code = $item.brick_code;
          vm.tag.class_code = $item.class_code;

          vm.gs1_brick_description = $item.brick_description;
          vm.gs1_class_description = $item.class_description;
        } else {
          vm.gs1_brick_description = '';
          vm.tag.brick_code        = null;
        }
      };

      vm.setTaxonomy = ($item = null) => {
        vm.taxonomyName         = $item ? $item.full_tree_text : '';
        vm.tag.taxonomy_node_id = $item ? $item.node_id : null;
      }

      User.query(function (users) {
        vm.users = {
          list: users
        };

        _.forEach(users, function (user) {
          vm.users[user.id] = user;
        });
      });

      vm.gs1         = gs1.bricks;
      vm.gs1_classes = gs1.classes;

      initialisedPromise.then(() => {
        if (vm.tag.class_code) {
          vm.gs1_class_description = _.get(_.find(vm.gs1_classes, data => +data.class_code === +vm.tag.class_code), 'class_description');
        }

        if (vm.tag.brick_code) {
          vm.gs1_brick_description = _.get(_.find(vm.gs1, data => +data.brick_code === +vm.tag.brick_code), 'brick_description');
        }
      });
    }
  );
