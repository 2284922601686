'use strict';

angular.module('app.services')
    .factory('Report', [
        '$resource',
        function ($resource) {
            return $resource('/api/reports/:id', {
                id: '@id'
            }, {
                get: {
                    isArray: true
                }
            });
        }
    ]);
