'use strict';

angular.module('app.services')
    .factory('ReportPhrase', [
        '$resource','$http',
        function ($resource, $http) {
            var ReportPhrase = $resource('/reports/phrases/:id', {id: '@id'});

            ReportPhrase.list = function (params) {
                return $http.get('/reports/phrases', {params: params})
                    .then(function (reponse) {
                        return reponse.data;
                    });
            };

            return ReportPhrase
        }
    ]);
