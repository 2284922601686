(function () {
  'use strict';

  const moduleName     = 'brand-recipes',
        controllerName = 'BrandCtrl';

  const moduleFullName = `app.modules.${moduleName}`;

  angular.module(moduleFullName)
    .controller(`${moduleFullName}.${controllerName}`, BrandCtrl);

  /*@ngInject*/
  function BrandCtrl($log, suggest, $state) {
    $log.debug(`[${moduleName}] Controller "${controllerName}" launched`);

    const vm = this;

    vm.suggestBrands = suggest.legacy_brands;

    vm.setBrand = brand => {
      $state.go('app.brand-recipes.brand.recipe.list', {brand: brand.webname});
    };
  }
}());
