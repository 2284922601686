'use strict';

angular.module('app.controllers')
    .controller('ReportTagsWithoutNdbNumberCtrl',
        function ($scope, $http, $notification, commenter) {
            $scope.addNotes = function (tag) {
                var dialog = commenter.open(tag.notes);
                dialog.result.then(function (notes) {
                    tag.notes = notes;
                });

                dialog.submit('nutritionix-api.tags', 'id', tag.id);
            };

            $http.get('/reports/tags-without-ndb-number')
                .success(function (tags) {
                    $scope.tags = tags;
                })
                .error(function () {
                    $notification.error('Report failed to load');
                });
        }
    );
