'use strict';

angular.module('app.services')
    .factory('S3', ['$resource', function ($resource) {
        var S3 = $resource('/api/s3');

        S3.sign = function(){
            return $resource('/api/s3/sign', null, {
                query: {
                    isArray: false
                }
            });
        };

        S3.deleteObject = function(){
            return $resource('/api/s3/delete', null, {
                del: {
                    method: 'DELETE',
                    isArray: false
                }
            });
        };

        return S3;
    }]);