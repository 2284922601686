'use strict';

const moduleName     = 'reports',
      controllerName = 'UpcTraceCtrl';

const moduleFullName = `app.modules.${moduleName}`;

angular.module(moduleFullName)
  .controller(`${moduleFullName}.${controllerName}`, UpcTraceCtrl);


/*@ngInject*/
function UpcTraceCtrl($scope, $state) {
  const vm = $scope.vm = this;
  vm.upc = $state.params.upc;

  vm.go = () => {
    if (vm.upc) {
      $state.go('app.reports.upc-trace.view', {upc: vm.upc});
    }
  }
}
