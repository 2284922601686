'use strict';

const moduleName     = 'tasks',
      controllerName = 'ManualReviewReportCtrl';

const moduleFullName = `app.modules.${moduleName}`;

angular.module(moduleFullName)
  .controller(`${moduleFullName}.${controllerName}`, ManualReviewReportCtrl);


/*@ngInject*/
function ManualReviewReportCtrl($scope, $http, $notification, ngTableParams,
                                $location, debounce, $q, $state, $localStorage) {
  const vm = $scope.vm = this;

  vm.results = [];

  // concurrency prevention
  let getDataPromise = $q.resolve();

  vm.openTask = (task) => {
    $localStorage.return = $location.url();

    $state.go(
      'app.tasks.perform.conflict-resolution',
      {
        file_group: task.file_group,
        region: task.region,
        taskId: task.id
      }
    )
  }

  vm.tableParams = new ngTableParams(
    {
      page: $location.search().page || 1,            // show first page
      count: 25,           // count per page
    },
    {
      counts: [],
      total: 0, // length of data
      getData: function ($defer, params) {
        $location.search('page', params.page() === 1 ? null : params.page());

        getDataPromise = getDataPromise.then(
          () => $http.get('/api/tasks/reports/manual-review',
            {
              params: {
                limit: params.count(),
                offset: (params.page() - 1) * params.count(),
              }
            }
          ).then(function (response) {
              const results = response.data;

              _.clone(vm.results).forEach(result => {
                if (!_.find(results, {id: result.id})) {
                  _.pull(vm.results, result);
                }
              });

              results.forEach(result => {
                if (!_.find(vm.results, {id: result.id})) {
                  vm.results.push(result);
                }
              });

              params.total(
                (params.page() - 1) * params.count() +
                vm.results.length +
                (vm.results.length < params.count() ? 0 : params.count())
              );
              $defer.resolve(vm.results);
            }
          ).catch(() => $notification.error('Failed to load the report'))
        );
      }
    }
  );
}
