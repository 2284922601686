(function () {
  'use strict';

  angular.module('app.controllers')
    .controller('S3AssetsCtrl', S3AssetsCtrl);

  /*@ngInject*/
  function S3AssetsCtrl($scope, $http, $state, confirm, $notification, $filter, cdnify, moment) {
    const vm = $scope.vm = this;

    vm.upc               = $scope.upc = $state.params.upc;
    vm.isRandomWeightUpc = vm.upc.length === 12 && vm.upc[0] === '2';
    if (vm.isRandomWeightUpc) {
      vm.sinPart = vm.upc.substring(0, 7);
    }

    $http.get(`/api/upc/${vm.upc}`)
      .then(response => {
        vm.masterUpc = response.data;
      });

    $http.get('/reports/s3_assets',
      {params: {upc: $state.params.upc}}
    ).success(function (data) {
        vm.s3Assets        = data.s3Assets;
        vm.latestAssetDate = _.get(_.sortByOrder(vm.s3Assets, 'created_at', 'desc'), '0.created_at');

        vm.showNoRecentAssetsWaring = !vm.latestAssetDate ||
          moment().diff(moment(vm.latestAssetDate), 'months') > 12

        vm.s3AssetsGroups       = _.chunk(vm.s3Assets, 4);
        vm.legacyS3Assets       = data.legacyS3Assets;
        vm.legacyS3AssetsGroups = _.chunk(vm.legacyS3Assets, 4);

        vm.s3Assets.forEach(a => a.url = cdnify(a.url));
        vm.legacyS3Assets.forEach(a => a.url = cdnify(a.url));
      }
    );

    vm.resetUpc = () => {
      confirm('Are you sure you want to restart this upc transcription lifecycle')
        .then(async () => {
          try {
            await $http.put(`/api/tasks/reset-upc/${$state.params.upc}`, {});
            $notification.info('UPC was reset');
          } catch (e) {
            $notification.error('UPC was not reset ' + $filter('readError')(e.data))
          }
        })
    };

    vm.toggleBadUpc = function () {
      let confirmMessage, successMessage, value;

      if (vm.masterUpc.bad_upc) {
        confirmMessage = "Are you sure you want to unhide this UPC?";
        successMessage = "Marked not bad UPC";
        value          = 0;
      } else {
        confirmMessage = "Are you sure you want to hide this UPC forever?";
        successMessage = "marked bad upc";
        value          = 1;
      }

      confirm(confirmMessage).then(async () => {
        try {
          await $http.post('/api/reports/masterUpc/' + vm.upc, {bad_upc: value})

          vm.masterUpc.bad_upc = value;
          $notification.info(successMessage);
        } catch (e) {
          $notification.error('failed to update');
        }
      });
    };

  }

}())

