'use strict'

angular.module('app.controllers')
  .controller('CSVURLCheckCtrl',
    ($scope, $stateParams, $upload, $http, $interval) => {
      let intervalPromise


      /**
       * Helpers
       */
      $scope.reset = () => {
        $scope.uploadFinished = false
        $scope.csvErrors = undefined
        $scope.sourceUploadProcessing = false
        $scope.taskFinished = false
        $scope.results = undefined
        // $scope.item_names_matched = {}
        // $scope.item_tags_matched = {}
        // $scope.validation_name_source = undefined
        // $scope.validation_tag_source = undefined
        // removeLastSession()
      }
      // End

      $scope.error = true;

      // $scope.validateForm = () => {
      //   $scope.saveState()
      //   if ($scope.repeated_db_item_names.length > 0) {
      //     alert('There are some CSV items matched to the same database item.') // TODO: Use a Bootstrap Modal instead of JS built-in alert
      //     return false
      //   }

      //   if ($scope.repeated_db_item_tags.length > 0) {
      //     alert('There are some CSV tags matched to the same database tag.') // TODO: Use a Bootstrap Modal instead of JS built-in alert
      //     return false
      //   }

      //   return true
      // }


      /**
       * CSV Upload
       */
      $scope.uploadCSV = (files) => {
        $scope.sourceUploadProcessing = true;
        $scope.reset();

        let sourceUploaded = (data, status, headers, config) => {
          $scope.s3_url = data.s3_url
          var profile_email = $scope.auth.profile? $scope.auth.profile.email:undefined;
          $scope.email = $scope.email? $scope.email:profile_email;
          $scope.rowsCount = data.csv_rows_count;

          $http({
            method: 'POST',
            url: 'https://qpe2z06rf0.execute-api.us-east-1.amazonaws.com/prod/csv',
            data: {
              'email_to':  $scope.email,
              'url': data.s3_url,
              'csv_rows_limit': data.csv_rows_count
            }
          }).then((response) => { // successCallback
            // this callback will be called asynchronously
            // when the response is available
            console.log('response: ', response.data);

            if (!response.data.errors) {
              $scope.eta_mins = response.data.eta_mins;
            } else {
              $scope.errors = response.data.errors;
            }
            $scope.uploadFinished = true;

            // intervalPromise = refreshLogEach(3000)
          }, (response) => { // errorCallback
            // called asynchronously if an error occurs
            // or server returns response with an error status.
            console.error(response);

            if (response.data.message && response.data.message.indexOf('timed out')) {
              $scope.eta_mins = $scope.rowsCount * 0.33 / 60;
              $scope.uploadFinished = true;
            }
          })
        }

        if (files && files.length) {
          $scope.sourceUploadProcessing = true;
          for (let i = 0; i < files.length; i++) {
            let file = files[i]

            $upload.upload({
              url: '/api/csv-url-check/upload/',
              fields: {
                'username': $scope.username
              },
              file: file
            })
              .success(sourceUploaded)
              .error((response, status, headers) => {
                if (response.csvErrors) {
                  $scope.csvErrors = response.csvErrors
                  $scope.sourceUploadProcessing = false
                }
              });
          }
        }
      };
      // END
    })
