(function () {
  'use strict';

  angular.module('app.services')
    .factory('Tag', tagFactory);

  /*@ngInject*/
  function tagFactory($http, $resource) {
    const url = '/api/tags';
    const Tag = $resource(url + '/:id/:action', {id: '@id'});

    Tag.query = async function (params) {
      const {data} = await $http.get(url, {params});
      return data;
    };

    Tag.phrases = $resource(url + '/phrases/:id/', {id: '@id'});

    return Tag;
  }
}());

