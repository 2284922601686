(function () {
  'use strict';

  const moduleName     = 'email-file-delivery',
        controllerName = 'RecipientsSaveCtrl';

  const moduleFullName = `app.modules.${moduleName}`;

  angular.module(moduleFullName)
    .controller(`${moduleFullName}.${controllerName}`, SaveCtrl);

  /*@ngInject*/
  function SaveCtrl($log, $state, $http, $notification, $q, $upload,
                    $filter, confirm, utils, $location, $sessionStorage, frequencies, distributions) {
    $log.debug(`[${moduleName}] Controller "${controllerName}" launched`);

    const vm  = this;
    const url = '/api/email-file-delivery/recipients' + ($state.params.id ? '/' + $state.params.id : '');

    vm.frequencies   = frequencies;
    vm.distributions = distributions;

    let flow;

    if ($state.params.id) {
      flow = $http.get(url)
        .then(response => response.data);
    } else {
      flow = $q.resolve({
        client_name:     '',
        emails:          [],
        distribution_id: null,
        frequency:       null,
      });
    }

    flow.then(model => {
      vm.model = model;
    });

    vm.save = () => {
      vm.$busy  = true;
      vm.$error = null;

      $http[vm.model.id ? 'put' : 'post'](url, vm.model)
        .then(response => {
          $notification.info('Recipient Saved');

          _.extend(vm.model, response.data);

          if (!$state.params.id) {
            $state.go(utils.state('recipients.edit'), {id: response.data.id});
          }
        })
        .catch(response => vm.$error = $filter('readError')(response.data))
        .finally(() => vm.$busy = false);
    };

    vm.delete = () => {
      vm.$busy  = true;
      vm.$error = null;

      confirm('Are you sure you want to delete this recipient?')
        .then(() => {
          return $http.delete(url);
        })
        .then(() => {
          $notification.info('Recipient Deleted');
          vm.goBack();
        })
        .catch(response => vm.$error = $filter('readError')(response.data))
        .finally(() => vm.$busy = false);
    };

    vm.goBack = ($event = null) => {
      if ($event) {
        $event.preventDefault();
      }

      if ($sessionStorage.efdRecipientsListState) {
        $location.url($sessionStorage.efdRecipientsListState);
      } else {
        $state.go(utils.state('recipients.list'));
      }
    };

    vm.sendTest = () => {
      vm.$error = null;
      $http.post(`/api/email-file-delivery/distributions/${vm.model.distribution_id}/send-test`, {})
        .then(() => $notification.info('Test email sent'))
        .catch(response => vm.$error = $filter('readError')(response.data));
    };

    vm.sendManual = () => {
      vm.$error = null;
      confirm('Do you want to send email to the recipient?').then(async function () {
        try {
          await $http.post(`/api/email-file-delivery/recipients/${vm.model.id}/send-manual`, {});
          $notification.info('Email sent');
        } catch (response) {
          vm.$error = $filter('readError')(response.data);
        }
      });
    };
  }
}());
