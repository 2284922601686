'use strict';

angular.module('app.directives')
    .directive('title', ['$rootScope', '$translate', function ($rootScope, $translate) {
        return {
            restrict: 'E',
            link:     function (scope, element) {
                var originalText = element.text();
                var titleLocked = false;

                $rootScope.$on('$stateChangeSuccess',
                    function (event, toState) {
                        $translate('pageTitles.' + toState.name).then(function (translation) {
                            if (toState.data && toState.data.overrideTitle) {
                                element.text(translation || originalText);
                            } else {
                                element.text(originalText + ' - ' + translation);
                            }

                            titleLocked = true;
                        }).catch(function () {
                            titleLocked = false;
                        });
                    }
                );

                $rootScope.$watch(
                    function () {
                        return angular.element('h1:visible').first().text();
                    },
                    function (title) {
                        if (!titleLocked) {
                            element.text(title.indexOf('{{') === -1 && title || originalText);
                        }
                    }
                );
            }
        };
    }]);
