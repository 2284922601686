(function () {
  'use strict';

  const moduleName     = 'auth',
        controllerName = 'LoginCtrl';

  const moduleFullName = `app.modules.${moduleName}`;

  angular.module(moduleFullName)
    .controller(`${moduleFullName}.${controllerName}`, LoginCtrl);

  /*@ngInject*/
  function LoginCtrl($rootScope, $log, $http, GoogleIdentity, session, $notification, $state) {
    $log.debug(`[${moduleName}] Controller "${controllerName}" launched`);

    GoogleIdentity.registerIdentityCallback(async (identity) => {
      try {
        const {data} = await $http.post('/login', {identity});

        const profile = data.user.profile;
        session.setProfile(profile);

        if (profile && profile.status === 'active') {
          await session.activate();
          $rootScope.loginRedirect();
        } else {
          $state.go('auth.pending');
        }
      } catch (e) {
        $notification.error(`Login Failed: ${e.message}`);
      }
    });
  }
}());
