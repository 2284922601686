'use strict';

angular.module('app.controllers')
  .controller('TopHiveFlaggedUPCsCtrl',
    function ($scope, $state, $http, ngTableParams, $notification,
              debounce, commenter, arrayRefill, endlessTotal, $modal,
              $location) {
      const vm = $scope.vm = this;

      let debouncedReload;

      $scope.search = {
        term:    '',
        filter:  {
          missing_nutrition_label: $state.params.missing_nutrition_label
        },
        perform: function () {
          $scope.tableParams.page(1);
          $scope.tableParams.reload();
        }
      };

      debouncedReload = debounce(function () {
        $scope.tableParams.reload();
      }, 1000);

      $scope.markMissingNutritionLabel = function (record, data) {
        record.missing_nutrition_label = 1;

        const promise = $http.post('/api/reports/masterUpc/' + record.upc, record, {params: {id: record.id}})
          .then(function () {
            $notification.info('marked missing nutrition label');
          })
          .catch(function () {
            $notification.error('failed to update');
          });

        vm.removeResult(record, promise);
      };

      $scope.markBadUpc = function (record, data) {
        if (confirm("Are you sure you want to hide this UPC forever?")) {
          record.bad_upc = 1;

          const promise = $http.post('/api/reports/masterUpc/' + record.upc, record, {params: {id: record.id}})
            .then(function () {
              $notification.info('marked bad upc');
            })
            .catch(function () {
              $notification.error('failed to update');
            });

          vm.removeResult(record, promise);
        }
      };

      $scope.addNotes = function (item) {
        const dialog = commenter.open(item.notes);
        dialog.result.then(function (notes) {
          item.notes = notes;
        });

        dialog.submit('nutritionix-api.master_upc', 'id', item.id);
      };

      $scope.flagUpcSpecialCase = function (upc) {
        $modal.open({
          animation:   true,
          templateUrl: 'views/report/top-hive-flagged-upcs/flag-special-case-modal.html',
          controller:  'FlagSpecialCaseController as vm',
          size:        'lg',
          resolve:     {
            upc: () => upc
          }
        }).result.then(() => vm.removeResult());
      };

      vm.data = {
        count:   0,
        results: [],
      };

      vm.removeResult = function (item, reloadAfter = Promise.resolve()) {
        _.pull(vm.data.results, item);
        reloadAfter.then(debouncedReload);
      };

      $scope.tableParams = new ngTableParams(
        {
          page:    +$location.search().page || 1,            // show first page
          count:   25,           // count per page
          sorting: (function () {
            let sort = $location.search().sort;
            if (sort) {
              sort = sort.split('.');

              let result      = {};
              result[sort[0]] = sort[1];

              return result;
            }

            return {scans: 'desc'};
          }())
        },
        {
          counts:  [],
          total:   0, // length of data
          getData: function ($defer, params) {
            $location.search('page', params.page() > 1 ? params.page() : null);
            if (_.keys(params.sorting()).length) {
              let [sortingAttribute, sortingDirection] = _.pairs(params.sorting())[0];
              let sort                                 = `${sortingAttribute}.${sortingDirection}`;

              $location.search('sort', sort === 'scans.desc' ? null : sort);
            } else {
              $location.search('sort', null);
            }

            $http.get('/reports/top-hive-flagged-upcs',
              {
                params: {
                  limit:   params.count(),
                  offset:  (params.page() - 1) * params.count(),
                  search:  $scope.search.term,
                  orderBy: _.pairs(params.sorting())[0].join('.'),
                  filter:  JSON.stringify($scope.search.filter)
                }
              }
            ).success(function (data) {
              vm.data.count = data.count;

              arrayRefill(vm.data.results, data.results);
              params.total(data.count);

              $defer.resolve(vm.data.results);
            });
          }
        }
      );
    }
  )
  .controller('FlagSpecialCaseController', function ($scope, $modalInstance, $http, upc, suggest, $notification) {
    const vm = this;

    vm.suggest = suggest;

    vm.upc = upc;

    vm.case = {
      reason:     '',
      brand_name: '',
      item_name:  '',
    };

    $scope.ok = async () => {
      try {
        await $http.put(
          `/reports/top-hive-flagged-upcs/set-missing-nutrition-label-special-case/${upc.upc}`,
          vm.case
        );

        $modalInstance.close();
      } catch (e) {
        $notification.error('Something went wrong');
      }
    };

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
    };
  });
