'use strict';

angular.module('app.services')
    .factory('spotCheck', [
        '$resource', '$http', '$q',
        function ($resource, $http, $q) {
            var spotCheck = $resource('/api/spot-checks/:id', {slug: '@slug', id: '@_id'});

            spotCheck.list = function (params) {
                return $http.get('/api/spot-checks', {params: params})
                    .then(function (reponse) {
                        return reponse.data;
                    });
            };
 
            return spotCheck;
        }
    ]);
