'use strict';

angular.module('app.controllers')
    .controller('DeleteConfirmationModalCtrl', [
        'item', '$scope', '$http', '$modalInstance', '$rootScope',

        function (item, $scope, $http, $modalInstance, $rootScope) {
            console.log('hey we are in a modal', $scope.items);

            $scope.delete = function () {
                $http.delete('/api/natural/natural/' + item.id)
                    .then(function (response) {
                        $rootScope.$broadcast('refresh parse');
                        $modalInstance.dismiss(response);
                    })
                    .catch(function (error) {
                        console.log(error)
                    })
            };

            $scope.cancel = function () {
                $modalInstance.dismiss('cancel');
            };


        }
    ]);
