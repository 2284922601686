(function () {
  'use strict';

  const moduleName = 'admin-tools',
    controllerName = 'ApiReviewInterfaceCtrl';

  const moduleFullName = `app.modules.${moduleName}`;

  angular.module(moduleFullName)
    .controller(`${moduleFullName}.${controllerName}`, ApiReviewInterfaceCtrl);

  /*@ngInject*/
  function ApiReviewInterfaceCtrl($scope, nixTrackApiClient) {
    const vm = $scope.vm = this;

    const SEARCH_TYPE = {
      DEFAULT: 'default',
      USDA: 'usda'
    }

    vm.searchText = '';
    vm.searchResults = [];
    vm.searchType = SEARCH_TYPE.DEFAULT;

    let sources = {
      self: false,
      common: true,
      branded: true
    };

    vm.clickOnRadioButton = () => {
      clearSearch();
    }

    vm.searchButtonClick = async () => {
      if (!vm.searchText) {
        clearSearch();
        return;
      }

      const { data } = await nixTrackApiClient('/search/instant', {
        params: { query: vm.searchText, ...sources, ...{ usda_search: vm.searchType === SEARCH_TYPE.USDA } },
        ignoreLoadingBar: true
      });
      vm.searchType === SEARCH_TYPE.DEFAULT ? categorizedResultsForDefault(data) : categorizedResultsForUsda(data);
      $scope.$apply();
    }


    const categorizedResultsForDefault = (data) => {
      vm.searchResults = [
        { label: 'COMMON', items: data.common },
        { label: 'BRANDED', items: data.branded }
      ]
    }

    const categorizedResultsForUsda = (data) => {
      const duplicateData = extractUniqueRecords(data.common);
      vm.searchResults = [
        { label: 'All results', items: data.common },
        { label: 'De-duplicated results', items: duplicateData }
      ]
    }

    const clearSearch = () => {
      vm.searchText = '';
      vm.searchResults = [];
    }

    const extractUniqueRecords = (results) => {
      const uniqueRecordsMap = new Map();

      results.forEach(result => {
        if (!uniqueRecordsMap.has(result.ndb_no)) {
          uniqueRecordsMap.set(result.ndb_no, result);
        }
      });
      return Array.from(uniqueRecordsMap.values());
    };
  }
}());
