(function () {
  'use strict';

  const moduleName     = 'bulk-export',
        controllerName = 'LinksCtrl';

  const moduleFullName = `app.modules.${moduleName}`;

  angular.module(moduleFullName)
    .controller(`${moduleFullName}.${controllerName}`, LinksCtrl);

  /*@ngInject*/
  function LinksCtrl($scope, $log, $http, $notification, moment) {
    (async () => {
      $log.debug(`[${moduleName}] Controller "${controllerName}" launched`);

      const vm = this;

      const loadLinks = async (forceRefresh = false) => {
        const links = (await $http.get('/api/bulk-export/links', {params: {refresh: forceRefresh ? 1 : undefined}})).data;

        for (const link of links) {
          try {
            const url     = new URL(link.link);
            const expires = url.searchParams.get('Expires');

            link.expires = moment.unix((expires));
          } catch (e) {}
        }

        vm.links = links;
      }

      await loadLinks();

      vm.focusTextarea = ($event) => {
        $event.target.select();
        document.execCommand('copy');

        $notification.info('Link has been copied to your clipboard');
      }

      vm.refresh = () => {
        loadLinks(true);
        $notification.info('Links have been refreshed');
      };
    })()
  }
}());
