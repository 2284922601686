(function () {
  'use strict';

  const moduleName = 'developers';

  const moduleFullName = `app.modules.${moduleName}`;

  const state       = stateName => `app.${moduleName}` + (stateName ? `.${stateName}` : '');
  const templateUrl = viewName => {
    let parts = viewName.split('/');
    viewName  = parts.pop();
    let path  = parts.join('/');

    return `modules/${moduleName}/${path}/${moduleName}.${viewName}.html`.replace(/\/+/g, '/');
  };
  const controller  = controllerName => `${moduleFullName}.${controllerName} as vm`;

  angular.module(moduleFullName, [])
    .config(function ($stateProvider) {
      $stateProvider
        .state(state(), {
          url:      `/${moduleName}`,
          abstract: true,
          template: `<div class="module_${moduleName}" ui-view></div>`,
          data:     {
            permissions: ['manage-developers']
          }
        })
        .state(state('list'), {
          url:         '',
          controller:  controller('ListCtrl'),
          templateUrl: templateUrl('list')
        })
        .state(state('edit'), {
          url:         '/:id',
          controller:  controller('EditCtrl'),
          templateUrl: templateUrl('edit'),
          resolve:     {
            developer:    ($http, $stateParams) => $http.get(`/api/developers/${$stateParams.id}`)
              .then(response => response.data),
            applications: ($http, $stateParams) => $http.get(`/api/developers/${$stateParams.id}/apps`)
              .then(response => response.data),
          }
        });

      angular.injector(['ng']).get('$log')
        .debug(`[${moduleName}] Module configured`);
    })
    .run(function ($log) {
      $log.debug(`[${moduleName}] Module launched`);
    });
}());


