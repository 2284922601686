'use strict';

const moduleName     = 'reports',
      controllerName = 'TagsWithoutValidImageCtrl';

const moduleFullName = `app.modules.${moduleName}`;

angular.module(moduleFullName)
  .controller(`${moduleFullName}.${controllerName}`, TagsWithoutValidImageCtrl);


/*@ngInject*/
function TagsWithoutValidImageCtrl($scope, $http, $notification, commenter, ngTableParams, $location, debounce, $q) {
  const vm = $scope.vm = this;

  vm.search = {
    needsReview: $location.search().needs_review ? 1 : 0,
    perform:     function () {
      vm.tableParams.page(1);
      vm.tableParams.reload();
    }
  };

  const debouncedReload = debounce(() => vm.tableParams.reload(), 1000);

  vm.toggleTagReview = tag => {
    _.pull(vm.results, tag);
    $http.post(`/api/tags/${tag.id}`, {needs_review: tag.needs_review})
      .then(() => {
        debouncedReload();
      })
      .catch(() => $notification.info('Tag review requirement updated'));
  };

  vm.results = [];

  // concurrency prevention
  let getDataPromise = $q.resolve();

  vm.tableParams = new ngTableParams(
    {
      page:  $location.search().page || 1,            // show first page
      count: 25,           // count per page
      // sorting: (function () {
      //   let sort = $location.search().sort;
      //   if (sort) {
      //     let parts      = sort.split('.');
      //     sort           = {};
      //     sort[parts[0]] = parts[1];
      //
      //     return sort;
      //   }
      //
      //   return {count: 'desc'};
      // }())
    },
    {
      counts:  [],
      total:   0, // length of data
      getData: function ($defer, params) {
        $location.search('page', params.page() === 1 ? null : params.page());

        // const sort = _.pairs(params.sorting())[0].join('.');
        // $location.search('sort', sort === 'count.desc' ? null : sort);

        getDataPromise = getDataPromise.then(
          () => $http.get('/reports/tags-without-valid-image',
            {
              params: {
                limit:        params.count(),
                offset:       (params.page() - 1) * params.count(),
                needs_review: vm.search.needsReview
                // orderBy: sort
              }
            }
          ).then(function (response) {
              const results = response.data;

              _.clone(vm.results).forEach(result => {
                if (!_.find(results, {id: result.id})) {
                  _.pull(vm.results, result);
                }
              });

              results.forEach(result => {
                if (!_.find(vm.results, {id: result.id})) {
                  vm.results.push(result);
                }
              });

              params.total(
                (params.page() - 1) * params.count() +
                vm.results.length +
                (vm.results.length < params.count() ? 0 : params.count())
              );
              $defer.resolve(vm.results);
            }
          ).catch(() => $notification.error('Failed to load the report'))
        );
      }
    }
  );
}
