'use strict';

angular.module('app.controllers')
    .controller('TopUploadedUpcAssetsFromTrackStillMissingCtrl',
        function ($scope, $state, $http, ngTableParams, $notification,
                  debounce, commenter, confirm, $location, arrayRefill, endlessTotal) {
            const vm = $scope.vm = this;
            const debouncedReload = debounce(function () {
                $scope.tableParams.reload();
            }, 1000);

            $scope.search = {
                filter:  {
                    upc: $location.search().upc || ''
                },
                perform: function () {
                    $location.search('upc', this.filter.upc || null);

                    $scope.tableParams.page(1);
                    $scope.tableParams.reload();
                }
            };

            vm.markUpc = function (record, data, type = 'bad') {
                let message, update;

                if (type === 'bad') {
                    message = "Are you sure you want to mark this UPC bad and hide it forever?";
                    update  = {bad_upc: 1};
                } else if (type === 'no-nutrition') {
                    message = "Are you sure you want to mark this UPC having no nutrition info and hide it forever?";
                    update  = {missing_nutrition_label: 1};
                }

                confirm(message).then(() => {
                    const reloadAfter = $http.post('/api/reports/masterUpc/' + record.upc, update)
                        .then(function () {
                            $notification.info('marked bad upc');
                            debouncedReload();
                        })
                        .catch(function () {
                            $notification.error('failed to update');
                        });

                    vm.removeResult(record, reloadAfter);
                });
            };

            $scope.addNotes = function (item) {
                const dialog = commenter.open(item.notes);
                dialog.result.then(function (notes) {
                    item.notes = notes;
                });

                dialog.submit('nutritionix-api.master_upc', 'upc', item.upc);
            };

            vm.results = [];

            vm.removeResult = function (item, reloadAfter = Promise.resolve()) {
                _.pull(vm.results, item);
                reloadAfter.then(debouncedReload);
            };

            $scope.tableParams = new ngTableParams(
                {
                    page:    $location.search().page || 1,            // show first page
                    count:   25,           // count per page
                    sorting: (function () {
                        let sort = $location.search().sort;
                        if (sort) {
                            let parts      = sort.split('.');
                            sort           = {};
                            sort[parts[0]] = parts[1];

                            return sort;
                        }

                        return {ct: 'desc'};
                    }())
                },
                {
                    counts:  [],
                    total:   0, // length of data
                    getData: function ($defer, params) {
                        $location.search('page', params.page() > 1 ? params.page() : null);
                        const sort = _.pairs(params.sorting())[0].join('.');
                        $location.search('sort', sort === 'ct.desc' ? null : sort);
                        $http.get('/reports/top-uploaded-upc-assets-from-track-still-missing',
                            {
                                params: {
                                    limit:   params.count(),
                                    offset:  (params.page() - 1) * params.count(),
                                    orderBy: _.pairs(params.sorting())[0].join('.'),
                                    filter:  JSON.stringify(_.pick($scope.search.filter, (v, k) => !!v))
                                }
                            }
                        ).success(
                            function (data) {
                                params.total(endlessTotal(params, data.results.length));
                                arrayRefill(vm.results, data.results);

                                $defer.resolve(vm.results);
                            }
                        );
                    }
                }
            );
        }
    );
