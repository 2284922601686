(function () {
  'use strict';

  angular.module('app.modules.record-edit-history')
    .factory('recordEditHistory', recordEditHistory);

  /*@ngInject*/
  function recordEditHistory($http, $modal) {
    return function emailTemplatesModal(entity, entity_id) {
      return $modal.open({
        animation:   false,
        templateUrl: 'modules/record-edit-history/record-edit-history.modal.html',
        controller:  function ($scope, $modalInstance, records, users) {
          let vm = $scope.vm = this;

          vm.users = users;

          vm.entity = entity;
          vm.entity_id = entity_id;

          vm.records = records;

          vm.records.forEach(record => {
            let diff = record.diff = [];

            let keys = _.uniq(_.keys(record.old_data || {}).concat(_.keys(record.new_data || {})));

            keys.forEach(key => {
              if (record.old_data[key] !== record.new_data[key]) {
                diff.push({
                  attribute: key,
                  oldValue: record.old_data[key],
                  newValue: record.new_data[key]
                });
              }
            });
          });

          vm.close = () => $modalInstance.dismiss();
        },
        size:        'lg',
        resolve:     {
          records: () => $http
            .get(`/api/record-edit-history/${entity}/${entity_id}`)
            .then(response => response.data),
          users:   () => $http.get('/api/users')
            .then(response => {
              let users = {};
              response.data.forEach(u => users[u.id] = u);

              return users;
            })
        }
      });
    }
  }

  /*@ngInject*/
  function ValidateEmailTemplate() {
    return {
      require: 'ngModel',
      link:    function (scope, element, attributes, ngModel) {
        ngModel.$validators.validateEmailTemplate = function (modelValue, viewValue) {
          return !(viewValue && viewValue.match(/\{\{\w+?}}/));
        };
      }
    };
  }

}());
