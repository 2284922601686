'use strict';

angular.module('app')
    .controller('AppScreensEditCtrl', function ($scope, $state, $http, $notification,
                                                generateThumb, $upload, $filter,
                                                locales, $location, $q, confirm) {
        const vm = $scope.vm = this;
        const id = $state.params.id;
        const url = `/api/app-screens/${id}`;

        vm.locales = _.without(locales, l => l.language_culture_name === 'en-US');

        vm.setLocale = (locale) => {
            vm.locale = locale;
            $location.search('locale', locale.language_culture_name);
            vm.loadTexts();
        };

        vm.refreshPhoto = () => {
            vm.refreshPhoto.tag = Date.now();
        };

        vm.refreshPhoto();

        vm.screenshot = {
            thumbnail:         null,
            file:              null,
            generateThumbnail: function () {
                if (this.file) {
                    generateThumb(this.file[0])
                        .then(thumbnail => {this.thumbnail = thumbnail});
                }
            },
            clearUpload:       function () {
                this.file = null;
                this.thumbnail = null;
            }
        };

        vm.loadScreen = () => {
            $http.get(url)
                .then(response => vm.model = response.data)
                .catch(response => $notification.error('App screen could not be loaded: ' +
                    JSON.stringify(response.data)));
        };

        vm.loadTexts = () => {
            $http.get(`/api/app-screens/${id}/texts`, {params: {locale_id: vm.locale.id}})
                .then(response => vm.texts = response.data)
                .then(() => vm.initNewText())
                .catch(response => $notification.error('App screen texts could not be loaded: ' +
                    JSON.stringify(response.data)));
        };

        vm.initNewText = () => {
            vm.texts.push({
                text:        '',
                comments:    '',
                translation: ''
            })
        };

        vm.submit = () => {
            vm.submit.$busy = true;

            let flow;

            let model = _.pick(vm.model, ['name', 'description']);

            if (vm.screenshot.file) {
                flow = $upload
                    .upload({
                        url,
                        file:             vm.screenshot.file,
                        fileFormDataName: ['screenshot'],
                        fields:           model
                    })
            } else {
                flow = $http.post(url, model);
            }


            flow.then(response => {
                    _.extend(vm.model, response.data);

                    $notification.success('Screen saved');
                    vm.refreshPhoto();
                    vm.screenshot.clearUpload();
                    vm.form && vm.form.$setPristine();
                })
                .catch(response => {
                    vm.submit.$error = 'Failed to create new record: ' + $filter('readError')(response.data);
                })
                .finally(() => vm.submit.$busy = false)
        };

        vm.submitText = (text, $form) => {
            vm.submitText.$busy = true;

            $http.post(`/api/app-screens/${id}/texts/${text.id || ''}`, text, {params: {locale_id: vm.locale.id}})
                .then(response => {
                    if (!text.id) {
                        vm.initNewText();
                    }

                    _.extend(text, response.data);

                    let form = $form || text.$form;

                    form && form.$setPristine();

                    $notification.info('Text saved');
                })
                .catch(response => {
                    $notification.error('Text could not be saved: ' + $filter('readError')(response.data));
                })
                .finally(() => vm.submitText.$busy = false)
        };

        vm.deleteText = (text) => {
            confirm(`Are you sure you want to delete text "${text.text}"`).then(
                () => $http.delete(`/api/app-screens/${id}/texts/${text.id}`)
                    .then(response => {
                        if (response.data === 1) {
                            _.remove(vm.texts, text);
                            $notification.info('Text was deleted');
                        } else {
                            return $q.reject({data: 'Affected rows = 0'});
                        }
                    })
                    .catch(response => {
                        $notification.error('Text could not be deleted: ' + $filter('readError')(response.data));
                    })
            );
        };

        vm.toggleTextVisibility = text => {
            text.is_hidden = text.is_hidden ? 0 : 1;
            vm.submitText(text);
        };

        vm.loadScreen();
        vm.setLocale(_.find(vm.locales, {language_culture_name: $location.search().locale || 'de-DE'}));
    });
