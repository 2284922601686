(function () {
  'use strict';

  const moduleName     = 'translations',
        controllerName = 'UploadTranslatedWeightsCtrl';

  const moduleFullName = `app.modules.${moduleName}`;

  angular.module(moduleFullName)
    .controller(`${moduleFullName}.${controllerName}`, UploadTranslatedWeightsCtrl);

  /*@ngInject*/
  function UploadTranslatedWeightsCtrl($scope, $notification, $upload, locales) {
    let vm = $scope.vm = this;

    vm.locales = [];
    locales.forEach(l => {
      if (l.language_culture_name === 'de-DE') {
        vm.locales.unshift(l);
      } else if (l.limitNavigation !== 'en-US') {
        vm.locales.push(l);
      }
    });

    vm.locale = vm.locales[0];

    vm.clearUpload = () => {
      vm.uploadedFile           = null;
      vm.uploadedFileCollection = null;
    };
    vm.clearUpload();

    vm.fileSelected = collection => {
      if (vm.fileSelected.rejected && vm.fileSelected.rejected.length) {
        $notification.error('Unsupported file type. Please upload CSV files only');
      } else if (collection && collection.length) {
        vm.uploadedFile = collection[0];
      }
    };

    vm.submit = () => {
      vm.submit.$busy = true;

      $upload
        .upload({
          url:              '/api/translations/upload-translated-weights',
          file:             vm.uploadedFile,
          fileFormDataName: 'csv',
          fields:           {
            locale_id: vm.locale.id,
            locale:    vm.locale.language_culture_name,
            country:   vm.locale.country_alpha_2
          }
        })
        .then(function () {
          $notification.info('File uploaded successfully. Records are processed in the background.');
          vm.clearUpload();
        })
        .catch(function (/*error*/) {
          $notification.error('File upload failed');
        })
        .finally(function () {
          vm.submit.$busy = false;
        });
    };
  }
}());
