(function () {
  'use strict';

  const moduleName     = 'bot',
        controllerName = 'ListConversationsCtrl';

  const moduleFullName = `app.modules.${moduleName}`;

  angular.module(moduleFullName)
    .controller(`${moduleFullName}.${controllerName}`, ListConversationsCtrl);

  /*@ngInject*/
  function ListConversationsCtrl($log, $http, $notification, $filter, ngTableParams, $location, confirm) {
    $log.debug(`[${moduleName}] Controller "${controllerName}" launched`);

    const vm = this;

    vm.data = [];

    vm.tableParams = new ngTableParams(
      {
        page:    $location.search().page || 1,            // show first page
        count:   25,           // count per page
        sorting: (function () {
          let sort = $location.search().sort;
          if (sort) {
            sort = sort.split('.');

            let result      = {};
            result[sort[0]] = sort[1];

            return result;
          }

          return {last_interaction_at: 'desc'};
        }())
      },
      {
        counts:  [],
        total:   0, // length of data
        getData: function ($defer, params) {
          $location.search('page', params.page() > 1 ? params.page() : null);

          let data = vm.data;

          params.total(data.length);

          if (_.keys(params.sorting()).length) {
            let [sortingAttribute, sortingDirection] = _.pairs(params.sorting())[0];
            let sort                                 = `${sortingAttribute}.${sortingDirection}`;

            $location.search('sort', sort === 'last_interaction_at.desc' ? null : sort);

            data = _.sortByOrder(data, [sortingAttribute], [sortingDirection]);
          } else {
            $location.search('sort', null);
          }
          data = data.slice((params.page() - 1) * params.count(), params.page() * params.count() - 1);

          $defer.resolve(data);

          if (params.page() > 1 && data.length === 0) {
            params.page(1);
            params.reload();
          }
        }
      }
    );

    vm.loadData = () => {
      return $http.get('/api/bot/conversations')
        .then(response => {
          vm.data = response.data;
          vm.tableParams.reload();
        })
        .catch(response => $notification.error(
          'Failed to load conversations: ' + $filter('readError')(response.data)
        ));
    };

    vm.loadData();
  }
}());
