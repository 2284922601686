(function () {
  'use strict';

  const moduleName     = 'ingredient-learning',
        controllerName = 'EditIngredientCtrl';

  const moduleFullName = `app.modules.${moduleName}`;

  angular.module(moduleFullName)
    .controller(`${moduleFullName}.${controllerName}`, EditIngredientCtrl);

  /*@ngInject*/
  function EditIngredientCtrl($scope, $log, $http, $notification, $filter,
                              ngTableParams, $location, $state, confirm, $modal,
                              ingredient, grouping, questions, additionalQuestions, $sessionStorage, sampleItemsModal) {
    $log.debug(`[${moduleName}] Controller "${controllerName}" launched`);

    const vm      = this;
    vm.ingredient = ingredient;
    vm.grouping   = grouping;
    vm.questions  = questions;

    vm.expandAdditionalQuestions = _.keys(ingredient.data.answers_additional).length;

    vm.showSampleItems = () => sampleItemsModal(vm.ingredient);

    if (_.get(vm.ingredient, 'data.grouping')) {
      vm.group = _.find(vm.grouping, {id: vm.ingredient.data.grouping});
    }

    vm.phrases = [];

    vm.tableParams = new ngTableParams(
      {
        page:    $location.search().page || 1,            // show first page
        count:   25,           // count per page
        sorting: (function () {
          let sort = $location.search().sort;
          if (sort) {
            sort = sort.split('.');

            let result      = {};
            result[sort[0]] = sort[1];

            return result;
          }

          return {};
        }())
      },
      {
        counts:  [],
        total:   0, // length of data
        getData: function ($defer, params) {
          $location.search('page', params.page() > 1 ? params.page() : null);

          let data = vm.phrases;

          params.total(data.length);

          if (_.keys(params.sorting()).length) {
            let [sortingAttribute, sortingDirection] = _.pairs(params.sorting())[0];

            $location.search('sort', `${sortingAttribute}.${sortingDirection}`);

            data = _.sortByOrder(data, [sortingAttribute], [sortingDirection]);
          } else {
            $location.search('sort', null);
          }
          data = data.slice((params.page() - 1) * params.count(), params.page() * params.count() - 1);

          $defer.resolve(data);
        }
      }
    );

    vm.newPhrase = {};
    vm.addPhrase = () => {
      vm.addPhrase.$error = null;
      if (vm.newPhrase.text) {
        vm.assignPhrase.$candidate = null;

        $http.post(`/api/ingredient-learning/ingredients/${$state.params.id}/phrases`, vm.newPhrase)
          .then(response => {
            $notification.info('Phrase added');
            vm.phrases.push(response.data);
            vm.newPhrase = {};
            vm.phraseForm.$setPristine();
          })
          .catch(response => {
            $notification.error('Failed to add phrase: ' + $filter('readError')(response.data));

            if (response.data.status === 409 && response.data.errors[0].duplicate.tag_id !== vm.ingredient.id) {
              vm.newPhrase = {};
              vm.phraseForm.$setPristine();
              vm.assignPhrase.$candidate = response.data.errors[0].duplicate;
            }
          });
      }
    };

    vm.calculateIsCompleted = () => {
      let isCompleted = 0;

      const questionIds = _.sortBy(vm.questions.map(q => q.id));


      for (let i = 0; i < questionIds.length; i += 1) {
        if (!_.isUndefined(vm.ingredient.data.answers[questionIds[i]])) {
          isCompleted = questionIds[i];
        } else {
          break;
        }
      }

      vm.ingredient.is_completed = isCompleted;
    };

    vm.saveIngredient = (attributes = null) => {
      vm.saveIngredient.$busy = true;

      vm.calculateIsCompleted();

      return $http.put(
        `/api/ingredient-learning/ingredients/${$state.params.id}`,
        attributes ? _.pick(vm.ingredient, attributes) : vm.ingredient
      )
        .then(response => {
          angular.merge(vm.ingredient, response.data);
          $notification.info('Ingredient updated');

          vm.loadPhrases();
        })
        .catch(response => $notification.error(
          'Failed to update ingredient: ' + $filter('readError')(response.data)
        ))
        .finally(() => vm.saveIngredient.$busy = false);
    };

    vm.deletePhrase = phrase => {
      confirm(`Are you sure you want to delete phrase "${phrase.text}"`).then(() => {
        _.pull(vm.phrases, phrase);

        $http.delete(`/api/ingredient-learning/ingredients/${$state.params.id}/phrases/${phrase.id}`)
          .then(response => {
            $notification.success(response.data.message);
          })
          .catch(response => {
            $notification.error($filter('readError')(response.data));
          });
      })
    };

    vm.assignPhrase = (phrase, ingredient) => {
      confirm(`Are you sure you want to assign phrase "${phrase.text}" to ingredient ${ingredient.name}`)
        .then(() => {
          $http.put(`/api/ingredient-learning/ingredients/${$state.params.id}/phrases/${phrase.id}`, {tag_id: ingredient.id})
            .then(response => {
              $notification.success('Phrase re-assigned');

              if (!_.find(vm.phrases, {id: response.data.id})) {
                vm.phrases.push(response.data);
              }

              vm.assignPhrase.$candidate = null;
            })
            .catch(response => {
              $notification.error($filter('readError')(response.data));
            });
        });
    };

    vm.editPhrase = phrase => {
      const modalInstance = $modal.open({
        size:        'md',
        animation:   false,
        templateUrl: 'modules/ingredient-learning/ingredient-learning.edit-ingredient.edit-phrase.modal.html',
        controller:  function ($scope, $modalInstance, ingredient) {
          const vm = $scope.vm = this;

          vm.phrase            = phrase;
          vm.ingredientName    = ingredient.name;
          vm.searchIngredients = (q) => $http.get('/api/ingredient-learning/ingredients', {params: {q}})
            .then(response => response.data.ingredients)
            .catch(() => []);


          vm.setIngredient = ingredient => {
            vm.ingredientName = ingredient.name;
            vm.phrase.tag_id  = ingredient.id;
          };

          vm.save = () => {
            vm.save.$busy = true;
            $http.put(`/api/ingredient-learning/ingredients/${$state.params.id}/phrases/${phrase.id}`, vm.phrase)
              .then(response => {
                $notification.success('Phrase saved');
                $modalInstance.close(vm.phrase);
              })
              .catch(response => {
                $notification.error($filter('readError')(response.data));
              })
              .finally(() => vm.save.$busy = false);
          };

          vm.close = () => {
            $modalInstance.dismiss();
          }
        },
        resolve:     {
          ingredient: () => vm.ingredient
        }
      });

      modalInstance.result.then(phrase => {
        if (phrase.tag_id !== vm.ingredient.id) {
          _.pull(vm.phrases, phrase);
        }
      });
    };

    vm.openSeparatePhrasesDialog = () => {
      const modalInstance = $modal.open({
        size:        'lg',
        animation:   false,
        templateUrl: 'modules/ingredient-learning/ingredient-learning.edit-ingredient.separate-phrases.modal.html',
        controller:  `${moduleFullName}.SeparatePhrasesModalCtrl as vm`,
        resolve:     {
          phrases:    () => vm.phrases.slice(1),
          ingredient: () => vm.ingredient
        }
      });

      modalInstance.result.then(data => {
        $state.go($state.current.name, {id: data.targetIngredient})
      });
    };


    vm.mergeWithIngredient = () => {
      const modalInstance = $modal.open({
        size:        'lg',
        animation:   false,
        templateUrl: 'modules/ingredient-learning/ingredient-learning.edit-ingredient.merge.modal.html',
        controller:  function ($scope, $modalInstance, ingredient) {
          const vm = $scope.vm = this;

          vm.ingredient          = ingredient;
          vm.mergeIngredientName = '';

          vm.searchIngredients = () => {
            vm.mergeIngredients = [];
            const q             = vm.mergeIngredientName;

            $http.get(
              '/api/ingredient-learning/ingredients',
              {
                params: {
                  q,
                  except: [ingredient.id].concat(vm.getSelectedMergeIngredients().map(i => i.id)).join(','),
                  limit:  1000
                }
              })
              .then(response => {
                if (vm.mergeIngredientName === q) {
                  vm.mergeIngredients = _.sortBy(
                    response.data.ingredients,
                    i => {
                      let index = i.name.indexOf(q);
                      if (index === -1) {
                        index = 999999;
                      }
                      return index;
                    }
                  );
                }
              })
              .catch(() => [])
          };

          vm.mergeIngredients = [];

          vm.getIsAnySelected = () => {
            for(let i =0; i < vm.mergeIngredients.length; i += 1){
              if(vm.mergeIngredients[i].$merge){
                return true;
              }
            }
            return false;
          };

          vm.getSelectedMergeIngredients = () => _.filter(vm.mergeIngredients, {$merge: true});

          vm.mergeIngredientsNames = () => vm.getSelectedMergeIngredients().map(i => i.name).join(', ');

          vm.merge = () => {
            confirm(`Are you sure you want to delete ingredients 
            "${vm.getSelectedMergeIngredients().map(i => i.name).join(', ')}" and move all their phrases to ingredient "${vm.ingredient.name}"`)
              .then(() => {
                vm.merge.$busy = true;
                $http.post(`/api/ingredient-learning/ingredients/${$state.params.id}/merge`, {id: vm.getSelectedMergeIngredients().map(i => i.id)})
                  .then(response => {
                    $notification.success('Merged');
                    $modalInstance.close();
                  })
                  .catch(response => {
                    $notification.error($filter('readError')(response.data));
                  })
                  .finally(() => vm.merge.$busy = false);
              });
          };

          vm.close = () => {
            $modalInstance.dismiss();
          }
        },
        resolve:     {
          ingredient: () => vm.ingredient
        }
      });

      modalInstance.result.then(() => vm.loadPhrases());
    };

    vm.flagIngredient = () => {
      const modalInstance = $modal.open({
        size:        'md',
        animation:   false,
        templateUrl: 'modules/ingredient-learning/ingredient-learning.edit-ingredient.flag.modal.html',
        controller:  function ($scope, $modalInstance, parentVm, ingredientFlagReasons) {
          const vm = $scope.vm = this;

          vm.reasons = ingredientFlagReasons;

          vm.ingredient    = parentVm.ingredient;
          vm.unflagEnabled = vm.ingredient.flagged_type;

          vm.save = () => {
            vm.save.$busy = true;

            parentVm.saveIngredient()
              .then(() => $modalInstance.close(vm.ingredient))
              .finally(() => vm.save.$busy = false);
          };

          vm.unflag = () => {
            _.forEach(ingredient, (value, key) => {
              if (key.substr(0, 8) === 'flagged_') {
                ingredient[key] = null;
              }
            });

            vm.save();
          };

          vm.close = () => {
            $modalInstance.dismiss();
          }
        },
        resolve:     {
          parentVm: () => vm
        }
      });

      modalInstance.result.then(ingredient => {
        if (ingredient.flagged_type) {
          vm.goBack();
        }
      });
    };

    vm.deleteIngredient = () => {
      confirm(`Are you sure you want to delete ingredient "${vm.ingredient.name}"`).then(() => {

        $http.delete(`/api/ingredient-learning/ingredients/${$state.params.id}`)
          .then(response => {
            $notification.success(response.data.message);
            vm.goBack();
          })
          .catch(response => {
            $notification.error($filter('readError')(response.data));
          });
      })
    };

    vm.goBack = () => {
      if ($sessionStorage.ingredientLearningListState) {
        $location.url($sessionStorage.ingredientLearningListState);
      } else {
        $state.go('app.ingredient-learning.list-ingredients');
      }
    };

    vm.setGroup = group => {
      vm.ingredient.data.grouping = group.id;
      vm.ingredient.data.facets   = {};
      group.facets.forEach(facet => {
        if (facet.type === 'boolean') {
          vm.ingredient.data.facets[facet.name] = null;
        }
      });
    };

    vm.answerAll = (value) => {
      _.forEach(vm.questions, question => {
        vm.ingredient.data.answers[question.id] = value;
      });
    };

    vm.answerAllAdditional = (value) => {
      _.forEach(vm.additionalQuestions, question => {
        vm.ingredient.data.answers_additional[question.id] = value;
      });
    };

    vm.savePhrase = (phrase) => {
      $http.put(
        `/api/ingredient-learning/ingredients/${$state.params.id}/phrases/${phrase.id}`,
        _.pick(phrase, ['is_typo'])
        )
        .then(response => {
          $notification.success('Phrase saved');
        })
        .catch(response => {
          $notification.error($filter('readError')(response.data));
        })
    };

    vm.loadPhrases = () => {
      $http.get(`/api/ingredient-learning/ingredients/${$state.params.id}/phrases`)
        .then(response => {
          vm.phrases          = response.data;
          const defaultPhrase = _.find(vm.phrases, {text: vm.ingredient.name});
          if (defaultPhrase) {
            defaultPhrase.$default = true;
            _.pull(vm.phrases, defaultPhrase);
            vm.phrases.unshift(defaultPhrase);
          }


          $scope.$watchCollection('vm.phrases', () => {
            vm.tableParams.reload();
          });
        })
        .catch(response => $notification.error(
          'Failed to load ingredient phrases: ' + $filter('readError')(response.data)
        ));
    };

    vm.setDefaultPhrase = (phrase) => {
      vm.ingredient.name = phrase.text;
      vm.saveIngredient(['name']);
    };

    vm.loadPhrases();

    $scope.$watchCollection('vm.ingredient.data.answers', () => {
      const filteredAdditionalQuestions = [];

      const data = vm.ingredient.data;

      additionalQuestions.forEach((question) => {
        if (!question.linked_question || data.answers[question.linked_question]) {
          filteredAdditionalQuestions.push(question);
        }
      });

      _.keys(data.answers_additional).forEach((questionId) => {
        if (!_.find(filteredAdditionalQuestions, {id: +questionId})) {
          delete data.answers_additional[questionId];
        }
      });

      vm.additionalQuestions       = filteredAdditionalQuestions;
      vm.expandAdditionalQuestions = filteredAdditionalQuestions.length;
    });
  }
}());
