'use strict';

angular.module('app.controllers')
    .controller('ItemsListCtrl', [
        '$scope', '$state', '$http', 'ngTableParams', '$location',
        function ($scope, $state, $http, ngTableParams, $location) {
            var q;

            $scope.search = {
                term:    '',
                filter:  {
                    'i.item_type': '2'
                },
                perform: function () {
                    $scope.tableParams.page(1);
                    $scope.tableParams.reload();
                }
            };



            $scope.$watch(
                function () {
                    return JSON.stringify($scope.search) + JSON.stringify($scope.tableParams.filter());
                },
                function (newValue, oldValue) {
                    if(newValue !== oldValue){
                        $location.search({q: JSON.stringify(_.merge({}, $scope.search, {filter: $scope.tableParams.filter()}))});
                    }
                }
            );

            if ($location.search().q) {
                q = JSON.parse($location.search().q);

                $scope.search.term = q.term;
                $scope.search.filter['i.item_type'] = q.filter['i.item_type'];
            }

            $scope.tableParams = new ngTableParams(
                {
                    page:    1,            // show first page
                    count:   25,           // count per page
                    sorting: {},
                    filter: q ? _.pick(q.filter, ['upc', 'i.item_name', 'b.name']) : {}
                },
                {
                    counts:  [],
                    total:   0, // length of data
                    getData: function ($defer, params) {
                        $http.get('/api/items', {
                            params: {
                                limit:   params.count(),
                                offset:  (params.page() - 1) * params.count(),
                                search:  $scope.search.term,
                                orderBy: _.keys(params.sorting()).length ? _.pairs(params.sorting())[0].join('.') : undefined,
                                filter: _.extend({}, $scope.search.filter, params.filter())
                            }
                        }).success(
                            function (data) {
                                if(data.count !== null){
                                    params.total(data.count);
                                }

                                $defer.resolve(data.results);
                            }
                        );
                    }
                }
            );


        }
    ]);
