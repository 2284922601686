(function () {
  'use strict';

  const moduleName     = 'brand-recipes',
        controllerName = 'RecipeSaveCtrl';

  const moduleFullName = `app.modules.${moduleName}`;

  angular.module(moduleFullName)
    .controller(`${moduleFullName}.${controllerName}`, RecipeSaveCtrl);

  /*@ngInject*/
  function RecipeSaveCtrl($log, $scope, $state, $http, $notification, $q, $upload, $filter, confirm, brand, utils, nixTrackUtils) {
    $log.debug(`[${moduleName}] Controller "${controllerName}" launched`);

    const vm = this;

    const url = `/api/brand-recipes/brands/${brand.id}/recipes` + ($state.params.recipeId ? '/' + $state.params.recipeId : '');

    let flow;

    if ($state.params.recipeId) {
      flow = $http.get(url)
        .then(response => response.data);
    } else {
      flow = $q.resolve({
        name:         '',
        serving_qty:  '',
        serving_unit: '',
        directions:   '',
      });
    }

    flow.then(model => {
      vm.model = vm.recipe = model;

      vm.refreshLabelData();
    });

    vm.refreshLabelData = () => {
      if (vm.recipe && vm.recipe.ingredients) {
        const ingredientsSum           = nixTrackUtils.sumFoods(vm.recipe.ingredients);
        vm.recipe.full_nutrients       = ingredientsSum.full_nutrients;
        vm.recipe.serving_weight_grams = ingredientsSum.serving_weight_grams;

        vm.labelData = $filter('trackFoodToLabelData')(
          ingredientsSum,
          {
            itemName:             vm.recipe.name,
            valueServingSizeUnit: vm.recipe.serving_unit
          },
          vm.recipe.serving_qty
        );
      }
    };

    vm.save = () => {
      vm.$busy  = true;
      vm.$error = null;

      return $http[vm.model.id ? 'put' : 'post'](url, vm.model)
        .then(response => {
          $notification.info('Recipe Saved');

          _.extend(vm.model, response.data);

          if (!$state.params.recipeId) {
            $state.go('app.brand-recipes.brand.recipe.edit', {recipeId: response.data.id});
          }
        })
        .catch(response => {
          vm.$error = $filter('readError')(response.data);
          return $q.reject(response);
        })
        .finally(() => vm.$busy = false);
    };

    vm.delete = () => {
      vm.$busy  = true;
      vm.$error = null;

      confirm('Are you sure you want to delete this recipe?')
        .then(() => {
          return $http.delete(url);
        })
        .then(() => {
          $notification.info('Recipe Deleted');
          $state.go('app.brand-recipes.brand.recipe.list', {brand: brand.webname});
        })
        .catch(response => vm.$error = $filter('readError')(response.data))
        .finally(() => vm.$busy = false);
    };

    // default substate would be ingredients list
    $scope.$watch(() => $state.current.name, name => {
      if (name === utils.state('brand.recipe.edit')) {
        $state.go(utils.state('brand.recipe.edit.ingredients-list'), $state.params);
      }
    });

    $scope.$watch(() => {
      let nSum = 0;

      if (vm.recipe && vm.recipe.ingredients) {
        for (let i = 0; i < vm.recipe.ingredients.length; i += 1) {
          for (let j = 0; j < vm.recipe.ingredients[i].full_nutrients.length; j += 1) {
            nSum += vm.recipe.ingredients[i].full_nutrients[j].value || 0;
          }
        }

        nSum = nSum.toString() + vm.recipe.serving_qty + vm.recipe.serving_unit + vm.recipe.name
      }

      return nSum;
    }, () => vm.refreshLabelData())
  }
}());
