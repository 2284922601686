'use strict';

angular.module('app')
    .controller('AppScreensListCtrl', function ($scope, $http, $notification,
                                                generateThumb, $upload, $filter, locales) {
        const vm = $scope.vm = this;
        vm.locales = _.without(locales, l => l.language_culture_name === 'en-US');
        vm.locale  = _.find(vm.locales, {language_culture_name: 'de-DE'});

        vm.initNewAppScreen = () => {
            vm.newAppScreen = {
                name:        '',
                description: '',
                screenshot:  {
                    thumbnail:         null,
                    file:              null,
                    generateThumbnail: function () {
                        if (this.file) {
                            generateThumb(this.file[0])
                                .then(thumbnail => {this.thumbnail = thumbnail});
                        }
                    },
                    clearUpload:       function () {
                        this.file = null;
                        this.thumbnail = null;
                    }
                }
            };
        };

        vm.loadScreens = () => {
            $http.get('/api/app-screens')
                .then(response => vm.appScreens = response.data)
                .catch(response => $notification.error('App screens could not be loaded: ' +
                    JSON.stringify(response.data)));
        };

        vm.submit = () => {
            console.log(vm.form);

            if (vm.form && vm.form.$invalid) {return;}

            vm.submit.$busy = true;

            let flow;

            let newAppScreen = _.pick(vm.newAppScreen, ['name', 'description']);

            if (vm.newAppScreen.screenshot.file) {
                flow = $upload
                    .upload({
                        url:              '/api/app-screens',
                        file:             vm.newAppScreen.screenshot.file,
                        fileFormDataName: ['screenshot'],
                        fields:           newAppScreen
                    })
            } else {
                flow = $http.post('/api/app-screens', newAppScreen);
            }


            flow.then(response => {
                    $notification.success('Screen created');
                    vm.appScreens.push(response.data);

                    vm.initNewAppScreen();

                    vm.form && vm.form.$setPristine();
                })
                .catch(response => {
                    vm.submit.$error = 'Failed to create new record: ' + $filter('readError')(response.data);
                })
                .finally(() => vm.submit.$busy = false)
        };

        vm.loadScreens();
        vm.initNewAppScreen();
    });
