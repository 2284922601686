'use strict';

angular.module('app.controllers')
    .controller(
        'RecentExerciseQueriesWith0ExercisesFound',
        function ($scope, $http, $notification, $q, confirm,
                  session, moment, $modal, ngTableParams, $location, suggest) {
            const vm = $scope.vm = this;

            vm.loadReport = () => {
                vm.queries = [];
                $http.get('/api/track-reports/recent-exercise-queries-with-0-exercises-found')
                    .then(response => {
                        vm.queries = response.data;
                        vm.tableParams.reload();
                    })
                    .catch((/*error*/) => $notification.error('Queries load failed'));
            };


            vm.resolve = function (query, hidePermanently = false) {
                if (hidePermanently) {
                    _.remove(
                        vm.queries,
                        value => value.query.toLowerCase().trim() === query.query.toLowerCase().trim()
                    )
                } else {
                    _.pull(vm.queries, query);
                }

                vm.tableParams.reload();

                $http.post(`/api/track-reports/recent-exercise-queries-with-0-exercises-found/${query.id}/resolve`, {hidePermanently})
                    .success(function () {
                        $notification.info(`Query "${query.query}" resolved` + (hidePermanently ? ' and permanently hidden' : ''));
                    })
                    .error(function () {
                        $notification.error(`Query "${query.query}" not resolved`);
                    });
            };

            vm.sendEmail = function (query) {
                $modal.open({
                    animation:   true,
                    templateUrl: 'views/track-reports/send-email-modal.html',
                    controller:  function ($scope, $modalInstance, query) {
                        let vm = $scope.vm = this;
                        let queryDate = moment(query.created_at).format('MM/DD/YYYY');
                        let name = session.profile.public_name || session.profile.name;

                        vm.query = query;

                        vm.email = {
                            entity:  'natural_exercise_queries',
                            id:      query.id,
                            to:      {
                                email: query.email,
                                name:  query.fullname
                            },
                            subject: `"${query.query}" has been added to Nutritionix Track`,
                            message: `Hi ${query.first_name},` +
                                     '\n\n' +
                                     `I wanted to write and let you know that when you recently tried to log "${query.query}" ` +
                                     `on ${queryDate} it was not yet added to our system. ` +
                                     `I went ahead and added "${query.query}" to our system, ` +
                                     'and it should be updated in the Track app in the next hour. ' +
                                     'I apologize for any inconvenience this caused, ' +
                                     'and please do not hesitate to let us know if you have any more feedback.' +
                                     '\n\n' +
                                     'Sincerely,' +
                                     '\n' +
                                     `${name}` +
                                     '\n' +
                                     'www.nutritionix.com'
                        };

                        vm.send = function () {
                            vm.send.$busy = true;
                            $http.post(`/api/track-reports/send-email`, vm.email)
                                .success(function () {
                                    query.hive2_email_sent = true;
                                    $notification.info('Email sent');
                                    $modalInstance.close();
                                })
                                .error(function () {
                                    $notification.error('Email not sent');
                                })
                                .finally(() => vm.send.$busy = false);
                        };

                        vm.close = () => $modalInstance.dismiss();
                    },
                    size:        'lg',
                    resolve:     {
                        query: () => query
                    }
                });
            };

            vm.createTag = function (query) {
                $modal.open({
                    animation:   true,
                    templateUrl: 'views/track-reports/create-exercise-tag-modal.html',
                    size:        'lg',
                    windowClass: 'modal-create-exercise-tag',
                    controller:  function ($scope, $controller, $location, $modalInstance) {
                        const vm    = this;
                        vm.embeded  = true;
                        vm.close    = () => $modalInstance.dismiss();


                        let listController      = $controller('TagsExerciseListCtrl', {
                            $scope: $scope,
                            $location
                        });
                        listController.screen   = 'list';
                        listController.template = 'views/tags-exercise/list.html';

                        listController.edit = tag => {
                            $http.get(`/api/tags-exercise/${tag.id}/phrases`)
                                .then(response => response.data)
                                .then(phrases => {
                                    let editController      = $controller('TagsExerciseEditCtrl', {
                                        $scope: $scope,
                                        $location, tag, phrases,
                                        $state: {
                                            go: () => {
                                            }
                                        }
                                    });
                                    editController.screen   = 'edit';
                                    editController.template = 'views/tags-exercise/edit.html';

                                    let copyTag            = editController.copyTag;
                                    editController.copyTag = function () {
                                        let modalInstance = copyTag();
                                        modalInstance.result.then(createdTag => {
                                            listController.edit(createdTag);
                                        })
                                    };

                                    angular.extend(editController, vm);

                                    $scope.vm = editController;
                                });
                        };

                        angular.extend(listController, vm);

                        vm.showList = () => {
                            $scope.vm = listController;
                        };
                        vm.showList();
                    },
                    resolve:     {
                        $location: () => {
                            return {
                                val:    {q: query.query},
                                search: function (key = undefined, value = undefined) {
                                    if (!key) {
                                        return this.val;
                                    }

                                    if (_.isUndefined(value)) {
                                        return this.val[key];
                                    }

                                    let oldVal    = this.val[key];
                                    this.val[key] = value;
                                    return oldVal;
                                }
                            }
                        }
                    }
                });
            };

            vm.assignPhrase = function (query) {
                const modalInstance = $modal.open({
                    animation:   true,
                    templateUrl: 'views/track-reports/assign-exercise-phrase-modal.html',
                    controller:  function ($scope, $modalInstance) {
                        let vm = $scope.vm = this;
                        let phrase = vm.phrase = {
                            text: query.query
                        };

                        vm.mode = 'phrase';

                        vm.suggest = suggest;

                        vm.phraseSuggestionQuery = phrase.text;

                        vm.suggestPhrases = function () {
                            if (vm.phraseSuggestionQuery) {
                                suggest.similar_exercise_phrases(vm.phraseSuggestionQuery)
                                    .then(function (phrases) {
                                        vm.phrases = phrases;
                                    });
                            } else {
                                vm.phrases = [];
                            }
                        };

                        vm.suggestPhrases();

                        vm.setTag = function (tag, save = false) {
                            if (!angular.isObject(tag)) {
                                tag = {id: tag};
                            }

                            vm.phrase.tag_id = tag && tag.id || null;

                            if (save) {
                                vm.save();
                            }
                        };

                        vm.clearTag = function () {
                            vm.phrase.tag_id = null;
                        };

                        vm.close = function () {
                            $modalInstance.dismiss('cancel');
                        };

                        vm.save = function (allowReassignment = false) {
                            if (!vm.phrase.tag_id) {
                                return
                            }

                            $http.post(`/api/tags-exercise/${vm.phrase.tag_id}/phrases`, vm.phrase, {params: {allowReassignment: allowReassignment ? 1 : 0}})
                                .then(function (response) {
                                    $notification.info('Phrase saved');
                                    $modalInstance.close(response.data);
                                })
                                .catch(function (response) {
                                    let error = response.data.errors && response.data.errors[0] || response.data;
                                    if (error.type === 'duplicate-assignment') {
                                        confirm(`Phrase is already assigned to tag [${error.phrase.tag_id}]. Do you want to reassign?`)
                                            .then(() => {
                                                vm.save(true)
                                            })
                                    }
                                    $notification.error('Phrase not updated');
                                });
                        };

                    },
                    size:        'md'
                });
            };

            vm.tableParams = new ngTableParams(
                {
                    page:    $location.search().page || 1,            // show first page
                    count:   25,           // count per page
                    sorting: (function () {
                        let sort = $location.search().sort;
                        if (sort) {
                            sort = sort.split('.');

                            let result = {};
                            result[sort[0]] = sort[1];

                            return result;
                        }

                        return {totalCount: 'desc'};
                    }())
                },
                {
                    counts:  [],
                    total:   0, // length of data
                    getData: function ($defer, params) {
                        $location.search('page', params.page() > 1 ? params.page() : null);

                        let data = vm.queries;

                        params.total(data.length);

                        if (_.keys(params.sorting()).length) {
                            let [sortingAttribute, sortingDirection] = _.pairs(params.sorting())[0];

                            $location.search('sort', `${sortingAttribute}.${sortingDirection}`);

                            data = _.sortByOrder(data, [sortingAttribute], [sortingDirection]);
                        } else {
                            $location.search('sort', null);
                        }
                        data = data.slice((params.page() - 1) * params.count(), params.page() * params.count() - 1);

                        $defer.resolve(data);
                    }
                }
            );

            vm.loadReport();
        }
    );
