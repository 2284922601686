(function () {
  'use strict';

  const moduleName     = 'ingredient-learning.dual-entry',
        controllerName = 'EditIngredientCtrl';

  const moduleFullName = `app.modules.${moduleName}`;

  angular.module(moduleFullName)
    .controller(`${moduleFullName}.${controllerName}`, EditIngredientCtrl);

  /*@ngInject*/
  function EditIngredientCtrl($scope, $log, $http, $notification, $filter,
                              ngTableParams, $location, $state, confirm, $modal,
                              ingredient, grouping, questions, additionalQuestions, $sessionStorage, sampleItemsModal) {
    $log.debug(`[${moduleName}] Controller "${controllerName}" launched`);

    const vm               = this;
    vm.ingredient          = ingredient;
    vm.grouping            = grouping;
    vm.questions           = questions;
    vm.additionalQuestions = additionalQuestions;

    vm.conflicts = {
      grouping:           false,
      facets:             {},
      answers:            {},
      answers_additional: {},

      resolved: {
        grouping:           false,
        facets:             {},
        answers:            {},
        answers_additional: {},
      },

      resolve: function (section, id = null) {
        if (section === 'grouping') {
          this.resolved.grouping = true;
        }

        if (section === 'facet') {
          this.resolved.facets[id] = true;
        }

        if (section === 'question') {
          this.resolved.answers[id] = true;
        }

        if (section === 'question_additional') {
          this.resolved.answers_additional[id] = true;
        }
      }
    };

    if (vm.ingredient.for_entry1) {
      vm.dataStore = 'entry1_data';
      vm.queue     = 'entry';
    } else if (vm.ingredient.for_entry2) {
      vm.dataStore = 'entry2_data';
      vm.queue     = 'entry';
      if (_.isNull(vm.ingredient.entry2_data)) {
        vm.ingredient.entry2_data = _.cloneDeep(vm.ingredient.entry1_data);
      }
    } else if (vm.ingredient.for_conflict_resolution) {
      vm.dataStore = 'conflict_data';
      vm.queue     = 'conflict_resolution';
    }

    const dataStoreDefaults = {
      grouping:           null,
      facets:             {},
      answers:            {},
      answers_additional: {}
    };

    // prepare earlier data for added functionality
    ['entry1_data', 'entry2_data', 'conflict_data'].forEach((dataStore) => {
      if (vm.ingredient[dataStore]) {
        _.forEach(dataStoreDefaults, (value, key) => {
          if (_.isUndefined(vm.ingredient[dataStore][key])) {
            vm.ingredient[dataStore][key] = _.clone(value);
          }
        })
      }
    });

    if (!vm.ingredient[vm.dataStore]) {
      vm.ingredient[vm.dataStore] = dataStoreDefaults;

      // setting up conflict resolution interface
      if (vm.queue === 'conflict_resolution') {
        if (vm.ingredient.entry1_data.grouping === vm.ingredient.entry2_data.grouping) {
          vm.ingredient.conflict_data.grouping = vm.ingredient.entry1_data.grouping;

          if (_.isEqual(vm.ingredient.entry1_data.facets, vm.ingredient.entry2_data.facets)) {
            vm.ingredient.conflict_data.facets = _.cloneDeep(vm.ingredient.entry1_data.facets);
          } else {
            let group = _.find(vm.grouping, {id: vm.ingredient.conflict_data.grouping});
            _.forEach(group.facets, facet => {
              if (facet.type === 'boolean') {
                if (vm.ingredient.entry1_data.facets[facet.name] === vm.ingredient.entry2_data.facets[facet.name]) {
                  vm.ingredient.conflict_data.facets[facet.name] = vm.ingredient.entry1_data.facets[facet.name];
                } else {
                  vm.ingredient.conflict_data.facets[facet.name] = null;
                  vm.conflicts.facets[facet.name]                = true;
                }
              }
            });
          }
        } else {
          vm.conflicts.grouping = true;
        }

        if (_.isEqual(vm.ingredient.entry1_data.answers, vm.ingredient.entry2_data.answers)) {
          vm.ingredient.conflict_data.answers = _.cloneDeep(vm.ingredient.entry1_data.answers);
        } else {
          _.forEach(vm.questions, question => {
            if (_.isEqual(vm.ingredient.entry1_data.answers[question.id], vm.ingredient.entry2_data.answers[question.id])) {
              vm.ingredient.conflict_data.answers[question.id] = vm.ingredient.entry1_data.answers[question.id];
            } else {
              vm.conflicts.answers[question.id] = true;
            }
          });
        }

        if (_.isEqual(vm.ingredient.entry1_data.answers_additional, vm.ingredient.entry2_data.answers_additional)) {
          vm.ingredient.conflict_data.answers_additional = _.cloneDeep(vm.ingredient.entry1_data.answers_additional);
        } else {
          _.forEach(additionalQuestions, question => {
            if (_.isEqual(vm.ingredient.entry1_data.answers_additional[question.id], vm.ingredient.entry2_data.answers_additional[question.id])) {
              vm.ingredient.conflict_data.answers_additional[question.id] = vm.ingredient.entry1_data.answers_additional[question.id];
            } else {
              vm.conflicts.answers_additional[question.id] = true;
            }
          });
        }
      }
    }

    vm.data = vm.ingredient[vm.dataStore];

    vm.expandAdditionalQuestions = _.keys(vm.data.answers_additional).length;

    if ('data.grouping') {
      vm.group = _.find(vm.grouping, {id: vm.data.grouping});
    }

    vm.submitIngredient = (next = true) => {
      vm.ingredient.flagged_type    = null;
      vm.ingredient.flagged_comment = null;

      return vm.saveIngredient(next);
    };

    vm.saveIngredient = (next = true) => {
      vm.saveIngredient.$busy = true;

      return $http.put(
        `/api/ingredient-learning/dual-entry/ingredients/${$state.params.id}`,
        _.pick(vm.ingredient, [
          vm.dataStore,
          'for_entry1', 'for_entry2', 'for_conflict_resolution',
          'flagged_type', 'flagged_comment'
        ])
      )
        .then(response => {
          angular.merge(vm.ingredient, response.data);
          $notification.info('Ingredient updated');

          if (next) {
            vm.pullQueue();
          } else {
            vm.goBack();
          }

        })
        .catch(response => $notification.error(
          'Failed to update ingredient: ' + $filter('readError')(response.data)
        ))
        .finally(() => vm.saveIngredient.$busy = false);
    };

    vm.cancel = () => {
      vm.saveIngredient.$busy = true;
      return $http.put(
        `/api/ingredient-learning/dual-entry/ingredients/${$state.params.id}`,
        _.pick(vm.ingredient, ['for_entry1', 'for_entry2', 'for_conflict_resolution']), {params: {cancel: true}}
        )
        .then(() => {
          $notification.info('Entry has been cancelled');
          vm.goBack();
        })
        .catch(response => $notification.error(
          'Failed to update ingredient: ' + $filter('readError')(response.data)
        ))
        .finally(() => vm.saveIngredient.$busy = false);
    };

    vm.showSampleItems = () => sampleItemsModal(vm.ingredient);

    vm.flagIngredient = () => {
      const modalInstance = $modal.open({
        size:        'md',
        animation:   false,
        templateUrl: 'modules/ingredient-learning/dual-entry/dual-entry.edit-ingredient.flag.modal.html',
        controller:  function ($scope, $modalInstance, parentVm, ingredientFlagReasons) {
          const vm = $scope.vm = this;

          vm.reasons = ingredientFlagReasons;

          vm.ingredient    = parentVm.ingredient;
          vm.unflagEnabled = vm.ingredient.flagged_type;

          vm.save = () => {
            vm.save.$busy = true;

            parentVm.saveIngredient()
              .then(() => $modalInstance.close(vm.ingredient))
              .finally(() => vm.save.$busy = false);
          };

          vm.unflag = () => {
            _.forEach(ingredient, (value, key) => {
              if (key.substr(0, 8) === 'flagged_') {
                ingredient[key] = null;
              }
            });

            vm.save();
          };

          vm.close = () => {
            $modalInstance.dismiss();
          };
        },
        resolve:     {
          parentVm: () => vm
        }
      });

      modalInstance.result.then(ingredient => {
        if (ingredient.flagged_type) {
          vm.pullQueue();
        }
      });
    };

    vm.goBack = () => {
      if ($sessionStorage.ingredientLearningQueueState) {
        $location.url($sessionStorage.ingredientLearningQueueState);
      } else {
        $state.go('app.ingredient-learning.dual-entry.queue');
      }
    };

    vm.skipIngredient = () => {
      confirm('Are you sure you want to skip this ingredient?')
        .then(() => {
          vm.ingredient.flagged_type = 'skipped';
          vm.saveIngredient();
        });
    };

    vm.setGroup = group => {
      vm.group         = group;
      vm.data.grouping = group.id;
      vm.data.facets   = {};
      group.facets.forEach(facet => {
        if (facet.type === 'boolean') {
          vm.data.facets[facet.name] = null;
        }
      });
    };

    vm.answerAll = (value) => {
      _.forEach(vm.questions, question => {
        vm.data.answers[question.id] = value;

        vm.conflicts.resolve('question', question.id);
      });
    };

    vm.answerAllAdditional = (value) => {
      _.forEach(vm.additionalQuestions, question => {
        vm.data.answers_additional[question.id] = value;

        vm.conflicts.resolve('question_additional', question.id);
      });
    };

    vm.displayConflict = function (section, id = null) {
      const displayData = {
        title:  null,
        entry1: {
          user: vm.ingredient.entry1_user_id
        },
        entry2: {
          user: vm.ingredient.entry2_user_id
        }
      };

      let accept;

      if (section === 'grouping') {
        displayData.title        = 'Grouping';
        displayData.entry1.value = vm.ingredient.entry1_data.grouping;
        displayData.entry1.label = _.find(vm.grouping, {id: vm.ingredient.entry1_data.grouping}).name;

        displayData.entry2.value = vm.ingredient.entry2_data.grouping;
        displayData.entry2.label = _.find(vm.grouping, {id: vm.ingredient.entry2_data.grouping}).name;

        accept = (value) => vm.setGroup(_.find(vm.grouping, {id: value}));
      } else if (section === 'facet') {
        displayData.title = 'Facet: ' + id;

        displayData.entry1.value = vm.ingredient.entry1_data.facets[id];
        if (displayData.entry1.value === true) {
          displayData.entry1.label = 'True';
        } else if (displayData.entry1.value === false) {
          displayData.entry1.label = 'False';
        } else {
          displayData.entry1.label = 'NULL';
        }


        displayData.entry2.value = vm.ingredient.entry2_data.facets[id];
        if (displayData.entry2.value === true) {
          displayData.entry2.label = 'True';
        } else if (displayData.entry2.value === false) {
          displayData.entry2.label = 'False';
        } else {
          displayData.entry2.label = 'NULL';
        }

        accept = (value) => {
          vm.data.facets[id] = value;
        };
      } else if (section === 'question') {
        const question    = _.find(vm.questions, {id});
        displayData.title = question.text;

        displayData.entry1.value = vm.ingredient.entry1_data.answers[question.id];
        if (displayData.entry1.value === true) {
          displayData.entry1.label = 'Yes';
        } else if (displayData.entry1.value === false) {
          displayData.entry1.label = 'No';
        } else {
          displayData.entry1.label = 'Unsure';
        }


        displayData.entry2.value = vm.ingredient.entry2_data.answers[question.id];
        if (displayData.entry2.value === true) {
          displayData.entry2.label = 'Yes';
        } else if (displayData.entry2.value === false) {
          displayData.entry2.label = 'No';
        } else {
          displayData.entry2.label = 'Unsure';
        }

        accept = (value) => {
          vm.data.answers[question.id] = value;
        };
      } else if (section === 'question_additional') {
        const question    = _.find(additionalQuestions, {id});
        displayData.title = question.text;

        displayData.entry1.value = vm.ingredient.entry1_data.answers_additional[question.id];
        if (displayData.entry1.value === true) {
          displayData.entry1.label = 'Yes';
        } else if (displayData.entry1.value === false) {
          displayData.entry1.label = 'No';
        } else {
          displayData.entry1.label = 'Unsure';
        }


        displayData.entry2.value = vm.ingredient.entry2_data.answers_additional[question.id];
        if (displayData.entry2.value === true) {
          displayData.entry2.label = 'Yes';
        } else if (displayData.entry2.value === false) {
          displayData.entry2.label = 'No';
        } else {
          displayData.entry2.label = 'Unsure';
        }

        accept = (value) => {
          vm.data.answers_additional[question.id] = value;
        };
      }

      const modalInstance = $modal.open({
        size:        'md',
        animation:   false,
        templateUrl: 'modules/ingredient-learning/dual-entry/dual-entry.edit-ingredient.display-conflict.modal.html',
        controller:  function ($scope, $modalInstance) {
          const vm = $scope.vm = this;

          vm.displayData = displayData;

          vm.accept = (value) => {
            $modalInstance.close(value);
          };

          vm.close = () => {
            $modalInstance.dismiss();
          };
        }
      });

      modalInstance.result.then(value => {
        accept(value);
        vm.conflicts.resolve(section, id);
      });
    };

    vm.pullQueue = () => {
      $http.get('/api/ingredient-learning/dual-entry/queue/pull', {params: {queue: vm.queue}})
        .then(response => {
          const ingredient = response.data;

          $state.go($state.current.name, {id: ingredient.id});
        })
        .catch(response => {
          if (response.status === 404) {
            $notification.info('Queue is empty');
          } else {
            $notification.error('Failed to pull the queue: ' + $filter('readError')(response.data));
          }

          vm.goBack();
        });
    };

    $scope.$watchCollection('vm.data.answers', () => {
      const filteredAdditionalQuestions = [];

      const data = vm.data;

      additionalQuestions.forEach((question) => {
        if (
          !question.linked_question || data.answers[question.linked_question] ||
          (vm.ingredient.for_conflict_resolution && (
            !_.isUndefined(vm.ingredient.entry1_data.answers_additional[question.id]) ||
            !_.isUndefined(vm.ingredient.entry2_data.answers_additional[question.id])
          ))
        ) {
          filteredAdditionalQuestions.push(question);
        }
      });

      _.keys(data.answers_additional).forEach((questionId) => {
        if (!_.find(filteredAdditionalQuestions, {id: +questionId})) {
          delete data.answers_additional[questionId];
        }
      });

      vm.additionalQuestions       = filteredAdditionalQuestions;
      vm.expandAdditionalQuestions = filteredAdditionalQuestions.length;
    });
  }
}());
