(function () {
  'use strict';

  angular.module('app.controllers')
    .controller('TasksIngredientStatementCtrl', TasksIngredientStatementCtrl)
    .directive('reviewInput', reviewInputFactory);

  /*@ngInject*/
  function TasksIngredientStatementCtrl($scope, $state, $http, $notification, $q, flagTask, cdnify) {
    const taskVm = $scope.taskVm = this;

    async function init() {
      const task     = await $scope.init(10, {file_group: 3});
      const layoutVm = $scope.vm;

      layoutVm.title = 'Ingredient Statement Resolution';

      if (!task) {return;}

      const {image, text} = task.meta;

      layoutVm.debugInfo.push({
        name:   'Current Image',
        href:   image,
        target: '_blank'
      });

      taskVm.image = cdnify(image);
      taskVm.text  = task.entry1 || text
      taskVm.finalIngredientPreview  = taskVm.text.replace(/\n/g, ' ');

    }

    taskVm.$busy = null;
    taskVm.hasBeenReviewed = false;

    taskVm.flag = async function flag() {
      taskVm.$busy = 'flagging';

      try {
        await flagTask($scope.vm.currentTask, {}, flagTask.scenarios.INGREDIENT_STATEMENT)
        await init();
      } catch (e) {} finally {
        taskVm.$busy = null;
      }
    }

    taskVm.submit = async function submit() {
      taskVm.$busy = 'submitting';

      try {
        await $http.post('/api/tasks/submit-ingredient-statement/', {
          task_id: $scope.vm.currentTask.id,
          text:    taskVm.finalIngredientPreview,
        });
        $notification.info('Saved')
      } catch (e) {
        $notification.error('Not saved')
      } finally {
        taskVm.$busy = null;

        await init();
      }
    };

    taskVm.missingIngredients     = [];
    taskVm.hasBalancedParentheses = true;
    taskVm.review                 = async function review() {
      taskVm.missingIngredients     = [];
      taskVm.hasBalancedParentheses = true;

      const {data} = await $http.post('/api/tasks/ingredient-statement-manual-review', {text: taskVm.text})

      taskVm.missingIngredients     = data.missingIngredients;
      taskVm.hasBalancedParentheses = data.hasBalancedParentheses;

      taskVm.hasBeenReviewed = true;
    };

    init();
  }

  /*@ngInject*/
  function reviewInputFactory($sce) {
    return {
      restrict: 'A',
      require:  '?ngModel',
      scope:    {
        'missingIngredients': '=',
      },
      link:     function reviewInput(scope, element, attrs, ngModel) {
        if (!ngModel) return;

        scope.$watch('missingIngredients', () => ngModel.$render())

        ngModel.$render = function $render() {
          let viewValue = ngModel.$viewValue;

          if (viewValue) {
            for (const missing of scope.missingIngredients || []) {
              const regexString = '(' + missing.replace(/\s+/g, '\\s+') + ')'

              const regex = new RegExp(regexString, 'i');

              viewValue = viewValue.replace(regex, '<span class="warning">$1</span>');
            }
          }

          element.html($sce.getTrustedHtml(viewValue || ''));
        };

        element.on('blur keyup change', () => {
          scope.$evalAsync(() => {
            ngModel.$setViewValue(element[0].innerText.replace(/\n+/g, '\n'));
          });
        });
      }
    }
  }
}());
