(function () {
  'use strict';

  const moduleName     = 'email-templates',
        controllerName = 'SaveCtrl';

  const moduleFullName = `app.modules.${moduleName}`;

  angular.module(moduleFullName)
    .controller(`${moduleFullName}.${controllerName}`, SaveCtrl);

  /*@ngInject*/
  function SaveCtrl($log, $state, $http, $notification, $q, $upload, $filter, confirm) {
    $log.debug(`[${moduleName}] Controller "${controllerName}" launched`);

    const vm = this;
    const url = '/api/email-templates' + ($state.params.id ? '/' + $state.params.id : '');

    vm.attachment = {
      file:          null,
      clearUpload:   function () {
        this.file = null;
      },
      clearExisting: function () {
        confirm('Are you sure you want to remove the attachment?')
          .then(() => {
            vm.model.attachment = null;

            return $http.post(url, {attachment: null});
          })
          .then(response => {
            _.extend(vm.model, response.data);
            $notification.info('Attachment removed');
          })
      }
    };

    let flow;

    if ($state.params.id) {
      flow = $http.get(`/api/email-templates/${$state.params.id}`)
        .then(response => response.data);
    } else {
      flow = $q.resolve({
        alias:      '',
        name:       '',
        subject:    '',
        body:       '',
        bcc:        null,
        attachment: null
      });
    }

    flow.then(model => {
      vm.model = model;
    });

    vm.save = () => {
      vm.$busy = true;
      vm.$error = null;

      let flow;

      if (vm.attachment.file) {
        flow = $upload
          .upload({
            url:              url,
            file:             vm.attachment.file,
            fileFormDataName: ['attachmentFile'],
            fields:           vm.model
          })
      } else {
        flow = $http.post(url, vm.model);
      }

      flow
        .then(response => {
          $notification.info('Email Template Saved');
          vm.attachment.clearUpload();

          _.extend(vm.model, response.data);

          if (!$state.params.id) {
            $state.go('app.email-templates.edit', {id: response.data.id})
          }
        })
        .catch(response => vm.$error = $filter('readError')(response.data))
        .finally(() => vm.$busy = false);
    };

    vm.delete = () => {
      vm.$busy = true;
      vm.$error = null;

      confirm('Are you sure you want to delete this email template?')
        .then(() => {
          return $http.delete(url)
        })
        .then(() => {
          $notification.info('Email Template Deleted');
          $state.go('app.email-templates.list')
        })
        .catch(response => vm.$error = $filter('readError')(response.data))
        .finally(() => vm.$busy = false);
    }
  }
}());
