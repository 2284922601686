(function () {
  'use strict';

  const moduleName     = 'ingredient-learning.dual-entry',
        controllerName = 'QueueCtrl';

  const moduleFullName = `app.modules.${moduleName}`;

  angular.module(moduleFullName)
    .controller(`${moduleFullName}.${controllerName}`, QueueCtrl);

  /*@ngInject*/
  function QueueCtrl($log, $http, $notification,
                     $filter, ngTableParams, $location,
                     $state, $sessionStorage, session, moment) {
    $log.debug(`[${moduleName}] Controller "${controllerName}" launched`);

    const vm = this;

    vm.pullQueue = (queue, ingredient = null) => {
      $http.get('/api/ingredient-learning/dual-entry/queue/pull', {
        params: {
          queue,
          ingredientId: ingredient && ingredient.id,
          orderBy:      (_.pairs(vm.tableParams.sorting())[0] || ['counts', 'desc']).join('.')
        }
      })
        .then(response => {
          const ingredient = response.data;

          $sessionStorage.ingredientLearningQueueState = $location.url();
          $state.go('app.ingredient-learning.dual-entry.edit-ingredient', {id: ingredient.id});
        })
        .catch(response => {
          if (response.status === 404) {
            if (ingredient) {
              $notification.info('Could not enter this ingredient. It might have been taken by another user');
              vm.tableParams.reload();
            } else {
              $notification.info('Queue is empty');
            }

          } else {
            $notification.error('Failed to pull the queue: ' + $filter('readError')(response.data));
          }
        });
    };


    vm.search = {
      term:    $location.search().q || '',
      perform: function () {
        vm.tableParams.page(1);
        vm.tableParams.reload();
      }
    };

    vm.getStatus = ingredient => {
      if (ingredient.conflict_started_at && moment().diff(ingredient.conflict_started_at, 'hours') < 24) {
        if (ingredient.conflict_user_id === session.profile.id) {
          ingredient.$queue = 'conflict_resolution';
        }

        return 'conflict resolution in progress by ' +
          $filter('username')($filter('user')(ingredient.conflict_user_id));
      }

      if (ingredient.dual_entry_has_conflict) {
        ingredient.$queue = 'conflict_resolution';
        return 'pending conflict resolution';
      }

      if (ingredient.entry2_started_at && moment().diff(ingredient.entry2_started_at, 'hours') < 24) {
        if (ingredient.entry2_user_id === session.profile.id) {
          ingredient.$queue = 'entry';
        }

        return 'second entry in progress by ' +
          $filter('username')($filter('user')(ingredient.entry2_user_id));
      }

      if (ingredient.entry1_completed_at) {
        if (ingredient.entry1_user_id === session.profile.id) {
          return 'pending the second entry by another user';
        }

        ingredient.$queue = 'entry';
        return 'pending the second entry';
      }

      if (ingredient.entry1_started_at && moment().diff(ingredient.entry1_started_at, 'hours') < 24) {
        if (ingredient.entry1_user_id === session.profile.id) {
          ingredient.$queue = 'entry';
        }

        return 'first entry in progress by ' +
          $filter('username')($filter('user')(ingredient.entry1_user_id));
      }

      ingredient.$queue = 'entry';
      return 'pending the first entry';
    };


    const defaultOrder = 'counts.desc';
    vm.tableParams     = new ngTableParams(
      {
        page:    $location.search().page || 1,            // show first page
        count:   10,           // count per page
        sorting: (function () {
          let orderBy = $location.search().orderBy || defaultOrder;
          if (orderBy) {
            orderBy = orderBy.split('.');

            let result         = {};
            result[orderBy[0]] = orderBy[1];

            return result;
          }
        }())
      },
      {
        counts:  [],
        total:   0, // length of data
        getData: function ($defer, params) {
          $location.search('page', params.page() > 1 ? params.page() : null);

          $location.search('q', vm.search.term || null);

          let orderBy = null;
          if (_.keys(params.sorting()).length) {
            let [sortingAttribute, sortingDirection] = _.pairs(params.sorting())[0];
            orderBy                                  = `${sortingAttribute}.${sortingDirection}`;
          }

          $location.search('orderBy', orderBy === defaultOrder ? null : orderBy);


          $http.get('/api/ingredient-learning/dual-entry/queue/list', {
            params: {
              limit:           params.count(),
              offset:          (params.page() - 1) * params.count(),
              q:               vm.search.term || undefined,
              display_flagged: vm.search.display_flagged,
              orderBy:         orderBy
            }
          })
            .then(response => {
              params.total(response.data.total);
              $defer.resolve(response.data.ingredients);

            })
            .catch(response => {
              $notification.error(
                'Failed to load ingredients: ' + $filter('readError')(response.data)
              );

              $defer.reject();
            });
        }
      }
    );

    vm.stats = {
      for_entry:               0,
      for_entry1:              0,
      for_entry2:              0,
      for_conflict_resolution: 0
    };

    vm.getStats = () => {
      $http.get('/api/ingredient-learning/dual-entry/queue/stats')
        .then(response => {
          vm.stats           = response.data;
          vm.stats.for_entry = vm.stats.for_entry1 + vm.stats.for_entry2;
        });
    };

    vm.getStats();
  }
}());
