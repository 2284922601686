'use strict';

angular.module('app.controllers')
    .controller('TagsExerciseEditCtrl',
        function ($scope, $state, ngTableParams, debounce, $filter,
                  $notification, $http, generateThumb, $upload, navigationGuard, confirm, tag, phrases, $modal, suggest) {
            const url = `/api/tags-exercise/${tag.id}`;
            const vm = $scope.vm = this;

            vm.tag = tag;
            vm.phrases = phrases;

            vm.$km_min_null = vm.tag.km_min === null;
            vm.$rep_sec_null = vm.tag.rep_sec === null;
            vm.$default_duration_null = vm.tag.default_duration === null;

            navigationGuard.watch(
                () => !vm.photo.file,
                'You haven\'t saved the image. Are you sure you want to leave the page? Press "Save" button to finish upload'
            );

            navigationGuard.watch(
                () => vm.tagForm.$pristine,
                'You have unsaved changes. Are you sure you want to leave the page?'
            );

            vm.photo = {
                thumbnail:         null,
                file:              null,
                generateThumbnail: function () {
                    if (this.file) {
                        generateThumb(this.file[0])
                            .then(thumbnail => {this.thumbnail = thumbnail});
                    }
                },
                clearUpload:       function () {
                    this.file = null;
                    this.thumbnail = null;
                },
                clearExisting:     function () {
                    confirm('Are you sure you want tot remove this tag\'s photo?')
                        .then(() => {
                            vm.tag.image = null;
                            vm.tag.admin_image = null;

                            return $http.post(`/api/tags-exercise/${vm.tag.id}`, _.pick(vm.tag, ['image', 'admin_image']));
                        })
                        .then(() => {
                            $notification.info('Tag image removed');
                        })
                }
            };

            vm.refreshPhoto = () => {
                vm.refreshPhoto.tag = Date.now();
            };

            vm.refreshPhoto();

            $scope.addPhrase = function () {
                $scope.error = null;

                $http.post(`${url}/phrases`, {text: $scope.addPhrase.text})
                    .success(function (phrase) {
                        if ($filter('filter')(vm.phrases, {id: phrase.id}).length === 0) {
                            vm.phrases.push(phrase);
                        }
                        $scope.addPhrase.text = null;
                        $scope.error = null;
                    })
                    .error(function (error) {
                        error = error.errors && error.errors[0] || error;
                        if (error && ['duplicate-assignment', 'hidden-phrase'].indexOf(error.type) > -1) {
                            $scope.error = error;
                        } else {
                            $scope.error = {
                                type:    'generic',
                                message: error && $.type(error) === 'string' ?
                                             error :
                                             'Unexpected backend error'
                            };
                        }
                    });
            };

            $scope.detachPhrase = function (phrase) {
                $http.post(`${url}/phrases/${phrase.id}`, {tag_id: null})
                    .then(function () {
                        var index = vm.phrases.indexOf(phrase);
                        if (index !== -1) {
                            vm.phrases.splice(index, 1);
                        }

                        $scope.error = null;
                    })
                    .catch(function (/* error */) {
                        $scope.error = {
                            type:    'generic',
                            message: 'Unexpected backend error'
                        };
                    });
            };

            $scope.markPhraseTypo = function (phrase) {
                $http.post(`${url}/phrases/${phrase.id}`, {is_typo: phrase.is_typo})
                    .then(function () {
                        let action = phrase.is_typo ? 'marked' : 'unmarked';
                        $notification.info(`Phrase ${action} as typo`);
                    })
                    .catch(function (/* error */) {
                        $notification.error('Phrase typo status update failed');
                    });
            };

            $scope.save = function () {
                $scope.save.$busy = true;
                $scope.save.error = false;

                let flow;

                if (vm.photo.file) {
                    flow = $upload
                        .upload({
                            url:              url,
                            file:             vm.photo.file,
                            fileFormDataName: ['photo'],
                            fields:           vm.tag
                        })
                } else {
                    flow = $http.post(`/api/tags-exercise/${vm.tag.id}`, vm.tag);
                }

                flow.then(response => response.data)
                    .then(function (updatedTag) {
                        vm.refreshPhoto();

                        vm.tagForm.$setPristine(true);
                        _.forEach(vm.tagForm.$error, function (inputs, validation) {
                            _.forEach(inputs, function (input) {
                                input.$setValidity(validation, true);
                            });
                        });

                        updatedTag.phrases = vm.phrases;

                        vm.tag = updatedTag;
                        vm.$km_min_null = vm.tag.km_min === null;
                        vm.$rep_sec_null = vm.tag.rep_sec === null;
                        vm.$default_duration_null = vm.tag.default_duration === null;

                        vm.photo.clearUpload();

                        $notification.info('Tag Saved');
                    })
                    .catch(function (response) {
                        var errors = response.data && response.data.errors ||
                            [{
                                type:    'generic',
                                message: 'Unexpected backend error'
                            }];

                        _.forEach(errors, function (error) {
                            if (error.type === 'validation') {
                                vm.tagForm[error.field].$setValidity(error.validationType, false);
                            }
                        });

                        $scope.save.error = {
                            message: _.pluck(errors, 'message').join('; ')
                        };
                    })
                    .finally(function () {
                        $scope.save.$busy = false;
                    });

            };

            vm.copyTag = function () {
                return $modal.open({
                    size:        'md',
                    templateUrl: '/views/tags-exercise/copy.html',
                    controller:  function ($scope, $modalInstance, tag) {
                        $scope.tag = tag;

                        $scope.close = function () {
                            $modalInstance.dismiss('cancel');
                        };

                        $scope.save = function () {
                            $scope.save.$busy = true;
                            $http.post('/api/tags-exercise', $scope.tag)
                                .success(function (createdTag) {
                                    $notification.info('Exercise Tag Created');
                                    $state.go('app.tags-exercise.edit', {id: createdTag.id});
                                    $modalInstance.close(createdTag);
                                })
                                .error(function () {
                                    $notification.error('Tag Not Created');
                                })
                                .finally(()=> {
                                    $scope.save.$busy = false;
                                });
                        };
                    },
                    resolve:     {
                        tag: function () {
                            return _.merge(
                                {name: ''},
                                _.pick(vm.tag, [
                                    'compendium_code', 'met', 'admin_image',
                                    'image', 'image_highres', 'image_original_stats',
                                    'image_license_type', 'image_attribution_url', 'image_creative_commons_user_name'
                                ])
                            );
                        }
                    }
                })
            };

            vm.movePhrases = function () {
                $modal.open({
                    size:        'md',
                    templateUrl: '/views/tags-exercise/move-phrases.html',
                    controller:  function ($scope, $modalInstance) {
                        const vm = $scope.vm = this;

                        vm.phrases = phrases;

                        vm.suggestTags       = search => suggest.tags_exercise(search)
                            .then(tags => tags.filter(t => t.id !== tag.id));
                        vm.setDestinationTag = function (tag) {
                            vm.destinationTag = tag;
                        };

                        vm.close = function () {
                            $modalInstance.dismiss('cancel');
                        };

                        vm.save = function () {
                            vm.save.$busy = true;

                            let transferPhrases = vm.phrases
                                .filter(phrase => phrase.$transfer)
                                .map(phrase => phrase.id);

                            $http.post(`/api/tags-exercise/${vm.destinationTag.id}/phrases/move`, {phrases: transferPhrases})
                                .success(function (affectedRows) {
                                    console.log(vm.destinationTag.id);

                                    $notification.info(`Moved ${affectedRows} phrases to tag ${vm.destinationTag.name}`);
                                    $state.go('app.tags-exercise.edit', {id: vm.destinationTag.id});
                                    $modalInstance.close();
                                })
                                .error(function (error) {
                                    $notification.error('Phrases not moved: ' + $filter('readError')(error));
                                })
                                .finally(() => {
                                    vm.save.$busy = false;
                                });
                        };
                    }
                })
            };
        }
    );
