'use strict';

angular.module('app.controllers')
    .controller('TagsExerciseListCtrl',
        function ($scope, $state, ngTableParams, $location, $http, $notification) {
            const vm = $scope.vm = this;

            function navigate() {
                $location.search({
                    q:    vm.search.term || null,
                    page: vm.tableParams.page() > 1 ? vm.tableParams.page() : null
                });
            }

            vm.search = {
                term:    $location.search().q || '',
                perform: function () {
                    navigate();
                    vm.tableParams.page(1);
                    vm.tableParams.reload();
                },
                clear:   function () {
                    this.term = '';
                    this.perform();
                }
            };

            vm.tableParams = new ngTableParams(
                {
                    page:    $location.search().page || 1,            // show first page
                    count:   25,           // count per page
                    sorting: {
                        id: 'asc'     // initial sorting
                    }
                },
                {
                    counts:  [],
                    total:   0, // length of data
                    getData: function getData($defer, params) {
                        navigate();

                        if (!getData.initialSkip) {
                            getData.initialSkip = true;
                            if (!vm.search.term) {
                                $defer.resolve([]);
                                return;
                            }
                        }

                        $http.get('/api/tags-exercise', {
                                params: {
                                    limit:   params.count(),
                                    offset:  (params.page() - 1) * params.count(),
                                    search:  vm.search.term || undefined,
                                    orderBy: _.pairs(params.sorting())[0].join('.')
                                }
                            })
                            .success(tagsData => {
                                params.total(tagsData.count);
                                $defer.resolve(tagsData.results);
                            })
                            .error(error => {
                                $notification.error('Could not load exercise tags: ' + JSON.stringify(error));
                            });
                    }
                }
            );
        }
    );
