(function () {
  'use strict';

  angular
    .module('app.filters')
    .filter('fdaRound', function fdaRoundFilterFactory() {
      /**
       * Class FDARounder
       * @link http://www.fda.gov/Food/GuidanceRegulation/GuidanceDocumentsRegulatoryInformation/LabelingNutrition/ucm064932.htm
       * @link https://edgenetteam.atlassian.net/browse/WELL-826
       */
      const FDARounder = {
        round(data, attribute) {
          return data[attribute] ? this["round_" + attribute](data[attribute]) : 0;
        },

        roundAs(value, attribute) {
          return this["round_" + attribute](value);
        },

        /**
         * Calories
         * - Round to 0 if <5 calories
         * - Round to nearest 5 if <=50 and >=5 calories
         * - Round to nearest 10 if >50 calories
         *
         * @param value
         * @return number
         */
        round1(value) {
          if (value < 5) {
            return 0;
          }

          if (value <= 50) {
            return this.roundToNearestIncrement(value, 5);
          }

          return this.roundToNearestIncrement(value, 10);
        },

        /**
         * @param value
         * @return number
         */
        round_calories(value) {
          return this.round1(value);
        },

        round_fat_calories(value) {
          return this.round1(value);
        },

        // ==================================================

        /**
         Total Fat, Saturated Fat, Trans Fat, Polyunsaturated Fat, and Monounsaturated Fat

         * - Round to 0 if <0.5g
         * - Round to nearest 0.5 if <5g and >=0.5g
         * - Round to nearest 1g if >=5g
         *
         * @param {number} value
         * @return number
         */
        round2(value) {
          if (value < 0.5) {
            return 0;
          }

          if (value < 5) {
            return this.roundToNearestIncrement(value, 0.5);
          }

          return this.roundToNearestIncrement(value, 1);
        },

        round_total_fat(value) {
          return this.round2(value);
        },

        round_saturated_fat(value) {
          return this.round2(value);
        },

        round_trans_fat(value) {
          return this.round2(value);
        },

        round_polyunsaturated_fat(value) {
          return this.round2(value);
        },

        round_monounsaturated_fat(value) {
          return this.round2(value);
        },

        // ==================================================

        /**
         * Cholesterol
         * - Round to 0 if <2mg
         * - Round to 2.5mg if 2-5mg
         * - Round to nearest 5mg if >5mg
         *
         * @param {number} value
         * @return number
         */
        round_cholesterol(value) {
          if (value < 2) {
            return 0;
          }

          if (value <= 5) {
            return 2.5;
          }

          return this.roundToNearestIncrement(value, 5);
        },

        // ==================================================

        /**
         * Sodium
         * - Round to 0 if <5mg
         * - Round to nearest 5mg if 5-140mg
         * - Round to nearest 10mg if >140mg

         * @param {number} value
         * @return {number}
         */
        round_sodium(value) {
          if (value < 5) {
            return 0;
          }

          if (value <= 140) {
            return this.roundToNearestIncrement(value, 5);
          }

          return this.roundToNearestIncrement(value, 10);
        },

        // ==================================================

        /**
         * Total Carb, Fiber, Sugar, Added Sugar, and Protein
         * - Round to 0 if <0.5g
         * - Round to 0.5g if <1g and >=0.5g
         * - Round to nearest 1 if >=1g
         *
         * @param {number} value
         * @return number
         */
        round4(value) {
          if (value < 0.5) {
            return 0;
          }

          if (value < 1) {
            return 0.5;
          }

          return this.roundToNearestIncrement(value, 1);
        },

        round_total_carb(value) {
          return this.round4(value);
        },

        round_fibers(value) {
          return this.round4(value);
        },

        round_sugars(value) {
          return this.round4(value);
        },

        round_added_sugars(value) {
          return this.round4(value);
        },

        round_protein(value) {
          return this.round4(value);
        },

        // ==================================================

        round_calcium(value) {
          return this.roundToNearestIncrement(value, 10);
        },

        round_potassium(value) {
          return this.roundToNearestIncrement(value, 10);
        },

        // ==================================================

        round_vitamin_d(value) {
          return this.roundToNearestIncrement(value, 0.1);
        },

        round_iron(value) {
          return this.roundToNearestIncrement(value, 0.1);
        },

        /**
         * @param {number} value
         * @param {number} increment
         * @return number
         */
        roundToNearestIncrement(value, increment) {
          return (increment < 0) ?
            Math.round(value * increment) / increment :
            Math.round(value / increment) * increment;
        }
      };

      /**
       * @param {number} value
       * @param {string} nutrient
       * @return number|null
       */
      return function fdaRoundFilter(value, nutrient) {
        if (!value && value !== 0) {
          return null;
        }

        return FDARounder.roundAs(value, nutrient);
      }
    });

}());
