'use strict';

angular.module('app.controllers')
    .controller('BrandsListCtrl',
      function ($scope, $state, Brand, ngTableParams, $location, $notification) {
        const vm = $scope.vm = this;
        vm.downloadInProgress = false;
            $scope.search = {
                term:    $location.search().q || '',
                filter:  {
                    type: $location.search().type || ''
                },
                perform: function () {
                    $scope.tableParams.page(1);
                    $scope.tableParams.reload();
                }
            };

            $scope.tableParams = new ngTableParams(
                {
                    page:    1,            // show first page
                    count:   25,           // count per page
                    sorting: (() => {
                        if ($location.search().sort) {
                            let parts = $location.search().sort.split('.');
                            let sort = {};

                            sort[parts[0]] = parts[1];

                            return sort;
                        }

                        return {name: 'asc'};
                    })()
                },
                {
                    counts:  [],
                    total:   0, // length of data
                    getData: function ($defer, params) {
                        $location.search('q', $scope.search.term || undefined);

                        $location.search('type', $scope.search.filter.type || undefined);

                        let sort = _.pairs(params.sorting())[0].join('.');

                        $location.search('sort', sort !== 'name.asc' ? sort : undefined);

                        Brand.list(
                            {
                                limit:   params.count(),
                                offset:  (params.page() - 1) * params.count(),
                                search:  $scope.search.term,
                                orderBy: sort,
                                filter:  $scope.search.filter
                            }
                        ).then(
                            function (data) {
                                params.total(data.count);
                                $defer.resolve(data.results);
                            }
                        );
                    }
                }
            );

        vm.exportBrandCsv = async () => {
          try {
            $notification.info('CSV Export in progress...');
            vm.downloadInProgress = true;
            const { data } = await Brand.exportBrandCsv();
            const blob = new Blob([data], { type: 'text/csv' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'All_Admin_Brands.csv';
            a.click();
            window.URL.revokeObjectURL(url);

            vm.downloadInProgress = false;
            $notification.info('CSV Exported Successfully');
          } catch (error) {
            console.error('Error exporting CSV:', error);
            vm.downloadInProgress = false;
            $notification.error('Error Exporting CSV: Please try again later');
          }
        }
        }
    );
