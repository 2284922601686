(function () {
  'use strict';

  const moduleName = 'brand-recipes';

  const moduleFullName = `app.modules.${moduleName}`;

  const state       = stateName => `app.${moduleName}` + (stateName ? `.${stateName}` : '');
  const templateUrl = viewName => `modules/${moduleName}/${moduleName}.${viewName}.html`;
  const controller  = (controllerName, as = 'vm') => `${moduleFullName}.${controllerName}` + (as ? ` as ${as}` : '');

  angular.module(moduleFullName, ['nutritionix.nutrition-label'])
    .config(function ($stateProvider) {
      $stateProvider
        .state(state(), {
          url:      `/${moduleName}`,
          abstract: true,
          template: `<div class="module_${moduleName}" ui-view></div>`,
          data:     {
            permissions: ['admin']
          },
          resolve:  {
            utils: () => ({state, templateUrl, controller})
          }
        })
        .state(state('brand'), {
          url:         '',
          controller:  controller('BrandCtrl'),
          templateUrl: templateUrl('brand'),
          onEnter:     function ($anchorScroll) {
            $anchorScroll();
          }
        })
        .state(state('brand.recipe'), {
          url:        '/:brand',
          template:   '<div ui-view></div>',
          controller: function ($scope, brand) {
            $scope.brand = brand;
          },
          abstract:   true,
          resolve:    {
            brand: ($http, $stateParams, $notification, $filter, $q, $state, $timeout) => {
              if ($stateParams.brand) {
                return $http.get(`/api/brand-recipes/brands/${$stateParams.brand}`)
                  .then(response => response.data)
                  .catch(response => {
                    $notification.error('Could not load brand: ' + $filter('readError')(response.data));
                    return $q.reject(response);
                  });
              }

              $timeout(() => $state.go(state('brand')));
              return $q.reject(null);
            }
          }
        })
        .state(state('brand.recipe.list'), {
          url:         '',
          controller:  controller('RecipeListCtrl'),
          templateUrl: templateUrl('recipe.list'),
          onEnter:     function ($anchorScroll) {
            $anchorScroll();
          }
        })
        .state(state('brand.recipe.create'), {
          url:         '/recipes/create',
          controller:  controller('RecipeSaveCtrl'),
          templateUrl: templateUrl('recipe.save'),
          onEnter:     function ($anchorScroll) {
            $anchorScroll();
          }
        })
        .state(state('brand.recipe.edit'), {
          url:         '/recipes/:recipeId',
          controller:  controller('RecipeSaveCtrl'),
          templateUrl: templateUrl('recipe.save'),
          onEnter:     function ($anchorScroll) {
            $anchorScroll();
          }
        })
        .state(state('brand.recipe.edit.ingredients-list'), {
          // controller:  controller('RecipeIngredientsListCtrl', 'ivm'),
          templateUrl: templateUrl('recipe.save.ingredients-list')
        })
        .state(state('brand.recipe.edit.ingredients-create'), {
          url:         '/ingredients/add',
          controller:  controller('RecipeIngredientsSaveCtrl', 'ivm'),
          templateUrl: templateUrl('recipe.save.ingredients-save'),
        })
        .state(state('brand.recipe.edit.ingredients-edit'), {
          url:         '/ingredients/{ingredientId:int}',
          controller:  controller('RecipeIngredientsSaveCtrl', 'ivm'),
          templateUrl: templateUrl('recipe.save.ingredients-save'),
        });

      angular.injector(['ng']).get('$log')
        .debug(`[${moduleName}] Module configured`);
    })
    .run(function ($log) {
      $log.debug(`[${moduleName}] Module launched`);
    });
}());


