'use strict';

angular.module('app.services')
    .factory('TrackUser', [
        '$resource',
        function ($resource) {
            var TrackUser = $resource('/api/trackUsers/:id', {id: '@id'});
            TrackUser.prototype.getSelectOption = function () {
                return [
                    this.last_name, ', ', this.first_name,
                    ' [', this.id, ']'
                ].join('');
            };

            return TrackUser;
        }
    ]);