'use strict';

angular.module('app.controllers')
    .controller('Hive2AssetUploadCtrl', function ($scope, $timeout, $upload, $notification, $state, regions) {
        $scope.regions    = regions;
        $scope.assetTypes = ['front_of_package', 'nutrition_label'];
        $scope.labels     = {
            front_of_package: 'Front Of Package',
            nutrition_label:  'Nutrition Label'
        };

        function reset() {
            $scope.asset = {
                upc:                  parseInt($state.params.upc) || null,
                region:               1,
                front_of_package:     null,
                nutrition_label:      null,
                ingredient_statement: null,
                rejectedFiles:        [],
                getFile:              function (name) {
                    return this[name] && this[name][0] || null;
                }
            };

            (function () {
                var assetsFormUnwatch = $scope.$watch('assetsForm', function assetsFormWatch() {
                    assetsFormUnwatch();
                    $scope.assetsForm.$setPristine();
                    if ($scope.asset.upc) {
                        $scope.assetsForm.upc.$setDirty();
                    }
                });
            }());

            $scope.thumbnails = {
                front_of_package:     null,
                nutrition_label:      null,
                ingredient_statement: null
            };
        }

        reset();

        $scope.generateThumbnail = function (fileName) {
            var file                    = $scope.asset.getFile(fileName);
            $scope.thumbnails[fileName] = null;

            if (file && file.type.indexOf('image') > -1) {
                $timeout(function () {
                    var fileReader = new FileReader();
                    fileReader.readAsDataURL(file);
                    fileReader.onload = function (e) {
                        $timeout(function () {
                            $scope.thumbnails[fileName] = e.target.result;
                        });
                    };
                });
            }
        };

        $scope.clearUpload = function (type) {
            $scope.asset[type]      = null;
            $scope.thumbnails[type] = null;
        };

        $scope.submit = function () {
            var validUpload           = $scope.assetsForm.upc.$valid &&
                $scope.asset.getFile('front_of_package') &&
                $scope.asset.getFile('nutrition_label'),
                formNames = [], files = [];

            $scope.assetTypes.forEach(function (type) {
                var file = $scope.asset.getFile(type);
                if (file) {
                    formNames.push(type);
                    files.push(file);
                }
            });

            if (validUpload) {
                $scope.submit.$busy = true;
                $upload
                    .upload({
                        url:              '/api/admin/hive2-asset-upload',
                        file:             files,
                        fileFormDataName: formNames,
                        fields:           {
                            upc:    $scope.asset.upc,
                            region: $scope.asset.region
                        }
                    })
                    .then(function () {
                        $notification.info('File uploaded successfully');
                        reset();
                    })
                    .catch(function (error) {
                        $notification.error('File upload failed');
                    })
                    .finally(function () {
                        $scope.submit.$busy = false;
                    });
            } else {
                $notification.error('Invalid form data');

                ['front_of_package', 'nutrition_label'].forEach(function (type) {
                    if (!$scope.asset.getFile(type)) {
                        $notification.error($scope.labels[type] + ' is required');
                    }
                });
            }
        };
    });
