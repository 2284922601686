'use strict';

angular.module('app.controllers')
    .controller('UpcCheckCtrl',
        function($scope, $interval, $upload, $http, UPCCheck) {
            /**
             * Helpers
             */
            $scope.reset = function() {
                $scope.uploadFinished = false;
                $scope.sourceUploadProcessing = false;
            };


            /**
             * CSV Upload
             */
            $scope.uploadCSV = function(files) {
                var checkUPC = function(s3_url) {
                        var profile_email = $scope.auth.profile? $scope.auth.profile.email:undefined;
                        var worker = new UPCCheck;

                        $scope.email = $scope.email? $scope.email:profile_email;

                        // Payload
                        worker.origin = 'hive2';
                        worker.username = $scope.auth.profile.name;
                        worker.email = $scope.email? $scope.email:profile_email;
                        worker.debug = 'false';
                        worker.s3_url = s3_url;

                        // Run Worker
                        worker.$save().then(function(test) {
                            $scope.taskID = test.id;
                            $scope.uploadFinished = true;
                        });
                };

                var sourceUploaded = function(data, status, headers, config) {
                    checkUPC(data.s3_url);
                    $scope.sourceUploadProcessing = true;
                };

                $scope.sourceUploadProcessing = false;
                $scope.sourceUploadError = undefined;
                if (files && files.length) {
                    $scope.sourceUploadProcessing = true;
                    for (var i = 0; i < files.length; i++) {
                        var file = files[i];

                        $upload.upload({
                            url: '/api/upc-check/upload/',
                            fields: {
                                'username': $scope.username
                            },
                            file: file
                        }).success(sourceUploaded);
                    }
                }
            };
            // END
        });
