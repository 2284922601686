(function () {
  'use strict';

  angular.module('app.controllers').controller('TasksStatsCtrl', TasksStatsCtrl);

  /*@ngInject*/
  function TasksStatsCtrl($scope, $state, $http, session, $filter, $notification, regions) {
    const vm = $scope.vm = this;

    vm.user = session.profile;

    vm.tasks = {
      types:                 [
        {title: 'Image Manipulation Tasks', attribute: 'image'},
        {title: 'Transcription Tasks', attribute: 'transcription'},
        {title: 'Conflict Resolution Tasks', attribute: 'conflict-resolution'}
      ],
      image:                 [
        {
          title:     'Select Best Photo: Front of Package',
          remaining: null,
          params:    {type: 4, file_group: 1},
          viewState: {name: 'app.tasks.perform.select-best', params: {file_group: 1}}
        },
        {
          title:     'Select Best Photo: Nutrition Label',
          remaining: null,
          params:    {type: 4, file_group: 2},
          viewState: {name: 'app.tasks.perform.select-best', params: {file_group: 2}}
        },
        {
          title:     'Rotate: Front of Package',
          remaining: null,
          params:    {type: 1, file_group: 1},
          viewState: {name: 'app.tasks.perform.rotate', params: {file_group: 1}}
        },
        {
          title:     'Rotate: Nutrition Label',
          remaining: null,
          params:    {type: 1, file_group: 2},
          viewState: {name: 'app.tasks.perform.rotate', params: {file_group: 2}}
        },
        {
          title:     'Crop: Front of Package',
          remaining: null,
          params:    {type: 2, file_group: 1},
          viewState: {name: 'app.tasks.perform.crop', params: {file_group: 1}}
        },
        {
          title:     'Crop: Nutrition Label',
          remaining: null,
          params:    {type: 2, file_group: 2},
          viewState: {name: 'app.tasks.perform.crop', params: {file_group: 2}}
        },
      ],
      transcription:         (function () {
        const tasks = [];

        [
          {file_group: 2, title: 'Nutrition Transcribe'},
          {file_group: 1, title: 'Front of Package Transcript', permission: 'tasks.transcription.fop'}
        ].forEach(taskDefinition => {
          regions.forEach(region => {
            tasks.push({
              title:      `${taskDefinition.title}: ${region.name}`,
              permission: taskDefinition.permission,
              remaining:  null,
              params:     {type: [3, 6], file_group: taskDefinition.file_group, region: region.id},
              viewState:  {
                name:   'app.tasks.perform.transcribe',
                params: {file_group: taskDefinition.file_group, region: region.id}
              }
            })
          });
        });

        return tasks;
      }()),
      'conflict-resolution': (function () {
        const tasks = [];

        [
          {file_group: 2, title: 'Conflict Resolution of Nutrition Transcribe'}
          // , {file_group: 1, title: 'Conflict Resolution of Front of Package Transcript'}
        ].forEach(taskDefinition => {
          regions.forEach(region => {
            tasks.push({
              title:     `${taskDefinition.title}: ${region.name}`,
              remaining: null,
              params:    {type: [5, 7], file_group: taskDefinition.file_group, region: region.id},
              viewState: {
                name:   'app.tasks.perform.conflict-resolution',
                params: {file_group: taskDefinition.file_group, region: region.id}
              }
            })
          });
        });

        return tasks;
      }()),
    };

    async function loadStats() {
      try {
        const {data: stats} = await $http.get('/api/tasks/stats');

        for (const group of vm.tasks.types.map(t => t.attribute)) {
          for (const task of vm.tasks[group]) {
            task.remaining = Object.values(stats)
              .filter(stat => {
                for (const attribute of Object.keys(task.params)) {
                  const referenceFilterValues = Array.isArray(task.params[attribute])
                    ? task.params[attribute]
                    : [task.params[attribute]];

                  const match = referenceFilterValues.includes(stat[attribute]);

                  if (!match) {
                    return false;
                  }
                }

                return true;
              })
              .reduce((total, stat) => total + stat.remaining, 0);
          }
        }
      } catch (e) {
        $notification.error('Failed to load ' + $filter('readError')(e.data || e));
      }
    }

    loadStats();
  }
}())
