(function (window, angular, undefined) {
    'use strict';

    var module = angular.module('confirm', ['ui.bootstrap']);

    module.service('confirm', function confirm($modal) {
        // @formatter:off
        var template = [
            '<div class="modal-header">',
                '<h3 class="modal-title">{{strings.title || "Confirm"}}</h3>',
            '</div>',
            '<div class="modal-body">{{strings.message || "Are you sure?"}}</div>',
            '<div class="modal-footer">',
                '<button class="btn btn-danger" ng-click="ok()">{{strings.yes || "Yes"}}</button>',
                '<button class="btn btn-default" ng-click="cancel()">{{strings.no || "No"}}</button>',
            '</div>',
        ].join('\n');
        // @formatter:on

        function confirm(strings, modalOptions) {
            modalOptions = angular.extend({
                animation:  true,
                template:   template,
                controller: 'ConfirmController',
                size:       'lg',
                resolve:    {
                    strings: function () {
                        if (angular.isString(strings)) {
                            return {message: strings};
                        }

                        if (angular.isObject(strings)) {
                            return strings;
                        }

                        return {};
                    }
                }
            }, modalOptions || {});

            return $modal.open(modalOptions).result;
        }

        confirm.isCancelled = (e) => e === 'cancel';

        return confirm;
    });

    module.controller('ConfirmController', function ($scope, $modalInstance, strings, $window) {
        $scope.strings = strings || {};

        let agreeOnEnter = function (e) {
            if (e.which === 13) {
                $scope.ok();
            }
        };

        angular.element($window).on('keypress', agreeOnEnter);

        $scope.ok = function () {
            angular.element($window).off('keypress', agreeOnEnter);
            $modalInstance.close($scope.comment);
        };

        $scope.cancel = function () {
            $modalInstance.dismiss('cancel');
        };
    });
})(window, window.angular);
