(function () {
  'use strict';

  const moduleName     = 'ingredient-learning',
        controllerName = 'ListGuessedPhrasesCtrl';

  const moduleFullName = `app.modules.${moduleName}`;

  angular.module(moduleFullName)
    .controller(`${moduleFullName}.${controllerName}`, ListGuessedPhrasesCtrl);

  /*@ngInject*/
  function ListGuessedPhrasesCtrl($log, $scope, $http, $notification, $filter, ngTableParams,
                                  $location, debounce, $modal, confirm, $state) {
    $log.debug(`[${moduleName}] Controller "${controllerName}" launched`);

    const vm = this;

    const debouncedReload = debounce(function () {
      vm.tableParams.reload();
    }, 1000);

    vm.removeResult = function (item, reloadAfter = Promise.resolve()) {
      _.pull(vm.data, item);
      reloadAfter.then(debouncedReload);
    };

    vm.ignorePhrase = (phrase) => {
      confirm(`Are you sure you want to ignore phrase "${phrase.phrase}"`).then(() => {
        const reloadAfter = $http.put(`/api/ingredient-learning/guessed-phrases/${phrase.id}/ignore`, {is_ignored: 1})
          .then(response => {
            $notification.success(response.data.message);
          })
          .catch(response => {
            $notification.error($filter('readError')(response.data));
          });

        vm.removeResult(phrase, reloadAfter);
      });
    };

    vm.hidePhrase = (phrase) => {
      confirm(`Are you sure you want to ${phrase.is_ignored ? 'restore' : 'hide'} phrase "${phrase.phrase}"`).then(() => {
        const reloadAfter = $http.put(`/api/ingredient-learning/guessed-phrases/${phrase.id}`, {is_ignored: !phrase.is_ignored})
          .then(response => {
            $notification.success(response.data.message);
          })
          .catch(response => {
            $notification.error($filter('readError')(response.data));
          });

        vm.removeResult(phrase, reloadAfter);
      });
    };

    vm.deletePhrase = (phrase) => {
      return $http.delete(`/api/ingredient-learning/guessed-phrases/${phrase.id}`)
        .then(response => {
          $notification.info(response.data.message);
        })
        .catch(response => {
          $notification.error(`Phrase not deleted: ` + $filter('readError')(response.data));
        });
    };

    vm.createIngredient = (phrase) => {
      confirm(`Are you sure you want to create new ingredient "${phrase.phrase}"`).then(() => {
        const reloadAfter = $http.post(`/api/ingredient-learning/ingredients`, {name: phrase.phrase})
          .then(response => {
            $notification.info(`
              Ingredient created: 
              <a target="_blank" href="${$state.href('app.ingredient-learning.edit-ingredient', {id: response.data.id})}">
                  [${response.data.id}] ${response.data.name}
              </a>
             `);
            return vm.deletePhrase(phrase);
          })
          .catch(response => {
            $notification.error(`Ingredient not created: ` + $filter('readError')(response.data));
          });

        vm.removeResult(phrase, reloadAfter);
      });
    };

    vm.assignPhraseToIngredient = (phrase) => {
      const modalInstance = $modal.open({
        size:        'md',
        animation:   false,
        templateUrl: 'modules/ingredient-learning/ingredient-learning.list-guessed-phrases.assign-phrase-to-ingredient.modal.html',
        controller:  function ($scope, $modalInstance, ingredient) {
          const vm = $scope.vm = this;

          vm.phrase            = phrase;
          vm.ingredientName    = '';
          vm.searchIngredients = (q) => $http.get('/api/ingredient-learning/ingredients', {params: {q}})
            .then(response => response.data.ingredients)
            .catch(() => []);


          vm.setIngredient = ingredient => {
            vm.ingredientName = ingredient.name;
            vm.phrase.tag_id  = ingredient.id;
          };

          vm.save = () => {
            vm.save.$busy = true;
            $http.post(`/api/ingredient-learning/ingredients/${vm.phrase.tag_id}/phrases`, {text: vm.phrase.phrase})
              .then(response => {
                $notification.success('Phrase saved');
                $modalInstance.close(vm.phrase);
              })
              .catch(response => {
                $notification.error($filter('readError')(response.data));
              })
              .finally(() => vm.save.$busy = false);
          };

          vm.close = () => {
            $modalInstance.dismiss();
          }
        },
        resolve:     {
          ingredient: () => vm.ingredient
        }
      });

      modalInstance.result.then(phrase => {
        vm.removeResult(phrase, vm.deletePhrase(phrase));
      });
    };

    vm.data = [];

    vm.search = {
      exact:      false,
      term:       $location.search().q || '',
      is_ignored: +$location.search().is_ignored || 0,
      perform:    function (exact = false) {
        this.exact = exact;
        vm.tableParams.page(1);
        vm.tableParams.reload();
      }
    };

    const defaultOrder = 'id.asc';
    $scope.params      = vm.tableParams = new ngTableParams(
      {
        page:    $location.search().page || 1,            // show first page
        count:   25,           // count per page
        sorting: (function () {
          let orderBy = $location.search().orderBy || defaultOrder;
          if (orderBy) {
            orderBy = orderBy.split('.');

            let result         = {};
            result[orderBy[0]] = orderBy[1];

            return result;
          }
        }())
      },
      {
        counts:  [],
        total:   0, // length of data
        getData: function ($defer, params) {
          $location.search('page', params.page() > 1 ? params.page() : null);

          $location.search('q', vm.search.term || null);
          $location.search('is_ignored', vm.search.is_ignored || null);

          let orderBy = null;
          if (_.keys(params.sorting()).length) {
            let [sortingAttribute, sortingDirection] = _.pairs(params.sorting())[0];
            orderBy                                  = `${sortingAttribute}.${sortingDirection}`;
          }

          $location.search('orderBy', orderBy === defaultOrder ? null : orderBy);

          $http.get('/api/ingredient-learning/guessed-phrases', {
              params: {
                limit:      params.count(),
                offset:     (params.page() - 1) * params.count(),
                q:          vm.search.term || undefined,
                is_ignored: vm.search.is_ignored,
                orderBy:    orderBy,
              }
            })
            .then(response => {
              params.total(response.data.total);
              $defer.resolve(vm.data = response.data.phrases);
            })
            .catch(response => {
              $notification.error(
                'Failed to load phrases: ' + $filter('readError')(response.data)
              );

              $defer.reject();
            });
        }
      }
    );
  }
}());
