(() => {
  'use strict';

  const moduleName = 'reports',
    controllerName = 'NonClientRestaurantUpdatesController';

  const moduleFullName = `app.modules.${moduleName}`;
  angular.module(moduleFullName)
    .controller(`${moduleFullName}.${controllerName}`, NonClientRestaurantUpdatesController);

  /*@ngInject*/
  function NonClientRestaurantUpdatesController($scope, $http, ngTableParams, $location) {
    (async () => {
      const vm = $scope.vm = this;

      vm.statusType = (await $http.get('/reports/restaurants/non-client/filter-status')).data;

      vm.search = {
        term: '',
        filter: {},
        perform: function () {
          const query = {};
          if (this.term) {
            query.search = this.term;
          }
          _.forEach(this.filter, function (value, key) {
            query['filter.' + key] = value;
          });
          $location.search(query);

          vm.tableParams.page(1);
          vm.tableParams.reload();
        },
      };

      const urlParams = $location.search();

      if (urlParams.hasOwnProperty('filter.status_type')) {
        urlParams['filter.status_type'] = +urlParams['filter.status_type'];
      }

      _.forEach(urlParams, function (value, key) {
        if (key === 'search') {
          vm.search.term = value;
        } else if (key.slice(0, 7) === 'filter.') {
          vm.search.filter[key.slice(7)] = value;
        }
      });

      let sorting = { recent_event_date: 'desc' };

      if (urlParams.sort) {
        let parts = urlParams.sort.split('.');
        sorting = {};
        sorting[parts[0]] = parts[1];
      }

      vm.tableParams = new ngTableParams(
        {
          page: urlParams.page || 1,
          count: 50,
          sorting: sorting
        },
        {
          counts: [],
          total: 0,
          getData: async ($defer, params) => {
            let sort;
            angular.forEach(params.sorting(), function (order, column) {
              sort = [column, order].join('.');
            });

            $location.search('sort', sort === 'recent_event_date.desc' ? null : sort);
            $location.search('page', +params.page() > 1 ? params.page() : null);

            const filter = {};
            _.forEach(vm.search.filter, function (value, key) {
              if (value === 'null') {
                filter[key] = null;
              } else if (value !== null) {
                filter[key] = value;
              }
            });

            const reqParams = {
              search: vm.search.term,
              orderBy: sort,
              filter: JSON.stringify(filter),
              limit: params.count(),
              offset: (params.page() - 1) * params.count(),
            }

            const response = await $http.get('/reports/restaurants/non-client', { params: reqParams });
            const { count, results } = response.data;

            params.total(count);
            $defer.resolve(results);
          }
        });
    })();
  }
})()
