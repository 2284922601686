'use strict';

angular.module('app.services')
    .factory('ironWorker', [
        '$resource',
        function ($resource) {
            return $resource('https://worker-aws-us-east-1.iron.io/2/projects/5425885f38ef2d000700008b/tasks/webhook', {
                oauth: 'Q-Q5FXJtZ5hanpTUJtbwchj51tk'
            }, {
                get: {
                    isArray: false
                },
                stopTask: {
                    url: 'https://worker-aws-us-east-1.iron.io/2/projects/5425885f38ef2d000700008b/tasks/:id/cancel',
                    method: 'POST',
                    params: {
                        id: '@id',
                        code_name: '@code_name'
                    },
                    isArray: false
                },
                getTask: {
                    url: 'https://worker-aws-us-east-1.iron.io/2/projects/5425885f38ef2d000700008b/tasks/:id',
                    params: {
                        id: '@id',
                        code_name: '@code_name'
                    },
                    isArray: false
                }
            });
        }
    ]);
