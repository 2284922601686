'use strict';

const moduleName     = 'reports',
      controllerName = 'MissingIngredientsCxhSourcedCtrl';

const moduleFullName = `app.modules.${moduleName}`;
angular.module(moduleFullName)
  .controller(`${moduleFullName}.${controllerName}`, MissingIngredientsCxhSourcedCtrl);

/*@ngInject*/
function MissingIngredientsCxhSourcedCtrl($scope, $http, $notification, ngTableParams, $filter, $location, debounce) {
  const vm   = $scope.vm = this;
  vm.results = [];

  const defaultOrderBy  = 'scans_30day.desc';
  const urlParams       = $location.search();
  const debouncedReload = debounce(() => vm.tableParams.reload(), 1000);

  vm.removeResult = upcId => {
    vm.results = vm.results.filter(result => result.upc !== upcId);
    debouncedReload();
  };

  vm.tableParams = new ngTableParams(
    {
      page:    +urlParams.page || 1,
      count:   50,
      sorting: Object.fromEntries([(urlParams.sort || defaultOrderBy).split('.')]),
      filter:  Object.fromEntries(
        Object.entries(urlParams)
          .map(([urlKey, value]) => {
            const [prefix, filterAttribute] = urlKey.split('.');

            return prefix === 'filter' ? [filterAttribute, value] : null;
          })
          .filter((v) => !!v)
      ),
    },
    {
      counts:  [],
      total:   0, // length of data
      getData: function ($defer, params) {
        let viewData = vm.results;

        $location.search('page', params.page() === 1 ? null : params.page());

        const [[sortAttribute, sortDirection]] = Object.entries(params.sorting());
        const sort                             = `${sortAttribute}.${sortDirection}`;
        $location.search('sort', sort === defaultOrderBy ? null : sort);


        const filterParams = params.filter();
        if (filterParams && Object.values(filterParams).length) {
          viewData = $filter('filter')(viewData, filterParams);

          Object.entries(filterParams).forEach(([filterAttribute, filterValue]) => {
            $location.search(`filter.${filterAttribute}`, filterValue);
          })
        }

        viewData = $filter('orderBy')(
          viewData,
          sortAttribute,
          sortDirection === 'desc'
        );

        params.total(viewData.length);
        viewData = viewData.slice(
          (params.page() - 1) * params.count(),
          params.page() * params.count()
        );
        $defer.resolve(viewData);
      }
    });

  vm.difficult = async record => {
    record.difficult_ing = 1;
    vm.removeResult(record.upc);
    try {
      await $http.post(`/api/reports/masterUpc/${record.upc}`, record);
      $notification.info(`${record.upc} marked difficult`);
    } catch (error) {
      $notification.error('failed to update');
    }
  };

  (async () => {
    const { data } = await $http.get('/reports/missing-ingredient-statements-cxh-sourced');
    vm.results     = data;
    debouncedReload();
  })();
}
