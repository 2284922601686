(function () {
  'use strict';

  angular.module('app.controllers')
    .controller('TagsManageCtrl', TagsManageCtrl);

  /*@ngInject*/
  function TagsManageCtrl(
    $scope, $state, tags, $http, $filter,
    $notification, $location, $timeout, confirm, suggest,
    nixTrackApiClient
  ) {
    const vm = $scope.vm = this;

    const manager = {
      url: '/api/tags/manage/',
      async save(tag) {
        const url = this.isTemporary(tag) ? this.url : (this.url + tag.id);

        if (tag.id.toString() === tag.parent_id.toString()) {
          return $notification.error("Tag id and parent id can't be equal");
        }

        if (this.isTemporary(tag)) {
          if (!(tag.name && tag.usda_ndb && tag.usda_seq)) { return; }

          const name           = tag.name.replace(/[.,!&;?/"*'_]/g, '');
          const {data: phrase} = (await $http.get('/api/phrases', {params: {q: name}}));

          if (phrase && phrase.tag_name !== tag.name) {
            try {
              await confirm(
                `Please confirm, by creating this tag,
                you will move the phrase "${phrase.text}" from the tag named "${phrase.tag_name}" to this newly created tag.
                Press OK to confirm, or cancel if this is not the intended result`
              );
            } catch (e) {
              return;
            }
          }
        }

        try {
          const {data: savedTag} = await $http.post(url, {
            name:      tag.name,
            parent_id: tag.parent_id,
            usda_ndb:  tag.usda_ndb,
            usda_seq:  tag.usda_seq,
          });

          tag.id         = savedTag.id;
          tag.created_at = savedTag.created_at;
          tag.updated_at = savedTag.updated_at;

          $notification.info(`Tag ${tag.name} saved`);
        } catch (e) {
          const {response: {data}} = e;

          const message = $filter('readError')(data);
          $notification.error(`Tag ${tag.name} not saved: ${message}`);
        }
      },
      async delete(tag) {
        if (!this.isTemporary(tag)) {
          try {
            $http.delete(this.url + tag.id);
            $notification.info(`Tag ${tag.name} marked deleted`);
          } catch (e) {
            const {response: {data}} = e;

            const message = $filter('readError')(data);
            $notification.error(`Tag ${tag.name} could not be deleted: ${message}`);
          }
        }
      },
      isTemporary(tag) {
        return !tag.id || tag.id.toString().substring(0, 4) === 'tmp#';
      }
    };

    Object.assign(vm, {
      tmpId:    0,
      tagsById: {},

      suggestUsda(tag, search) {
        const isExactUsdaNdb = search.trim().match(/\d+/);
        if (!isExactUsdaNdb) {
          return suggest.common_foods(search);
        }

        vm.setNdb(tag, +search);

        return [];
      },

      async loadUsda(tag, query) {
        try {
          const [food] = (await nixTrackApiClient.natural.nutrients(query)).data.foods;
          await vm.setNdb(tag, food.ndb_no);
        } catch (e) {
          $notification.error(`Could not load USDA#: ${e.message}`);
          tag.usda_ndb = null;
          tag.weights = [];
        }
      },

      async setNdb(tag, ndbNo) {
        tag.usda_ndb  = ndbNo;
        tag.weights   = (await $http.get(`/api/tags/ndb-weights/${tag.usda_ndb}`)).data;
      },

      newSubItem(scope) {
        let tag;
        let collection;

        if (scope) {
          tag = scope.$modelValue;
          scope.expand();
          collection = tag.children;
        } else {
          collection = vm.rootTags;
        }

        collection.unshift({
          $edit:     true,
          id:        `tmp#${this.tmpId++}`,
          parent_id: tag ? tag.id : 0,
          name:      '',
          children:  []
        });
      },

      async delete(scope) {
        const tag = scope.$modelValue;
        vm.tags.splice(vm.tags.indexOf(tag), 1);
        tag.deleted = 1;
        scope.remove();
        await manager.delete(tag);
      },

      suggestTag(term, except) {
        let suggestions;
        if (term.length < 3) { return [];}

        suggestions = $filter('filter')(tags, {name: term});

        suggestions.sort(function (a, b) {
          if (a.name === term) {
            return -1;
          }

          if (b.name === term) {
            return 1;
          }

          return a.full_name.length - b.full_name.length;
        });

        if (except) {
          suggestions = suggestions.filter(s => s !== except);
        }

        return suggestions.slice(0, 10);
      },

      async replace(what, withWhat) {
        try {
          const targetCollection = what.parent_id ? vm.tagsById[what.parent_id].children : vm.rootTags;
          const sourceCollection = withWhat.parent_id ? vm.tagsById[withWhat.parent_id].children : vm.rootTags;

          sourceCollection.splice(sourceCollection.indexOf(withWhat), 1);
          targetCollection.splice(targetCollection.indexOf(what), 1, withWhat);

          withWhat.parent_id = what.parent_id || 0;

          console.log(withWhat);

          await manager.save(withWhat);
        } catch (e) {
          $notification.error(`Could not move tag: ${e.message}`);
        }
      },

      async finalize(tag) {
        const existingTag = tags.find(t => t.name === tag.name);

        if (existingTag) {
          await vm.replace(tag, existingTag);
        } else {
          tag.$edit = false;
          await manager.save(tag);
        }
      },

      async moveTo(tag, parent) {
        const targetCollection = parent ? parent.children : vm.tags;
        const sourceCollection = tag.parent_id ? vm.tagsById[tag.parent_id].children : vm.tags;

        sourceCollection.splice(sourceCollection.indexOf(tag), 1);
        targetCollection.unshift(tag);

        tag.parent_id = parent ? parent.id : 0;

        await manager.save(tag);
      },

      navigate(tag, collapsed) {
        $location.search('tagId', collapsed ? null : tag.id);
      }
    });

    vm.treeOptions = {
      dropped(event) {
        const tag       = event.source.nodeScope.$modelValue;
        const newParent = event.dest.nodesScope.tag;
        tag.parent_id   = newParent ? newParent.id : 0;

        manager.save(tag);
        return true;
      }
    };

    vm.rootTags = tags.filter((tag) => !tag.parent_id);
    vm.tags     = $filter('orderBy')(tags, 'name');

    vm.tags.forEach((tag) => {
      tag.collapsed       = true;
      tag.children        = [];
      vm.tagsById[tag.id] = tag;
    });

    vm.tags.forEach((tag) => {
      if (tag.parent_id && !vm.tagsById[tag.parent_id]) {
        tag.parent_id = 0;
      }
      if (tag.parent_id) {
        tag.full_name = `${tag.parent_name} > ${tag.name}`;
        vm.tagsById[tag.parent_id].children.push(tag);
      } else {
        tag.full_name = tag.name;
      }
    });

    vm.activeTag = +$location.search().tagId;
    (function expand(id) {
      if (id) {
        vm.tagsById[id].collapsed = false;
        expand(vm.tagsById[id].parent_id);
      }
    })(vm.activeTag);
    $timeout(() => vm.activeTag && angular.element('body').scrollTo(`#tag-${vm.activeTag}`, {offset: -55}), 100);
  }
}())
