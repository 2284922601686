(function () {
  'use strict';

  const moduleName     = 'brand-recipes',
        controllerName = 'RecipeIngredientsSaveCtrl';

  const moduleFullName = `app.modules.${moduleName}`;

  angular.module(moduleFullName)
    .controller(`${moduleFullName}.${controllerName}`, RecipeIngredientsSaveCtrl);

  /*@ngInject*/
  function RecipeIngredientsSaveCtrl($log, $scope, $state, $http, $notification,
                                     $q, $filter, confirm, brand, utils,
                                     nixTrackApiClient, suggest) {
    $log.debug(`[${moduleName}] Controller "${controllerName}" launched`);

    const vm  = $scope.vm;
    const ivm = $scope.ivm = this;

    ivm.ingredientForm = null;
    ivm.recipeForm     = null;

    ivm.resetIngredient = function (type = 'manual') {
      ivm.ingredient = {
        type,
        meta: {}
      };

      ivm.natural.query = '';

      if (type === 'manual') {
        ivm.ingredient.full_nutrients = [];
      }
    };

    ivm.save = () => {
      ivm.ingredient.$save = true;

      if (!ivm.ingredient.$added && (!ivm.ingredient.id || !_.find(vm.recipe.ingredients, {id: ivm.ingredient.id}))) {
        vm.recipe.ingredients.push(ivm.ingredient);
        ivm.ingredient.$added = true;
      }
      vm.save()
        .then(() => $state.go(utils.state('brand.recipe.edit', $state.params)));
    };

    ivm.delete = () => {
      ivm.$busy  = true;
      ivm.$error = null;

      confirm('Are you sure you want to delete this ingredient?')
        .then(() => {
          _.remove(vm.recipe.ingredients, ivm.ingredient);
          return vm.save();
        })
        .then(() => {
          $notification.info('Ingredient Deleted');
          $state.go(utils.state('brand.recipe.edit.ingredients-list'), $state.params);
        })
        .catch(response => ivm.$error = $filter('readError')(response.data))
        .finally(() => ivm.$busy = false);
    };

    ivm.tabs = {
      init:   function () {
        ['manual', 'recipe', 'modifier'].forEach(tab => {
          ivm.tabs[tab] = {
            active:  ivm.ingredient.type === tab,
            disable: !!(ivm.ingredient.id && ivm.ingredient.type !== tab)
          };
        });
      },
      select: function (tab) {
        if (tab !== ivm.ingredient.type) {
          ivm.resetIngredient(tab);
        }
      }
    };

    ivm.mapFullNutrients = function () {
      if (ivm.ingredient.full_nutrients) {
        [208, 204, 606, 605, 601, 307, 205, 291, 269, 539, 203, 306, 646, 645].forEach(attr_id => {
          if (!_.find(ivm.ingredient.full_nutrients, {attr_id})) {
            ivm.ingredient.full_nutrients.push({attr_id, value: null});
          }
        });

        ivm.ingredient.full_nutrients.$map = {};
        ivm.ingredient.full_nutrients.forEach(i => ivm.ingredient.full_nutrients.$map[i.attr_id] = i);

        _.forEach(DvNutrient.$inheritors, (NutrientConstructor, NutrientConstructorName) => {
          const nutrient = new NutrientConstructor();
          nutrient.loadFromFullNutrients(ivm.ingredient.full_nutrients, true);

          // VitaminANutrient -> $vitaminA etc...
          const nutrientName = '$' + NutrientConstructorName.charAt(0).toLowerCase() +
            NutrientConstructorName.substr(1).replace('Nutrient', '');

          ivm.ingredient.full_nutrients[nutrientName] = nutrient;
        })
      }
    };

    ivm.natural = {
      query:                '',
      populateNutrients:    function () {
        if (!this.query) {
          return;
        }

        nixTrackApiClient.natural.nutrients(this.query)
          .success(response => {
            let food = response.foods[0];

            _.assign(
              ivm.ingredient,
              _.pick(food, ['serving_unit', 'serving_qty', 'serving_weight_grams', 'full_nutrients'])
            );

            ivm.ingredient.food_name            = food.food_name;
            ivm.ingredient.serving_weight_grams = food.serving_weight_grams;
            ivm.ingredient.meta                 = _.assign(food.metadata || {}, _.pick(food, ['photo', 'tags']));
            ivm.ingredient.meta.original_query  = this.query;
            ivm.ingredient.external_id          = _.get(food, 'tags.tag_id');

            $notification.info('Nutrient data has been populated from natural query');
          })
          .error(error => {
            $notification.error('Natural query failed: ' + JSON.stringify(error));
          });
      },
      suggestCommonPhrases: suggest.common_foods
    };

    ivm.dvNutrients = [
      {label: 'Vitamin A', attribute: '$vitaminA'},
      {label: 'Vitamin C', attribute: '$vitaminC'},
      {label: 'Vitamin D', attribute: '$vitaminD'},
      {label: 'Calcium', attribute: '$calcium'},
      {label: 'Iron', attribute: '$iron'},
    ];

    ivm.searchRecipeIngredient = (term) => {
      return $http.get(`/api/brand-recipes/brands/${brand.id}/recipes`, {params: {q: term, except: vm.recipe.id}})
        .then(response => response.data)
        .catch(response => {
          $notification.error('Could not search recipe: ' + $filter('readError')(response.data));

          return [];
        });
    };

    ivm.searchModifierIngredient = (term) => {
      return $http.get(`/api/brand-recipes/brands/${brand.id}/modifiers`, {params: {q: term}})
        .then(response => response.data)
        .catch(response => {
          $notification.error('Could not search recipe: ' + $filter('readError')(response.data));

          return [];
        });
    };

    ivm.loadRecipeIngredient = (id) => {
      return $http.get(`/api/brand-recipes/brands/${brand.id}/recipes/${id}`)
        .then(response => {
          ivm.ingredient.$external = response.data;
          return ivm.ingredient.$external;
        })
    };

    ivm.loadModifierIngredient = (id) => {
      return $http.get(`/api/brand-recipes/brands/${brand.id}/modifiers/${id}`)
        .then(response => {
          ivm.ingredient.$external = response.data;
          return ivm.ingredient.$external;
        })
    };

    ivm.selectRecipeIngredient = (recipe) => {
      return ivm.loadRecipeIngredient(recipe.id)
        .then(recipe => {
          ivm.ingredient.external_id = recipe.id;
          ivm.ingredient.food_name   = recipe.name;
          _.extend(ivm.ingredient, _.pick(recipe, ['serving_qty', 'serving_unit', 'serving_weight_grams', 'full_nutrients']));
        })
        .catch(response => {
          $notification.error('Could not load recipe: ' + $filter('readError')(response.data));
        });
    };

    ivm.selectModifierIngredient = (modifier) => {
      _.extend(ivm.ingredient, modifier);
      ivm.ingredient.$external = modifier;
    };

    ivm.recalculateExternalIngredient = () => {
      ivm.ingredient.full_nutrients = _.cloneDeep(ivm.ingredient.$external.full_nutrients);

      const factor = ivm.ingredient.serving_weight_grams / ivm.ingredient.$external.serving_weight_grams;
      if (factor !== 1) {
        ivm.ingredient.full_nutrients.forEach(n => n.value && (n.value *= factor));
      }
    };

    if ($state.params.ingredientId) {
      ivm.ingredient = _.find(vm.recipe.ingredients, {id: $state.params.ingredientId});

      if (!ivm.ingredient) {
        $state.go(utils.state('brand.recipe.edit.ingredients-create'), $state.params);
        return;
      }

      ivm.natural.query = ivm.ingredient.meta.original_query || '';

      if (ivm.ingredient.type === 'recipe') {
        ivm.loadRecipeIngredient(ivm.ingredient.external_id);
      } else if (ivm.ingredient.type === 'modifier') {
        ivm.loadModifierIngredient(ivm.ingredient.external_id);
      }
    } else {
      ivm.resetIngredient();
    }

    ivm.tabs.init();

    $scope.$watchCollection('ivm.ingredient.full_nutrients', () => ivm.mapFullNutrients());
  }

  class DvNutrient {
    constructor(settings) {
      this.value      = null;
      this.unit       = 'mg';
      this.nutrientId = null;

      this.dailyValue           = 0;
      this.dailyValuePercentage = null;

      this.iu            = null;
      this.iuCoefficient = null;
      this.iuNutrientId  = null;

      this.fullNutrients = null;

      this.$viewValue   = this.value;
      this.$currentView = 'value';

      _.extend(
        this,
        settings
      );

      this.syncViewValue();
    }

    syncDV() {
      if (this.value === null) {
        this.dailyValuePercentage = null;
      } else {
        this.dailyValuePercentage = _.round(this.value / this.dailyValue * 100, 2);
      }
    }

    syncFromDV() {
      if (this.dailyValuePercentage === null) {
        this.value = null;
      } else {
        this.value = _.round(this.dailyValuePercentage / 100 * this.dailyValue, 2);
      }

      this.syncIU();
    }

    syncIU() {
      if (this.iuCoefficient) {
        if (this.value === null) {
          this.iu = null;
        } else {
          this.iu = _.round(this.value / this.iuCoefficient, 2);
        }
      }
    }

    syncFromIU() {
      if (this.iuCoefficient) {
        if (this.iu === null) {
          this.value = null;
        } else {
          this.value = this.iu * this.iuCoefficient;
        }
      }

      this.syncDV();
    }

    syncViewValue() {
      switch (this.$currentView) {
        case "value":
          this.$viewValue = this.value;
          break;
        case "dv":
          this.$viewValue = this.dailyValuePercentage;
          break;
        case "iu":
          this.$viewValue = this.iu;
          break;
      }
    }

    syncFromViewValue() {
      switch (this.$currentView) {
        case "value":
          this.setValue(this.$viewValue);
          break;
        case "dv":
          this.setDV(this.$viewValue);
          break;
        case "iu":
          this.setIU(this.$viewValue);
          break;
      }
    }

    setValue(value) {
      this.value = value;
      this.syncDV();
      this.syncIU();

      this.syncViewValue();
      this.syncFullNutrients();
    }

    setDV(value) {
      this.dailyValuePercentage = value;
      this.syncFromDV();
      this.syncIU();

      this.syncViewValue();
      this.syncFullNutrients();
    }

    setIU(value) {
      this.iu = value;
      this.syncFromIU();
      this.syncDV();

      this.syncViewValue();
      this.syncFullNutrients();
    }

    loadFromFullNutrients(fullNutrients, bind = false) {
      if (bind) {
        this.fullNutrients = fullNutrients;
      }

      let nutrient = _.find(fullNutrients, {attr_id: this.nutrientId});
      if (nutrient) {
        this.setValue(nutrient.value);
        return true;
      }
      nutrient = _.find(fullNutrients, {attr_id: this.iuNutrientId});
      if (nutrient) {
        this.setIU(nutrient.value);
        return true;
      }

      return false;
    }

    syncFullNutrients() {
      if (this.fullNutrients) {
        let nutrient = _.find(this.fullNutrients, {attr_id: this.nutrientId});
        if (!nutrient) {
          nutrient = {attr_id: this.nutrientId, value: null};
          this.fullNutrients.push(nutrient);
        }

        nutrient.value = this.value;

        if (this.iuNutrientId) {
          nutrient = _.find(this.fullNutrients, {attr_id: this.iuNutrientId});
          if (!nutrient) {
            nutrient = {attr_id: this.iuNutrientId, value: null};
            this.fullNutrients.push(nutrient);
          }

          nutrient.value = this.iu;
        }
      }
    }
  }

  class VitaminANutrient extends DvNutrient {
    constructor(settings = {}) {
      super(_.defaults(
        settings,
        {
          nutrientId:    320,
          unit:          'µg',
          dailyValue:    1500,
          iuNutrientId:  318,
          iuCoefficient: 0.3
        }
      ));
    }
  }

  class VitaminCNutrient extends DvNutrient {
    constructor(settings = {}) {
      super(_.defaults(
        settings,
        {
          nutrientId: 401,
          unit:       'mg',
          dailyValue: 60
        }
      ));
    }
  }

  class VitaminDNutrient extends DvNutrient {
    constructor(settings = {}) {
      super(_.defaults(
        settings,
        {
          nutrientId:    328,
          unit:          'µg',
          dailyValue:    20,
          iuNutrientId:  324,
          iuCoefficient: 0.025
        }
      ));
    }
  }

  class CalciumNutrient extends DvNutrient {
    constructor(settings = {}) {
      super(_.defaults(
        settings,
        {
          nutrientId: 301,
          unit:       'mg',
          dailyValue: 1300
        }
      ));
    }
  }

  class IronNutrient extends DvNutrient {
    constructor(settings = {}) {
      super(_.defaults(
        settings,
        {
          nutrientId: 303,
          unit:       'mg',
          dailyValue: 18
        }
      ));
    }
  }

  DvNutrient.$inheritors = {
    VitaminANutrient,
    VitaminCNutrient,
    VitaminDNutrient,
    CalciumNutrient,
    IronNutrient,
  }

}());
