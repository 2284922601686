angular.module('google-identity', [])
  .provider('GoogleIdentity', function GoogleIdentityProvider() {
    const options = {
      client_id: null,
      callback:  null,
    };

    /**
     * Sets the client id.
     * @param {string} clientId the client id
     * @returns {this} a chainable reference
     */
    this.setClientId = function setClientId(clientId) {
      options.client_id = clientId;
      return this;
    };

    this.$get = function GoogleIdentityServiceFactory($q, $document, $window) {
      let scriptTagLoadedPromise;
      let initializedPromise;

      let identityCallback = () => {};

      return {
        async getLib() {
          await this.load();
          return $window.google.accounts.id;
        },
        load() {
          if (!scriptTagLoadedPromise) {
            scriptTagLoadedPromise = $q((resolve) => {
              const scriptElement  = $document[0].createElement('script');
              scriptElement.type   = 'text/javascript';
              scriptElement.async  = true;
              scriptElement.src    = 'https://accounts.google.com/gsi/client';
              scriptElement.onload = scriptElement.onreadystatechange = function () {
                if ((!this.readyState || this.readyState === 'loaded' || this.readyState === 'complete')) {
                  resolve();
                }
              };

              const s = document.getElementsByTagName('script')[0];
              s.parentNode.insertBefore(scriptElement, s);
            });
          }

          return scriptTagLoadedPromise;
        },

        initialize() {
          if (!initializedPromise) {
            initializedPromise = (async () => {
              options.callback = (response) => this.handleCredentialResponse(response);
              (await this.getLib()).initialize(options);
            })()
          }

          return initializedPromise;
        },

        async renderButton(element) {
          await this.initialize();
          (await this.getLib()).renderButton(
            element,
            {theme: "outline", size: "large"}  // customization attributes
          );
        },

        handleCredentialResponse(response) {
          identityCallback(response.credential);
        },

        registerIdentityCallback(fn) {
          if (typeof fn === 'function') {
            identityCallback = fn;
          }
        }
      }
    }
  })
  .directive('loginButton', function LoginButtonFactory(GoogleIdentity) {
    return {
      template: '<div></div>',
      replace:  true,
      async link(scope, elm) {
        await GoogleIdentity.renderButton(elm[0]);
      }
    }
  })
