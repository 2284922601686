'use strict';

const moduleName     = 'reports',
      controllerName = 'TagsWithoutFoodGroupCtrl';

const moduleFullName = `app.modules.${moduleName}`;

angular.module(moduleFullName)
  .controller(`${moduleFullName}.${controllerName}`, TagsWithoutFoodGroupCtrl);


/*@ngInject*/
function TagsWithoutFoodGroupCtrl($scope, $http, $notification, commenter, ngTableParams, $location, debounce, $q) {
  const vm = $scope.vm = this;

  vm.addNotes = function (tag) {
    const dialog = commenter.open(tag.notes);
    dialog.result.then(function (notes) {
      tag.notes = notes;
    });

    dialog.submit('nutritionix-api.tags', 'id', tag.id);
  };

  const debouncedReload = debounce(() => vm.tableParams.reload(), 1000);

  vm.foodGroups = [
    {id: 1, label: 'Dairy'},
    {id: 2, label: 'Protein'},
    {id: 3, label: 'Fruit'},
    {id: 4, label: 'Vegetable'},
    {id: 5, label: 'Grain'},
    {id: 6, label: 'Fat'},
    {id: 7, label: 'Legume'},
    {id: 8, label: 'Combination'}
  ];

  vm.foodGroups = _.sortBy(vm.foodGroups, 'label');
  vm.foodGroups.unshift({id: 9, label: '*Not Applicable*'});

  vm.setTagFoodGroup = tag => {
    _.pull(vm.results, tag);
    $http.post(`/api/tags/${tag.id}`, {food_group: tag.food_group})
      .then(() => {
        debouncedReload();
      })
      .catch(() => $notification.info('Tag group updated'));
  };

  vm.results = [];

  // concurrency prevention
  let getDataPromise = $q.resolve();

  vm.tableParams = new ngTableParams(
    {
      page:  $location.search().page || 1,            // show first page
      count: 25,           // count per page
      // sorting: (function () {
      //   let sort = $location.search().sort;
      //   if (sort) {
      //     let parts      = sort.split('.');
      //     sort           = {};
      //     sort[parts[0]] = parts[1];
      //
      //     return sort;
      //   }
      //
      //   return {count: 'desc'};
      // }())
    },
    {
      counts:  [],
      total:   0, // length of data
      getData: function ($defer, params) {
        $location.search('page', params.page() === 1 ? null : params.page());

        // const sort = _.pairs(params.sorting())[0].join('.');
        // $location.search('sort', sort === 'count.desc' ? null : sort);

        getDataPromise = getDataPromise.then(
          () => $http.get('/reports/tags-without-food-group',
            {
              params: {
                limit:  params.count(),
                offset: (params.page() - 1) * params.count(),
                // orderBy: sort
              }
            }
          ).then(function (response) {
              const results = response.data;

              _.clone(vm.results).forEach(result => {
                if (!_.find(results, {id: result.id})) {
                  _.pull(vm.results, result);
                }
              });

              results.forEach(result => {
                if (!_.find(vm.results, {id: result.id})) {
                  vm.results.push(result);
                }
              });

              params.total(
                (params.page() - 1) * params.count() +
                vm.results.length +
                (vm.results.length < params.count() ? 0 : params.count())
              );
              $defer.resolve(vm.results);
            }
          ).catch(() => $notification.error('Failed to load the report'))
        );
      }
    }
  );
}
