'use strict';

angular.module('app.controllers')
    .controller('spotCheckCtrl',
      function ($scope, $http, spotCheck, ngTableParams, $location) {
            // var vm = $scope.vm = this;
            $scope.user_id = $scope.auth.profile.id;
            $scope.filter_by = 'all';

            $scope.search = {
                term:    $location.search().q || '',
                filter: {
                    type: $location.search().type || ''
                },
                perform: function () {
                    $scope.tableParams.page(1)
                    $scope.tableParams.reload()
                }
            };

            $scope.tableParams = new ngTableParams(
                {
                    page:    1,            // show first page
                    count:   25,           // count per page
                    sorting: (() => {
                        if ($location.search().sort) {
                            let parts = $location.search().sort.split('.');
                            let sort = {};

                            sort[parts[0]] = parts[1];

                            return sort;
                        }

                        return {name: 'asc'};
                    })()
                },
                {
                    counts:  [],
                    total:   0, // length of data
                    getData: function ($defer, params) {
                        $location.search('q', $scope.search.term || undefined);
                        $location.search('type', $scope.search.filter.type || undefined);

                        let sort = _.pairs(params.sorting())[0].join('.');

                        $location.search('sort', sort !== 'name.asc' ? sort : undefined);

                        // Fetch Data
                        spotCheck.list(
                            {
                                limit:   params.count(),
                                offset:  (params.page() - 1) * params.count(),
                                search:  $scope.search.term,
                                // orderBy: sort,
                                // filter:  $scope.search.filter
                                user_id: $scope.user_id,
                                filter_by: $scope.filter_by
                            }
                        )
                        .then(
                            function (data) {
                                data.results.forEach(function (item) {
                                    // Calculate day after last spot check
                                    if (item.last_spot_checked_at != null) {
                                        item.last_spot_checked_at_str = Math.ceil(Math.abs(new Date(item.last_spot_checked_at) - new Date()) / (1000 * 60 * 60 * 24)) + ' Days Ago';
                                    } else {
                                        item.last_spot_checked_at_str = 'Never';
                                    }

                                    // Set Status
                                    if (!item.spot_checked_at || item.is_pending === 1) {
                                        item.status = 'Pending';
                                    } else if (item.spot_checked_at && item.pass === 0 && item.is_pending !== 1) {
                                        item.status = 'Fail';
                                    } else if (item.spot_checked_at && item.pass === 1 && item.is_pending !== 1) {
                                        item.status = 'Pass';
                                    } else {
                                        item.status = '';
                                    }
                                });

                                params.total(data.count);
                                $defer.resolve(data.results);
                            }
                        );
                    }
                }
            );
        })
    .controller('spotCheckFailModalCtrl',
      function ($scope, $modalInstance, submit, brandName) {
        $scope.brandName = brandName;

        $scope.ok = function () {
          $modalInstance.close()
          submit(false)
        }

        $scope.cancel = function () {
          $modalInstance.dismiss('cancel')
        }
      })
    .controller('canNotCompleteModalCtrl',
      function ($scope, $modalInstance, brand, $http, $notification) {
        const { brand_id } = brand;
        $scope.selectedOption = null;
        $scope.incompletionReasons = {
          nutritionDataNotAvailable: 'NUTRITION_DATA_UNAVAILABLE',
          other: 'OTHER',
        };
        $scope.notes = { value: '' };

        $scope.save = async () => {
          const payload = {
            brand_id,
            incompletionReason: $scope.selectedOption,
            notes: $scope.notes.value
          };

          await $http.post(`/api/brands/${brand_id}/events`, payload);
          $notification.success(`Spot check incompletion saved successfully.`);
          $modalInstance.close();
        }

        $scope.cancel = () => {
          $modalInstance.dismiss('cancel')
        }
      })
    .controller('spotCheckViewCtrl',
      function ($scope, $modal, $http, $state) {
            /**
             * Modal settings for `Mark as Fail` option
             */
            $scope.open = function (size) {
              var modalInstance = $modal.open({
                animation: false,
                templateUrl: 'myModalContent.html',
                controller: 'spotCheckFailModalCtrl',
                size: size,
                resolve: {
                  submit: function () {
                    return $scope.submit
                  },
                  brandName: function () {
                    return $scope.task.brand_name
                  }
                }
              })

              modalInstance.result.then(function (selectedItem) {
                $scope.selected = selectedItem
              }, function () {
                // $log.info('Modal dismissed at: ' + new Date());
              })
            }

            $scope.openModal = function (size) {
              $modal.open({
                animation: false,
                templateUrl: 'canNotCompleteModalContent.html',
                controller: 'canNotCompleteModalCtrl',
                size: size,
                resolve: {
                  brand: function () {
                    return $scope.task;
                  }
                }
              });
            }
            // End

            $scope.spot_check_id = $state.params.id;
            $scope.user_id = $scope.auth.profile.id;
            $scope.user_name = $scope.auth.profile.name;
            $scope.menu_items_buffer = '';
            $scope.is_submitted = false;

            // Reset nf_calories and nf_sodium when market as unable to locate
            $scope.unableToLocate = function (menuItemIndex) {
              $scope.task.entered_menu_items[menuItemIndex].nf_calories=undefined;
              $scope.task.entered_menu_items[menuItemIndex].nf_sodium=undefined;
            };

            function populateForm () {
              $http({
                  method: 'GET',
                  url: '/api/spot-checks/' + $scope.spot_check_id,
                  params: {'user_id': $scope.user_id}
              }).then(function successCallback (response) {
                  $scope.task = response.data[0][0];
                  $scope.task.user_id=$scope.user_id;
                  $scope.task.user_name = $scope.user_name;

                  // Parse JSON strings
                  $scope.task['entered_menu_items'] = JSON.parse($scope.task.entered_menu_items);
                  $scope.task['random_menu_items'] = JSON.parse($scope.task.random_menu_items);
              }, function errorCallback (response) {
                  // called asynchronously if an error occurs
                  // or server returns response with an error status.
                  console.log('error: ', response);
              });
            }
            populateForm();

            $scope.updateStep1 = function (trigger) {
              if ($scope.task.all_menu_items_exists == 'na') {
                $scope.menu_items_buffer = $scope.task.new_menu_items;
                $scope.task.new_menu_items = '';

                $scope.task.cant_locale_new_menu_items = 1;
              }

              else {
                if ($scope.menu_items_buffer) {
                  $scope.task.new_menu_items = $scope.menu_items_buffer;
                }
                $scope.task.cant_locale_new_menu_items = 0;
              }
            };

            $scope.updateStep2 = function (trigger) {
              if ($scope.task.cant_locale_new_menu_items == 1) {
                $scope.menu_items_buffer = $scope.task.new_menu_items;
                $scope.task.new_menu_items = '';

                $scope.task.all_menu_items_exists = 'na';
              }

              else {
                $scope.task.new_menu_items = $scope.menu_items_buffer;
                $scope.task.all_menu_items_exists = undefined;
              }
            };

            // Submit
            $scope.submit = function (pass) {
              if (pass === true) {
                $scope.task.pass = true;
              } else if (pass === false) {
                $scope.task.pass = false;
              }

              if ($scope.task.pass === false || $scope.spotCheckForm.$valid) {
                $scope.task.user_id = $scope.auth.profile.id;

                $http({
                    method: 'POST',
                    url: '/api/spot-checks/' + $scope.spot_check_id,
                    data: $scope.task
                }).then(function successCallback (response) {
                    $scope.is_submitted = true;
                }, function errorCallback (response) {
                    // called asynchronously if an error occurs
                    // or server returns response with an error status.
                    console.log('error: ', response);
                });
              }
            }
        });
