'use strict';

angular.module('app.controllers')
    .controller('NutrDefCtrl', function ($scope, $http, $notification) {
        $http.get('/api/nutr-def/list')
            .success(function (nutrDef) {
                $scope.nutrDef = nutrDef;
            })
            .catch(function () {
                $notification.error("Loading of nutrition definitions failed");
            });

        $scope.save = function save(definition) {
            definition.$busy = true;
            $http.post('/api/nutr-def/' + definition.id, definition)
                .success(function (savedDefintion) {
                    definition.aliases = savedDefintion.aliases;
                    $notification.info("Saved");
                })
                .catch(function (response) {
                    if(response.status === 409){
                        $notification.error(response.data.errors[0].message);
                    } else {
                        $notification.error("Saving failed");
                    }
                })
                .finally(function(){
                    definition.$busy = false;
                });
        };
    }
);
