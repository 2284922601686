(function () {
  'use strict';

  angular.module('app.controllers')
    .controller('TasksTranscribeCtrl', TasksTranscribeCtrl)
    .directive('validateTranscriptionBrand', validateTranscriptionBrandDirectiveFactory);

  /*@ngInject*/
  function TasksTranscribeCtrl($scope, $state, $http, $notification, suggest, $q, flagTask, cdnify, gs1, $filter, $timeout) {
    async function init() {
      $scope.transcription = null;
      $scope.namePattern = /^[a-zA-Z0-9 #&(),!%"'|$./-]+$/;

      $scope.image = {src: null};

      $scope.resetTranscription = function () {
        $scope.transcription = {};

        if (+$state.params.file_group === 2) {
          $scope.transcription.metric_measure_unit = 'g';

          if ($scope.vm.currentTask.region === 1) {
            $scope.transcription.labelType = '2018';
          }

          if ($scope.vm.currentTask.region === 2) {
            $scope.transcription.$energy_term = 'energy';
          } else {
            $scope.transcription.servings_per_container = null;
          }

          if ($scope.vm.currentTask.region === 3) {
            $scope.transcription.$energy_term   = 'energy';
            $scope.transcription.salt_term      = 'sodium';
            $scope.transcription.salt_term_unit = 'mg';
          }
        }
      };

      const task = await $scope.init($state.params.task_type, {
        file_group: $state.params.file_group,
        region:     $state.params.region
      });

      if (task) {
        $scope.resetTranscription();

        let src;

        if (task.transcription) {
          _.extend($scope.transcription, task.transcription);
        }

        if (task.type === 3) {
          let bestPhotoCropped = _.get(task, 'meta.best_photo_cropped');

          if (bestPhotoCropped) {
            src = bestPhotoCropped;
          } else {
            src = (await $http.get('/api/tasks/transcribe', {
              params: {
                file_group: $state.params.file_group,
                upc:        task.upc
              }
            })).data;
          }
        } else if (task.type === 6) {
          src = task.custom_project_image_url;
        }

        $scope.vm.debugInfo.push({
          name:   'Current Image',
          href:   cdnify(src),
          target: '_blank'
        });
        $scope.vm.debugInfo.push({
          name:   'Original Image URL',
          href:   src,
          target: '_blank'
        });

        $scope.image.src = cdnify(src);
      }
    }

    $scope.suggest = suggest;

    $scope.submit = function (flag) {
      $scope.submit.$busy = flag ? 'flagging' : 'submitting';
      let flow;

      if (flag) {
        flow = flagTask($scope.vm.currentTask)
          .then(init);
      } else {
        let params = {
          task_id:       $scope.vm.currentTask.id,
          transcription: $scope.transcription,
        };

        flow = $http.post('/api/tasks/transcribe', params)
          .then(() => $notification.info('Saved'))
          .catch(() => $notification.error('Not saved'))
          .finally(init);
      }

      flow.finally(() => {
        $scope.submit.$busy = null;
        $scope.tagManager.clear();
      });
    };

    $scope.changeTaskRegion = () => {
      $http.post('/api/tasks/change-region/' + $scope.vm.currentTask.id, {
        region: $scope.vm.currentTask.region
      })
        .then(() => {
          $notification.info('Region changed');
          $scope.resetTranscription();
        })
        .catch(() => {
          $notification.info('Region change failed');
        });
    };


    $scope.toggleEnergyTerm = () => {
      delete $scope.transcription[$scope.transcription.$energy_term === 'energy' ? 'calories' : 'energy'];
    };

    $scope.changeTaskType = () => {
      const attributes = [];
      if ($scope.transcription.labelType === '2018') {
        attributes.push(
          'fat_calories',
          'vitamin_a',
          'vitamin_c',
          'nf_vitamin_d_dv',
          'calcium',
          'iron',
          'potassium',
        )
      } else {
        attributes.push(
          'nf_added_sugars',
          'nf_vitamin_d_unit',
          'nf_vitamin_d_value',
          'nf_calcium_unit',
          'nf_calcium_value',
          'nf_iron_unit',
          'nf_iron_value',
          'nf_potassium_unit',
          'nf_potassium_value',
        )
      }

      for (const attribute of attributes) {
        delete $scope.transcription[attribute];
      }
    }

    init();
  }

  /*@ngInject*/
  function validateTranscriptionBrandDirectiveFactory($http) {
    return {
      scope:   {transcription: '=validateTranscriptionBrand'},
      require: 'ngModel',
      link:    function (scope, elm, attrs, ngModel) {
        ngModel.$viewChangeListeners.push(async () => {
          const value = ngModel.$modelValue || ngModel.$viewValue;

          if (value && value.length > 2) {
            const {data} = await $http.get('/api/suggest/brands', {
              params: {
                q:     value,
                exact: 1,
              }
            });

            if (data.length && !scope.transcription.brand_id) {
              return ngModel.$setValidity('transcriptionBrand', false);
            }
          }

          ngModel.$setValidity('transcriptionBrand', true);
        });

        scope.$watch('transcription.brand_id', (id) => id && ngModel.$setValidity('transcriptionBrand', true));
      }
    };
  }
}())


